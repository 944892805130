import type { Hive } from '../ScopingEditor/useGetState';
import { SVGProps, memo } from 'react';
import { AutoTooltip } from '@/components';
import { HiveIcon, WarningIcon } from '@/components/Icons';
import { useZoom } from '../ZoomContext';
import { HiveConnectionHealthStatus } from '@/pages/DeviceManagementPage/HiveTable';
import { useAppSelector } from '@/redux/hooks';
import { selectIsHighlighted } from '../ScopingEditor/reducer';

export const HIVE_ICON_SIZE = 1.25;

function HiveItem({
  x = 0,
  y = 0,
  name,
  selected,
  id,
  locked,
  selecting = false,
  fade = false,
  onClick,
  serial_number,
  connectionHealth,
  ...rest
}: {
  selected: boolean;
  serial_number: string;
  locked?: boolean;
  selecting?: boolean;
  fade?: boolean;
  connectionHealth?: { status: HiveConnectionHealthStatus };
  id: string;
} & SVGProps<SVGGElement> &
  Pick<Hive, 'name'>) {
  const { adjustedZoom } = useZoom();
  const color =
    connectionHealth?.status === 'CONNECTED'
      ? '#10B981'
      : connectionHealth?.status === 'PARTIALLY_CONNECTED'
      ? '#EB860A'
      : connectionHealth?.status === 'NOT_CONNECTED'
      ? '#FF0000'
      : '#24252C';

  const isHighlighted = useAppSelector((state) => selectIsHighlighted(state, id));
  const showAsSelected = selected || selecting;
  // stroke is dashed for highlighted, non-selected items
  const outlineIsDashed = !selected && !selecting && isHighlighted;

  return (
    <g
      data-id="hive-item"
      id={id}
      onClick={onClick}
      transform={`translate(${x}, ${y})`}
      style={{ pointerEvents: locked ? 'none' : 'all', opacity: fade ? 0.5 : 1 }}
      data-highlighted={isHighlighted}
      data-selected={selected}
      {...rest}
    >
      <AutoTooltip
        label={
          (serial_number &&
            serial_number.length >= 6 &&
            serial_number.substring(serial_number.length - 6)) ||
          serial_number
        }
        variant="white"
        placement="top"
        keepOpenOnMouseOver
        forceOpen={selected || isHighlighted}
        autoUpdateOnAnimationFrame
      >
        {(props) => (
          <g transform="scale(1, -1)" {...props}>
            <rect
              x={-HIVE_ICON_SIZE / 2 / adjustedZoom}
              y={-HIVE_ICON_SIZE / 2 / adjustedZoom}
              width={HIVE_ICON_SIZE / adjustedZoom}
              height={HIVE_ICON_SIZE / adjustedZoom}
              stroke={color}
              strokeWidth={(showAsSelected ? 0.1 : 0.05) / adjustedZoom}
              strokeOpacity={0.8}
              strokeDasharray={outlineIsDashed ? 0.05 : 0}
              fill="white"
              rx={HIVE_ICON_SIZE / 10 / adjustedZoom}
            />
            <HiveIcon
              className="pointer-events-none"
              color={color}
              height={HIVE_ICON_SIZE / 1.5 / adjustedZoom}
              width={HIVE_ICON_SIZE / 1.5 / adjustedZoom}
              x={-HIVE_ICON_SIZE / 1.5 / 2 / adjustedZoom}
              y={-HIVE_ICON_SIZE / 1.5 / 2 / adjustedZoom}
            />
            {!serial_number && (
              <WarningIcon
                data-id="hive-warning"
                aria-label="Hive is missing serial number"
                x={0.2}
                y={0.2}
                width={1}
                height={1}
                color="#EB860A"
              />
            )}
          </g>
        )}
      </AutoTooltip>
    </g>
  );
}

export default memo(HiveItem);
