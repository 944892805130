import { MutableRefObject, useCallback, useEffect } from 'react';
import { TCommonMouseRelaseEvent } from '@/types';

/**
 * Hooks for detecting outside mouse relase
 * @param ref
 * @param handler
 * @param mouseEvent
 */
export const useOnClickOutside = (
  ref: MutableRefObject<HTMLElement | null>,
  handler?: () => void,
  mouseEvent: TCommonMouseRelaseEvent = 'mousedown',
): void => {
  const handleMouseEvent = useCallback(
    (e: any) => {
      const el = ref?.current;
      // Do nothing if clicking ref's element or descendent elements
      if (!el || el.contains(e.target as Node)) {
        return;
      }
      handler?.();
    },
    [handler, ref],
  );

  useEffect(() => {
    window.addEventListener(mouseEvent, handleMouseEvent);

    return () => {
      window.removeEventListener(mouseEvent, handleMouseEvent);
    };
  }, [handleMouseEvent, mouseEvent]);
};
