#define PIXEL_COUNT 64

varying vec4 vPos;
varying vec3 vNormal;
varying vec2 vUv;

uniform vec2 gridSize;
uniform float gridData[PIXEL_COUNT];

vec4 gradient(float w, vec2 uv) {
  w = pow(clamp(w, 0., 1.) * 3.14159 * .5, .9);
  vec3 c = vec3(sin(w), sin(w * 2.), cos(w)) * 1.1;
  return vec4(c, clamp(w * 5., 0., 1.));
}

void main() {
  vec2 pixelSize = vec2(1.0 / gridSize.x, 1.0 / gridSize.y);

  float d = 0.;
  for(int i = 0; i < int(gridSize.x); i ++) {
    for(int j = 0; j < int(gridSize.y); j ++) {
      vec2 pixelCenter = vec2(pixelSize.x * float(i), pixelSize.y * float(j)) + pixelSize * 0.5;
      int pixelId = i + j * int(gridSize.x);
      float intensity = gridData[pixelId] * 1.0;
      float pd = (1. - length(vUv - pixelCenter) / (2.0 / gridSize.x)) * intensity;

      d += pow(max(0., pd), 2.);
    }
  }

  gl_FragColor = gradient(d, vUv);
}
