import { useEffect } from 'react';
import { useAuthUser } from '.';
import { datadogRum } from '@datadog/browser-rum';

export function useThirdParty() {
  const { name, user, clientId } = useAuthUser();
  useEffect(() => {
    if (name && clientId) {
      window.pendo?.initialize({
        visitor: {
          email: user?.email ?? 'unknown',
          id: name,
        },
        account: {
          id: clientId,
        },
      });
    }
  }, [name, clientId, user?.email]);

  useEffect(() => {
    if (user) {
      datadogRum.setUser({
        email: user.email,
        name: user.nickname,
        id: user.name,
      });
    }
  }, [user]);
}
