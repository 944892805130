import { SearchDropdown } from '@/components/primitives/SearchDropdown';
import { useAppDispatch } from '@/redux/hooks';
import { useGetSuperuserClientsQuery } from '@/hooks/api/users';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { setSelectedClient } from '@/redux/reducers/superuser';

function SelectOrgDropdown() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { data: clients } = useGetSuperuserClientsQuery();

  // Organizations
  const searchOrgOptions: string[] = useMemo(() => {
    if (!clients) return [];

    return Object.keys(clients).sort((a, b) => a.localeCompare(b));
  }, [clients]);

  // Listen when searchbox of organization is changed (superuser dashboard)
  const handleSearchOrgChange = useCallback(
    (selected?: string) => {
      if (!clients) return;

      if (selected) {
        return dispatch(setSelectedClient({ [selected]: clients[selected] }));
      }

      return dispatch(setSelectedClient(null));
    },
    [clients, dispatch],
  );

  return (
    <SearchDropdown
      placeholder={t('selectOrganization')}
      options={searchOrgOptions}
      onChange={handleSearchOrgChange}
    />
  );
}

export default SelectOrgDropdown;
