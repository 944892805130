import type { ButtonColor, ButtonSize, ButtonVariant } from '@/components/primitives/button/Button';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

const colors: Record<ButtonColor, Record<ButtonVariant, string>> = {
  black: {
    solid: clsx(
      'bg-gray-900 border-gray-900 text-gray-50',
      'hover:bg-gray-700 hover:border-gray-700 hover:text-gray-50',
      'focus-visible:bg-gray-700 focus-visible:border-gray-700 focus-visible:text-gray-50',
      'active:bg-gray-800 active:border-gray-800 active:text-gray-50',
      'disabled:bg-gray-400 disabled:border-gray-400 disabled:text-gray-50',
    ),
    normal: clsx(
      'bg-gray-100 border-gray-100 text-gray-900',
      'hover:bg-gray-200 hover:border-gray-200 hover:text-gray-900',
      'focus-visible:bg-gray-200 focus-visible:border-gray-200 focus-visible:text-gray-900',
      'active:bg-gray-300 active:border-gray-300 active:text-gray-900',
      'disabled:bg-gray-50 disabled:border-gray-50 disabled:text-gray-500',
    ),
    outline: clsx(
      'bg-transparent border-gray-200 text-gray-900',
      'hover:border-gray-700 hover:text-gray-900',
      'focus-visible:bg-gray-100 focus-visible:border-gray-900 focus-visible:text-gray-900',
      'active:border-gray-900 active:text-gray-900',
      'disabled:bg-gray-50 disabled:border-gray-100 disabled:text-gray-500',
    ),
    ghost: clsx(
      'bg-transparent border-transparent text-gray-900',
      'hover:bg-gray-100 hover:border-gray-100 hover:text-gray-900',
      'focus-visible:bg-gray-50 focus-visible:text-gray-900',
      'active:bg-gray-200 active:text-gray-900',
      'disabled:bg-transparent disabled:text-gray-500',
    ),
  },
  red: {
    solid: clsx(
      'bg-red-500 border-red-500 text-gray-50',
      'hover:bg-red-300 hover:border-red-300 hover:text-gray-50',
      'focus-visible:bg-red-300 focus-visible:border-red-300 focus-visible:text-gray-50',
      'active:bg-red-400 active:border-red-400 active:text-gray-50',
      'disabled:bg-red-100 disabled:border-red-100 disabled:text-gray-50',
    ),
    normal: clsx(
      'bg-red-50 border-red-50 text-red-500',
      'hover:bg-red-100 hover:border-red-100 hover:text-red-500',
      'focus-visible:bg-red-100 focus-visible:border-red-100 focus-visible:text-red-500',
      'active:bg-red-200 active:border-red-200 active:text-red-500',
      'disabled:bg-red-50 disabled:border-red-50 disabled:text-red-200',
    ),
    outline: clsx(
      'bg-transparent border-red-200 text-red-500',
      'hover:border-red-400 hover:text-red-500',
      'focus-visible:bg-red-50 focus-visible:border-red-400 focus-visible:text-red-500',
      'active:border-red-500 active:text-red-500',
      'disabled:bg-red-50 disabled:border-red-100 disabled:text-red-200',
    ),
    ghost: clsx(
      'bg-transparent border-transparent text-red-500',
      'hover:bg-red-100 hover:border-red-100 hover:text-red-500',
      'focus-visible:bg-red-50 focus-visible:text-red-500',
      'active:bg-red-200 active:text-red-500',
      'disabled:bg-transparent disabled:text-red-200',
    ),
  },
};

// size directly affects the padding and font size of the button
const sizes: Record<ButtonSize, string> = {
  md: 'px-[14px] py-[5px] text-sm/[22px]',
  sm: 'px-3 py-1 text-xs/[20px]',
  xs: 'px-[10px] py-[3px] text-[10px]/[18px]',
};

/**
 * Returns a class name which can be applied to an element to make it look
 * and behave like a button. This is abstracted separately from the Button
 * component to make it easier to reuse these styles in other places, since
 * that's common for buttons.
 */
export function getButtonClassName({
  color,
  size,
  variant,
}: {
  color: ButtonColor;
  size: ButtonSize;
  variant: ButtonVariant;
}) {
  return twMerge(
    // some baseline styles for all button types
    'border border-solid relative select-none whitespace-nowrap rounded',
    'inline-flex flex-row gap-[6px] items-center justify-center',
    'not:disabled:cursor-pointer',
    // and baseline transitions
    'transition-colors duration-200 ease-in-out',
    // and the size
    sizes[size],
    // and the color
    colors[color][variant],
    // disable focus ring; it will only show on focus-visible
    'focus:outline-none',
  );
}
