import React, { FC, SVGProps } from 'react';

/** @deprecated use <Icon name="..." /> (import from @/components/primitives/icons/Icon) instead. */
const SvgComponent: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.007 9.827c1.548 0 2.862-1.287 2.862-2.953A2.868 2.868 0 0010.007 4a2.88 2.88 0 00-2.869 2.887c0 1.653 1.314 2.94 2.87 2.94zM5.577 16h8.847c1.171 0 1.576-.332 1.576-.943 0-1.705-2.315-4.055-6-4.055-3.678 0-6 2.35-6 4.055 0 .611.405.943 1.576.943z"
        fill="#9ca3af"
      />
    </svg>
  );
};

export default SvgComponent;
