export const COLORS = {
  white: '#FFFFFF',
  black: '#000000',
  gray50: '#F9F9FA',
  gray100: '#F4F4F6',
  gray200: '#E0E0E6',
  gray110: '#000028',
  gray400: '#A8AAB8',
  gray500: '#84869A',
  gray600: '#6A6D81',
  gray700: '#535565',
  gray800: '#3C3D49',
  gray900: '#24252c',
  orange50: '#FFF6EB',
  orange400: '#FB9E24',
  orange500: '#EB860A',
  red50: '#FFF0F0',
  red400: '#FE4343',
  red500: '#FF0000',
  purple300: '#C4B5FD',
  purple400: '#A78BFA',
};

export const SV_COLORS = {
  white: 0xffffff,
  black: 0x000000,
  gray50: 0xf9f9fa,
  gray100: 0xf4f4f6,
  gray200: 0xe0e0e6,
  gray110: 0x000028,
  gray400: 0xa8aab8,
  gray500: 0x84869a,
  gray600: 0x6a6d81,
  gray700: 0x535565,
  gray800: 0x3c3d49,
  gray900: 0x24252c,
  orange50: 0xfff6eb,
  orange400: 0xfb9e24,
  orange500: 0xeb860a,
  red50: 0xfff0f0,
  red400: 0xfe4343,
  red500: 0xff0000,
  purple300: 0xc4b5fd,
  purple400: 0xa78bfa,
};

export const SENSOR_COLORS = [
  '#9b5de5',
  '#fee440',
  '#00f5d4',
  '#ee4266',
  '#3bceac',
  '#f15bb5',
  '#00bbf9',
  '#540d6e',
  '#ffd23f',
  '#0ead69',
];
