import React, { FC, SVGProps } from 'react';

/** @deprecated use <Icon name="..." /> (import from @/components/primitives/icons/Icon) instead. */
const SvgComponent: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      aria-hidden="true"
      data-prefix="far"
      data-icon="logo"
      className="prefix__svg-inline--fa prefix__fa-logo prefix__fa-w-14"
      viewBox="0 0 64 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.0235 12.3495C16.0235 17.0114 12.6274 19.9444 9.01513 19.9444C7.53319 19.9444 5.61902 19.3578 4.56931 17.9685V19.5739H0V0H4.56931V6.73047C5.61902 5.37203 7.53319 4.78543 9.01513 4.78543C12.6274 4.78543 16.0235 7.68756 16.0235 12.3495ZM11.3924 12.3495C11.3924 10.5279 9.84872 9.01513 8.05804 9.01513C6.32911 9.01513 4.72368 10.4971 4.72368 12.3495C4.72368 14.2019 6.29824 15.7147 8.05804 15.7147C9.84872 15.7147 11.3924 14.171 11.3924 12.3495Z"
        fill="#24252C"
      />
      <path
        d="M43.7379 5.15591V8.61377H40.7123V19.5739H36.1429V8.61377H33.5187V5.15591H36.1429V0H40.7123V5.15591H43.7379Z"
        fill="#24252C"
      />
      <path d="M45.401 19.5739V0H49.9703V19.5739H45.401Z" fill="#24252C" />
      <path
        d="M63.9088 5.58824L62.8261 9.57095C62.204 9.1916 61.4861 8.99873 60.7576 9.01522C58.9669 9.01522 57.485 10.528 57.485 14.7886V19.574H52.9156V5.15601H57.4253V8.21251C58.2589 5.83523 59.9878 5.15601 61.7476 5.15601C62.4248 5.15601 63.1657 5.21775 63.9088 5.58824Z"
        fill="#24252C"
      />
      <path
        d="M22.4978 5.15601V11.2947C22.4978 13.7183 22.5729 15.6757 24.6764 15.6757C26.78 15.6757 26.7265 13.7204 26.7265 11.2947V5.15601H31.5469V13.4199C31.5469 14.5917 31.3874 15.6033 31.0683 16.4548C30.7596 17.2163 30.2255 17.9024 29.466 18.513C28.2105 19.5044 26.6139 20.0001 24.6764 20.0001C22.7389 20.0001 21.1476 19.5044 19.9023 18.513C19.1319 17.9017 18.5871 17.2156 18.2681 16.4548C17.9587 15.7762 17.804 14.7646 17.804 13.4199V5.15601H22.4978Z"
        fill="#24252C"
      />
    </svg>
  );
};

export default SvgComponent;
