import React, { createContext, ReactNode, FC, useState, useCallback } from 'react';
import { AutoTooltip } from '@/components';
import { TScreenPos } from '@/types';

export interface IChartTooltipContextProps {
  chartTooltipContent: React.ReactNode;
  setChartTooltipContent?: (s: React.ReactNode) => void;
  handleChartTooltipHide?: () => void;
  handleChartTooltipShow?: (pos: TScreenPos, content: React.ReactNode) => void;
}

interface ChartTooltipContextProviderProps {
  children: ReactNode;
}
/**
 * Manages and displays a chart tooltip. Just wrap your chart component with this provider
 * to enable the tooltip, and call the setChartTooltipContent and setChartTooltipPosition
 * functions as needed. */
export const ChartTooltipContextProvider: FC<ChartTooltipContextProviderProps> = ({ children }) => {
  const [chartTooltipContent, setChartTooltipContent] = useState<React.ReactNode>(null);
  const [chartTooltipPosition, setChartTooltipPosition] = useState<TScreenPos | null>(null);

  // Listener for chart tooltip hide
  const handleChartTooltipHide = useCallback(() => {
    setChartTooltipContent(null);
  }, []);

  // Listener for chart tooltip show
  const handleChartTooltipShow = useCallback((pos: TScreenPos, content: React.ReactNode) => {
    setChartTooltipPosition(pos);
    setChartTooltipContent(content);
  }, []);

  return (
    <ChartTooltipContext.Provider
      value={{
        chartTooltipContent,
        setChartTooltipContent,
        handleChartTooltipHide,
        handleChartTooltipShow,
      }}
    >
      <AutoTooltip
        variant="chart"
        arrowSize={6}
        label={chartTooltipContent}
        forceOpen={!!chartTooltipContent}
        position={chartTooltipPosition ?? { x: 0, y: 0 }}
        placement="top"
      />
      {children}
    </ChartTooltipContext.Provider>
  );
};

export const ChartTooltipContext = createContext<IChartTooltipContextProps>({
  chartTooltipContent: null,
});
