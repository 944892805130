import { FC, memo, useCallback, useRef, useState, useEffect, useMemo } from 'react';
import { useInterval, useTimezone } from '@/hooks';
import { useDateRangePickerState } from '@react-stately/datepicker';
import { useDateRangePicker } from '@react-aria/datepicker';
import { today, parseAbsolute, isSameDay, ZonedDateTime, now } from '@internationalized/date';
import { I18nProvider } from 'react-aria';
import { PopoverButton } from './PopoverButton';
import { RangeCalendar } from './RangeCalendar';
import { DateField } from './DateField';
import { useDispatch } from 'react-redux';
import {
  CHART_QUICK_RANGE_BUTTON_LABELS,
  DETECTION_QUICK_RANGE_BUTTON_LABELS,
  EDateRangePickerType,
} from './Constant';
import { QuickRangeButton } from './QuickRangeButton';
import { isQuickRangeEdited, getNewDateRange } from './utility';
import { DateValue } from '@react-types/calendar';
import { AriaDateRangePickerProps } from '@react-types/datepicker';
import dayjs from 'dayjs';
import { convertTZString } from '@/utils/common';
import CloseIcon from '@/components/Icons/Close';
import { useTranslation } from 'react-i18next';
import { DropdownContent, DropdownRoot } from '@/components/primitives/Dropdown/Dropdown';
import { useTime } from '@/redux/hooks';
import {
  TIME_RANGE_MODE,
  updateTimeRange,
  updateRangeMode,
  getCurrentTimeState,
} from '@/redux/reducers/time';
import { datadogRum } from '@datadog/browser-rum';

export interface IDateRangePicker extends AriaDateRangePickerProps<DateValue> {
  type: EDateRangePickerType;
  id: string;
  hAlignment?: 'left' | 'right' | 'center';
  vAlignment?: 'top' | 'bottom' | 'middle';
  status?: 'error' | 'normal';
}

export const DateRangePicker: FC<IDateRangePicker> = memo(
  ({ type, id, status = 'normal', ...rest }) => {
    const tz = useTimezone();
    // useTime should not return null so here is just a precaution.

    const time = useTime() || getCurrentTimeState(tz);

    useEffect(() => {
      const { startEpoch, endEpoch } = time.chart.timeRange;
      const newStart = parseAbsolute(dayjs(startEpoch).format('YYYY-MM-DDTHH:mm:ssZ'), tz).set({
        second: 0,
        millisecond: 0,
      });
      const newEnd = parseAbsolute(dayjs(endEpoch).format('YYYY-MM-DDTHH:mm:ssZ'), tz).set({
        second: 0,
        millisecond: 0,
      });
      setValue({ start: newStart, end: newEnd });
    }, [time.chart.timeRange]);

    const {
      timeRangeMode,
      timeRange: { startEpoch, endEpoch },
    } = type === EDateRangePickerType.CHARTS ? time.chart : time.detection;

    const {
      t,
      i18n: { language: currentLang },
    } = useTranslation();
    const dispatch = useDispatch();

    const defaultNowTime = parseAbsolute(
      dayjs(time?.currentDate || new Date())
        .tz(tz)
        .format('YYYY-MM-DDTHH:mm:ssZ'),
      tz,
    ).set({ second: 0, millisecond: 0 });
    const [nowTime, setNowTime] = useState(defaultNowTime);
    const state = useDateRangePickerState({
      ...rest,
      defaultValue: {
        start: (
          (time && parseAbsolute(dayjs(startEpoch).format('YYYY-MM-DDTHH:mm:ssZ'), tz)) ||
          defaultNowTime
        ).set({
          second: 0,
          millisecond: 0,
        }),
        end:
          (time &&
            parseAbsolute(dayjs(endEpoch).format('YYYY-MM-DDTHH:mm:ssZ'), tz).set({
              second: 0,
              millisecond: 0,
            })) ||
          defaultNowTime,
      },
      hideTimeZone: true,
      shouldCloseOnSelect: false,
    });

    const { value: rangeValue, setValue } = state;

    useEffect(() => {
      if (isQuickRangeEdited(rangeValue, nowTime, timeRangeMode, currentLang)) {
        dispatch(
          updateRangeMode({
            mode: TIME_RANGE_MODE.CUSTOM,
            type,
          }),
        );
      }
    }, [rangeValue, nowTime, timeRangeMode, currentLang, dispatch]);

    const ref = useRef<Element>(null);
    const { startFieldProps, endFieldProps, calendarProps } = useDateRangePicker(
      {
        ...rest,
        minValue: today(tz).subtract({ years: 1 }),
        maxValue: nowTime,
      },
      state,
      ref,
    );

    // Time range and quick range labels based on picker type
    const quickRangeLabels = useMemo(() => {
      if (type === EDateRangePickerType.CHARTS) {
        return CHART_QUICK_RANGE_BUTTON_LABELS;
      }

      return DETECTION_QUICK_RANGE_BUTTON_LABELS;
    }, [type]);

    const updateNowtime = useCallback(() => {
      const newNowTime = now(tz).set({ second: 0, millisecond: 0 });
      setNowTime(newNowTime);
      if (timeRangeMode === TIME_RANGE_MODE.LAST_5_MINS) {
        setValue({
          start: newNowTime.subtract({ minutes: 5 }),
          end: newNowTime.copy(),
        });
      }
    }, [timeRangeMode, tz]);

    // Update nowTime every 30 seconds
    useInterval(30000, updateNowtime, []);

    const onTimeRangeModeChange = (nextTimeRangeMode: TIME_RANGE_MODE) => {
      state.setValue(getNewDateRange(nowTime, nextTimeRangeMode, currentLang));
      dispatch(
        updateRangeMode({
          mode: nextTimeRangeMode,
          type,
        }),
      );
    };

    const applyCustomRange = () => {
      state.setOpen(false);
      dispatch(
        updateTimeRange({
          range: {
            startEpoch: rangeValue.start.toDate(tz).getTime(),
            endEpoch: rangeValue.end.toDate(tz).getTime(),
          },
          type,
        }),
      );
    };

    return (
      <div className="relative inline-flex flex-col justify-self-end" id={id}>
        <DropdownRoot open={state.isOpen} onOpenChange={state.setOpen}>
          <PopoverButton
            label={`${type}`}
            tz={tz}
            timeRangeMode={timeRangeMode}
            rangeValue={rangeValue}
            rangeLabels={quickRangeLabels}
            status={status}
          />
          <DropdownContent align="end" side="bottom">
            <div className="py-3 pl-5 text-xxs font-semibold leading-3 border-solid border-b-1 border-b-gray-200 flex-shrink-0">
              {t('selectTimeRange')}
            </div>
            <div className="flex h-[350px] flex-grow">
              <div className="w-40 overflow-y-auto bg-white">
                <QuickRangeButton
                  type={type}
                  label={t('custom')}
                  id={0}
                  isSelected={timeRangeMode === 0}
                  onTimeRangeModeChange={(mode) => {
                    datadogRum.addAction('Custom date range selected', {
                      source: 'datepicker',
                      range: 'custom',
                    });
                    onTimeRangeModeChange(mode);
                  }}
                  onClose={() => state.setOpen(false)}
                />
                <div className="h-5 bg-gray-100 py-1 pl-5 text-xs font-normal leading-3 text-gray-600">
                  {t('quickRanges')}
                </div>
                {quickRangeLabels.map((label, index) => (
                  <QuickRangeButton
                    type={type}
                    label={t(label)}
                    key={index}
                    id={index + 1}
                    isSelected={timeRangeMode === index + 1}
                    onTimeRangeModeChange={(mode) => {
                      datadogRum.addAction('Quick date range selected', {
                        source: 'datepicker',
                        range: label,
                      });
                      onTimeRangeModeChange(mode);
                    }}
                    onClose={() => state.setOpen(false)}
                  />
                ))}
              </div>
              <div className="max-h-full w-[360px] bg-gray-100 px-2 pt-2 overflow-y-auto">
                <div className="rounded-lg bg-white">
                  <I18nProvider locale={currentLang}>
                    <RangeCalendar
                      tz={tz}
                      {...{
                        ...calendarProps,
                        defaultFocusedValue: rangeValue.end as ZonedDateTime,
                        timeRangeMode: timeRangeMode,
                      }}
                      onChange={(value) => {
                        calendarProps.onChange?.(value);
                        // Needed since the calendar can update the time as well. Above function seems locked
                        // to only update the date.
                        state.setValue(value);
                      }}
                    />
                  </I18nProvider>
                  <div className="ml-8 mb-1 text-xs font-normal text-gray-500">{t('from')}</div>
                  <div className="mb-3 flex justify-center gap-0.5 text-xs font-normal">
                    <DateField {...startFieldProps} />
                  </div>
                  <div className="ml-8 mb-1 text-xs font-normal text-gray-500">{t('until')}</div>
                  <div className="flex justify-center gap-0.5 text-xs font-normal">
                    <DateField
                      {...{
                        ...endFieldProps,
                        untilNow:
                          isSameDay(nowTime, rangeValue.end as ZonedDateTime) &&
                          timeRangeMode !== TIME_RANGE_MODE.CUSTOM,
                      }}
                    />
                  </div>
                  <div className="h-5"></div>
                </div>
                <button
                  tabIndex={0}
                  className="my-3 flex cursor-pointer"
                  onClick={() =>
                    onTimeRangeModeChange(
                      timeRangeMode === TIME_RANGE_MODE.CUSTOM
                        ? TIME_RANGE_MODE.CUSTOM
                        : TIME_RANGE_MODE.TODAY,
                    )
                  }
                >
                  <div className="py-0.5">
                    <CloseIcon />
                  </div>
                  <div className="ml-1.5 cursor-pointer text-xs font-normal text-gray-600">
                    {t('reset')}
                  </div>
                </button>
              </div>
            </div>
            <hr className="h-px border-0 bg-gray-200 dark:bg-gray-700"></hr>
            <div className="flex h-12.5 items-center justify-between">
              <div role="note" className="ml-5 text-xs font-semibold leading-4 text-blue-gray">
                {`${convertTZString(tz, currentLang)}, UTC ${dayjs().tz(tz).utcOffset() / 60}:00`}
              </div>
              <button
                className="mr-2.5 my-2 h-8 w-36 rounded-sm bg-product-red500 text-xs font-semibold leading-4 text-white hover:bg-product-red400 disabled:bg-gray-100 disabled:text-gray-300"
                onClick={applyCustomRange}
              >
                {t('applyTimeRange')}
              </button>
            </div>
          </DropdownContent>
        </DropdownRoot>
      </div>
    );
  },
);
