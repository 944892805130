import { TXYPoint } from '@/types';
import { point } from '@flatten-js/core';
import { Hive } from '../ScopingEditor/useGetState';
import type { PhysicalObject } from './types';

/**
 * Represents a hive and provides functionality for its behavior and properties.
 * Hives have a position but no rotation.
 */
export class HiveObject implements PhysicalObject {
  private _position: TXYPoint;

  constructor(position: TXYPoint) {
    this._position = position;
  }

  static fromHive(hive: Pick<Hive, 'coordinates'>): HiveObject {
    return new HiveObject(hive.coordinates);
  }

  get center(): TXYPoint {
    return this._position;
  }

  /** Hives are just single points, so they don't have any meaningful rotation. */
  get rotation(): number {
    return 0;
  }

  rotate(angle: number, center: TXYPoint = this.center): HiveObject {
    const nextPosition = point(this._position).rotate(-(angle * Math.PI) / 180, point(center));
    const { x, y } = nextPosition;
    this._position = [x, y];
    return this;
  }

  translate(dx: number, dy: number): PhysicalObject {
    const [x, y] = this._position;
    this._position = [x + dx, y + dy];
    return this;
  }
}
