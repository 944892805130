export default {
  // Nav bar
  dataDashboard: 'Data Dashboard',
  installationGuide: 'Installation Guide',
  apiDoc: 'API Doc',
  download: 'Download',
  downloads: 'Downloads',
  search: 'Search',
  selectOrganization: 'Select Organization',
  noResults: 'No results',
  searchAction:
    'Nothing matches your search. Try different keywords for site, building, floor, room, or zone.',
  delete: 'Delete',
  details: 'Details',
  customerSupport: 'Customer Support',
  // Header
  dashboards: 'Dashboards',
  settings: 'Settings',
  data: 'Data',
  language: 'Language',
  done: 'Done',
  toChangePassword: 'To change your password, ',
  clickHere: 'click here',
  changePassword: 'Change Password',
  logOut: 'Log Out',
  learningCenter: 'Learning Center',
  contactSupport: 'Contact Support',
  switchOrganization: 'Switch Organization',
  multipleClientError:
    'You have multiple tabs open with different clients, and are being redirected to the most recent.',
  // Main board
  charts: 'Charts',
  spaceVisualizer: 'Space Visualizer',
  featureUnavailable: 'Feature Temporarily Unavailable',
  featureUnavailableDescription:
    "We're upgrading this feature for better performance and will restore access once improvements are complete.",
  spaceSummary: 'Floor Summary',
  entrywayTraffic: 'Entryway Traffic',
  doorwayTraffic: 'Doorway Traffic',
  zoneTraffic: 'Zone Traffic',
  in: 'in',
  out: 'out',
  peak: 'Peak',
  average: 'Average',
  roomZoneOccupancy: 'Room & Zone Occupancy',
  roomOccupancy: 'Room Occupancy Count',
  occupancy: 'Occupancy Count',
  occupied: 'Occupied',
  dailyEntries: 'Daily Entries',
  dailyExits: 'Daily Exits',
  csvDownload: 'CSV download',
  downloadCSV: 'Download CSV',
  chartSettings: 'Chart settings',
  livePresenceCoverage: 'Live Presence Coverage',
  estOccupancy: 'Est. Occupancy',
  estOccupancyTrend: 'Est. Occupancy Change',
  occupancyTrend: 'Occupancy Change',
  deskUtilization: 'Desk Utilization',
  beta: 'Beta',
  individual: 'Single Entryway',
  combined: 'All Entryways',
  last24Hours: 'Last 24 Hours',
  last2Days: 'Last 2 Days',
  last7Days: 'Last 7 Days',
  last30Days: 'Last 30 Days',
  headcountEstimated: 'Traffic',
  roomName: 'Room Name',
  type: 'Type',
  batteryManagement: 'Battery Management',
  oldestBattery: 'Oldest Battery',
  newestBattery: 'Newest Battery',
  parentFloor: 'Parent Floor',
  parentRoom: 'Parent Room',
  floorName: 'Floor Name',
  parentBuilding: 'Parent Building',
  buildingName: 'Building Name',
  dataSource: 'Data Source',
  fullness: 'Fullness',
  utilization: 'Utilization',
  capacityCap: 'Capacity',
  avgOccupancy: 'Avg. Occupancy',
  peakOccupancy: 'Peak Occupancy',
  avgUtilization: 'Avg. Utilization',
  peakUtilization: 'Peak Utilization',
  roomZoneName: 'Room/Zone Name',
  '24HTrend': '24H Trend',
  occupants: 'Occupants',
  vacant: 'Vacant',
  min: 'Min',
  mid: 'Mid',
  max: 'Max',
  roomSummary: 'Room Summary',
  fromLastWeek: 'from last week',
  totalRoomCapacity: 'total room capacity',
  spaceCapacity: 'floor capacity',
  capacity: 'capacity',
  fromOneHourAgo: 'from one hour ago',
  now: 'Now',
  create: 'Create',
  chartDescription: 'Displays the latest occupancy count for all rooms and zones in this floor.',
  noTrafficSensor: 'No traffic sensors in this floor',
  noPresenceSensor: 'No presence sensors in this floor',
  noSensorsSpace: 'To display data, please click the Studio tab and add sensors to this floor.',
  noSensorsRoom: 'To display data, please click the Studio tab and add sensors to this room.',
  noSensorsZone: 'To display data, please click the Studio tab and add sensors to this zone.',
  pleaseInstallInRoom: 'To access additional data, please install traffic sensors in this room.',
  pleaseInstallInSpace: 'To access additional data, please install traffic sensors in this floor.',
  sites: 'Sites',
  kits: 'Kits',
  zoneSummary: 'Zone Summary',
  current: 'Current',
  all: 'All',
  entrances: 'Entrances',
  exits: 'Exits',
  entrancesExists: 'Entrances & Exits',
  trafficOcc: 'Occupancy at Monitored Entryways',
  trafficOccSubtitle: 'The difference in entrance and exit counts as detected by traffic sensors.',
  occupancyMetrics: 'Occupancy Metrics',
  ficOcc: 'Traffic Occupancy at Monitored Entryways',
  presenceOcc: 'Occupancy at Monitored Areas',
  presenceOccSubtitle: 'Counts of people detected by presence sensors',
  // Details panel
  showSpaceDetails: 'Show Floor Details',
  hideSpaceDetails: 'Hide Floor Details',
  showRoomDetails: 'Show Room Details',
  hideRoomDetails: 'Hide Room Details',
  showZoneDetails: 'Show Zone Details',
  hideZoneDetails: 'Hide Zone Details',
  spaceInformation: 'Floor Information',
  roomInformation: 'Room Information',
  moveFloor: 'Changing the Building will move its data (floor, rooms, zones) to the new Site.',
  confirmDeleteBuilding: 'Are you sure you want to delete this building?',
  deleteBuilding: 'This building will be deleted permanently. You can’t undo this action.',
  deleteSite: 'This site will be deleted permanently. You can’t undo this action.',
  confirmDeleteSite: 'Are you sure you want to delete this site?',
  unableToDelete: 'Unable to delete',
  unableToDeleteSite: 'This site has linked floors. Please remove the floors first.',
  unableToDeleteBuilding: 'This building has linked floors. Please remove the floors first.',
  customBuildingId: 'Custom Building ID',
  name: 'Name',
  timezone: 'Time Zone',
  selectTimezone: 'Please Select a Timezone',
  size: 'Size',
  id: 'ID',
  unit: 'Unit',
  notSet: 'Not Set',
  capacityLimit: 'Capacity Limit',
  capacityWarning: 'Capacity Warning',
  occupancyLimit: 'Occupancy Limit',
  occupancyWarning: 'Occupancy Warning',
  address: 'Address',
  spaceDevices: 'Floor Devices',
  roomDevices: 'Room Devices',
  zoneDevices: 'Zone Devices',
  headcountSensors: 'Traffic Sensor(s)',
  activitySensors: 'Presence Sensor(s)',
  hives: 'Hive(s)',
  editSpaceInformation: 'Edit Floor Information',
  editZoneInformation: 'Edit Zone Information',
  editBuildingParentSite:
    'Changing the Site will move its data (building, floor, room, zone) to the new Site.',
  buildingNumber: 'Building Code (Optional)',
  maxCapacity: 'Max Capacity',
  parentSite: 'Parent Site',
  customId: 'Custom ID',
  installationStatus: 'Installation Status',
  editRoomInformation: 'Edit Room Information',
  editSettings: 'Edit Settings',
  spaceName: 'Floor Name',
  area: 'Area',
  sqMeters: 'sq meters',
  sqFeet: 'sq feet',
  meters: 'Meters',
  installationInProgress: 'Installation in progress',
  QAInProgress: 'QA in progress',
  installed: 'Installed',
  notInstalled: 'Not Installed',
  feet: 'Feet',
  suiteRoom: 'Suite #, Room, etc',
  city: 'City',
  state: 'State',
  country: 'Country',
  zipcode: 'Zipcode',
  typeToSearchCountry: 'Type to search country',
  typeToSearchSite: 'Type to search site',
  typeToSearchTimezone: 'Type to search (country, city, abbreviation)',
  cancel: 'Cancel',
  save: 'Save',
  edit: 'Edit',
  export: 'Export',
  zoneName: 'Zone Name',
  dailyEntrywayTraffic: 'Daily Entryway Traffic',
  dailyMainEntrywayTraffic: 'Daily Main Entryway Traffic',
  comparedFromLastWeek: 'compared to this time last week',
  totalOccupants: 'Total Occupants',
  totalOccupantsPresence: 'Total Occupants - Presence',
  totalOccupantsTraffic: 'Total Occupants - Traffic',
  peakValueLast5Mins: 'peak value of last 5 mins / capacity',
  dataAggregation: 'Data Aggregation',
  note: 'Note',
  // QR Scanner
  seeSticker: 'See sticker on sensor for QR Code',
  scanSensor: 'Scan to add sensor',
  connectionHelp: 'Need help connecting your scanner?',
  typeMAC: 'Type in MAC address',
  sensorAlreadyAdded: 'This sensor has already been added in. Please add a different sensor.',
  // Space Visualizer
  live: 'Live',
  livePresence: 'Presence',
  dailyTraffic: 'Daily Traffic',
  objects: 'Objects',
  dataCards: 'Data Cards',
  dataVisualizations: 'Data Visualizations',
  sensorCoverageAreas: 'Sensor Coverage Areas',
  fixtures: 'Fixtures',
  rooms: 'Rooms',
  floorPlanImage: 'Floor Plan Image',
  liveDetections: 'Live Detections',
  zones: 'Zones',
  detections: 'Detections',
  presence: 'Presence',
  presenceData: 'Presence data',
  presenceChart: 'Presence Chart',
  trafficData: 'Traffic data',
  trafficDataMainEntrance: 'Traffic data (Main entrance)',
  estOccupancyChart: 'Est. Occupancy Chart',
  hotKeyCheatsheet: 'Hot Key Cheatsheet',
  zoomInOut: 'Zoom In/Out',
  mouseScroll: 'Mouse Scroll',
  pan: 'Pan',
  orbit: '3D',
  rightMouseDrag: 'Right Mouse + Drag',
  pleaseSelectATimeRange: 'Please select a time range',
  loadUpTo1WeekOfData: 'Load up to 1 week of data.',
  longerTimeRangeComingSoon: 'Longer time ranges coming soon!',
  generate: 'Generate',
  heatmapPeriodError: 'Please select a time period of 1 week or less',
  history: 'History',
  spaceVisualizations: 'Space Visualizations',
  fullScreen: 'Full Screen',
  exitFullScreen: 'Exit Full Screen',
  zoomIn: 'Zoom in',
  zoomOut: 'Zoom out',
  showHide: 'Show/hide',
  keyActions: 'Key actions',
  utilizationHeatmap: 'Utilization Heatmap',
  detectionReplay: 'Detection Replay',
  play: 'Play',
  pause: 'Pause',
  successSpaceVisualizationGeneration: 'Space visualizations are successfully generated.',
  failSpaceVisualizationGeneration: 'Failed to generate space visualizations.',
  pleaseInputMinMax: 'Please input Min/Max',
  // Tooltip
  refreshed: 'Refreshed',
  ago: 'ago',
  secondsAgo: '',
  minutesAgo: '',
  helpCenter: 'Support',
  noDataFilters: 'No data found with current filters',
  daily_entries:
    'Daily Entries is the main entrance traffic from midnight to now,\n% compared to same day & time last week.',
  daily_exits:
    'Daily Exits is the main entrance traffic from midnight to now,\n% compared to same day & time last week.',
  live_presence_coverage: 'Live Presence Coverage is the sum of all room occupancies.',
  total_occupants_description: 'Total occupants is the sum of all room occupancies.',
  occupancy_trend: 'Occupancy Change is the sum of all room occupancies.',
  live_occupancy_chart: 'Live Occupancy is the sum of all people under presence sensors.',
  est_occupancy:
    'Estimated Occupancy is based on main entrance entries & exits, and\nresets to zero at midnight. Data drift may occur.',
  est_occupancy_trend:
    'Estimated Occupancy Change is based on main entrance entries & exits, and\nresets to zero at midnight. Data drift may occur.',
  est_occupancy_chart:
    'Estimated Occupancy is based on main entrance entries & exits, and\nresets to zero at midnight. Data drift may occur.',
  entryway_traffic_chart: 'Entryway Traffic is the entry and exit data from all headcount sensors.',
  doorway_traffic_chart: 'Doorway Traffic is the entry and exit data from all headcount sensors.',
  room_occupancy_table: 'Room & Zones Occupancy Count is the sum of people in rooms and zones.',
  room_occupancy_table_beta_item:
    'Room Occupancy is estimated based on doorway entries & exits, and\nResets to zero at midnight. Data drift may occur.',
  space_visualizations_toggle:
    'This feature is currently enabled for spaces with presence sensors only',

  //TO DO: Need to build/define these tooltips
  no_data_found_room: 'testing',
  no_data_found_zone: 'testing',
  data_source_space: 'data source floor',
  data_source_room: 'data source room',
  occupants_space: 'occupants floor',
  occupants_room: 'occupants room',
  capacity_limit_space: 'capacity limit floor',
  capacity_limit: 'occupants room',
  // Units
  second: 'second',
  seconds: 'seconds',
  minute: 'minute',
  minutes: 'minutes',
  date: '',
  year: '',
  am: 'AM',
  pm: 'PM',
  text_direction: 'ltr',
  yes: 'Yes',
  no: 'No',
  sensorMode: {
    presence: 'Presence',
    repeater: 'Booster',
    traffic: 'Traffic',
  },
  page: 'Page {{number}} of {{count}}',
  goToPage: 'Go to page',
  online: 'Online',
  offline: 'Offline',
  na: 'N/A',
  updated: 'Updated {{timeAgo}}',
  // Studio on Web
  undoRedo: 'Undo / Redo',
  snapAlignment: 'Snap Alignment',
  floors: 'Floors',
  sensors: 'Sensors',
  floorplans: 'Floorplans',
  layers: 'Layers',
  zooming: 'Zooming',
  panning: 'Panning',
  //Item plural
  sensor: 'Sensor',
  sensor_other: 'Sensors',
  hive: 'Hive',
  hive_other: 'Hives',
  room: 'Room',
  room_other: 'Rooms',
  zone: 'Zone',
  zone_other: 'Zones',
  // Studio Panels
  wrongSpace:
    'This sensor is linked to a different floor. Please remove it from there first and try again.',
  visitSpace: 'Visit Floor.',
  constactSupport: 'Contact Support.',
  wrongClient: 'This sensor is associated with a different account and cannot be added here.',
  pleaseEnterMac: 'Please enter a MAC address to turn it into a live sensor',
  // Device management page
  deviceManagement: {
    title: 'Devices',
    sensors: 'Sensors',
    hives: 'Hives',
    search: 'Search sensor and hive info',
    toFixTryThis: 'To fix this, please check the following:',
    copyErrorReport: 'Copy error information',
    checkHiveConnection: 'Check Hive Connection',
    submitTicketDescription: "If the above doesn't resolve the issue, please submit a report.",
    submitTicket: 'Submit error report',
    status: {
      CONNECTED: 'Connected',
      PARTIALLY_CONNECTED: 'Partially Connected',
      NOT_CONNECTED: 'Not Connected',
    },
    hivesTable: {
      failed: 'Failed to load data',
      noData: 'No data found with current filters',
      serialNumber: 'Hive Serial #',
      hiveName: 'Hive Name',
      sensorWithCount_one: '{{count}} Sensor',
      sensorWithCount_other: '{{count}} Sensors',
      space: 'Floor',
      paired: 'Paired',
      connection: 'Connection',
      hiveModel: 'Model',
      pairedSensors: 'Paired Sensors',
      connectionTooltipFirst:
        'A Hive is connected when it successfully sends a heartbeat and its data is streaming.',
      connectionTooltipSecond:
        "Any issues with a Hive's connection will impact all sensors paired with it.",
      steps: {
        verifyStableConnection: 'Hive is connected to the internet.',
        verifyFirewall: 'Hive is not blocked by firewall/ACL.',
        verifyPoweredOn: 'Hive is plugged into a power source and powered on.',
        verifyFirmwareVersion: 'Hive firmware is up-to-date',
        verifyBattery: 'All sensors connected to the Hive still have battery life.',
        verifyCableDamage: 'Gateway or USB cable is not damaged.',
        verifyAntennaDamage: 'Antenna has not been removed, damaged, or physically blocked.',
        verifySensorsOn: 'All sensors connected to the hive are switched on.',
      },
      states: {
        connected: { statusText: 'Connected', customerStatusText: 'Streaming data' },
        notConnected: {
          statusText: 'Not connected',
          customerStatusText: 'Not streaming data',
          tooltipTitle: 'Hive has not sent a heartbeat and is not streaming data.',
        },
        noHeartbeat: {
          statusText: 'No heartbeat',
          // customers do not get heartbeat info
          customerStatusText: 'Streaming data',
          tooltipTitle: 'Hive cannot communicate with the API.',
        },
        noDataStreaming: {
          statusText: 'Not streaming data',
          customerStatusText: 'Not streaming data',
          tooltipTitle: 'Hive is not streaming data.',
        },
      },
      heartbeat: 'Heartbeat',
      dataStreaming: 'Data streaming',
    },
    sensorsTable: {
      failed: 'Failed to load data',
      noData: 'No data found with current filters',
      name: 'Sensor Name',
      macAddress: 'Mac Address',
      mode: 'Mode',
      isEntrance: 'Entryway',
      height: 'Height',
      lastBatteryChangeDate: 'Last Battery Change Date',
      nextBatteryChangeDate: 'Next Battery Change Date',
      hiveSerial: 'Hive Serial #',
      hiveName: 'Hive Name',
      spaceName: 'Floor',
      roomName: 'Room',
      zoneName: 'Zone',
      model: 'Model',
      notPaired: 'Not paired',
      connection: 'Connection',
      connectionTooltipFirst:
        "A sensor is connected when present in its parent Hive's heartbeat and its data is streaming to the Hive.",
      connectionTooltipSecond:
        "Any issues with a Hive's connection will impact all sensors paired with it.",
      main: 'Main',
      plusZones: '+{{count}} Zones',
      hide: 'Hide',
      steps: {
        resolveDisconnectWithParentHive: `To address the sensor's disconnection issue, please first resolve the connection issue with the parent Hive.`,
        resolveHeartbeatWithParentHive: `To address the sensor's missing heartbeat issue, please first resolve the connection issue with the parent Hive.`,
        resolveDataStreamingWithParentHive: `To address the sensor's data streaming issue, please first resolve the connection issue with the parent Hive.`,
        verifyPoweredOn: 'Sensor is switched on.',
        verifyBattery: 'Sensor still has battery life.',
        verifyPaired: 'Sensor is <externallink>paired with a hive.</externallink>',
        verifyRange: 'Sensor is within range of its <externallink>mesh network.</externallink>',
        verifyNotRepeater: 'Sensor is not set to <externallink>"booster" mode.</externallink>',
      },
      boosterSteps: {
        verifyPoweredOn: 'Booster is switched on.',
        verifyBattery: 'Booster still has battery life.',
        verifyPaired: 'Booster is <externallink>paired with a hive.</externallink>',
        verifyRange: 'Booster is within range of its <externallink>mesh network.</externallink>',
      },
      states: {
        connected: {
          statusText: 'Connected',
          tooltipTitle: '',
          customerStatusText: 'Streaming data',
        },
        notHiveConnected: {
          statusText: 'Hive not connected',
          customerStatusText: 'Not streaming data',
          tooltipTitle: 'Parent Hive has not sent a heartbeat and is not streaming data.',
        },
        notSensorConnected: {
          statusText: 'Not connected',
          customerStatusText: 'Not streaming data',
          tooltipTitle: 'The sensor is not connected to its parent Hive.',
        },
        noHeartbeat: {
          statusText: 'No hive heartbeat',
          // customers do not get heartbeat info
          customerStatusText: 'Streaming data',
          tooltipTitle: 'Parent Hive cannot communicate with the API.',
        },
        noSensorHeartbeat: {
          statusText: 'No sensor heartbeat',
          // customers do not get heartbeat info
          customerStatusText: 'Streaming data',
          tooltipTitle: "Sensor is not present in its parent Hive's heartbeat.",
        },
        noDataStreaming: {
          statusText: 'Hive not streaming data',
          customerStatusText: 'Not streaming data',
          tooltipTitle: 'Parent Hive is unable to communicate with broker.',
        },
        noDataSensorStreaming: {
          statusText: 'Not streaming data',
          customerStatusText: 'Not streaming data',
          tooltipTitle: 'Sensor is not streaming data to its parent Hive.',
        },
      },
    },
  },

  // Datetime picker
  applyTimeRange: 'Apply Time Range',
  today: 'Today',
  last5Mins: 'Last 5 minutes',
  thisWeek: 'This week',
  thisMonth: 'This month',
  thisYear: 'This year',
  yesterday: 'Yesterday',
  sevenDays: '7D',
  thirtyDays: '30D',
  custom: 'Custom',
  quickRanges: 'Quick ranges',
  reset: 'Reset',
  selectTimeRange: 'Select Time Range',
  from: 'From',
  until: 'Until',
  invalidDates: 'Invalid date/time range, please fix and try again.',

  // Utility
  copiedSuccessfully: 'Copied successfully!',

  betaStatus: {
    studio: {
      welcome: {
        title: 'Welcome to the Beta!',
        content:
          'Please note that beta features can change during the beta period. You may experience bugs or performance issues during this time.',
      },
      upgradedStudioApp: {
        title: 'Upgraded version of Butlr Studio App',
        content:
          'We are actively transferring all Butlr Studio App functionality to the Dashboard. Please stay tuned for any official announcements regarding the availability of the Butlr Studio App.',
      },
    },
  },
};
