import { useEffect } from 'react';

/**
 * Hooks for interval
 * @param interval interval in ms to refresh
 * @param callback functions for running every updateInterval ms
 * @param dependencies dependency array
 */
export const useInterval = (
  interval = 1000,
  callback: () => void,
  dependencies: any[] = [],
): void => {
  useEffect(() => {
    callback();

    const timer = setInterval(callback, interval);

    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback, interval, ...dependencies]);
};
