import { FC, forwardRef, HTMLAttributes, memo, ReactNode, useState } from 'react';
import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { ICommonComponentProps } from '@/types';
import * as Popover from '@radix-ui/react-popover';
import { withClassName } from 'hocs/withClassName';
import { ChevronDownIcon } from '@/components/Icons';

interface IDropdownProps extends ICommonComponentProps {
  dropdownButtonContent?: ReactNode | ((open: boolean) => ReactNode);
  dropdownContent?: ReactNode;
  wrapperClassName?: string;
  buttonClassName?: string;
  itemsClassName?: string;
  buttonProps?: HTMLAttributes<HTMLButtonElement>;
  sideOffset?: number;
  side?: 'left' | 'right' | 'top' | 'bottom';
  align?: 'start' | 'end' | 'center';
}

/**
 * @deprecated - use DropdownRoot/DropdownTrigger/DropdownContent to compose
 * a dropdown structure. This allows you full control over the trigger button
 * and alignment properties directly rather than having to pass everything
 * through this one component.
 */
export const Dropdown: FC<IDropdownProps> = memo(
  ({
    className,
    style,
    dropdownButtonContent,
    dropdownContent,
    wrapperClassName = '',
    buttonClassName = '',
    itemsClassName = '',
    buttonProps,
    sideOffset,
    side,
    align,
    ...rest
  }) => {
    const [open, setOpen] = useState(false);
    return (
      <div className={clsx(wrapperClassName, className)} style={style} {...rest}>
        <DropdownRoot open={open} onOpenChange={setOpen}>
          <DropdownTrigger
            className={clsx(
              'focus:outline-none focus-visible:outline inline-flex w-full justify-center rounded-md text-sm font-medium',
              buttonClassName,
            )}
            {...buttonProps}
          >
            {typeof dropdownButtonContent === 'function'
              ? dropdownButtonContent(open)
              : dropdownButtonContent}
          </DropdownTrigger>
          <DropdownContent
            sideOffset={sideOffset}
            className={itemsClassName}
            side={side}
            align={align}
          >
            {dropdownContent}
          </DropdownContent>
        </DropdownRoot>
      </div>
    );
  },
);

/**
 * Dropdown provides primitive components that can be used to construct an anchored popover. It's meant
 * for highly customized content. For more specific use cases see DropdownMenu and Select.
 *
 * The basic structure of a Dropdown:
 *
 * <DropdownRoot>
 *  <DropdownTrigger>
 *    Trigger
 *    <DropdownTriggerIcon />
 *  </DropdownTrigger>
 *  <DropdownContent>Content</DropdownContent>
 * </DropdownRoot>
 *
 * Because this component group is meant to be more adaptive, there is less styling applied
 * and less opinionated default structure.
 */
export const DropdownRoot = Popover.Root;
export const DropdownTrigger = withClassName(Popover.Trigger, 'cursor-pointer group');
export const DropdownContent = forwardRef<HTMLDivElement, Popover.PopoverContentProps>(
  ({ className, ...props }, ref) => {
    return (
      <Popover.Portal>
        <Popover.Content
          {...props}
          ref={ref}
          className={twMerge(
            'focus:outline-none mt-2 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 flex flex-col origin-radix-popover radix-state-open:animate-popoverIn radix-state-closed:animate-popoverOut z-50',
            // by default, copy the min width of the trigger - Select-like behavior. This (and other properties)
            // can be overridden by user className (hence twMerge usage here).
            'min-w-[var(--radix-popover-trigger-width)] max-h-[var(--radix-popover-content-available-height)]',
            className,
          )}
        />
      </Popover.Portal>
    );
  },
);
export const DropdownTriggerIcon = withClassName(
  ChevronDownIcon,
  'justify-self-end h-[12px] w-[12px] group-radix-state-open:rotate-180 transition-transform',
);
