import { TDialog } from '@/components';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'dialog',
  initialState: null as TDialog | null,
  reducers: {
    setDialog: (state: TDialog | null, action: PayloadAction<TDialog | null>) => action.payload,
    closeDialog: () => null,
  },
});

export const { setDialog, closeDialog } = slice.actions;
export default slice.reducer;
