import { DATE_FORMAT, DATE_ISO8601_FORMAT } from '@/constants';
import { Icon } from '@/components/primitives/icon/Icon';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useRef, useState } from 'react';

type DateInputProps = {
  onChange: (date: string | null) => void;
  disabled?: boolean;
  value?: string | null; // ISO 8601 format: YYYY-MM-DD
};

export default function DateInput({ value, onChange, disabled = false }: DateInputProps) {
  const parsed = dayjs(value, DATE_ISO8601_FORMAT);
  const [dateValue, setDateValue] = useState(parsed.isValid() ? parsed.format(DATE_FORMAT) : '');
  // Show date input when the value is valid or empty
  const [showInput, setShowInput] = useState(parsed.isValid() || !value);
  const ref = useRef<HTMLInputElement>(null);
  return showInput ? (
    <div
      className={clsx(
        'flex rounded pl-2',
        disabled ? 'text-gray-400 bg-white' : 'bg-gray-50 text-gray-600',
      )}
    >
      <button
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          ref.current?.showPicker();
        }}
      >
        <Icon name="calendar" size={20} className=" text-gray-800 my-auto" />
      </button>
      <input
        className="py-1 bg-transparent pl-2 w-full rounded text-left text-xs leading-5 focus:text-gray-900 outline-none"
        type="date"
        ref={ref}
        disabled={disabled}
        value={dateValue}
        onChange={(e) => {
          setDateValue(e.target.value);
          onChange(e.target.value);
        }}
      />
    </div>
  ) : (
    <button
      className={clsx(
        'flex rounded text-left pl-2 py-1 text-xs leading-5 w-48',
        disabled ? 'text-gray-400' : 'bg-gray-50 text-gray-600',
      )}
      onClick={() => setShowInput(true)}
    >
      <Icon name="calendar" size={20} className="text-gray-800 my-auto" />
      <span className="pl-2">{value}</span>
    </button>
  );
}
