import { ReactNode, useMemo } from 'react';
import { Auth0ContextInterface, Auth0Provider as BaseProvider, useAuth0 } from '@auth0/auth0-react';

const useLocalStorageCache =
  import.meta.env.VITE_AUTH_LOCAL_STORAGE === 'true' && import.meta.env.VITE_DEV === 'true';

export function Auth0Provider({ children }: { children: ReactNode }) {
  return (
    <BaseProvider
      domain={import.meta.env.VITE_AUTH0_DOMAIN}
      clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
      audience={import.meta.env.VITE_AUTH0_AUDIENCE}
      scope="offline_access"
      redirectUri={window.location.origin}
      // redirect login back to the dashboard, even if it's on a base path like
      // in review apps.
      onRedirectCallback={(appState) => {
        console.debug('appState', JSON.stringify(appState));
        if (appState?.returnTo && appState.returnTo.startsWith('/')) {
          history.pushState({}, '', appState.returnTo);
        }
      }}
      useRefreshTokens
      cacheLocation={useLocalStorageCache ? 'localstorage' : 'memory'}
    >
      <Auth0ClientExposer />
      {children}
    </BaseProvider>
  );
}

/**
 * Note: this is a hack, to provide some compatibility with RTK Query, which
 * exists "outside" the React tree, and therefore cannot use the Auth0Provider
 * and associated hooks to retrieve tools for getting an access token.
 *
 * If you're familiar with the history of this codebase, you may recall we once
 * had a custom Auth0 cache implementation to workaround this, but the cache
 * did not support refreshing tokens, and was ultimately really hacky and fragile.
 *
 * This is still a hack, but it's actually endorsed by Auth0, or at least the
 * community, because the whole thing is just kind of a mess.
 * See: https://gist.github.com/adamjmcgrath/0ed6a04047aad16506ca24d85f1b2a5c,
 * which was created by an Auth0 library maintainer as a guide for users.
 * Yes, they actually recommend this.
 *
 * We don't require this hack for Apollo, because the Apollo client is able to be
 * initialized within the React tree pretty easily, so instead we pass the Auth0
 * client via hook into Apollo's initialization, which you can see in
 * /components/apollo/ApolloProvider.tsx.
 */
function Auth0ClientExposer() {
  const client = useAuth0();
  // we want this ASAP, so abusing useMemo for
  // immediate invocation that also tracks dependencies.
  useMemo(() => {
    setAuth0(client);
  }, [client]);

  return null;
}

let resolveAuth0: (client: Auth0ContextInterface) => void;
const auth0ClientPromise = new Promise<Auth0ContextInterface>((resolve) => {
  resolveAuth0 = resolve;
});
function setAuth0(client: Auth0ContextInterface) {
  resolveAuth0(client);
}

export function getAuth0Client() {
  return auth0ClientPromise;
}

/**
 * Convenience wrapper utilizing getAuth0Client which
 * lets you either retrieve the current session token or
 * refresh the session if possible to get a new token.
 */
export async function getAccessTokenSilently() {
  const client = await auth0ClientPromise;
  return client.getAccessTokenSilently();
}
