import { FC, SVGProps } from 'react';

/** @deprecated use <Icon name="..." /> (import from @/components/primitives/icons/Icon) instead. */
const SvgComponent: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M2.36393 1.05028C1.97341 0.659759 1.34024 0.659759 0.949716 1.05028C0.559192 1.44081 0.559192 2.07397 0.949716 2.4645L4.48528 6.00007L0.949782 9.53557C0.559258 9.92609 0.559258 10.5593 0.949782 10.9498C1.34031 11.3403 1.97347 11.3403 2.364 10.9498L5.8995 7.41428L9.435 10.9498C9.82552 11.3403 10.4587 11.3403 10.8492 10.9498C11.2397 10.5593 11.2397 9.92609 10.8492 9.53557L7.31371 6.00007L10.8493 2.4645C11.2398 2.07398 11.2398 1.44081 10.8493 1.05029C10.4588 0.659761 9.82559 0.659762 9.43506 1.05029L5.8995 4.58585L2.36393 1.05028Z"
      />
    </svg>
  );
};

export default SvgComponent;
