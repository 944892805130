import { ESV_Vision_Options, ESV_VizMode } from '../types';

export enum MESH_NAMES {
  STUDIO_2D_DRAW_RAYCAST_PLANE = 'studio_2d_draw_raycast_plane',
}

export const MAX_HEATMAP_PERIOD = 7;
export const HEATMAP_SIZE = 8;
export const DETECTION_REPLAY_MULTIPLY = [1, 4, 8];
export const DETECTION_REQUEST_CHUNK_SIZE = 5;
export const HEATMAP_LEGEND_BAR_LABEL_COUNT = 6;

// Default options of space visualizer
export const DEFAULT_SV_OPTIONS = {
  vizMode: ESV_VizMode.OCCUPANCY,
  presentMode: false,
  zoom: 100,
  orbitMode: false,
  visionOptions: {
    [ESV_Vision_Options.ROOMS]: true,
    [ESV_Vision_Options.FLOOR_PLAN_IMAGE]: true,
    [ESV_Vision_Options.SENSOR_COVERAGE_AREAS]: true,
    [ESV_Vision_Options.SPACE_VISUALIZATIONS]: true,
    [ESV_Vision_Options.UTILIZATION_HEATMAP]: true,
    [ESV_Vision_Options.DETECTION_REPLAY]: true,
  },
};

// Hotkeys
export const HOT_KEYS = [
  ['zoomInOut', 'mouseScroll'],
  ['pan', 'rightMouseDrag'],
] as const;
