import { useContext } from 'react';
import { StatsigProvider as StatsigProviderCore, type StatsigUser } from 'statsig-react';
import { useAuthUser } from './hooks';
import { UserContext } from './contexts';

const StatsigProvider = ({ children }: { children: React.ReactNode }) => {
  const { name, user: authUser, clientId } = useAuthUser();
  const { roles, isSuperUser } = useContext(UserContext);
  const user: StatsigUser =
    authUser && name
      ? {
          email: authUser.email,
          appVersion: import.meta.env.VITE_APP_GIT_SHA,
          userID: name,
          custom: {
            roles,
            superUser: isSuperUser,
            clientId,
          },
          customIDs: {
            clientId,
          },
        }
      : { userID: 'unknown-user' };

  return (
    <StatsigProviderCore
      sdkKey={import.meta.env.VITE_STATSIG_SDK_KEY}
      user={user}
      options={{
        localMode: import.meta.env.MODE === 'test',
        environment: {
          tier:
            import.meta.env.MODE === 'production'
              ? 'production'
              : import.meta.env.MODE === 'staging'
              ? 'staging'
              : 'development',
        },
      }}
    >
      {children}
    </StatsigProviderCore>
  );
};

export default StatsigProvider;
