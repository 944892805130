import React, { FC, SVGProps } from 'react';

/** @deprecated use <Icon name="..." /> (import from @/components/primitives/icons/Icon) instead. */
const SvgComponent: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      aria-hidden="true"
      data-prefix="far"
      data-icon="vision"
      className="prefix__svg-inline--fa prefix__fa-user prefix__fa-w-14"
      viewBox="0 0 20 12"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M9.99655 12C15.7753 12 19.6 7.40206 19.6 6C19.6 4.58419 15.7684 0 9.99655 0C4.30817 0 0.400024 4.58419 0.400024 6C0.400024 7.40206 4.30122 12 9.99655 12ZM9.99655 10.4605C5.67811 10.4605 2.31933 6.94158 2.31933 6C2.31933 5.20275 5.67811 1.53952 9.99655 1.53952C14.308 1.53952 17.6807 5.20275 17.6807 6C17.6807 6.94158 14.308 10.4605 9.99655 10.4605ZM10.0035 9.56014C12.0063 9.56014 13.6126 7.93814 13.6126 6C13.6126 4.01375 12.0063 2.43986 10.0035 2.43986C7.99379 2.43986 6.38047 4.00687 6.38742 6C6.39437 7.93814 7.99379 9.56014 10.0035 9.56014ZM9.99655 7.1134C9.37069 7.1134 8.86304 6.60481 8.86304 6C8.86304 5.38144 9.37069 4.87972 9.99655 4.87972C10.6294 4.87972 11.137 5.38144 11.137 6C11.137 6.60481 10.6294 7.1134 9.99655 7.1134Z" />
    </svg>
  );
};

export default SvgComponent;
