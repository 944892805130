import { Color, ShaderMaterial, Vector2 } from 'three';
import { roomFrag, roomVert } from '@/components/SpaceVisualizer/ThreeRenderer';

export interface ISV_RoomMaterial {
  resolution?: Vector2;
  borderWidth?: number;
  fillCol?: number;
  borderCol?: number;
  fillOpacity?: number;
}

export class RoomMaterial extends ShaderMaterial {
  constructor({ resolution, borderWidth, fillCol, borderCol, fillOpacity }: ISV_RoomMaterial) {
    super({
      uniforms: {
        borderRadius: { value: 0 },
        borderWidth: { value: borderWidth },
        size: { value: resolution },
        fillColor: { value: new Color(fillCol) },
        fillOpacity: { value: fillOpacity },
        borderColor: { value: new Color(borderCol) },
      },
      vertexShader: roomVert,
      fragmentShader: roomFrag,
      depthWrite: false,
      transparent: true,
    });
  }

  /**
   * Update
   */
  update({ resolution, borderWidth, fillCol, borderCol, fillOpacity }: ISV_RoomMaterial) {
    resolution && (this.uniforms.size.value = resolution);
    borderWidth && (this.uniforms.borderWidth.value = borderWidth);
    fillCol && (this.uniforms.fillColor.value = new Color(fillCol));
    borderCol && (this.uniforms.borderColor.value = new Color(borderCol));
    fillOpacity && (this.uniforms.fillOpacity.value = fillOpacity);
  }
}
