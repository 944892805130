import { ESV_VizMode } from '@/types';
import { SV_COLORS } from '@/constants';
import { degToRad } from 'three/src/math/MathUtils';

// Number to # hex string
export const hex2string = (hex: number): string => {
  let hexString = hex.toString(16);
  hexString = '000000'.substring(0, 6 - hexString.length) + hexString;

  return `#${hexString}`;
};

// Get room colors based on viz mode and occupancy, [fillCol, fillOpacity, borderCol, nameTagFillCol, nameTagCol, occupancyTagCol]
export const getRoomColor = (occupancy: number, vizMode: ESV_VizMode): number[] => {
  if (vizMode === ESV_VizMode.TRAFFIC)
    return [SV_COLORS.gray50, 0, SV_COLORS.gray800, SV_COLORS.gray800, SV_COLORS.gray100, -1];

  if (occupancy === 0)
    return [
      SV_COLORS.gray50,
      1,
      SV_COLORS.gray400,
      SV_COLORS.gray200,
      SV_COLORS.gray500,
      SV_COLORS.gray400,
    ];

  if (occupancy > 0 && occupancy < 50)
    return [
      SV_COLORS.gray100,
      1,
      SV_COLORS.gray900,
      SV_COLORS.gray900,
      SV_COLORS.gray50,
      SV_COLORS.gray900,
    ];

  if (occupancy >= 50 && occupancy < 100)
    return [
      SV_COLORS.orange50,
      1,
      SV_COLORS.orange500,
      SV_COLORS.orange400,
      SV_COLORS.gray100,
      SV_COLORS.orange500,
    ];

  return [
    SV_COLORS.red50,
    1,
    SV_COLORS.red500,
    SV_COLORS.red500,
    SV_COLORS.gray100,
    SV_COLORS.red500,
  ];
};

// Get effective coverage width of sensor by fov and height
export const getSensorEffectiveCoverageWidth = (fov: number, height: number): number => {
  if (fov === 90) return 0.84 * (2 * Math.tan(degToRad(45)) * (height - 1.05) + 0.36) + 0.36;

  return 2 * Math.tan(degToRad(30)) * (height - 1.05) + 0.36 + 0.36;
};
