import { IClients } from '@/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type SuperUserState = { selectedClient: IClients | null; isSuperUserDashboard: boolean };

const slice = createSlice({
  name: 'superuser',
  initialState: {
    selectedClient: null,
    isSuperUserDashboard: false,
  } as SuperUserState,
  reducers: {
    setSelectedClient: (state: SuperUserState, action: PayloadAction<IClients | null>) => ({
      ...state,
      selectedClient: action.payload,
    }),
    setIsSuperUserDashboard: (state: SuperUserState, action: PayloadAction<boolean>) => {
      if (action.payload) {
        return { ...state, isSuperUserDashboard: true, selectedClient: null };
      }
      return { ...state, isSuperUserDashboard: false };
    },
  },
});

export const { setSelectedClient, setIsSuperUserDashboard } = slice.actions;
export default slice.reducer;
