import { createRef } from 'react';
import clsx from 'clsx';
import { useCalendarCell } from '@react-aria/calendar';
import { isSameMonth, isSameDay } from '@internationalized/date';
import { CalendarDate } from '@internationalized/date';
import { RangeCalendarState } from '@react-stately/calendar';
import { DateValue } from '@react-types/datepicker';

interface ICalendarCell {
  currentMonth: CalendarDate;
  date: CalendarDate;
  state: RangeCalendarState;
  weekCount: number;
}

export function CalendarCell({ state, date, currentMonth, weekCount }: ICalendarCell) {
  const ref = createRef<HTMLButtonElement>();

  const { cellProps, buttonProps, isInvalid, formattedDate } = useCalendarCell(
    { date },
    state,
    ref,
  );
  const start = state.value?.start as DateValue | null;
  const end = state.value?.end as DateValue | null;

  const isOutsideMonth = !isSameMonth(currentMonth, date);
  const isRangeSameDate = start && end && isSameDay(start, end);
  const isRangeStart = start && isSameDay(start, date);
  const isRangeEnd = end && isSameDay(end, date);
  const inDragRange =
    start &&
    end &&
    state.anchorDate &&
    ((date >= state.highlightedRange.start && date <= state.highlightedRange.end) ||
      isSameDay(state.anchorDate, date));
  const inRange = isRangeStart || (start && end && start <= date && date <= end);
  const inRangeNotInDrag =
    (state.anchorDate === null && inRange) ||
    (state.anchorDate !== null && inRange && !inDragRange);
  const isRangeIntersected =
    start &&
    end &&
    state.anchorDate !== null &&
    !(state.highlightedRange.end.add({ days: 1 }) < start || state.highlightedRange.start > end);
  const isDragRangeSameDate =
    state.anchorDate !== null &&
    isSameDay(state.highlightedRange.start, state.highlightedRange.end);
  let selectedRangeCss, inDragRangeCss;
  if (!isRangeIntersected) {
    selectedRangeCss = clsx(
      isRangeSameDate && isRangeStart && 'w-8 rounded-[50%]',
      !isRangeSameDate && isRangeStart && 'rounded-range-start',
      !isRangeSameDate && isRangeEnd && 'rounded-range-end',
      inRangeNotInDrag && 'bg-product-red500 text-white font-semibold',
      isOutsideMonth && inRangeNotInDrag && 'bg-red-300 font-semibold',
    );
  }

  if (state.anchorDate !== null) {
    inDragRangeCss = clsx(
      // calendar is in drag and this date is the only date in drag, should show as circle
      isSameDay(state.highlightedRange.start, state.highlightedRange.end) &&
        isSameDay(state.highlightedRange.start, date) &&
        'w-8 rounded-[50%]',
      // calendar is in drag and this date is the begining of the range, should show as left semicircle
      !isSameDay(state.highlightedRange.start, state.highlightedRange.end) &&
        isSameDay(state.highlightedRange.start, date) &&
        'rounded-range-start',
      // calendar is in drag and this date is the end of the range, should show as right semicircle
      !isSameDay(state.highlightedRange.start, state.highlightedRange.end) &&
        isSameDay(state.highlightedRange.end, date) &&
        'rounded-range-end',
      // calendar is in drag and this date is in the of drag range, should show hover state
      inDragRange && 'bg-product-red50 text-product-red500 font-semibold',
      // clear radius if the date is in the middle of drag range to show as retangle, because it could be the beging or end of selected range
      date > state.highlightedRange.start && date < state.highlightedRange.end && 'rounded-none',
    );
  }

  let buttonClassName = clsx(
    'h-8 w-11 text-sm font-normal outline-none',
    !isInvalid && isOutsideMonth && 'text-gray-300',
    !isInvalid && state.maxValue && date > state.maxValue && 'text-gray-400 cursor-auto',
    selectedRangeCss,
    inDragRangeCss,
  );

  // Should show the cell as circle
  const shouldShowCircle = (isRangeSameDate && isRangeEnd) || (isDragRangeSameDate && inDragRange);
  if (shouldShowCircle && buttonClassName.includes('w-8')) {
    buttonClassName = buttonClassName.replace(/w-11/g, '');
  }

  return (
    <td
      {...cellProps}
      className={clsx('w-11 pl-0 pr-0 text-center', weekCount === 6 ? 'h-8' : 'h-10')}
    >
      <button className={buttonClassName} {...buttonProps} ref={ref}>
        {formattedDate}
      </button>
    </td>
  );
}
