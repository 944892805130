import React, { FC, memo } from 'react';
import { SummaryTile, ArrowUpCircleIcon, ArrowDownCircleIcon, LoadingSpinner } from '@/components';
import { IQuestionTooltipContent, ICommonComponentProps } from '@/types';
import { useTranslation } from 'react-i18next';

interface IOccupancyTrendSummaryTileProps extends ICommonComponentProps {
  title?: string;
  label?: string;
  value?: number | null;
  tooltip?: IQuestionTooltipContent;
  loading?: boolean;
  error?: string;
  beta?: boolean;
}

export const OccupancyTrendSummaryTile: FC<IOccupancyTrendSummaryTileProps> = memo(
  ({
    className,
    style,
    title = '',
    label = '',
    value = null,
    tooltip,
    loading = false,
    error = '',
    beta = false,
    ...rest
  }) => {
    const { t } = useTranslation();
    return (
      <SummaryTile
        className={className}
        style={style}
        title={title}
        label={label}
        tooltip={tooltip}
        beta={beta}
        {...rest}
      >
        {loading ? (
          <div className="absolute inset-0 mr-1 flex h-full w-full items-center justify-center truncate rounded-2xl bg-white text-xs font-semibold">
            <LoadingSpinner />
          </div>
        ) : error ? (
          <div className="absolute inset-0 mr-1 flex h-full w-full items-center justify-center truncate rounded-2xl bg-white text-xs font-semibold">
            {/* <Error message={error} size={ESize.sm} /> */}
          </div>
        ) : (
          <>
            <div className="mb-4 flex items-center text-3xl font-light tracking-0.03em text-product-gray700">
              <span className="mr-2 font-semibold text-product-gray900">{value?.toFixed(0)}%</span>
              {(value || 0) > 0 && <ArrowUpCircleIcon width="32px" />}
              {(value || 0) < 0 && <ArrowDownCircleIcon width="32px" />}
            </div>
            <p className="truncate text-xs text-product-gray700">{t('fromOneHourAgo')}</p>
          </>
        )}
      </SummaryTile>
    );
  },
);
