import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ICommonComponentProps } from '@/types';
import { TimezoneDropdownContent } from './TimezoneDropdownContent';
import {
  DropdownMenuButtonRoot,
  DropdownMenuContent,
  DropdownMenuRoot,
} from '@/components/primitives/DropdownMenu';
import { DropdownTriggerIcon } from '@/components/primitives/Dropdown/Dropdown';

interface ITimezoneDropdownProps extends ICommonComponentProps {
  onSelect?: (name: string) => void;
  disabled?: boolean;
  errors?: boolean;
  value?: string;
}

export const TimezoneDropdown: FC<ITimezoneDropdownProps> = memo(
  ({ onSelect, disabled, errors, value }) => {
    const { t } = useTranslation();

    return (
      <DropdownMenuRoot>
        <DropdownMenuButtonRoot
          data-id="timezone-dropdown"
          className={`bg-product-gray50 rounded-md flex-grow flex-1 w-full py-2 ${
            errors ? 'border-red-500 border-[1px]' : ''
          }`}
          disabled={disabled}
        >
          <div className={`flex w-full justify-between mx-2 ${disabled ? 'text-gray-500' : ''}`}>
            <span>{value ?? t('selectTimezone')}</span>
            <DropdownTriggerIcon className="mt-2" />
          </div>
        </DropdownMenuButtonRoot>
        <DropdownMenuContent>
          <TimezoneDropdownContent
            onSelect={({ name }) => {
              onSelect?.(name);
            }}
          ></TimezoneDropdownContent>
        </DropdownMenuContent>
      </DropdownMenuRoot>
    );
  },
);
