import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import type { Dayjs } from 'dayjs';
import { useInterval, useQuery } from '@/hooks';
import { EQueryParam } from '@/types';
import { useApolloClient } from '@apollo/client';
import { getCurrentTimeState, updateCurrent, updateTime } from '@/redux/reducers/time';
import { LOCAL_TZ } from '@/constants';

/**
 * Hooks for fetching space/room id
 * @returns space/room id
 */
export const useSRZId = (): { spaceId?: string; roomId?: string; zoneId?: string } => {
  const query = useQuery();
  const spaceId = query.get(EQueryParam.SID) || undefined;
  const roomId = query.get(EQueryParam.RID) || undefined;
  const zoneId = query.get(EQueryParam.ZID) || undefined;

  return { spaceId, roomId, zoneId };
};

/**
 * Hooks for fetching space/room data every ms
 * @param timezone - the timezone of the active view. null will disable refreshing.
 * @returns manual refresh function
 */
export const useFetchSR = (
  space?: {
    id: string;
    timezone: string;
  } | null,
): { triggerRefresh: () => void; recentRefreshTime?: Dayjs } => {
  const dispatch = useDispatch();
  const client = useApolloClient();
  const [manualRefreshId, setManualRefreshId] = useState(0);

  useEffect(() => {
    dispatch(updateTime(getCurrentTimeState(space?.timezone || LOCAL_TZ)));
  }, [dispatch, space?.timezone]);

  // Trigger manual refresh
  const triggerRefresh = useCallback(() => {
    setManualRefreshId((val) => ++val);
    client.resetStore();
  }, [client]);

  // WARNING: this is extremely fragile. messing up the stability
  // of this function will cause infinite loops of metrics requests.
  const refreshStats = useCallback(() => {
    if (!space) return;
    // Updating the time will invalidate all queries that are based on time (including stats queries)
    dispatch(
      updateCurrent({
        currentDate: new Date(),
      }),
    );
  }, [dispatch, space]);

  useInterval(120 * 1000, refreshStats, [manualRefreshId, refreshStats]);

  return { triggerRefresh };
};
