import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Hooks for extracting query parameters from current url
 * @returns query parameters
 */
export const useQuery = (): URLSearchParams => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};
