import React, { FC, SVGProps } from 'react';

/** @deprecated use <Icon name="..." /> (import from @/components/primitives/icons/Icon) instead. */
const SvgComponent: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      aria-hidden="true"
      data-prefix="fas"
      data-icon="primary-refresh"
      className="prefix__svg-inline--fa prefix__fa-primary-refresh prefix__fa-w-16"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 18"
      {...props}
    >
      <path
        d="M7.80105 8.66333C8.04655 8.66333 8.24126 8.58221 8.39364 8.43619L11.6783 5.25628C11.8645 5.07781 11.9492 4.88312 11.9492 4.64788C11.9492 4.42885 11.8561 4.20983 11.6783 4.03947L8.39364 0.843336C8.24126 0.681096 8.03808 0.599976 7.79258 0.599976C7.32697 0.599976 6.95449 0.973129 6.95449 1.4274C6.95449 1.65454 7.04761 1.833 7.19152 1.98713L9.00316 3.69066C8.65607 3.64198 8.30898 3.61765 7.96189 3.61765C3.98306 3.61765 0.799988 6.66777 0.799988 10.4885C0.799988 14.3174 4.01692 17.4 8.00422 17.4C11.9831 17.4 15.2 14.3174 15.2 10.4885C15.2 9.9937 14.8275 9.63677 14.3111 9.63677C13.8116 9.63677 13.473 9.9937 13.473 10.4885C13.473 13.4007 11.0349 15.737 8.00422 15.737C4.96507 15.737 2.53544 13.4007 2.53544 10.4885C2.53544 7.55198 4.93967 5.23194 7.96189 5.23194C8.44443 5.23194 8.88464 5.26439 9.28253 5.32117L7.19999 7.30051C7.04761 7.44652 6.95449 7.62499 6.95449 7.84401C6.95449 8.3064 7.32697 8.66333 7.80105 8.66333Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SvgComponent;
