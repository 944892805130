import { createSelector } from '@reduxjs/toolkit';
import { partition } from 'lodash';
import { type ItemType } from '../../utils/utils';
import type { Hive, Room, Sensor, Zone } from '../useGetState';
import type { RootState } from '@/redux/store';
import { existsFilter } from '@/utils/filtering';
import pointInPolygon from 'point-in-polygon';
import type { AugmentedFloorPlan } from './reducer';

const selectSelf = (state: RootState) => {
  return state.studio;
};

export const selectFloorplans = createSelector(selectSelf, (state): AugmentedFloorPlan[] =>
  [...state.base.floorplans, ...state.added.floorplans]
    .map((f) => ({
      ...f,
      opacity: 1,
      ...state.modified.floorplans[f.id],
    }))
    .filter((f) => !state.deleted.includes(f.id)),
);

export const selectDeletedSensors = createSelector(selectSelf, (state): Sensor[] => {
  return state.base.sensors.filter((s) => state.deleted.includes(s.id));
});

export const selectRooms = createSelector(selectSelf, (state): Room[] => {
  return [...state.base.rooms, ...state.added.rooms]
    .map((r) => ({
      ...r,
      ...state.modified.rooms[r.id],
    }))
    .filter((r) => !state.deleted.includes(r.id));
});

export const selectSensors = createSelector(selectSelf, selectRooms, (state, rooms): Sensor[] => {
  return [...state.base.sensors, ...state.added.sensors]
    .map((s) => ({
      ...s,
      ...state.modified.sensors[s.id],
    }))
    .map((s) => ({ ...s, room: rooms.find((r) => pointInPolygon(s.center, r.coordinates)) }))
    .filter((s) => !state.deleted.includes(s.id));
});

export const selectZones = createSelector(selectSelf, (state): Zone[] => {
  return [...state.base.zones, ...state.added.zones]
    .map((z) => ({
      ...z,
      ...state.modified.zones[z.id],
    }))
    .filter((z) => !state.deleted.includes(z.id));
});

export const selectHives = createSelector(selectSelf, (state): Hive[] => {
  return [...state.base.hives, ...state.added.hives]
    .map((z) => ({
      ...z,
      ...state.modified.hives[z.id],
    }))
    .filter((z) => !state.deleted.includes(z.id));
});

export const selectDirty = createSelector(
  selectSelf,
  (state): boolean =>
    Object.values(state.modified).some((s) => Object.keys(s).length > 0) ||
    Object.values(state.added).some((s) => s.length > 0) ||
    state.deleted.length > 0,
);

export const selectTypesPresent = createSelector(
  selectRooms,
  selectZones,
  selectSensors,
  selectHives,
  selectFloorplans,
  (rooms, zones, sensors, hives, floorplans): Set<ItemType> => {
    const types = new Set<ItemType>();
    if (rooms.length > 0) {
      types.add('room');
    }
    if (hives.length > 0) {
      types.add('hive');
    }
    if (zones.length > 0) {
      types.add('zone');
    }
    if (sensors.length > 0) {
      types.add('sensor');
    }
    if (floorplans.length > 0) {
      types.add('floorplan');
    }
    return types;
  },
);

export const selectAllLayerStates = createSelector(selectSelf, (state) => state.layers);
export const selectLayerHeatmaps = createSelector(
  selectAllLayerStates,
  (layers) => layers.heatmaps,
);
export const selectLayerFloorplans = createSelector(
  selectAllLayerStates,
  (layers) => layers.floorplans,
);
export const selectLayerRooms = createSelector(selectAllLayerStates, (layers) => layers.rooms);
export const selectLayerSensorModel = createSelector(
  selectAllLayerStates,
  (layers) => layers.sensorModel,
);

export const selectVisibleItems = createSelector(
  selectAllLayerStates,
  selectRooms,
  selectZones,
  selectHives,
  selectSensors,
  selectFloorplans,
  (
    layers,
    rooms,
    zones,
    hives,
    sensors,
    floorplans,
  ): (Sensor | Room | Zone | Hive | AugmentedFloorPlan)[] => {
    return [
      ...(layers.floorplans.visible ? floorplans : []),
      ...(layers.rooms.visible ? rooms : []),
      ...(layers.zones.visible ? zones : []),
      ...(layers.hives.visible ? hives : []),
      ...(layers.sensors.visible ? sensors : []),
    ];
  },
);

export const selectSelectedIds = createSelector(selectSelf, (state) => {
  // cast to only read methods/properties, we don't want modifications (which wouldn't do anything)
  return new Set(state.selected) as Omit<Set<string>, 'add' | 'clear'>;
});

export const selectErrors = createSelector(selectSelf, (state) => {
  return state.errors;
});

export const selectItemsGroupedBySelection = createSelector(
  selectVisibleItems,
  selectSelectedIds,
  (visibleItems, selectedIds) => {
    const [selected, unselected] = partition(visibleItems, (i) => selectedIds.has(i.id));
    return { selected, unselected };
  },
);

export const selectIsInitialized = createSelector(selectSelf, (state) => state.initialized);

export const selectUnplottedHivesCount = createSelector(
  selectSelf,
  (state) => state.base.unplotted.length - state.added.hives.length,
);
export const selectUnplottedHives = createSelector(selectSelf, (state) => state.base.unplotted);

export const selectCanUndo = createSelector(selectSelf, (state) => state.undoState.length > 0);
export const selectCanRedo = createSelector(selectSelf, (state) => state.redoState.length > 0);

const selectSearch = createSelector(selectSelf, (state) => state.search);
export const selectSearchString = createSelector(selectSearch, (search) => search.text);
export const selectSearchHoverId = createSelector(selectSearch, (search) => {
  return search.hoverItemId;
});

export const selectAreRelatedDevicesHighlighted = createSelector(
  selectSelf,
  (state) => state.highlightRelatedDevices,
);

export const selectSelectedHovered = createSelector(selectSelf, (state) => state.selectedHovered);

export const selectIsHighlighted = createSelector(
  [selectSelf, (_, id: string) => id],
  (state, id) => {
    if (!state.highlightRelatedDevices) return false;
    // for all selected hives, we highlight sensors paired to them.
    const selectedHiveIds = state.selected.filter((id) => id.startsWith('hive_'));

    // for all selected sensors, we highlight the parent hive and all 'siblings'
    const selectedSensorIds = state.selected.filter((id) => id.startsWith('sensor_'));
    const selectedSensors = state.base.sensors.filter((s) => selectedSensorIds.includes(s.id));

    const hivesToHighlight = new Set<string>([
      ...selectedHiveIds,
      ...selectedSensors.map((s) => s.hive?.id).filter(existsFilter),
    ]);

    // go ahead and return true if the id in question is one of the hives
    if (hivesToHighlight.has(id)) return true;

    // otherwise if the id matches a sensor, does its hive exist in the set?
    const sensor = state.base.sensors.find((s) => s.id === id);
    if (sensor && sensor.hive && hivesToHighlight.has(sensor.hive.id)) return true;

    // nothing matched.
    return false;
  },
);
