import { ICommonComponentProps } from '@/types/Common';
import { CaretDownIcon, CaretUpIcon } from '@/components';
import clsx from 'clsx';

interface IPercentDisplayProps extends ICommonComponentProps {
  percent: number;
}

export const PercentDisplay = ({ percent, className, style }: IPercentDisplayProps) => {
  return (
    <span className={clsx(className, 'flex')} style={{ ...style }}>
      {percent >= 0 ? (
        <CaretUpIcon
          width="12px"
          className={clsx(
            'mt-[18px] mr-1',
            percent > 0 && 'text-green-500',
            percent === 0 && 'text-gray-400',
          )}
        />
      ) : (
        <CaretDownIcon className="mt-[18px] mr-1 text-red-500" width="12px" />
      )}
      <span
        className={clsx(
          'mr-1 mt-5 text-xs font-semibold leading-5',
          percent && percent > 0 && 'text-green-500',
          percent && percent < 0 && 'text-red-500',
          (!percent || percent === 0) && 'text-gray-400',
        )}
      >
        {percent.toFixed(0) || 0}%
      </span>
    </span>
  );
};
