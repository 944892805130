import React, { FC, SVGProps } from 'react';

/** @deprecated use <Icon name="..." /> (import from @/components/primitives/icons/Icon) instead. */
const SvgComponent: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.375 6.90625H13.875C13.9438 6.90625 14 6.85 14 6.78125V5.90625C14 5.8375 13.9438 5.78125 13.875 5.78125H6.375C6.30625 5.78125 6.25 5.8375 6.25 5.90625V6.78125C6.25 6.85 6.30625 6.90625 6.375 6.90625ZM6.25 10.0938C6.25 10.1625 6.30625 10.2188 6.375 10.2188H13.875C13.9438 10.2188 14 10.1625 14 10.0938V9.21875C14 9.15 13.9438 9.09375 13.875 9.09375H6.375C6.30625 9.09375 6.25 9.15 6.25 9.21875V10.0938ZM14.125 2.5H1.875C1.80625 2.5 1.75 2.55625 1.75 2.625V3.5C1.75 3.56875 1.80625 3.625 1.875 3.625H14.125C14.1938 3.625 14.25 3.56875 14.25 3.5V2.625C14.25 2.55625 14.1938 2.5 14.125 2.5ZM14.125 12.375H1.875C1.80625 12.375 1.75 12.4312 1.75 12.5V13.375C1.75 13.4438 1.80625 13.5 1.875 13.5H14.125C14.1938 13.5 14.25 13.4438 14.25 13.375V12.5C14.25 12.4312 14.1938 12.375 14.125 12.375ZM1.80313 8.10781L4.24531 10.0312C4.33594 10.1031 4.47031 10.0391 4.47031 9.92344V6.07656C4.47031 5.96094 4.3375 5.89687 4.24531 5.96875L1.80313 7.89219C1.7867 7.90495 1.77342 7.9213 1.76427 7.93999C1.75513 7.95867 1.75038 7.9792 1.75038 8C1.75038 8.0208 1.75513 8.04133 1.76427 8.06001C1.77342 8.0787 1.7867 8.09505 1.80313 8.10781Z"
        fill="currentColor"
        fillOpacity="0.88"
      />
    </svg>
  );
};

export default SvgComponent;
