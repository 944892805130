import { createContext, ReactNode, FC, useState, useContext } from 'react';
import { SVRenderer } from '@/components';
import '@/translations/i18n';

export interface ISVRendererContextProps {
  svInstance?: SVRenderer;
  setSVInstance: (s?: SVRenderer) => void;
}

interface SVRendererContextProviderProps {
  children: ReactNode;
}

export const SVRendererContextProvider: FC<SVRendererContextProviderProps> = ({ children }) => {
  const [svInstance, setSVInstance] = useState<SVRenderer>();

  return (
    <SVRendererContext.Provider
      value={{
        svInstance,
        setSVInstance,
      }}
    >
      {children}
    </SVRendererContext.Provider>
  );
};

export const SVRendererContext = createContext<ISVRendererContextProps>({
  setSVInstance: () => {},
});

export const useSVRendererContext = () => {
  const context = useContext(SVRendererContext);

  return context;
};
