import { useImpersonateClient } from '@/api/impersonate';
import {
  DialogContent,
  DialogRoot,
  DialogTitle,
  LoadingSpinner,
  SearchDropdown,
} from '@/components';
import { useAuthUser } from '@/hooks';
import { useGetSuperuserClientsQuery } from '@/hooks/api/users';
import { skipToken } from '@reduxjs/toolkit/query';
import { useMemo, useRef, useState } from 'react';
import toast from 'react-hot-toast';

export interface NewOrgSwitcherProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export function NewOrgSwitcher({ open, onOpenChange }: NewOrgSwitcherProps) {
  const { isSuperUser } = useAuthUser();
  // just to be sure, we skip this query if the user isn't a superuser.
  const { data: clients } = useGetSuperuserClientsQuery(isSuperUser ? undefined : skipToken);
  const [isSwitching, setIsSwitching] = useState(false);

  const flatKits = useMemo(
    () =>
      Object.entries(clients ?? {}).flatMap(([name, client]) =>
        Object.entries(client).map(([clientId, info]) => ({
          organizationName: name,
          clientId,
          emails: info.emails,
        })),
      ),
    [clients],
  );

  const impersonate = useImpersonateClient();
  const selectKit = async (
    kit: { organizationName: string; clientId: string; emails: string[] } | undefined,
  ) => {
    if (!kit) return;
    setIsSwitching(true);
    try {
      await impersonate(kit.clientId);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Failed to switch organizations', err);
      toast.error('Failed to switch organizations');
      setIsSwitching(false);
    }
  };

  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <DialogRoot open={open} onOpenChange={onOpenChange}>
      <DialogContent className="top-1/4" ref={containerRef}>
        <DialogTitle>Switch Organization</DialogTitle>
        {isSwitching ? (
          <LoadingSpinner size={32} />
        ) : (
          <SearchDropdown
            options={flatKits}
            onChange={selectKit}
            placeholder="Organization name, kit name, email..."
            optionToSearchValue={(option) =>
              option
                ? `${option.organizationName} ${option.emails
                    .map((e) => e.split('@')[0])
                    .join(' ')} ${option.clientId}`
                : ''
            }
            renderOption={(option) => (
              <div className="w-full flex flex-row divide-x">
                <div className="font-bold flex-grow-[1] [flex-basis:33%] capitalize pr-4 whitespace-normal overflow-hidden">
                  {option.organizationName}
                </div>
                <div className="text-sm flex-grow-[2] [flex-basis:66%] capitalize pl-4 whitespace-normal overflow-hidden">
                  {option.emails.map((e) => e.split('@')[0]).join(', ')}
                </div>
              </div>
            )}
            containerRef={containerRef}
          />
        )}
      </DialogContent>
    </DialogRoot>
  );
}
