import { ICommonComponentProps } from '@/types';

import { Popover, PopoverAnchor, PopoverContent, PopoverPortal } from '@radix-ui/react-popover';
import clsx from 'clsx';
import { useCombobox } from 'downshift';
import { useMemo, useState } from 'react';
import { twMerge } from 'tailwind-merge';
interface ITagDropdownProps<TOption = string> extends ICommonComponentProps {
  placeholder?: string;
  options: TOption[];
  onChange?: (s: TOption | undefined) => void;
  onHover?: (s: TOption | undefined) => void;
  onClear?: () => void;
  /**
   * Function to convert an option into a string that can be searched to filter down options.
   */
  optionToSearchValue?: OptionStringifier<TOption>;
  /**
   * Function to convert an option into a string for rendering in the search input field. Defaults
   * to the same as `optionToSearchValue` if not provided.
   */
  optionToFieldText?: OptionStringifier<TOption>;
  /**
   * Function to convert an option into a unique key for rendering. Defaults to the same as
   * `optionToSearchValue` if not provided.
   */
  optionToKey?: OptionStringifier<TOption>;
  renderOption?: (
    option: TOption,
    meta: { isCreateFromInput: boolean; searchValue: string },
  ) => React.ReactNode;
  /** React content to render inside of the dropdown menu before the results (for example, for filtering). */
  renderBeforeContent?: () => React.ReactNode;
  sortOptions?: (a: TOption, b: TOption, searchValue: string) => number;
  name?: string;
  containerRef?: React.RefObject<HTMLElement>;
  disabled?: boolean;
  value?: TOption;
  onInputChange?: (inputValue: string) => void;
  emptyContent?: React.ReactNode;
  id?: string;
  onBlur?: () => void;
  disableDefaultHighlight?: boolean;
  size?: 'sm' | 'md';
  /**
   * If provided, the dropdown will show a "create new" option at the bottom of the list
   * when the input doesn't exactly match any other option. Clicking it will trigger the
   * onChange event with a TOption which you compute with this function from the input
   * value.
   */
  createFromInput?: (inputValue: string) => TOption;
  enableHotkey?: boolean;
  hideCheck?: boolean;
}
type OptionStringifier<T extends unknown> = (option: T | null) => string;
const defaultOptionToSearchValue = <T extends unknown>(option: T | null) => `${option}`;
const defaultRenderOption = <T extends unknown>(
  option: T | null,
  { isCreateFromInput }: { isCreateFromInput: boolean },
) => `${isCreateFromInput ? 'Add: ' : ''}${option}`;
const createDefaultSortOption =
  <T extends unknown>(optionToSearchValue: OptionStringifier<T>) =>
  (a: T, b: T, searchValue: string) => {
    const aSearchValue = optionToSearchValue(a);
    const bSearchValue = optionToSearchValue(b);

    const aSearchValueIndex = aSearchValue.toLowerCase().indexOf(searchValue.toLowerCase());
    const bSearchValueIndex = bSearchValue.toLowerCase().indexOf(searchValue.toLowerCase());
    if (aSearchValueIndex !== bSearchValueIndex) return aSearchValueIndex - bSearchValueIndex;
    return aSearchValue.localeCompare(bSearchValue);
  };

const defaultEmptyContent = (
  <div className="flex items-center justify-center text-gray-500">No results found</div>
);

export const TagDropdown = <TOption extends unknown = string>({
  placeholder,
  options,
  onChange,
  onHover,
  onClear,
  optionToSearchValue = defaultOptionToSearchValue,
  optionToFieldText,
  optionToKey = optionToSearchValue,
  renderOption = defaultRenderOption,
  renderBeforeContent: renderFilters,
  sortOptions: providedSortOptions,
  name,
  containerRef,
  value,
  disabled,
  className,
  onBlur,
  onInputChange,
  emptyContent = defaultEmptyContent,
  size,
  id,
  createFromInput,
  disableDefaultHighlight,
  enableHotkey,
  hideCheck,
  ...rest
}: ITagDropdownProps<TOption>) => {
  const sortOptions = useMemo(() => {
    if (providedSortOptions) return providedSortOptions;
    return createDefaultSortOption(optionToSearchValue);
  }, [providedSortOptions, optionToSearchValue]);
  const [searchValue, setSearchValue] = useState('');

  // Filter options by search value
  const filteredOptions = options
    .filter((option) =>
      optionToSearchValue(option).toLowerCase().includes(searchValue.toLowerCase()),
    )
    .sort((a, b) => sortOptions(a, b, searchValue));

  const exactMatch = options.find((option) => optionToSearchValue(option) === searchValue);
  // keeping track of which option is the "create" one lets us
  // allow the UI to render it differently.
  let createIndex = -1;
  if (createFromInput && !exactMatch) {
    filteredOptions.push(createFromInput(searchValue));
    createIndex = filteredOptions.length - 1;
  }

  const { isOpen, getMenuProps, getInputProps, highlightedIndex, getItemProps, closeMenu } =
    useCombobox<TOption>({
      defaultIsOpen: true,
      onInputValueChange({ inputValue }) {
        setSearchValue(inputValue ?? '');
        onInputChange?.(inputValue ?? '');
      },
      onSelectedItemChange({ selectedItem: newValue }) {
        onChange?.(newValue ?? undefined);
      },
      items: filteredOptions,
      itemToString: optionToFieldText ?? optionToSearchValue,
      defaultHighlightedIndex: disableDefaultHighlight ? -1 : 0,
      onHighlightedIndexChange: onHover
        ? ({ highlightedIndex: idx }) => {
            // zero is a valid index, so we need to check for undefined instead.
            if (idx === undefined || !isOpen) return;
            if (idx === -1) {
              onHover(undefined);
            } else {
              onHover(filteredOptions[idx]);
            }
          }
        : undefined,
      selectedItem: value,
      stateReducer: (state, actionAndChanges) => {
        const { changes, type } = actionAndChanges;
        if (type === useCombobox.stateChangeTypes.InputBlur) {
          return {
            ...changes,
            isOpen: true,
          };
        }
        return changes;
      },
    });

  // when there's a search parameter, but no results (and no "create new" option either),
  // we show the provided empty content
  const showEmptyContent = searchValue !== '' && !createFromInput && !filteredOptions.length;
  // however, if there's no search parameter, we don't show the empty content,
  // or open the dropdown
  const isDropdownOpen = isOpen && (searchValue !== '' || highlightedIndex !== -1);

  return (
    <Popover
      open={isDropdownOpen}
      onOpenChange={(open) => {
        if (!open) closeMenu();
      }}
    >
      <PopoverAnchor {...rest} className={twMerge(className)}>
        <input
          className="focus:outline-none h-[22px] bg-transparent min-w-[100px] max-w-[100px] text-xs border rounded px-2"
          placeholder={placeholder}
          aria-label={placeholder}
          name={name}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          {...getInputProps({ id, disabled, autoComplete: 'off', onBlur })}
        />
      </PopoverAnchor>
      <PopoverPortal>
        <PopoverContent
          side="bottom"
          sideOffset={5}
          // prevent popover from being focused on open - we want to keep focus on the input
          onOpenAutoFocus={preventDefault}
          className={clsx(
            'focus:outline-none rounded-md bg-white shadow-lg text-gray-900 ring-1 ring-opacity-5 ring-black overflow-y-hidden h-auto z-50 flex flex-col text-xs',
            'will-change-[transform] radix-state-open:animate-popoverIn radix-state-closed:animate-popoverOut max-h-[calc(var(--radix-popover-content-available-height)-16px)]',
            'font-display',
          )}
        >
          {renderFilters && renderFilters()}
          <ul
            className="focus:outline-none h-auto divide-y divide-gray-100 overflow-y-auto"
            // ref error - since popover doesn't mount until visible, the ref is not assigned
            // until that happens... while not ideal, the component is still functional.
            {...getMenuProps({}, { suppressRefError: true })}
          >
            {filteredOptions.map((option, index) => (
              <li
                className={clsx(
                  'flex cursor-pointer gap-1 px-2 py-2 text-gray-800',
                  highlightedIndex === index && 'bg-product-gray100',
                )}
                key={optionToKey(option)}
                data-id="search-option"
                {...getItemProps({ item: option, index })}
              >
                <div
                  className={clsx(
                    'flex-grow flex flex-row items-center text-xs',
                    'gray text-gray-800',
                  )}
                >
                  {renderOption(option, { isCreateFromInput: index === createIndex, searchValue })}
                </div>
              </li>
            ))}
          </ul>
          {showEmptyContent && <div className="flex flex-col py-2 px-4">{emptyContent}</div>}
        </PopoverContent>
      </PopoverPortal>
    </Popover>
  );
};

function preventDefault(ev: Event) {
  ev.preventDefault();
}
