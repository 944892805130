import { History } from 'history';
export function updateUrlParams(
  history: History<unknown>,
  params: { floorId?: string; roomId?: string; zoneId?: string },
) {
  const currentUrl = new URL(location.href);
  const searchParams = new URLSearchParams(currentUrl.search);

  if (params.floorId) {
    searchParams.set('spaceId', params.floorId);
  }

  if (params.roomId) {
    searchParams.set('roomId', params.roomId);
  } else {
    searchParams.delete('roomId');
  }

  if (params.zoneId) {
    searchParams.set('zoneId', params.zoneId);
  } else {
    searchParams.delete('zoneId');
  }
  history.push({ search: searchParams.toString() });
}
