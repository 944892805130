import React, { FC, SVGProps } from 'react';

const WarningIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.01719 17H15.9904C16.398 17 16.752 16.9131 17.0525 16.7393C17.3531 16.5654 17.5848 16.3313 17.7479 16.0367C17.916 15.747 18 15.4308 18 15.0879C18 14.9238 17.9796 14.7644 17.9389 14.6099C17.8981 14.4506 17.8319 14.2961 17.7402 14.1464L11.7421 3.97775C11.5486 3.64942 11.2964 3.40559 10.9857 3.24625C10.6749 3.08208 10.3489 3 10.0076 3C9.66635 3 9.33779 3.08208 9.02197 3.24625C8.70614 3.41042 8.454 3.65425 8.26552 3.97775L2.26743 14.1536C2.08914 14.4578 2 14.7693 2 15.0879C2 15.4308 2.08405 15.747 2.25215 16.0367C2.42025 16.3313 2.65457 16.5654 2.95511 16.7393C3.25565 16.9131 3.60968 17 4.01719 17ZM10.0153 11.9157C9.5008 11.9157 9.23337 11.9688 9.21299 11.4811L9.09838 7.81635C9.08819 7.5701 9.16714 7.36972 9.33524 7.21521C9.50334 7.05587 9.72748 6.9762 10.0076 6.9762C10.2776 6.9762 10.4992 7.05587 10.6724 7.21521C10.8507 7.36972 10.9322 7.5701 10.9169 7.81635L10.7947 11.4739C10.7743 11.9664 10.5145 11.9157 10.0153 11.9157ZM10.0153 14.4723C9.73002 14.4723 9.48806 14.3927 9.2894 14.2333C9.09583 14.0691 8.99904 13.8591 8.99904 13.6032C8.99904 13.3473 9.09583 13.1373 9.2894 12.9731C9.48806 12.8089 9.73002 12.7268 10.0153 12.7268C10.2954 12.7268 10.5323 12.8089 10.7259 12.9731C10.9245 13.1373 11.0239 13.3473 11.0239 13.6032C11.0239 13.8591 10.9245 14.0691 10.7259 14.2333C10.5323 14.3927 10.2954 14.4723 10.0153 14.4723Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default WarningIcon;
