import React, { FC, memo, ReactNode } from 'react';
import { QuestionTooltip, Card, Tag, ETagVariants } from '@/components';
import { IQuestionTooltipContent, ICommonComponentProps } from '@/types';
import clsx from 'clsx';

interface ISummaryTileProps extends ICommonComponentProps {
  children: ReactNode;
  title?: string;
  label?: string;
  tooltip?: IQuestionTooltipContent;
  beta?: boolean;
}

export const SummaryTile: FC<ISummaryTileProps> = memo(
  ({ className, style, children, title, label = '', tooltip, beta = false, ...rest }) => {
    return (
      <div className={clsx('relative', className)} style={style} {...rest}>
        <Card className="flex flex-col justify-end px-5 py-5">
          <div className="flex flex-basis-auto flex-grow justify-between">
            <div>
              <h3 className="text-base inline-block font-semibold text-product-gray900 mb-4">
                {title}
              </h3>
              {tooltip && (
                <QuestionTooltip
                  className="ml-1.5 flex-shrink-0 w-3"
                  placement="right"
                  {...tooltip}
                  variant="outline"
                />
              )}
            </div>
            {beta && <Tag variant={ETagVariants.BETA} />}
          </div>
          {label && (
            <p className="mb-4 text-xs font-normal leading-5 text-product-gray900">{label}</p>
          )}
          {children}
        </Card>
      </div>
    );
  },
);
