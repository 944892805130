import React, { FC, SVGProps } from 'react';

/** @deprecated use <Icon name="..." /> (import from @/components/primitives/icons/Icon) instead. */
const SvgComponent: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      className="svg-icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M810.005333 274.005333l-237.994667 237.994667 237.994667 237.994667-60.010667 60.010667-237.994667-237.994667-237.994667 237.994667-60.010667-60.010667 237.994667-237.994667-237.994667-237.994667 60.010667-60.010667 237.994667 237.994667 237.994667-237.994667z" />
    </svg>
  );
};

export default SvgComponent;
