import React, { memo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ICommonComponentProps } from '@/types';
import clsx from 'clsx';

export enum ETagVariants {
  BETA = 'beta',
  GRAY = 'gray',
  BLACK = 'black',
  ORANGE = 'orange',
  RED = 'red',
}

interface ITagProps extends ICommonComponentProps {
  variant?: ETagVariants;
  label?: string;
}

export const Tag: FC<ITagProps> = memo(({ className, style, variant, label, ...rest }) => {
  const { t } = useTranslation();
  return (
    <span
      className={clsx(
        'whitespace-nowrap rounded py-1 px-3 text-xxs font-bold',
        variant === ETagVariants.BETA && 'bg-product-red50 px-2 text-product-red500',
        variant === ETagVariants.GRAY && 'bg-product-gray50 text-product-gray400',
        variant === ETagVariants.BLACK && 'bg-product-gray110 text-product-gray50',
        variant === ETagVariants.ORANGE && 'bg-product-orange500 text-product-gray50',
        variant === ETagVariants.RED && 'bg-product-red400 text-product-gray50',
        className,
      )}
      style={style}
      {...rest}
    >
      {variant === ETagVariants.BETA ? t('beta') : label}
    </span>
  );
});
