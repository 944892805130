import React, { FC, SVGProps } from 'react';

const OrganizationLogo: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="60"
      height="20"
      viewBox="0 0 60 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Butlr_Logo-White" clipPath="url(#clip0_3629_22082)">
        <path
          id="Vector"
          d="M60 6.00931L58.9855 9.61346C58.1449 9.13849 57.4783 9.11055 57.0435 9.11055C55.3623 9.11055 53.971 10.4796 53.971 14.3352V18.6657H49.6812V5.61816H53.913V8.38414C54.6957 6.23282 56.3188 5.61816 57.971 5.61816C58.6087 5.61816 59.3043 5.67404 60 6.00931Z"
          fill="white"
        />
        <path id="Vector_2" d="M42.628 18.6658V0.952393H46.9179V18.6658H42.628Z" fill="white" />
        <path
          id="Vector_3"
          d="M41.0627 5.61823V8.74741H38.2221V18.6658H33.9323V8.74741H31.4685V5.61823H33.9323V0.952393H38.2221V5.61823H41.0627Z"
          fill="white"
        />
        <path
          id="Vector_4"
          d="M21.1208 5.61816V11.1687C21.1208 13.3573 21.1884 15.1361 23.1691 15.1361C25.1401 15.1361 25.0918 13.3666 25.0918 11.1687V5.61816H29.6135V13.0965C29.6135 14.1582 29.4589 15.0709 29.1594 15.8439C28.8695 16.533 28.3671 17.157 27.6522 17.7065C26.4734 18.6005 24.9758 19.0569 23.1594 19.0569C21.3526 19.0569 19.8551 18.6098 18.6763 17.7065C17.9517 17.157 17.4396 16.533 17.1401 15.8439C16.8502 15.2292 16.7053 14.3165 16.7053 13.0965V5.61816H21.1208Z"
          fill="white"
        />
        <path
          id="Vector_5"
          d="M15.0435 12.128C15.0435 16.3468 11.8551 19.0011 8.46377 19.0011C7.07246 19.0011 5.27536 18.4702 4.28986 17.213V18.6658H0V0.952393H4.28986V7.04312C5.27536 5.8138 7.07246 5.28296 8.46377 5.28296C11.8551 5.28296 15.0435 7.90923 15.0435 12.128ZM10.6957 12.128C10.6957 10.4796 9.24638 9.11062 7.56522 9.11062C5.94203 9.11062 4.43478 10.4517 4.43478 12.128C4.43478 13.8044 5.91304 15.1734 7.56522 15.1734C9.24638 15.1734 10.6957 13.7765 10.6957 12.128Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3629_22082">
          <rect width="60" height="18.0952" fill="white" transform="translate(0 0.952393)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default OrganizationLogo;
