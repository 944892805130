import { useAuthChangePassword } from '@/hooks';
import { useGetUserSupportedLanguagesQuery } from '@/hooks/api/users';
import { FC, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  SelectButton,
  SelectContent,
  SelectItem,
  SelectRoot,
} from '@/components/primitives/Select/Select';
import { useUserPreferences } from './useUserPreferences';
import { useAppDispatch } from '@/redux/hooks';
import { closeDialog } from '@/redux/reducers/dialog';

export const UserSettings: FC<{}> = memo(() => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { loadingChangePassword, triggerChangePassword } = useAuthChangePassword();
  const { data: supportedLanguages } = useGetUserSupportedLanguagesQuery();
  const { preferences, setTempPreferences, saveUserPreferences, clearTempPreferences } =
    useUserPreferences();

  // Handler for close
  const handleClose = useCallback(() => {
    clearTempPreferences();
    dispatch(closeDialog());
  }, [dispatch, clearTempPreferences]);

  // Handler for done
  const handleDone = useCallback(() => {
    saveUserPreferences();
    dispatch(closeDialog());
  }, [dispatch, saveUserPreferences]);

  return (
    <>
      <div className="flex w-full flex-col min-w-[300px]">
        <p className="mb-1 text-sm text-product-gray600">{t('language')}</p>
        <SelectRoot
          value={preferences?.language || supportedLanguages?.default}
          onValueChange={(value) => setTempPreferences({ language: value })}
        >
          <SelectButton />
          <SelectContent>
            {Object.entries(supportedLanguages?.languages || {}).map(([key, label]) => (
              <SelectItem key={key} value={key}>
                {label}
              </SelectItem>
            ))}
          </SelectContent>
        </SelectRoot>
      </div>
      <hr />
      <p className="text-xs text-gray-600">
        {t('toChangePassword')}
        <button
          className="text-red-600"
          onClick={triggerChangePassword}
          disabled={loadingChangePassword}
        >
          {t('clickHere')}
        </button>
      </p>
      <div className="flex w-full justify-end gap-6">
        <button
          data-id="userSettingCancelButton"
          className="rounded-md bg-transparent px-6 py-2 text-gray-900"
          onClick={handleClose}
        >
          {t('cancel')}
        </button>
        <button
          data-id="userSettingDoneButton"
          className="rounded-md bg-gray-900 px-6 py-2 text-white"
          onClick={handleDone}
        >
          {t('done')}
        </button>
      </div>
    </>
  );
});
