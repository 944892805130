/**
 * Utilities for interpreting API errors, as defined in our API codebase
 * at https://github.com/butlrtechnologies/butlr-api-container/blob/main/pkg/common/butlrerror/error.go
 * (as of writing)
 */

import { GraphQLError } from 'graphql';

/**
 * Our error codes are 5-digit numbers which begin
 * with an HTTP code prefix. This function extracts
 * the HTTP code from the error code.
 */
export function getErrorCodeGroup(error: GraphQLError) {
  const code = error.extensions?.code;
  if (!code || typeof code !== 'number') {
    return 500;
  }
  return Math.floor(code / 100);
}

export function isErrorNotAuthenticated(error: GraphQLError) {
  return getErrorCodeGroup(error) === 401;
}

export function isErrorBadRequest(error: GraphQLError) {
  return getErrorCodeGroup(error) === 400;
}

export function isErrorNotFound(error: GraphQLError) {
  return getErrorCodeGroup(error) === 404;
}

export function isErrorConflict(error: GraphQLError) {
  return getErrorCodeGroup(error) === 409;
}

export function isErrorInternal(error: GraphQLError) {
  return getErrorCodeGroup(error) === 500;
}
