import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { PersonFilledIcon, SummaryTile, LoadingSpinner } from '@/components';
import { IQuestionTooltipContent, ICommonComponentProps } from '@/types';
import { Icon } from '@/components/primitives/icon/Icon';

interface IDailyHeadcountSummaryTileProps extends ICommonComponentProps {
  title?: string;
  label?: string;
  value?: number | null;
  percent?: number | null;
  tooltip?: IQuestionTooltipContent;
  loading?: boolean;
  error?: string;
}

export const DailyHeadcountSummaryTile: FC<IDailyHeadcountSummaryTileProps> = memo(
  ({
    className,
    style,
    title = '',
    label = '',
    value = null,
    percent = null,
    tooltip,
    loading = false,
    error = '',
    ...rest
  }) => {
    const { t } = useTranslation();
    return (
      <SummaryTile
        className={className}
        style={style}
        title={title}
        label={label}
        tooltip={tooltip}
        {...rest}
      >
        {loading ? (
          <div className="absolute inset-0 z-10 mr-1 flex h-full w-full items-center justify-center truncate rounded-2xl bg-white text-xs font-semibold">
            <LoadingSpinner />
          </div>
        ) : error ? (
          <div className="absolute inset-0 z-10 mr-1 flex h-full w-full items-center justify-center truncate rounded-2xl bg-white text-xs font-semibold">
            {/* <Error message={error} size={ESize.sm} /> */}
          </div>
        ) : (
          <>
            <div className="mb-4 flex items-center text-3xl font-light tracking-0.03em text-product-gray700">
              <span className="font-semibold text-product-gray900">{value}</span>
              <PersonFilledIcon className="mt-2" width="11px" fill="#535565" />
            </div>
            <div className="flex text-xs text-product-gray700">
              {percent !== null && percent > 0 && <Icon name="up" className="mr-2 w-2" size={8} />}
              {percent !== null && percent < 0 && (
                <Icon name="down" className="mr-2 w-2" size={8} />
              )}
              <p className="w-9/12 truncate">
                <span className="mr-1 font-semibold">{percent?.toFixed(2)}%</span>
                {t('fromLastWeek')}
              </p>
            </div>
          </>
        )}
      </SummaryTile>
    );
  },
);
