import React, { FC, SVGProps } from 'react';

/** @deprecated use <Icon name="..." /> (import from @/components/primitives/icons/Icon) instead. */
const SvgComponent: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      aria-hidden="true"
      data-prefix="far"
      data-icon="minus"
      className="prefix__svg-inline--fa prefix__fa-user prefix__fa-w-14"
      viewBox="0 0 14 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M0.699951 0.999976C0.699951 0.502919 1.1029 0.0999756 1.59995 0.0999756H12.4C12.897 0.0999756 13.3 0.502919 13.3 0.999976C13.3 1.49703 12.897 1.89998 12.4 1.89998H1.59995C1.10289 1.89998 0.699951 1.49703 0.699951 0.999976Z" />
    </svg>
  );
};

export default SvgComponent;
