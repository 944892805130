import React, { ReactNode, FC, memo } from 'react';
import * as CollapsibleComponent from '@radix-ui/react-collapsible';
import { ChevronDownIcon, ChevronUpIcon } from '@/components';
import clsx from 'clsx';

type CollapsibleProps = {
  title: ReactNode;
  children: ReactNode;
  nested?: boolean;
};

export const Collapsible: FC<CollapsibleProps> = memo(({ title, children, nested }) => {
  const [open, setOpen] = React.useState(false);
  return (
    <CollapsibleComponent.Root
      className={clsx(nested ? 'mb-2 pr-2' : 'mb-4', 'w-full')}
      open={open}
      onOpenChange={setOpen}
    >
      <CollapsibleComponent.Trigger asChild>
        <div className="flex justify-between align-middle">
          {title}
          <button
            aria-label="Collapsible Toggle"
            data-id="collapsible-toggle"
            className={clsx(
              nested ? ' h-[10px] w-[10px]' : ' h-[14px] w-[14px]',
              'inline-flex justify-center align-middle text-gray-600',
            )}
          >
            {open ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </button>
        </div>
      </CollapsibleComponent.Trigger>

      <CollapsibleComponent.Content
        aria-label="Collapsible Content"
        className="text-xs text-gray-900"
      >
        {children}
      </CollapsibleComponent.Content>
    </CollapsibleComponent.Root>
  );
});
