import { gql } from '@apollo/client';
import { useUpdateSiteInfoMutation, useGetSitesQuery } from '@/.generated/graphql';
import Close from '../Icons/Close';
import { closeDialog } from '@/redux/reducers/dialog';
import { useAppDispatch } from '@/redux/hooks';
import { Button } from '@/components/primitives';
import { Input, SubmitButton } from '@/components';
import { useForm } from 'react-hook-form';
import { datadogRum } from '@datadog/browser-rum';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { TimezoneDropdown } from '@/components';

interface SiteProps {
  siteId: string;
  onSave: () => void;
}
export const EditSiteComponent = ({ siteId, onSave }: SiteProps) => {
  const { data: siteData } = useGetSitesQuery({ variables: { siteId: siteId } });
  const { t } = useTranslation();
  const [save] = useUpdateSiteInfoMutation({
    refetchQueries: ['useSidebarSiteQuery'],
  });
  const [editing, setEditing] = useState(false);
  const site = siteData?.site;
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { isValid, isDirty, errors },
    clearErrors,
  } = useForm({
    resetOptions: { keepDirty: true },
    values: {
      name: site?.name,
      customID: site?.customID,
      id: siteId,
      timezone: site?.timezone ?? '',
    },
    mode: 'onSubmit',
  });

  const dispatch = useAppDispatch();
  const watchTimezone = watch('timezone');

  return (
    <form
      onSubmit={handleSubmit(async (values) => {
        datadogRum.addAction('Submit form on sites modal', { source: 'sites-and-buildings' });
        if (!isValid) {
          return;
        }
        try {
          await save({
            variables: {
              input: {
                id: values.id,
                name: values.name,
                customID: values.customID,
                timezone: values.timezone,
              },
            },
          });
          onSave();
          dispatch(closeDialog());
        } catch (error) {
          console.error('Error saving site:', error);
        }
      })}
    >
      <div className="flex justify-between text-xl py-3">
        <div>Site Details</div>
        <button onClick={() => dispatch(closeDialog())}>
          <Close />
        </button>
      </div>
      <div>
        <Input
          disabled={!editing}
          type="text"
          variant="narrow"
          label="Site Name"
          data-id="sitename-input"
          wrapperClassName="pt-3 mx-3"
          errorMessage={errors.name && errors.name.message}
          {...register('name', { required: "Site name can't be empty." })}
        />
        <Input
          disabled={!editing}
          type="text"
          variant="narrow"
          label={t('customId')}
          data-id="siteCustomId-input"
          wrapperClassName="py-3 mx-3"
          {...register('customID')}
        />
        <div>
          <span className="text-product-gray600 text-sm mx-3 ml-1">Timezone</span>
          <TimezoneDropdown
            {...register('timezone', { required: 'Timezone is required, please select one.' })}
            onSelect={(name) => {
              setValue('timezone', name, { shouldDirty: true });
              clearErrors('timezone');
            }}
            disabled={!editing}
            value={watchTimezone}
            errors={!!errors.timezone}
          ></TimezoneDropdown>
          {!!errors.timezone && (
            <div className="text-red-500 text-xs">{errors.timezone.message}</div>
          )}
        </div>
      </div>

      <div className="flex justify-end pt-5">
        <Button
          variant="ghost"
          onClick={() => dispatch(closeDialog())}
          className="mr-3"
          data-id="edit-site-cancel"
        >
          Cancel
        </Button>
        {!editing ? (
          <Button
            onClick={() => {
              datadogRum.addAction('Enter edit mode on sites modal', {
                source: 'sites-and-buildings',
              });
              setEditing(!editing);
            }}
            data-id="edit-site-edit"
          >
            Edit
          </Button>
        ) : (
          <SubmitButton disabled={!isDirty}>Save</SubmitButton>
        )}
      </div>
    </form>
  );
};

EditSiteComponent.fragments = {
  Site: gql`
    fragment SiteFragment on Site {
      id
      name
      customID
      timezone
    }
  `,
};
EditSiteComponent.queries = {
  Site: gql`
    query GetSites($siteId: ID!) {
      site(id: $siteId) {
        ...SiteFragment
      }
    }
    ${EditSiteComponent.fragments.Site}
  `,
};
EditSiteComponent.mutations = {
  UpdateSiteInfo: gql`
    mutation updateSiteInfo($input: UpdateSiteInfoInput!) {
      updateSiteInfo(input: $input) {
        site {
          id
          name
          customID
          timezone
        }
      }
    }
  `,
};
