import clsx from 'clsx';
import { SliderState } from 'react-stately';
import { mergeProps, useFocusRing, useSliderThumb, VisuallyHidden } from 'react-aria';
import { FC, RefObject, useRef } from 'react';
import { AutoTooltip } from '@/components';
import { ICommonComponentProps } from '@/types';
import { useToggle } from '@/hooks';

interface IThumbProps extends ICommonComponentProps {
  state: SliderState;
  trackRef: RefObject<Element>;
  tooltipLabel?: string;
}

export const Thumb: FC<IThumbProps> = ({
  className,
  style,
  state,
  trackRef,
  tooltipLabel,
  ...rest
}) => {
  const thumbRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef(null);
  const { thumbProps, inputProps, isDragging } = useSliderThumb(
    {
      index: 0,
      trackRef,
      inputRef,
    },
    state,
  );
  const { focusProps, isFocusVisible } = useFocusRing();
  const [showTooltip, { toggleOn: toggleOnShowTooltip, toggleOff: toggleOffShowTooltip }] =
    useToggle(false);

  return (
    <>
      {!isDragging && (
        <AutoTooltip
          label={tooltipLabel}
          placement={state.orientation === 'horizontal' ? 'top' : 'right'}
          offsetSize={0}
          position={
            state.orientation === 'horizontal'
              ? { x: thumbProps.style?.left || 0, y: -15 }
              : { x: 15, y: thumbProps.style?.top || 0 }
          }
          forceOpen={showTooltip}
        />
      )}
      <span
        className={clsx(
          'pointer-events-none absolute bg-gray-400',
          state.orientation === 'horizontal' && 'left-0 h-2 -translate-y-1/2 rounded',
          state.orientation === 'vertical' && 'top-0 w-2 -translate-x-1/2 rounded',
        )}
        style={
          state.orientation === 'horizontal'
            ? { width: thumbProps.style?.left }
            : { height: thumbProps.style?.top }
        }
      />
      <div
        ref={thumbRef}
        {...thumbProps}
        className={clsx(
          'h-4 w-4 rounded-full bg-gray-900',
          state.orientation === 'horizontal' && 'top-1/2',
          state.orientation === 'vertical' && 'left-1/2',
          isDragging && '!bg-gray-800',
          isFocusVisible && '!bg-gray-700',
          className,
        )}
        style={{ ...thumbProps.style, ...style }}
        onPointerOver={toggleOnShowTooltip}
        onPointerOut={toggleOffShowTooltip}
        {...rest}
      >
        <VisuallyHidden>
          <input ref={inputRef} {...mergeProps(inputProps, focusProps)} />
        </VisuallyHidden>
      </div>
    </>
  );
};
