import * as THREE from 'three';
import * as Cache from './CacheHelpers';

const loadingManager = new THREE.LoadingManager();
const textureLoader = new THREE.TextureLoader(loadingManager);

// Load texture
export const loadTexture = async (
  url: string,
  key: string | null = null,
  onLoad?: (texture: THREE.Texture) => void,
  onProgress?: (event: ProgressEvent<EventTarget>) => void,
  onError?: (event: ErrorEvent) => void,
): Promise<void> => {
  if (!url) return;

  const cacheKey = key || url;
  let texture: THREE.Texture | null = null;

  // Check if the texture is already loaded. If so, return with the cached one.
  texture = Cache.getTexture(cacheKey);
  if (texture) {
    return onLoad?.(texture);
  }

  textureLoader.load(
    url,
    (texture) => {
      // Save to cache
      Cache.appendTexture({
        key: cacheKey,
        texture,
      });
      onLoad?.(texture);
    },
    onProgress,
    (err) => {
      console.error(`Failed fetching texture from ${url}: ${err}`);
      onError?.(err);
    },
  );
};
