import React, { memo, FC } from 'react';
import ReactDOM from 'react-dom';
import { PersonFilledIcon } from '@/components';
import { TSV_Tag } from '@/types';

interface IRoomOccupancyTagContainerProps {
  tags?: TSV_Tag[];
}

export const RoomOccupancyTagContainer: FC<IRoomOccupancyTagContainerProps> = memo(({ tags }) => {
  return (
    <>
      {tags?.map(({ element, occupancy, color }) =>
        ReactDOM.createPortal(
          <div className="flex items-center justify-center">
            <span className="px-1 text-center text-xl" style={{ color }}>
              {occupancy}
            </span>
            <PersonFilledIcon width={10} fill={color} />
          </div>,
          element,
        ),
      )}
    </>
  );
});
