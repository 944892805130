import { gql } from '@apollo/client';
import { useDeleteSiteMutation } from '@/.generated/graphql';
import Close from '../Icons/Close';
import { closeDialog } from '@/redux/reducers/dialog';
import { useAppDispatch } from '@/redux/hooks';
import { Button } from '@/components/primitives';
import { datadogRum } from '@datadog/browser-rum';
import { useTranslation } from 'react-i18next';
import { SidebarMenuSiteQuery } from '../sidebar/SidebarMenu';

interface DeleteDialogProps {
  id: string;
  onSave: () => void;
  blockDelete: boolean;
}
export const DeleteSite = ({ id, onSave, blockDelete }: DeleteDialogProps) => {
  const [deleteSite] = useDeleteSiteMutation({
    refetchQueries: [{ query: SidebarMenuSiteQuery }],
  });
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleDelete = async () => {
    datadogRum.addAction('Submit delete site form', { source: 'sites-and-buildings' });
    try {
      await deleteSite({
        variables: {
          id: id,
        },
      });
      onSave();
      dispatch(closeDialog());
    } catch (error) {
      console.error('Error deleting: ', error);
    }
  };

  return (
    <div>
      <div className="flex items-start justify-between text-xl py-3">
        <div className="mr-3">{blockDelete ? t('unableToDelete') : t('confirmDeleteSite')}</div>
        <button onClick={() => dispatch(closeDialog())} className="mt-2">
          <Close />
        </button>
      </div>
      <div className="text-sm">{blockDelete ? t('unableToDeleteSite') : t('deleteSite')}</div>
      <div className="flex justify-end pt-5">
        <Button variant="ghost" onClick={() => dispatch(closeDialog())} className="mr-3">
          Cancel
        </Button>
        <Button
          variant="solid"
          color="red"
          onClick={handleDelete}
          data-id="delete-site"
          disabled={blockDelete}
        >
          Delete
        </Button>
      </div>
    </div>
  );
};
DeleteSite.mutations = {
  DeleteSite: gql`
    mutation deleteSite($id: ID!) {
      deleteSite(id: $id) {
        site {
          id
          name
        }
        deleted
      }
    }
  `,
};
