import { useCallback, useRef } from 'react';
import clsx from 'clsx';
import { useSVHeatmapLegendBar } from '@/hooks';
import { ICommonComponentProps } from '@/types';
import { useAppDispatch } from '@/redux/hooks';

interface IHeatmapLegendBarProps extends ICommonComponentProps {}

export const HeatmapLegendBar = ({ className, style, ...rest }: IHeatmapLegendBarProps) => {
  const dispatch = useAppDispatch();
  const { labels } = useSVHeatmapLegendBar();
  const barRef = useRef<HTMLDivElement | null>(null);

  const handleRedefineHeatmapWeight = useCallback(() => {
    // TODO Implement in next version
    // setDialog({ content: <HeatmapWeightRedefine value={value} onChange={onChange} /> });
  }, [dispatch]);

  return (
    <button
      className={clsx('pointer-events-none flex flex-col', className)}
      style={style}
      onClick={handleRedefineHeatmapWeight}
      {...rest}
    >
      <div ref={barRef} className="h-3 min-w-260px rounded-md bg-heatmap-weight-bar" />
      <div className="relative flex w-full justify-between">
        {labels.map((label) => {
          return (
            <span key={label} className="text-xxs text-gray-500">
              {label}
            </span>
          );
        })}
      </div>
    </button>
  );
};
