import { EventDispatcher } from 'three';

// Globally accessible Dispatcher
export const dispatcher = new EventDispatcher();

// TODO Determine required event types
export enum Events {
  SPACE_DATA_UPDATE = 'spaceDataUpdate',
  OPTIONS_UPDATE = 'optionsUpdate',
  ZOOM_UPDATE = 'zoomUpdate',
  TAG_UPDATE = 'tagUpdate',
  ROOM_HOVER = 'roomHover',
  ROOM_POINTER_UP = 'roomPointerUp',
}
