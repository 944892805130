import {
  useSetSpaceMaxCapacityMutation,
  useSetRoomMaxCapacityMutation,
  useSetZoneMaxCapacityMutation,
} from '@/.generated/graphql';
import { OccupancyChart } from '@/chart';
import { ChartTooltipContext } from '@/contexts';
import { ECommonVariants, ICommonComponentProps, ITimeRange, TStatsOccupancyDP } from '@/types';
import { gql } from '@apollo/client';
import clsx from 'clsx';
import { useCallback, useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useResizeDetector } from 'react-resize-detector';

interface IOccupancyViewProps extends ICommonComponentProps {
  data: TStatsOccupancyDP[];
  spaceId?: string;
  roomId?: string;
  zoneId?: string;
  maxCapacity: number;
  midCapacity: number;
  timeRange: ITimeRange;
  tz: string;
  variant?: ECommonVariants;
}

export const OccupancyView = // left this comment to force newline after removing memo() so diff is cleaner.
  ({
    className,
    style,
    spaceId,
    roomId,
    zoneId,
    data,
    maxCapacity,
    midCapacity,
    timeRange,
    variant = ECommonVariants.PRIMARY,
    tz,
    ...rest
  }: IOccupancyViewProps) => {
    const {
      t,
      i18n: { language: currentLang },
    } = useTranslation();
    const { handleChartTooltipHide, handleChartTooltipShow } = useContext(ChartTooltipContext);
    const chartRef = useRef<HTMLDivElement | null>(null);
    const chartInstanceRef = useRef<OccupancyChart | null>(null);
    const [setSpaceCapacity] = useSetSpaceMaxCapacityMutation();
    const [setRoomCapacity] = useSetRoomMaxCapacityMutation();
    const [setZoneCapacity] = useSetZoneMaxCapacityMutation();
    // Init/Dipose chart
    useEffect(() => {
      if (
        !handleChartTooltipHide ||
        !handleChartTooltipShow ||
        chartInstanceRef.current ||
        !chartRef.current
      )
        return;

      chartInstanceRef.current = new OccupancyChart({
        container: chartRef.current,
        data,
        capacity: maxCapacity,
        timeRange,
        tz,
        t,
        currentLang,
        variant,
        updateCapacityLimit: (updatedMaxCapacity: number) => {
          if (spaceId && roomId) {
            setRoomCapacity({ variables: { roomId, maxCapacity: updatedMaxCapacity } });
          } else if (spaceId && zoneId) {
            setZoneCapacity({ variables: { zoneId, maxCapacity: updatedMaxCapacity } });
          } else if (spaceId && !roomId) {
            setSpaceCapacity({ variables: { spaceId, maxCapacity: updatedMaxCapacity } });
          }
        },
      });
      chartInstanceRef.current.onMouseOut = handleChartTooltipHide;
      chartInstanceRef.current.onMouseMove = handleChartTooltipShow;
      chartInstanceRef.current.init();

      return () => {
        chartInstanceRef.current?.dispose();
      };
    }, [
      handleChartTooltipHide,
      handleChartTooltipShow,
      data,
      maxCapacity,
      timeRange,
      tz,
      t,
      currentLang,
      variant,
      spaceId,
      roomId,
      zoneId,
      setRoomCapacity,
      setZoneCapacity,
      setSpaceCapacity,
      midCapacity,
    ]);

    // Update chart data
    useEffect(() => {
      chartInstanceRef.current?.update({
        data,
        capacity: maxCapacity,
        timeRange,
        t,
        currentLang,
        tz,
      });
    }, [data, timeRange, t, currentLang, maxCapacity, tz]);

    // Listen when container is resized
    const handleResize = useCallback(() => {
      chartInstanceRef.current?.onResize();
    }, []);

    const { ref } = useResizeDetector({ onResize: handleResize });

    return (
      <div
        ref={ref}
        className={clsx('relative w-full', className)}
        style={style}
        data-id="occupancy-view"
        {...rest}
      >
        <div ref={chartRef} className="h-full w-full" />
      </div>
    );
  };

OccupancyView.mutations = {
  SetSpaceMaxCapacity: gql`
    mutation SetSpaceMaxCapacity($spaceId: ID!, $maxCapacity: Float!) {
      updateFloors(floors: [{ floor_id: $spaceId, capacity: { capacity: $maxCapacity } }]) {
        id: floor_id
        capacity {
          max
        }
      }
    }
  `,
  SetRoomMaxCapacity: gql`
    mutation SetRoomMaxCapacity($roomId: ID!, $maxCapacity: Float!) {
      updateRooms(rooms: [{ room_id: $roomId, capacity: { capacity: $maxCapacity } }]) {
        id: room_id
        capacity {
          max
        }
      }
    }
  `,
  SetZoneMaxCapacity: gql`
    mutation SetZoneMaxCapacity($zoneId: ID!, $maxCapacity: Float!) {
      updateZones(zones: [{ zone_id: $zoneId, capacity: { capacity: $maxCapacity } }]) {
        id: zone_id
        capacity {
          max
        }
      }
    }
  `,
};
