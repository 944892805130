import { gql } from '@apollo/client';
import { useContext } from 'react';
import {
  useUpdateBuildingInfoMutation,
  useGetAllSitesQuery,
  useMoveBuildingMutation,
  useGetBuildingsQuery,
} from '@/.generated/graphql';
import Close from '../Icons/Close';
import { closeDialog } from '@/redux/reducers/dialog';
import { useAppDispatch } from '@/redux/hooks';
import { Button } from '@/components/primitives';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { datadogRum } from '@datadog/browser-rum';
import { PopupContext } from '@/contexts';
import {
  Input,
  SubmitButton,
  SearchDropdown,
  // TimezoneDropdown,
} from '@/components';
import { SidebarMenuSiteQuery } from '../sidebar/SidebarMenu';

interface BuildingProps {
  buildingId: string;
  onSave: () => void;
}

export const EditBuildingComponent = ({ buildingId, onSave }: BuildingProps) => {
  const { data: buildingData } = useGetBuildingsQuery({
    variables: { buildingId: buildingId },
  });
  const building = buildingData?.building;

  const { data: sitesData } = useGetAllSitesQuery({});
  const [moveBuilding] = useMoveBuildingMutation({
    refetchQueries: [{ query: SidebarMenuSiteQuery }],
  });
  const [editing, setEditing] = useState(false);
  const [save] = useUpdateBuildingInfoMutation({
    refetchQueries: ['useSidebarSiteQuery'],
  });
  const dispatch = useAppDispatch();
  const initialSite = sitesData?.sites?.data.find((site) =>
    site.buildings.some((building) => building.id === buildingId),
  );
  const {
    register,
    handleSubmit,
    formState: { isDirty, errors },
    watch,
    setValue,
  } = useForm({
    resetOptions: { keepDirty: true },
    values: {
      name: building?.name,
      customID: building?.customID,
      parentSite: initialSite?.id,
      capacity: building?.capacity?.max,
      address: building?.address,
      // timezone: '',
      buildingCode: building?.buildingNumber,
      id: building?.id,
    },
  });
  const { setConfig: setPopupConfig } = useContext(PopupContext);

  const { t } = useTranslation();

  const parentSite = watch('parentSite');

  const selectedSite = sitesData?.sites.data.find((s) => s.id === parentSite);
  return (
    <PopupContext.Provider value={{ setConfig: setPopupConfig }}>
      <form
        onSubmit={handleSubmit(async (values) => {
          datadogRum.addAction('Submit form on buildings modal', { source: 'sites-and-buildings' });
          if (values.parentSite && values.parentSite !== initialSite?.id) {
            await moveBuilding({
              variables: { input: { buildingId: buildingId, siteId: values.parentSite } },
            });
          }
          try {
            await save({
              variables: {
                input: {
                  id: buildingId,
                  name: values.name,
                  customID: values.customID,
                  address: {
                    lines: values.address?.lines || [],
                    country: values.address?.country || '',
                  },
                  buildingNumber: values.buildingCode,
                },
              },
            });
            onSave();
            dispatch(closeDialog());
          } catch (error) {
            console.error(error);
          }
        })}
      >
        <div className="flex justify-between text-xl py-3">
          <div>Building Details</div>
          <button onClick={() => dispatch(closeDialog())}>
            <Close />
          </button>
        </div>
        <div className="py-3">
          <div className="flex py-2">
            <Input
              disabled={!editing}
              type="text"
              label="Building Name"
              wrapperClassName="w-[360px] mr-3"
              variant="narrow"
              errorMessage={errors.name && errors.name.message}
              {...register('name', { required: "Building name can't be empty." })}
            />
            <Input
              disabled={!editing}
              type="text"
              label={t('customBuildingId') + ' (optional)'}
              wrapperClassName="flex-grow"
              {...register('customID')}
              variant="narrow"
            />
          </div>
          <div className="flex py-2">
            <div className="w-[360px] mr-3 align-top mb-3">
              <span className="text-product-gray600 text-sm mx-3 ml-0">Parent Site</span>
              <SearchDropdown
                id="siteDropdown"
                size="sm"
                disabled={!editing}
                options={sitesData?.sites?.data ?? []}
                optionToSearchValue={(option) => (option ? option.name : '')}
                renderOption={(option) => option.name}
                placeholder="Site Name"
                className={`w-full py-2 ${editing ? 'text-gray-900' : 'text-gray-500'}`}
                onChange={(value) => {
                  if (value) {
                    const selectedSite = sitesData?.sites?.data.find(
                      (site) => site.id === value.id,
                    );
                    if (selectedSite) {
                      setValue('parentSite', value.id, { shouldDirty: true });
                    }
                  }
                }}
                value={selectedSite}
              />
            </div>
            <Input
              type="number"
              label={t('maxCapacity')}
              wrapperClassName="flex-grow mt-1"
              // TODO: re-enable max capacity mutation in future phase
              disabled
              errorMessage={errors.capacity && errors.capacity.message}
              {...register('capacity')}
              variant="narrow"
            />
          </div>
          {editing && (
            <div className="w-[360px] flex rounded-md text-xs text-product-gray600 mt-[-10px]">
              <span> {t('editBuildingParentSite')}</span>
            </div>
          )}
          <div className="flex py-2">
            <Input
              disabled={!editing}
              type="text"
              {...register('address.lines.0', { required: "Address can't be empty." })}
              errorMessage={errors.address?.lines?.[0]?.message || errors.address?.message}
              label={t('address')}
              placeholder={t('address')}
              variant="narrow"
              wrapperClassName="w-full"
            />
          </div>
          <div className="flex py-2">
            {/* TODO: bring in timezone edit functionality for phase 2 */}
            {/* <div className="w-[360px] mr-3"> */}
            {/* <TimezoneDropdown
            {...register('timezone', { required: true })}
            onSelect={(name) => {
              setValue('timezone', name, { shouldDirty: true });
              clearErrors('timezone');
            }}
            disabled={!editing}
            value={watchTimezone}
            errors={!!errors.timezone}
          ></TimezoneDropdown> */}

            {/* Temporarily removing timezone until phase 2 */}
            {/* <Input
              disabled={!editing}
              type="text"
              label={t('buildingNumber')}
              wrapperClassName="flex-grow w-full"
              variant="narrow"
              {...register('buildingCode')}
            /> */}
          </div>
        </div>
        <div className="flex justify-end pt-5">
          <Button variant="ghost" onClick={() => dispatch(closeDialog())} className="mr-3">
            Cancel
          </Button>
          {!editing ? (
            <Button
              onClick={() => {
                setEditing(!editing);
                datadogRum.addAction('Enter edit mode on buildings modal', {
                  source: 'sites-and-buildings',
                });
              }}
            >
              Edit
            </Button>
          ) : (
            <SubmitButton disabled={!isDirty}>Save</SubmitButton>
          )}
        </div>
      </form>
    </PopupContext.Provider>
  );
};

EditBuildingComponent.fragments = {
  Building: gql`
    fragment BuildingFragment on Building {
      id
      name
      capacity {
        max
        mid
      }
      buildingNumber
      address {
        lines
        country
      }
      customID
    }
  `,
};

EditBuildingComponent.queries = {
  Sites: gql`
    query GetAllSites {
      sites {
        data {
          id
          name
          buildings {
            id
          }
        }
      }
    }
  `,
  Building: gql`
    query GetBuildings($buildingId: ID!) {
      building(id: $buildingId) {
        ...BuildingFragment
      }
    }
    ${EditBuildingComponent.fragments.Building}
  `,
};

EditBuildingComponent.mutations = {
  MoveBuilding: gql`
    mutation moveBuilding($input: MoveBuildingInput!) {
      moveBuilding(input: $input) {
        site {
          id
          name
        }
        building {
          id
          name
        }
      }
    }
  `,
  UpdateBuildingInfo: gql`
    mutation updateBuildingInfo($input: UpdateBuildingInfoInput!) {
      updateBuildingInfo(input: $input) {
        building {
          id
          name
          buildingNumber
          address {
            lines
            country
          }
          customID
        }
      }
    }
  `,
};
