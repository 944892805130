import { Object3D, Raycaster, Vector2 } from 'three';
import { dispatcher, Events } from './DispatchHelper';
import { EMouseButton, ESV_Pointer } from '@/types';
import SVRenderer from '..';

export class InteractionHelper {
  public objects: Object3D[]; // List of 3D objects we can interact with
  public hovObjects: Object3D[]; // List of hoverable objects

  private svRenderer: SVRenderer;
  private mouse: Vector2; // Vector2 for tracking mouse position
  private raycaster: Raycaster; // Raycaster object
  private isDown: boolean; // Mouse down state
  private isDragged: boolean; // Mouse drag state

  constructor(svRenderer: SVRenderer) {
    this.svRenderer = svRenderer;
    this.objects = [];
    this.hovObjects = [];
    this.mouse = new Vector2();
    this.raycaster = new Raycaster();
    this.isDown = false;
    this.isDragged = false;

    this.init();
  }

  /**
   * Initialize
   */
  init() {
    this.eventListenerSetup();
  }

  /**
   * Setup event listeners
   */
  eventListenerSetup() {
    this.svRenderer.container.addEventListener('pointerdown', this.onPointerDown, false);
    this.svRenderer.container.addEventListener('pointermove', this.onPointerMove, false);
    this.svRenderer.container.addEventListener('pointerup', this.onPointerUp, false);
  }

  /**
   * Dispose event listeners
   */
  eventListenerDispose() {
    this.svRenderer.container.removeEventListener('pointerdown', this.onPointerDown, false);
    this.svRenderer.container.removeEventListener('pointermove', this.onPointerMove, false);
    this.svRenderer.container.removeEventListener('pointerup', this.onPointerUp, false);
  }

  /**
   * Mouse down event listener
   */
  onPointerDown = (evt: PointerEvent) => {
    if (evt.button !== EMouseButton.LEFT || this.svRenderer.options.orbitMode) return;

    this.isDown = true;
    this.isDragged = false;

    // TODO Dispatch events corresponding to object
  };

  /**
   * Mouse move event listener
   */
  onPointerMove = (evt: PointerEvent) => {
    if (this.svRenderer.options.orbitMode) return;

    this.isDragged = true;

    const intersect = this.getIntersection(evt);

    const intersectedObj = intersect?.object;
    const intersectObjName = intersectedObj?.name;

    // Hover or not
    if (intersectObjName && this.hovObjects.some((item) => item === intersectedObj)) {
      this.changePointer(ESV_Pointer.POINTER);

      if (intersectObjName?.includes('room_')) {
        dispatcher.dispatchEvent({
          type: Events.ROOM_HOVER,
          param: intersectObjName,
        });
      }
    } else {
      this.changePointer(ESV_Pointer.AUTO);
      dispatcher.dispatchEvent({
        type: Events.ROOM_HOVER,
        param: undefined,
      });
    }
  };

  /**
   * Mouse up event listener
   */
  onPointerUp = (evt: PointerEvent) => {
    if (evt.button !== EMouseButton.LEFT || this.isDragged || this.svRenderer.options.orbitMode)
      return;

    this.isDown = false;

    const intersect = this.getIntersection(evt);

    const intersectObjName = intersect?.object?.name;

    if (intersectObjName?.includes('room_')) {
      dispatcher.dispatchEvent({
        type: Events.ROOM_POINTER_UP,
        param: intersectObjName,
      });
    }
  };

  getIntersection(evt: PointerEvent) {
    const { container, activeCamera } = this.svRenderer;
    const { left, top, width, height } = container.getBoundingClientRect();

    const x = evt.clientX - left;
    const y = evt.clientY - top;

    this.mouse.set((x / width) * 2 - 1, -(y / height) * 2 + 1);
    this.raycaster.setFromCamera(this.mouse, activeCamera);
    const intersects = this.raycaster.intersectObjects(this.objects, true);

    if (!intersects.length) return null;

    return intersects[0];
  }

  /**
   * Add object that can be interractive (on click, hover etc...)
   * @param object an object that will be checked by the raycaster
   */
  add(object: Object3D, hoverable = false) {
    if (this.objects.some((item) => item === object)) return;

    this.objects.push(object);
    hoverable && this.hovObjects.push(object);
  }

  /**
   * Remove object that can be interractive (on click, hover etc...)
   * @param object an object that will be checked by the raycaster
   */
  remove(object: Object3D) {
    this.objects = this.objects.filter((item) => item !== object);
    this.hovObjects = this.hovObjects.filter((item) => item !== object);
  }

  /**
   * Change cursor style of container
   */
  changePointer(target: ESV_Pointer) {
    this.svRenderer.container.style.cursor = target;
  }

  /**
   * Tick
   */
  tick() {
    // TODO Tick logic here
  }

  /**
   * Update
   */
  update() {}

  /**
   * Dispose
   */
  dispose() {
    this.eventListenerDispose();
  }
}
