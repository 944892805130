import { pickBy, isNil, chunk } from 'lodash';
import { FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query';
import { baseApi } from './base';
import { DETECTIONS_WINDOW, HEATMAP_SIZE, DETECTION_REQUEST_CHUNK_SIZE } from '@/constants';
import {
  IGetDetectionsQueryParams,
  IGetDetectionsQueryResponse,
  IDetections,
  IDetection,
  IOccupancy,
  IHeatmap,
  TXYPoint,
} from '@/types';
import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';

// Convert IGetDetectionsQueryResponse to IDetections
// TODO Confirm if we can rid of occupancy and heatmap capability later.
const convertDetectionsResp = (data: IGetDetectionsQueryResponse[] = []): IDetections => {
  const localDetectionsData: { [key: string]: IDetection[] } = {};
  const worldDetectionsData: { [key: string]: IDetection[] } = {};
  const occupancyData: { [key: string]: IOccupancy[] } = {};
  const heatmapData: { [key: string]: IHeatmap[] } = {};

  data.forEach(({ detections_local, detections_world, occupancy, heatmap }) => {
    // Local detections
    (detections_local || []).forEach((el) => {
      if (localDetectionsData[el.mac_address]) {
        localDetectionsData[el.mac_address].push(el);
      } else {
        localDetectionsData[el.mac_address] = [];
      }
    });
    // World detections
    (detections_world || []).forEach((el) => {
      if (worldDetectionsData[el.mac_address]) {
        worldDetectionsData[el.mac_address].push(el);
      } else {
        worldDetectionsData[el.mac_address] = [];
      }
    });
    // Occupancy
    (occupancy || []).forEach((el) => {
      if (occupancyData[el.room_id]) {
        occupancyData[el.room_id].push(el);
      } else {
        occupancyData[el.room_id] = [];
      }
    });
    // Heatmap
    (heatmap || []).forEach((el) => {
      if (heatmapData[el.mac_address]) {
        heatmapData[el.mac_address].push(el);
      } else {
        heatmapData[el.mac_address] = [];
      }
    });
  });

  return {
    detections_local: localDetectionsData,
    detections_world: worldDetectionsData,
    occupancy: occupancyData,
    heatmap: heatmapData,
  };
};

// Query for fetching chunk detections
const getQuery = async (
  {
    data_types = ['detections_local'],
    start,
    stop,
    spaceId,
    roomId,
    mac_addresses,
    every = `${DETECTIONS_WINDOW}s`,
    heatmap_grid_size_n = HEATMAP_SIZE,
  }: IGetDetectionsQueryParams,
  fetchWithBQ: (
    arg: string | FetchArgs,
  ) => MaybePromise<QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>>,
  onSuccess: () => void,
): Promise<IGetDetectionsQueryResponse> => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await fetchWithBQ({
      url: '/api/v3/detections',
      method: 'POST',
      body: {
        data_types,
        filter: {
          start,
          stop,
          mac_addresses,
          space_id: spaceId,
          ...(roomId && { room_id: roomId }),
        },
        window: {
          every,
          function: 'last',
          omit_empty_data: true,
          heatmap_grid_size_n,
        },
      },
    });

    if (res.error) {
      reject(res.error);
    }

    onSuccess();
    resolve(res.data as IGetDetectionsQueryResponse);
  });
};

export const detectionApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getDetectionsSZ: builder.query<IDetections, IGetDetectionsQueryParams | null>({
      keepUnusedDataFor: 3600,
      providesTags: ['Detections'],
      queryFn: async (queryArg, queryApi, extraOptions, fetchWithBQ) => {
        if (!queryArg)
          return { error: { status: 'CUSTOM_ERROR', error: 'No args for fetching detections' } };

        const { mac_addresses, onStart, onProgress, onEnd, ...restArgs } = queryArg;
        const macAddressChunks = chunk(mac_addresses, DETECTION_REQUEST_CHUNK_SIZE);
        let result: { data: IDetections } | { error: FetchBaseQueryError };
        let progress: number = 0;

        onStart?.();

        try {
          const res = await Promise.all(
            macAddressChunks.map((chunk_mac_addresses) =>
              getQuery(
                {
                  mac_addresses: chunk_mac_addresses,
                  ...restArgs,
                },
                fetchWithBQ,
                // Once query of chunk detections is successful, calculate progress
                () => {
                  progress += (chunk_mac_addresses.length / mac_addresses.length) * 100;
                  onProgress?.(progress);
                },
              ),
            ),
          );

          result = { data: convertDetectionsResp(res) };
        } catch (error) {
          result = { error: error as FetchBaseQueryError };
        }

        onEnd?.();

        return result;
      },
    }),
    getDetections: builder.query<
      Array<{
        start_time: number;
        sample_time: number;
        mac_address: string;
        value: TXYPoint[];
      }>,
      {
        filter?: {
          start: string;
          stop: string;
          space_id: string;
          room_id?: string;
          mac_addresses: string[];
        };
        window?: {
          every: string;
          function: string;
          omit_empty_value?: boolean;
        };
      }
    >({
      query: (values) => ({
        url: '/api/v3/detections',
        method: 'POST',
        body: {
          data_types: ['detections_local'],
          ...pickBy(values, (v) => !isNil(v)),
        },
      }),
      transformResponse: (response: any) => response.detections_local,
    }),
  }),
});

export const { useLazyGetDetectionsSZQuery, useGetDetectionsQuery } = detectionApi;
export const useGetDetectionsSZState = detectionApi.endpoints.getDetectionsSZ.useQueryState;
