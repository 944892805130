import { useMemo } from 'react';
import { LOCAL_TZ } from '@/constants';
import { useSRZId } from './useOrg';
import { gql } from '@apollo/client';
import { useUseTimezoneSpaceQuery } from '@/.generated/graphql';

/**
 * Hooks for getting current timezone
 */
export const useTimezone = (timezone?: string): string => {
  const { spaceId } = useSRZId();
  const { data } = useUseTimezoneSpaceQuery({
    // ! usage is asserted by skip below
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { spaceId: spaceId! },
    skip: !spaceId,
  });
  const space = data?.floors?.data?.[0];

  // Timezone
  const tz = useMemo(() => timezone || space?.timezone || LOCAL_TZ, [space?.timezone, timezone]);

  return tz;
};
useTimezone.queries = {
  Space: gql`
    query UseTimezoneSpace($spaceId: String!) {
      floors(ids: [$spaceId]) {
        data {
          id: floor_id
          timezone
        }
      }
    }
  `,
};
