import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useTimezone } from '@/hooks';

interface TimeDisplayProps {
  className?: string;
}

const TimeDisplay: React.FC<TimeDisplayProps> = ({ className }) => {
  const [localTime, setLocalTime] = useState('');
  const tz = useTimezone();
  useEffect(() => {
    const intervalId = setInterval(() => {
      setLocalTime(dayjs().tz(tz).format('MMMM D, YYYY, h:mm A'));
    }, 1000);

    return () => clearInterval(intervalId);
  }, [tz]);

  return (
    <div className={className}>
      {localTime} {tz}
    </div>
  );
};

export default TimeDisplay;
