import { CheckIcon, ChevronDownIcon } from '@/components/Icons';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import clsx from 'clsx';
import { withClassName } from 'hocs/withClassName';
import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

/**
 * DropdownMenu is a primitive component that allows you to create a dropdown
 * list of options that can be selected. Use this for a control which is clicked
 * to show a menu which consists only of selectable items in a list - not more
 * complex content.
 *
 * The basic structure of a DropdownMenu is:
 *
 * <DropdownMenuRoot>
 *  <DropdownMenuButton>
 *    Some text
 *   </DropdownMenuButton>
 *   <DropdownMenuContent>
 *     <DropdownMenuItem onSelect={handleSelect}>Item 1</DropdownMenuItem>
 *     <DropdownMenuItem onSelect={handleSelect}>Item 2</DropdownMenuItem>
 *     <DropdownMenuCheckboxItem checked={checked} onCheckedChange={handleCheckedChange}>
 *       Item 3
 *     </DropdownMenuCheckboxItem>
 *   </DropdownMenuContent>
 * </DropdownMenuRoot>
 *
 * For further customization of individual elements, more low-level components
 * are exposed:
 *
 * - DropdownMenuButtonRoot: the root element of the button, allowing you to wrap complex contents or utilize asChild to attach to a child component
 * - DropdownMenuButtonIcon: the chevron icon that indicates the dropdown is open. automatically rotates.
 */
export const DropdownMenuRoot = DropdownMenu.Root;
export const DropdownMenuButtonRoot = withClassName(
  DropdownMenu.Trigger,
  'group rounded-md  flex flex-row gap-1 items-center',
  'hover:border-product-gray700 active:border-product-gray900 focus:bg-product-gray200 focus:outline-none focus-visible:ring-1',
);
export const DropdownMenuButton = forwardRef<
  HTMLButtonElement,
  Omit<DropdownMenu.DropdownMenuTriggerProps, 'asChild'>
>(function DropdownMenuButton({ children, ...props }, ref) {
  return (
    <DropdownMenuButtonRoot {...props} ref={ref}>
      {children}
      <DropdownMenuButtonIcon />
    </DropdownMenuButtonRoot>
  );
});
export const DropdownMenuContent = forwardRef<
  HTMLDivElement,
  DropdownMenu.DropdownMenuContentProps
>(function DropdownMenuContent({ className, ...props }, ref) {
  return (
    <DropdownMenu.Portal>
      <DropdownMenu.Content
        {...props}
        ref={ref}
        className={twMerge(
          'focus:outline-none mt-2 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 flex flex-col origin-radix-popover radix-state-open:animate-popoverIn radix-state-closed:animate-popoverOut z-50',
          'min-w-[var(--radix-dropdown-menu-trigger-width)] max-h-[var(--radix-dropdown-menu-content-available-height)]',
          className,
        )}
      />
    </DropdownMenu.Portal>
  );
});
export const DropdownMenuButtonIcon = withClassName(
  ChevronDownIcon,
  'justify-self-end h-[12px] w-[12px] group-radix-state-open:rotate-180 transition-transform',
);
export const DropdownMenuItem = withClassName(
  DropdownMenu.Item,
  'flex w-full rounded-md py-2 px-4 text-sm font-normal radix-highlighted:bg-gray-50 cursor-pointer items-center gap-2',
);
export const DropdownMenuCheckboxItem = forwardRef<
  HTMLDivElement,
  DropdownMenu.DropdownMenuCheckboxItemProps
>(function DropdownMenuCheckboxItem({ className, children, ...props }, ref) {
  return (
    <DropdownMenu.CheckboxItem
      className={twMerge(
        'group flex w-full rounded-md py-2 px-4 text-sm font-normal radix-highlighted:bg-gray-50 cursor-pointer items-center gap-2',
        'radix-disabled:opacity-25 radix-disabled:pointer-events-none focus:outline-none focus-visible:ring-1 focus-visible:relative',
        className,
      )}
      {...props}
      ref={ref}
    >
      <div
        className={clsx(
          'flex h-4 w-4 items-center justify-center rounded-sm border',
          'group-radix-state-checked:border-product-gray900 group-radix-state-checked:bg-product-gray900',
          'group-radix-state-unchecked:border-product-gray400 group-radix-state-unchecked:bg-white',
          'active:group-radix-state-unchecked:border-product-gray900',
        )}
      >
        <DropdownMenu.ItemIndicator>
          <CheckIcon className="h-3 w-3 self-center text-white" />
        </DropdownMenu.ItemIndicator>
      </div>
      {children}
    </DropdownMenu.CheckboxItem>
  );
});

// TODO: support other DropdownMenu primitives like Group, Label, different item types
// SEE: https://www.radix-ui.com/primitives/docs/components/dropdown-menu
