import KSUID from 'ksuid-edge';

function idGenerator<Prefix extends string>(prefix: Prefix): () => `${Prefix}_${string}` {
  return function generate() {
    const result = `${prefix}_${KSUID.randomSync().string}` as const;
    return result;
  };
}
export const generateId = {
  floor: idGenerator('space'),
  room: idGenerator('room'),
  sensor: idGenerator('sensor'),
  hive: idGenerator('hive'),
  zone: idGenerator('zone'),
  floorplan: idGenerator('floorplan'),
};
