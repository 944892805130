import React, { FC, MouseEvent, memo } from 'react';
import { ICommonComponentProps } from '@/types';
import clsx from 'clsx';

interface IToggleButtonProps extends ICommonComponentProps {
  value: boolean;
  disabled?: boolean;
  onClick?: (e: MouseEvent<HTMLElement>) => void;
}

export const ToggleButton: FC<IToggleButtonProps> = memo(
  ({ className, style, value, disabled = false, onClick, ...rest }) => {
    return (
      <button
        className={clsx(
          'relative h-3.5 w-7 rounded-full transition-colors',
          disabled
            ? 'pointer-events-none bg-product-gray50'
            : value
            ? 'bg-product-gray900'
            : 'bg-product-gray300',
          className,
        )}
        style={style}
        onClick={onClick}
        {...rest}
      >
        <span
          className={clsx(
            'pointer-events-none absolute top-1/2 left-1/2 h-3 w-3 -translate-y-1/2 transform rounded-full bg-white shadow-md transition-transform',
            value ? 'translate-x-0' : '-translate-x-full',
          )}
        />
      </button>
    );
  },
);
