import { FC, SVGProps } from 'react';

/** @deprecated use <Icon name="..." /> (import from @/components/primitives/icons/Icon) instead. */
const SvgComponent: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      aria-hidden="true"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="40" height="40" rx="20" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 14.3799C12 13.0655 13.0655 12 14.3799 12H25.6201C26.9345 12 28 13.0655 28 14.3799V22.4086C28 23.723 26.9345 24.7885 25.6201 24.7885H16.3063L13.3216 27.7732C13.1002 27.9946 12.7672 28.0609 12.4779 27.941C12.1886 27.8212 12 27.5389 12 27.2258V14.3799Z"
        fill="#F9F9FA"
      />
      <path
        d="M17.7142 18.8572C17.7142 19.4884 17.2025 20.0001 16.5713 20.0001C15.9401 20.0001 15.4285 19.4884 15.4285 18.8572C15.4285 18.226 15.9401 17.7144 16.5713 17.7144C17.2025 17.7144 17.7142 18.226 17.7142 18.8572Z"
        fill="currentColor"
      />
      <path
        d="M21.1428 18.8572C21.1428 19.4884 20.6311 20.0001 19.9999 20.0001C19.3687 20.0001 18.857 19.4884 18.857 18.8572C18.857 18.226 19.3687 17.7144 19.9999 17.7144C20.6311 17.7144 21.1428 18.226 21.1428 18.8572Z"
        fill="currentColor"
      />
      <path
        d="M24.5713 18.8572C24.5713 19.4884 24.0596 20.0001 23.4285 20.0001C22.7973 20.0001 22.2856 19.4884 22.2856 18.8572C22.2856 18.226 22.7973 17.7144 23.4285 17.7144C24.0596 17.7144 24.5713 18.226 24.5713 18.8572Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SvgComponent;
