import dayjs, { Dayjs } from 'dayjs';
import { TStatsOccupancyDP, ITimeRange } from '@/types';
import { sortBy } from 'lodash';
import { parseAbsolute } from '@internationalized/date';

/**
 * Creates an array of Dates going from start to end at the given interval and with the given timezone.
 * Useful for both tick marks and for interpolating data.
 */
export const generateDateIntervals = (start: string, end: string, tz: string, interval: number) => {
  const dates: Array<Date> = [];

  // use @internationalized/date to handle timezone because dayjs doesn't properly handle
  // adding seconds to a date that crosses a DST boundary (it uses local time instead of the
  // timezone to determine where the DST boundary is)
  let current = parseAbsolute(start, tz);
  const lastTick = parseAbsolute(end, tz);

  // Determine if we need to go forward or backwards
  const direction = current.compare(lastTick) < 0 ? 1 : -1;

  while (direction * current.compare(lastTick) < 0) {
    dates.push(current.toDate());

    // These offsets are in ms not s like the docs claim
    const originalOffset = current.offset / 1000;

    current = current.add({ seconds: direction * interval });

    const newOffset = current.offset / 1000;

    // If offset is changed (for example, crossing DST bounds), handle the difference.
    // We only need to do this if the interval is greater than one hour – in that case,
    // hourly values can get out of sync with what you would expect for 2h+ intervals.
    const diff = newOffset - originalOffset;
    if (diff !== 0 && interval > 60 * 60) {
      current = current.subtract({ seconds: diff });
    }
  }
  return dates;
};

export function generateInterpolatedWindows(sampleTs: string, timeRange: ITimeRange, tz: string) {
  const { start, end, window: interval } = timeRange;

  const forwardDates = generateDateIntervals(sampleTs, end.toISOString(), tz, interval).slice(1);
  const backwardDates = generateDateIntervals(sampleTs, start.toISOString(), tz, interval).slice(1);

  return [...backwardDates, ...forwardDates].map((d) => dayjs(d));
}

/**
 * We need to generate a point that definitely would line up with the given time range window.
 * For example, if the interval is 1m, then we just need to pick timestamp in the range that has zero seconds.
 * The logic below assumes that we will always use clean interval sizes that nicely go into minutes, hours, days, and weeks
 */
export function generateInterpolationStartPoint(timeRange: ITimeRange, tz: string) {
  const interval = timeRange.window;
  const midpoint = timeRange.start.add(timeRange.start.diff(timeRange.end, 's') / 2, 's');
  let point: Dayjs;
  if (interval <= 60) {
    point = midpoint.tz(tz).startOf('minute');
  } else if (interval <= 60 * 60) {
    point = midpoint.tz(tz).startOf('hour');
  } else if (interval <= 60 * 60 * 24) {
    point = midpoint.tz(tz).startOf('day');
  } else {
    point = midpoint.tz(tz).startOf('week');
  }
  // Get the point into the time range if it's not already
  while (point.diff(timeRange.start, 's') < 0) {
    point = point.add(interval, 's');
  }
  return point.toISOString();
}

/**
 * Interpolates data point by finding points that are at the same interval but that don't overlap
 * with the given set of data points. Provide a function to generate the interpolated data point.
 */
export function interpolateDataPoints<T extends { start: string; stop: string }>(
  occupancyDP: T[],
  timeRange: ITimeRange,
  tz: string,
  generateInterpolatedPoint: (t: dayjs.Dayjs) => T,
): T[] {
  if (!occupancyDP || occupancyDP.length === 0) {
    return occupancyDP;
  }
  const points = sortBy(occupancyDP, 'stop');
  const interpolationPoint = generateInterpolationStartPoint(timeRange, tz);
  const interpolatedTimestamps = generateInterpolatedWindows(interpolationPoint, timeRange, tz);
  // Look for cases where the interpolated time stamps are not included in the data set
  const interpolated = interpolatedTimestamps
    .filter((t) => !points.some((p) => Math.abs(dayjs(p.stop).diff(t, 's')) < timeRange.window))
    .map((t) => generateInterpolatedPoint(t));

  // Ensures that the original data points are all in the array without being mutated
  return sortBy([...points, ...interpolated], 'stop');
}

export function interpolateEstOccupancy(
  occupancyDP: TStatsOccupancyDP[],
  timeRange: ITimeRange,
  tz: string,
): TStatsOccupancyDP[] {
  if (!occupancyDP || occupancyDP.length === 0) {
    return occupancyDP;
  }
  const points = sortBy(occupancyDP, 'stop').map((p) => ({ ...p, interpolated: false }));
  const interpolationPoint = generateInterpolationStartPoint(timeRange, tz);
  const interpolatedTimestamps = generateInterpolatedWindows(interpolationPoint, timeRange, tz);
  // Look for cases where the interpolated time stamps are not included in the data set
  const interpolated = interpolatedTimestamps
    .filter((t) => !points.some((p) => Math.abs(dayjs(p.stop).diff(t, 's')) < timeRange.window))
    .map((t) => {
      // Produce an interpolated value
      const time = t.utc().toISOString();
      return { start: time, stop: time, value: 0, interpolated: true };
    });

  let mostRecentRealPoint: TStatsOccupancyDP;
  // Ensures that the original data points are all in the array without being mutated
  return sortBy([...points, ...interpolated], 'stop').map(({ interpolated, ...p }) => {
    if (!interpolated) {
      mostRecentRealPoint = p;
      return p;
    }
    const sameDay =
      mostRecentRealPoint &&
      dayjs(p.stop).tz(tz).isSame(dayjs(mostRecentRealPoint.stop).tz(tz), 'day');

    return { ...p, value: sameDay ? mostRecentRealPoint.value : 0 };
  });
}
