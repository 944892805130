import React, { FC, memo } from 'react';
export const MainEntrywayTrafficDataSource = () => <p>Traffic sensors (Main Entrance)</p>;

export const EntrywayTrafficDataSource = () => <p>Traffic sensors</p>;

export const EntrywayTrafficAllDataSource = () => (
  <ul>
    <li>Traffic sensors (Main Entrance)</li>
    <li>Traffic sensors</li>
  </ul>
);

export const PresenceDataSource = () => <p>Presence sensors</p>;

export const DataSourceSpace = () => (
  <ul style={{ listStyle: 'unset', listStylePosition: 'unset' }} className="pl-4">
    <li>
      <span className="font-semibold text-black">Occupancy Data Sources may vary</span> based on the
      modes of sensors installed in each room.
    </li>
    <li>
      <span className="font-semibold text-black">
        Traffic-based occupancy data is displayed by default{' '}
      </span>
      if a room has both traffic and presence sensors.
    </li>
    <li>
      <span className="font-semibold text-black">
        Presence sensors are required to get zone occupancy.
      </span>
    </li>
  </ul>
);

export const DataSourceRoom = () => (
  <ul style={{ listStyle: 'unset', listStylePosition: 'unset' }} className="pl-4">
    <span className="font-semibold text-black">Presence sensors</span> are required to get zone
    occupancy.
  </ul>
);

export const CSVSubtitle: FC<any> = memo(({ source }) => {
  return (
    <p className="">
      Data from <span className="underline">{source}</span> in this floor/room
      {source === 'presence sensors' ? '/zone' : ''}.
    </p>
  );
});
