import { getAccessTokenSilently } from '@/components/auth/Auth0Provider';
import { API_BASE_URL } from '@/constants/api';
import { datadogRum } from '@datadog/browser-rum';
import {
  createApi,
  defaultSerializeQueryArgs,
  fetchBaseQuery,
  retry,
} from '@reduxjs/toolkit/query/react';
import { v4 as uuid } from 'uuid';

// if not initialized separately, the TypeScript types seem to break.
const baseQuery = retry(
  fetchBaseQuery({
    baseUrl: API_BASE_URL,
    prepareHeaders: async (headers, api) => {
      const token = await getAccessTokenSilently();
      if (!token) {
        console.warn('Token not set');
      }
      headers.set('Authorization', `Bearer ${token}`);
      const id = uuid();
      // log the request info with the ID so we can start tracing from
      // frontend logs
      datadogRum.addAction('legacyRequest', {
        requestId: id,
        endpoint: api.endpoint,
      });
      headers.set('X-Request-ID', id);
      return headers;
    },
  }),
  { maxRetries: 2 },
);

// initialize an empty api service that we'll inject endpoints into later as needed
export const baseApi = createApi({
  refetchOnFocus: true,
  serializeQueryArgs: (args) => {
    // because superusers can change client ID and
    // that will change the results of the exact same
    // API calls, we need to include client ID in the
    // session storage (set by hooks/useUser)
    // FIXME: make this more robust.
    const clientId = window.sessionStorage.getItem('client_id');
    if (!clientId) {
      return defaultSerializeQueryArgs(args);
    }
    return defaultSerializeQueryArgs(args) + clientId;
  },
  baseQuery,
  tagTypes: ['Reporting', 'Room', 'Space', 'Zone', 'Sensors', 'User', 'Image', 'Detections'],
  endpoints: () => ({}),
});
