import { FC, memo, createRef, useRef, RefObject, MutableRefObject } from 'react';
import { useButton, AriaButtonProps } from '@react-aria/button';
import { useRangeCalendarState } from '@react-stately/calendar';
import { useRangeCalendar } from '@react-aria/calendar';
import { createCalendar, CalendarDate } from '@internationalized/date';
import { CalendarGrid } from './CalendarGrid';
import { DateValue, AriaRangeCalendarProps } from '@react-types/calendar';
import dayjs from 'dayjs';
import { usePrevious } from '@/hooks/usePrevious';
import { Icon } from '@/components/primitives/icon/Icon';
import { TIME_RANGE_MODE } from '@/redux/reducers/time';

interface IRangeCalendarProps extends AriaRangeCalendarProps<DateValue> {
  timeRangeMode: TIME_RANGE_MODE;
  tz: string;
}

function CalendarButton(props: AriaButtonProps<'button'>) {
  const ref: MutableRefObject<HTMLButtonElement | undefined> = useRef<HTMLButtonElement>();
  const { buttonProps } = useButton(props, ref as RefObject<HTMLButtonElement>);
  return (
    <button
      className="bg-white  text-sm"
      {...buttonProps}
      ref={ref as RefObject<HTMLButtonElement>}
    >
      {props.children}
    </button>
  );
}

export const RangeCalendar: FC<IRangeCalendarProps> = memo((props) => {
  const state = useRangeCalendarState({
    ...props,
    onChange: (value) => {
      const { tz } = props;
      const previousValue = props.value;
      const nextValue = value;
      const sameStartDay =
        previousValue &&
        dayjs(previousValue.start.toDate(tz)).isSame(value.start.toDate(tz), 'day');
      const sameEndDay =
        previousValue && dayjs(previousValue.end.toDate(tz)).isSame(value.end.toDate(tz), 'day');
      const now = dayjs().tz(tz);
      const isEndDayToday = dayjs().tz(tz).isSame(value.end.toDate(tz), 'day');
      if (previousValue && !sameStartDay) {
        nextValue.start = nextValue.start.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      }
      if (previousValue && !sameEndDay) {
        nextValue.end = nextValue.end.set({ hour: 23, minute: 59, second: 59, millisecond: 0 });
      }
      if (isEndDayToday) {
        nextValue.end = nextValue.end.set({
          hour: now.hour(),
          minute: now.minute(),
          second: 0,
          millisecond: 0,
        });
      }
      props.onChange?.(nextValue);
    },
    locale: 'en-US',
    createCalendar,
  });

  const previousRange = usePrevious(props.timeRangeMode);
  const rangeChanged = previousRange && previousRange !== props.timeRangeMode;
  if (rangeChanged && props.value && state.visibleRange.end.add({ days: 1 }) < props.value.end) {
    const focusedDate = props.value.end;
    state.setFocusedDate(new CalendarDate(focusedDate.year, focusedDate.month, focusedDate.day));
  }

  if (rangeChanged && props.timeRangeMode === TIME_RANGE_MODE.CUSTOM && props.value) {
    const startFullDay = props.value.start.set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    const endFullDay = props.value.end.set({
      hour: 23,
      minute: 59,
      second: 0,
      millisecond: 0,
    });
    state.setValue({
      start: startFullDay,
      end: endFullDay,
    });
  }

  const ref = createRef<HTMLDivElement>();
  const { calendarProps, prevButtonProps, nextButtonProps, title } = useRangeCalendar(
    props,
    state,
    ref,
  );

  return (
    <div {...calendarProps} ref={ref}>
      <div className="flex items-center pt-3">
        <div className="ml-6 flex-1 text-left text-xs font-bold">{title.toUpperCase()}</div>
        <CalendarButton {...prevButtonProps}>
          <Icon name="left" className="mr-2" />
          <span className="sr-only">Previous month</span>
        </CalendarButton>
        <CalendarButton {...nextButtonProps}>
          <Icon name="right" className="mr-6" />
          <span className="sr-only">Next month</span>
        </CalendarButton>
      </div>
      <div className="mt-4 flex justify-center gap-2 text-xs">
        <CalendarGrid state={state} />
      </div>
      <hr className="mb-3.5 mt-3 h-px border-0 bg-gray-200 dark:bg-gray-700"></hr>
    </div>
  );
});
