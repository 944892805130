import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { SummaryTile, LoadingSpinner } from '@/components';
import { IQuestionTooltipContent, ICommonComponentProps } from '@/types';

interface IOccupancySummaryTileProps extends ICommonComponentProps {
  title?: string;
  label?: string;
  value?: number;
  maxVal?: number;
  tooltip?: IQuestionTooltipContent;
  loading?: boolean;
  error?: string;
  est?: boolean;
  beta?: boolean;
  space?: boolean;
  subtitle?: string;
}

export const OccupancySummaryTile: FC<IOccupancySummaryTileProps> = memo(
  ({
    className,
    style,
    title = '',
    label = '',
    value = 0,
    maxVal = 1,
    tooltip,
    loading = false,
    error = '',
    est,
    beta,
    space,
    subtitle,
    ...rest
  }) => {
    const { t } = useTranslation();

    return (
      <SummaryTile
        className={className}
        style={style}
        title={title}
        label={label}
        tooltip={tooltip}
        beta={beta}
        {...rest}
      >
        {loading ? (
          <div className="absolute inset-0 mr-1 flex h-full w-full items-center justify-center truncate rounded-2xl bg-white text-xs font-semibold">
            <LoadingSpinner />
          </div>
        ) : error ? (
          <div className="absolute inset-0 mr-1 flex h-full w-full items-center justify-center truncate rounded-2xl bg-white text-xs font-semibold">
            {/* <Error message={error} size={ESize.sm} /> */}
          </div>
        ) : (
          <>
            <div className="mb-2 flex h-5 text-xs font-normal text-product-gray900">
              <span>{subtitle}</span>
              <div className="ml-[195px] w-[76px] rounded bg-blue-50 py-1 text-center text-[10px] font-semibold leading-3 text-blue-500">
                {((value / maxVal) * 100).toFixed(0)}% fullness
              </div>
            </div>
            <div className="mb-3 flex items-center text-3xl font-light tracking-0.03em text-product-gray700">
              <span className="pr-1 text-[32px] font-semibold leading-10 text-gray-900">
                {value}
              </span>
              <span className="mt-1 text-xl tracking-0.15em">/</span>
              <span className="mt-1 text-xl">{maxVal}</span>
            </div>
            <p className="truncate text-xs font-normal text-product-gray600">
              {t('peakValueLast5Mins')}
            </p>
          </>
        )}
      </SummaryTile>
    );
  },
);
