import { FC, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import dayjs from 'dayjs';
import {
  CloseIcon,
  InfoIcon,
  DateRangePicker,
  EDateRangePickerType,
  AutoTooltip,
} from '@/components';
import { ITime } from '@/types';
import { MAX_HEATMAP_PERIOD } from '@/constants/spaceVisualizer';
import { useAppDispatch, useTime } from '@/redux/hooks';
import { closeDialog } from '@/redux/reducers/dialog';

interface IHistoryGenerateProps {
  onSubmit: (time: ITime) => void;
  onClose?: () => void;
}

export const HistoryGenerate: FC<IHistoryGenerateProps> = memo(({ onSubmit, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const time = useTime();

  const isTimeRangeError = time
    ? dayjs(time.detection.timeRange.endEpoch).diff(
        dayjs(time.detection.timeRange.startEpoch),
        'd',
      ) +
        1 >
      MAX_HEATMAP_PERIOD
    : false;

  // Handler for close
  const handleClose = useCallback(() => {
    dispatch(closeDialog());
    onClose?.();
  }, [onClose, dispatch]);

  // Handler for heatmap generation
  const handleGenerate = useCallback(() => {
    if (!time) return;

    dispatch(closeDialog());
    onSubmit(time);
  }, [time, dispatch, onSubmit]);

  return (
    <>
      <div className="flex w-full flex-col">
        <div className="mb-8 flex w-full items-center">
          <p className="min-w-0 flex-grow text-xl font-semibold text-product-gray900">
            {t('pleaseSelectATimeRange')}
          </p>
          <CloseIcon className="w-5 cursor-pointer fill-current" onClick={handleClose} />
        </div>
        <p className="mb-2 text-xs font-semibold text-product-gray500">{t('selectTimeRange')}</p>
        <DateRangePicker
          type={EDateRangePickerType.DETECTION}
          id="detection-datetime-picker"
          hAlignment="center"
          vAlignment="middle"
          status={isTimeRangeError ? 'error' : 'normal'}
        />
        {isTimeRangeError && (
          <p className="mt-1 text-xs text-product-red500">{t('heatmapPeriodError')}</p>
        )}
      </div>
      <div className="flex gap-2 text-xs text-gray-600">
        <p>{t('loadUpTo1WeekOfData')}</p>

        <AutoTooltip label={t('longerTimeRangeComingSoon')} variant="white" placement="top">
          <InfoIcon className="w-4 fill-current" />
        </AutoTooltip>
      </div>
      <div className="flex w-full justify-end gap-6">
        <button
          data-id="historyGenerateCancelButton"
          className="rounded-md bg-transparent px-6 py-2 text-gray-900"
          onClick={handleClose}
        >
          {t('cancel')}
        </button>
        <button
          data-id="historyGenerateDoneButton"
          className={clsx(
            'rounded-md px-6 py-2 text-white',
            isTimeRangeError ? 'bg-gray-400' : 'bg-gray-900',
          )}
          onClick={handleGenerate}
          disabled={isTimeRangeError}
        >
          {t('generate')}
        </button>
      </div>
    </>
  );
});
