import { FC, memo, useEffect } from 'react';
import { Dialog, disposeCache, TDialog } from '@/components';
import { useHistory } from 'react-router-dom';
import { SuperUserPage } from './SuperUserPage';
import OrganizationPage from './OrganizationPage';
import { useAppDispatch, useDialog, useSuperUser } from '@/redux/hooks';
import { closeDialog, setDialog } from '@/redux/reducers/dialog';
import { useFetchSR, useSRZId } from '@/hooks';
import { getSingleGraphQLModel } from '@/utils/getSingleGraphQLModel';
import { useHeaderFloorsQuery } from '@/.generated/graphql';

export const LandingPage: FC = () => {
  const { spaceId } = useSRZId();
  const { data: spaceData } = useHeaderFloorsQuery({
    // skip validates this assertion.
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { spaceId: spaceId! },
    skip: !spaceId,
  });
  const space = getSingleGraphQLModel(spaceData?.floors);

  const { triggerRefresh } = useFetchSR(space);
  useEffect(() => {
    triggerRefresh();
  }, []);

  const history = useHistory();
  const dialog = useDialog();
  const { isSuperUserDashboard } = useSuperUser();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isSuperUserDashboard) history.push('/');
  }, [history, isSuperUserDashboard]);

  // Cleanup cache of webgl assets
  useEffect(() => {
    return () => disposeCache();
  }, []);

  return (
    <div className="flex h-full w-full flex-col">
      {isSuperUserDashboard ? <SuperUserPage /> : <OrganizationPage />}
      {dialog && (
        <Dialog
          dialog={dialog}
          setDialog={(dialog: TDialog | null) => dispatch(setDialog(dialog))}
          closeDialog={() => dispatch(closeDialog())}
        />
      )}
    </div>
  );
};

export default memo(LandingPage);
