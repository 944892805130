import {
  DetailsPanelBuildingFragment,
  DetailsPanelFloorFragment,
  useDetailsPanelGetFloorQuery,
  useUpdateFloorMutation,
} from '@/.generated/graphql';
import { ESQUnit } from '@/types';
import { gql } from '@apollo/client';
import { datadogRum } from '@datadog/browser-rum';

export type Capacity = {
  max: number;
  mid: number;
};
export type AreaInput = {
  area_unit: ESQUnit;
  area: number;
};
export type Area = {
  unit: ESQUnit;
  value: number;
};

export type CapacityInput = {
  capacity: number;
  mid_capacity: number;
};

export type FloorDetails = DetailsPanelFloorFragment & { building: DetailsPanelBuildingFragment };

const buildingFragment = gql`
  fragment DetailsPanelBuilding on Building {
    id
    name
    buildingNumber
    address {
      lines
      country
    }
  }
`;

const floorFragment = gql`
  fragment DetailsPanelFloor on Floor {
    id: floor_id
    name
    timezone
    area {
      value
      unit
    }
    installation_status
    capacity {
      max
      mid
    }
    last_battery_change_date
    next_battery_change_date
    sensors {
      last_battery_change_date
      next_battery_change_date
    }
    name
    timezone
    customID
  }
`;

export const GET_FLOOR = gql`
  query DetailsPanelGetFloor($ids: [String!]!) {
    floors(ids: $ids) {
      data {
        building {
          ...DetailsPanelBuilding
        }
        ...DetailsPanelFloor
      }
    }
  }
  ${floorFragment}
  ${buildingFragment}
`;
export const UPDATE_FLOOR = gql`
mutation UpdateFloor($input: UpdateFloorInfoInput!, $building: UpdateBuildingInfoInput!) {
  updateFloorInfo(input: $input) {
    floor {
      ...DetailsPanelFloor
    }
  }
      updateBuildingInfo(input: $building) {
      building {
        ...DetailsPanelBuilding
      }
    }
    ${floorFragment}
  ${buildingFragment}
}
`;

export interface IGetFloorQueryParams {
  spaceId?: string;
}

export const useGetFloorQuery = ({ floorId: spaceId }: { floorId?: string }) => {
  const { data, ...rest } = useDetailsPanelGetFloorQuery({
    // asserted by skip
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { ids: [spaceId!] },
    skip: !spaceId,
    fetchPolicy: 'cache-and-network',
  });
  return {
    data: data?.floors?.data?.[0] ?? null,
    ...rest,
  };
};

export const useSaveFloor = () => {
  return useUpdateFloorMutation({
    onError: (err) => {
      console.error(err.message);
      datadogRum.addError(err, { source: 'space-details-panel' });
    },
    onCompleted: () => {
      datadogRum.addAction('Save', { source: 'space-details-panel' });
    },
    refetchQueries: ['GetFloorDetails', 'useSidebarSiteQuery'],
  });
};
