import { MutableRefObject } from 'react';
import clsx from 'clsx';
import { Slider, ButtonGroup, PauseIcon, PlayIcon, CheckIcon } from '@/components';
import { useSVReplayBar } from '@/hooks';
import { ICommonComponentProps } from '@/types';
import { DETECTIONS_WINDOW, DETECTION_REPLAY_MULTIPLY } from '@/constants';

interface IReplayBarProps extends ICommonComponentProps {
  detailPanelOpen: boolean;
  isPresentationIdle: boolean;
  toolsRef: MutableRefObject<HTMLDivElement | null>;
}

export const ReplayBar = ({
  className,
  style,
  detailPanelOpen,
  isPresentationIdle,
  toolsRef,
  ...rest
}: IReplayBarProps) => {
  const {
    isPlaying,
    shouldFill,
    startTime,
    endTime,
    selectedTime,
    endTimeLabel,
    selectedTimeLabel,
    multiply,
    onPlay,
    onPause,
    onMultiplyChange,
    onTimeChange,
  } = useSVReplayBar(toolsRef, detailPanelOpen);

  return (
    <div
      className={clsx(
        'h-[84px] flex-col items-center gap-1 rounded-md border border-gray-100 bg-white p-4 text-sm font-medium text-gray-700 shadow-xl',
        !isPresentationIdle ? 'flex' : 'hidden',
        shouldFill ? 'min-w-0 flex-grow' : 'absolute left-1/2 w-600px -translate-x-1/2',
        className,
      )}
      style={style}
      {...rest}
    >
      <div className="flex w-full items-center gap-4">
        {isPlaying ? (
          <ButtonGroup
            className="mr-0 !h-7 border-none !p-0 shadow-none"
            size="lg"
            content={[
              {
                buttonContent: <PauseIcon className="h-5 w-5 fill-current" />,
                onClick: onPause,
                variant: 'secondary',
                portalId: 'space-visualizer-container',
              },
            ]}
          />
        ) : (
          <ButtonGroup
            className="mr-0 !h-7 border-none !p-0 shadow-none"
            size="lg"
            content={[
              {
                buttonContent: <PlayIcon className="h-5 w-5 fill-current" />,
                onClick: onPlay,
                variant: 'secondary',
                portalId: 'space-visualizer-container',
              },
            ]}
          />
        )}
        <Slider
          className="min-w-0 flex-grow"
          aria-label="replay-bar-slider"
          minValue={startTime}
          maxValue={endTime}
          value={selectedTime}
          step={DETECTIONS_WINDOW * 1000} // TODO For now, all detection query is running with DETECTIONS_WINDOW. But maybe, in the future, we would need to set the window dynamically
          tooltipLabel={selectedTimeLabel}
          onChange={onTimeChange}
          onPointerDown={onPause}
        />
        <ButtonGroup
          className="mr-0 !h-7 border-none !p-0 shadow-none"
          size="lg"
          content={[
            {
              buttonContent: <span className="mx-0.5 w-5 font-semibold">{`${multiply}x`}</span>,
              buttonProps: { 'aria-label': 'Keyboard shortcuts' },
              popover: (
                <div className="w-20">
                  {DETECTION_REPLAY_MULTIPLY.map((value, index) => {
                    return (
                      <button
                        key={value}
                        className={clsx(
                          'flex h-11 w-full items-center justify-between border border-product-gray100 bg-white px-5 text-sm hover:bg-gray-50 group-hover:text-product-gray900 group-focus:text-product-gray900',
                          index === DETECTION_REPLAY_MULTIPLY.length - 1 && 'rounded-b-md',
                          value === multiply && 'text-red-500',
                        )}
                        onClick={() => onMultiplyChange(value)}
                      >
                        <span>{`${value}x`}</span>
                        {value === multiply && <CheckIcon className="w-3 fill-current" />}
                      </button>
                    );
                  })}
                </div>
              ),
              disabled: isPlaying,
              variant: 'third',
              portalId: 'space-visualizer-container',
            },
          ]}
        />
      </div>
      <div className="flex w-full items-center justify-between px-11">
        <span>{selectedTimeLabel}</span>
        <span>{endTimeLabel}</span>
      </div>
    </div>
  );
};
