import React, { FC, SVGProps } from 'react';

const HorizontalDividerIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg viewBox="0 0 1 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="1" height="24" />
    </svg>
  );
};

export default HorizontalDividerIcon;
