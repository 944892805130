import { FC, SVGProps } from 'react';

const CopyIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="12"
      height="15"
      viewBox="0 0 12 15"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.599976 12.8142C0.599976 14.0082 1.22268 14.6309 2.39954 14.6309H7.44973C8.62659 14.6309 9.24929 14.0025 9.24929 12.8142V11.8144H10.1519C11.3345 11.8144 11.9572 11.186 11.9572 9.99774V5.78163C11.9572 5.04467 11.803 4.5705 11.3631 4.12489L8.68372 1.39413C8.26667 0.965662 7.76394 0.799988 7.11838 0.799988H5.10745C3.93059 0.799988 3.30789 1.42841 3.30789 2.61669V3.61644H2.39954C1.22839 3.61644 0.599976 4.24486 0.599976 5.43314V12.8142ZM8.68943 7.11844L5.78728 4.17631C5.38738 3.76498 5.03318 3.63929 4.47332 3.61644V2.70809C4.47332 2.23392 4.71897 1.97113 5.22742 1.97113H7.41545V4.44481C7.41545 5.13607 7.79822 5.51312 8.48377 5.51312H10.7861V9.90633C10.7861 10.3862 10.5404 10.649 10.0377 10.649H9.24929V8.68949C9.24929 7.94681 9.15217 7.59261 8.68943 7.11844ZM8.35237 4.31342V2.41674L10.4776 4.57621H8.61516C8.43235 4.57621 8.35237 4.49623 8.35237 4.31342ZM1.77112 12.7228V5.52455C1.77112 5.05038 2.01677 4.78187 2.51951 4.78187H4.1134V7.63832C4.1134 8.46669 4.51902 8.86659 5.34167 8.86659H8.07815V12.7228C8.07815 13.2027 7.83821 13.4598 7.32976 13.4598H2.51379C2.01677 13.4598 1.77112 13.2027 1.77112 12.7228ZM5.44451 7.86112C5.21599 7.86112 5.11887 7.764 5.11887 7.53549V4.99325L7.94675 7.86112H5.44451Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CopyIcon;
