import { forwardRef, HTMLProps, memo, ReactNode } from 'react';
import { ICommonComponentProps } from '@/types';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

interface IInputProps extends ICommonComponentProps {
  label?: ReactNode;
  trailingContent?: ReactNode;
  wrapperClassName?: string;
  variant?: 'narrow' | 'wide';
  errorMessage?: string;
  disabled?: boolean;
}

export const Input = memo(
  forwardRef<HTMLInputElement, IInputProps & HTMLProps<HTMLInputElement>>(
    (
      {
        className,
        style,
        label,
        trailingContent,
        wrapperClassName = '',
        variant,
        disabled,
        errorMessage,
        ...rest
      },
      ref,
    ) => {
      return (
        <label
          className={clsx(
            `text-product-gray${disabled ? '500' : '900'}`,
            wrapperClassName,
            className,
          )}
          style={style}
        >
          <div className="flex text-sm text-product-gray600">
            {label ? <span className="shrink">{label}</span> : null}
          </div>
          <div
            className={twMerge(
              `flex justify-between rounded ${variant === 'narrow' ? 'px-2 py-2' : 'px-4 py-4'} ${
                errorMessage ? 'border-red-500 border-[1px]' : ''
              } bg-product-gray100`,
              className,
            )}
          >
            <input
              ref={ref}
              disabled={disabled}
              {...rest}
              className={twMerge(
                'outline-none min-w-0 max-w-full flex-grow bg-product-gray100',
                className,
              )}
            />
            {trailingContent ? (
              <span className="ml-2 text-product-gray400">{trailingContent}</span>
            ) : null}
          </div>
          <div className="text-red-500 text-xs">{errorMessage}</div>
        </label>
      );
    },
  ),
);
