import React, { FC, SVGProps } from 'react';

/** @deprecated use <Icon name="..." /> (import from @/components/primitives/icons/Icon) instead. */
const SvgComponent: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      aria-hidden="true"
      data-prefix="fas"
      data-icon="question-circle"
      className="prefix__svg-inline--fa prefix__fa-question-circle prefix__fa-w-16"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M7 14C10.8294 14 14 10.8225 14 7C14 3.17059 10.8225 0 6.99314 0C3.17059 0 0 3.17059 0 7C0 10.8225 3.17745 14 7 14ZM6.84902 8.46863C6.48529 8.46863 6.27941 8.27647 6.27941 7.90588V7.81667C6.27941 7.11667 6.66373 6.74608 7.17157 6.38922C7.78922 5.95686 8.08431 5.72353 8.08431 5.24314C8.08431 4.70784 7.66569 4.34412 7.02745 4.34412C6.55392 4.34412 6.21765 4.57745 5.98431 4.96176C5.75098 5.22941 5.68922 5.44216 5.28431 5.44216C5.05098 5.44216 4.7902 5.27059 4.7902 4.9549C4.7902 4.83824 4.82451 4.71471 4.85882 4.6049C5.05098 3.95294 5.84706 3.38333 7.06863 3.38333C8.27647 3.38333 9.32647 4.00784 9.32647 5.18824C9.32647 6.03922 8.83235 6.45098 8.14608 6.90392C7.65196 7.22647 7.42549 7.48039 7.42549 7.87843V7.96078C7.42549 8.24216 7.20588 8.46863 6.84902 8.46863ZM6.83529 10.5755C6.42353 10.5755 6.06667 10.2461 6.06667 9.84118C6.06667 9.43628 6.41667 9.1 6.83529 9.1C7.25392 9.1 7.60392 9.42941 7.60392 9.84118C7.60392 10.2529 7.24706 10.5755 6.83529 10.5755Z" />
    </svg>
  );
};

export default SvgComponent;
