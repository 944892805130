import clsx from 'clsx';
import { RadioIcon } from '../../Icons';
import { RadioGroupProps, RadioGroupState, useRadioGroupState } from 'react-stately';
import { AriaRadioProps, VisuallyHidden, useRadio, useRadioGroup } from 'react-aria';
import { useContext, useRef, createContext } from 'react';

const RadioContext = createContext<RadioGroupState>(null as any);

function PageOption({
  selected,
  dataUrl,
  ...props
}: AriaRadioProps & { selected: boolean; dataUrl: string }) {
  const ref = useRef<HTMLInputElement>(null);
  const state = useContext(RadioContext);
  const { inputProps } = useRadio(props, state, ref);
  return (
    <label className="block">
      <VisuallyHidden>
        <input {...inputProps} ref={ref} />
      </VisuallyHidden>
      <svg
        height={110}
        width={110}
        className={clsx(
          'border-2 rounded m-auto',
          selected
            ? 'border-gray-900 hover:border-gray-700 text-gray-900 hover:text-gray-700'
            : 'border-gray-200 hover:border-gray-700 text-gray-400 hover:text-gray-700 hover:border-[1px] hover:p-[1px]',
        )}
      >
        <image href={dataUrl} height={110} width={110} />
        {selected ? (
          // TODO: use an icon from library
          <RadioIcon x={85} y={5} />
        ) : (
          <g>
            <circle cx={93} cy={13} r={7.5} fill="currentColor" />
            <circle cx={93} cy={13} r={6} fill="white" />
          </g>
        )}
      </svg>
    </label>
  );
}

export default function PageSelection({
  dataUrls,
  currentPage,
  onSelect,
}: {
  dataUrls: string[];
  currentPage: number;
  onSelect: (index: number) => void;
}) {
  const props: RadioGroupProps = {
    defaultValue: '0',
    value: `${currentPage}`,
    onChange: (index) => onSelect(parseInt(index)),
  };
  const state = useRadioGroupState(props);
  const { radioGroupProps } = useRadioGroup(props, state);

  return (
    <div className="grid grid-cols-3 gap-3 w-[400px] mx-8 my-4" {...radioGroupProps}>
      <RadioContext.Provider value={state}>
        {dataUrls.map((dataUrl, idx) => (
          <PageOption key={idx} selected={idx === currentPage} dataUrl={dataUrl} value={`${idx}`} />
        ))}
      </RadioContext.Provider>
    </div>
  );
}
