import { TRectCoords, TRectangle, TXYPoint } from '@/types';
import { Polygon, point, vector } from '@flatten-js/core';
import type { Room } from '../ScopingEditor/useGetState';
import { clampAngleDegrees, lerpPoint } from '../utils/utils';
import { SensorObject } from './SensorObject';
import type { PhysicalObject } from './types';

/**
 * Represents a room and provides functionality for its behavior and properties.
 */
export class RoomObject implements PhysicalObject {
  private _coordinates: TRectCoords;
  private _rotation: number;

  constructor(coordinates: TRectCoords, rotation: number) {
    this._coordinates = coordinates;
    this._rotation = rotation;
  }

  /** Creates a room from a full room object */
  static fromRoom(room: Pick<Room, 'coordinates' | 'rotation'>): RoomObject {
    return new RoomObject(room.coordinates, room.rotation);
  }

  get coordinates(): TRectCoords {
    return this._coordinates;
  }

  get center(): TXYPoint {
    const { x, y } = new Polygon(this._coordinates).box.center;
    return [x, y];
  }

  set center(nextCenter: TXYPoint) {
    const dx = nextCenter[0] - this.center[0];
    const dy = nextCenter[1] - this.center[1];
    this.translate(dx, dy);
  }

  get rotation(): number {
    return this._rotation;
  }

  get xmin(): number {
    return new Polygon(this._coordinates).box.xmin;
  }

  get ymax(): number {
    return new Polygon(this._coordinates).box.ymax;
  }

  /** Returns the rotated bounding box of this object. */
  get bbox(): TRectangle {
    const { xmin, ymin, width, height } = new Polygon(this._coordinates).rotate(
      (this._rotation * Math.PI) / 180,
      point(this.center),
    ).box;
    return [xmin, ymin, width, height];
  }

  get area(): number {
    return new Polygon(this._coordinates).area();
  }

  rotate(angle: number, center: TXYPoint = this.center): RoomObject {
    const nextCoordinates = new Polygon(this._coordinates).rotate(
      -(angle * Math.PI) / 180,
      point(center),
    );
    this._coordinates = nextCoordinates.vertices.map(({ x, y }) => [x, y]) as TRectCoords;
    this._rotation = clampAngleDegrees(this._rotation + angle);
    return this;
  }

  translate(dx: number, dy: number): RoomObject {
    const nextCoordinates = new Polygon(this._coordinates).translate(vector(dx, dy));
    this._coordinates = nextCoordinates.vertices.map(({ x, y }) => [x, y]) as TRectCoords;
    return this;
  }

  /**
   * Scales the room with an x and y factor.
   * By default, the room will be scaled along the x and y axis from its center.
   * @param sx The scaling factor along the x-axis. A value of 1 will keep the room the same size.
   * @param sy The scaling factor along the y-axis. A value of 1 will keep the room the same size.
   * @param anchor The point to scale from. This point will stay fixed as the rest of the room scales.
   * @param rotation The rotation to scale from. If you want to scale the room along its width/height, you should provide the room's rotation.
   *
   * @returns The room object after scaling.
   */
  scale(
    sx: number,
    sy: number,
    [anchorX, anchorY]: TXYPoint = this.center,
    rotation: number = 0,
  ): RoomObject {
    let polygon = new Polygon(this._coordinates);
    polygon = polygon.rotate((rotation * Math.PI) / 180, point(this.center));
    polygon = polygon.translate(vector(-anchorX, -anchorY));
    polygon = polygon.scale(sx, sy);
    polygon = polygon.translate(vector(anchorX, anchorY));
    polygon = polygon.rotate(-(rotation * Math.PI) / 180, point(this.center));
    this._coordinates = polygon.vertices.map(({ x, y }) => [x, y]) as TRectCoords;
    return this;
  }

  /** Sets the rotation to an exact value. */
  setRotation(nextRotation: number) {
    const initialRotation = this.rotation;
    return this.rotate(nextRotation - initialRotation);
  }

  /** Sets the width to an exact value. */
  setWidth(nextWidth: number) {
    const width = this.bbox[2];
    const rotation = this.rotation;
    return this.scale(nextWidth / width, 1, this.center, rotation);
  }

  /** Sets the height to an exact value. */
  setHeight(nextHeight: number) {
    const height = this.bbox[3];
    const rotation = this.rotation;
    return this.scale(1, nextHeight / height, this.center, rotation);
  }

  /** Returns true if the room contains a point. */
  containsPoint(p: TXYPoint): boolean {
    return new Polygon(this._coordinates).contains(point(p));
  }

  /** Returns true if the room contains the sensor's center. */
  containsSensor(sensor: SensorObject): boolean {
    return this.containsPoint(sensor.center);
  }

  /** Returns true if the room contains a rectangle. */
  containsRect(coordinates: TRectCoords): boolean {
    return new Polygon(this._coordinates).contains(new Polygon(coordinates));
  }

  /** Return the mid point for left, bottom, right, top */
  getMidPoints(): TRectCoords {
    const [lt, rt, rb, lb] = new Polygon(this._coordinates).vertices.map(({ x: _x, y: _y }) => [
      _x,
      _y,
    ]) as TRectCoords;
    return [
      lerpPoint(lt, lb, 0.5),
      lerpPoint(lt, rt, 0.5),
      lerpPoint(rt, rb, 0.5),
      lerpPoint(lb, rb, 0.5),
    ];
  }
}
