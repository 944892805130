varying vec4 vPos;
varying vec3 vNormal;
varying vec2 vUv;

uniform vec3 color;

void main() {
  float alpha = smoothstep(0.0, 1.0, vUv.y) /3.0;

  gl_FragColor = vec4(color, alpha);
}