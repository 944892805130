import { ShaderMaterial, Vector2 } from 'three';
import { sensorHeatmapFrag, sensorHeatmapVert } from '@/components/SpaceVisualizer/ThreeRenderer';

export interface ISV_SensorHeatmapMaterial {
  gridSize?: Vector2;
  gridData?: number[];
}

export class SensorHeatmapMaterial extends ShaderMaterial {
  constructor({ gridSize, gridData }: ISV_SensorHeatmapMaterial) {
    super({
      uniforms: {
        gridSize: { value: gridSize },
        gridData: { value: gridData },
      },
      vertexShader: sensorHeatmapVert,
      fragmentShader: sensorHeatmapFrag,
      transparent: true,
      depthWrite: false,
    });
  }

  /**
   * Update
   */
  update({ gridSize, gridData }: ISV_SensorHeatmapMaterial) {
    gridSize && (this.uniforms.gridSize.value = gridSize);
    gridData && (this.uniforms.gridData.value = gridData);
  }
}
