import { Select } from 'antd';

interface TagSelectProps {
  tags: Set<string>;
  options: string[];
  onSelect: (item: string) => void;
  onDeselect?: (item: string) => void;
}

const TagSelect: React.FC<TagSelectProps> = ({ tags, options, onSelect, onDeselect }) => {
  const selectOptions = options.map((option) => ({
    value: option,
    label: option,
  }));

  return (
    <Select
      mode="tags"
      className="w-72 min-h-8 rounded-md"
      placeholder="Add a Tag"
      onSelect={onSelect}
      onDeselect={onDeselect}
      options={selectOptions}
      value={Array.from(tags)}
      notFoundContent="No tags found"
      data-id="tag-select"
    />
  );
};

export default TagSelect;
