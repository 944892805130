import React, { useId } from 'react';
import clsx from 'clsx';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import * as LabelPrimitive from '@radix-ui/react-label';
import { CheckIcon } from '../../Icons';

export function Checkbox({ className, children, ...rest }: CheckboxPrimitive.CheckboxProps) {
  const id = useId();
  return (
    <div className="flex items-center gap-2">
      <CheckboxPrimitive.Root
        id={id}
        {...rest}
        className={clsx(
          'flex h-4 w-4 items-center justify-center rounded-sm border',
          'radix-state-checked:border-product-gray900 radix-state-checked:bg-product-gray900',
          'radix-state-unchecked:border-product-gray400 radix-state-unchecked:bg-white',
          'hover:radix-state-unchecked:border-product-gray700 active:radix-state-unchecked:border-product-gray900',
          'radix-disabled:pointer-events-none radix-disabled:opacity-25',
          'focus:outline-none focus-visible:ring focus-visible:ring-blue-700',
          className,
        )}
      >
        <CheckboxPrimitive.Indicator>
          <CheckIcon className="h-3 w-3 self-center text-white" />
        </CheckboxPrimitive.Indicator>
      </CheckboxPrimitive.Root>

      {children && (
        <LabelPrimitive.Label
          htmlFor={id}
          className="select-none text-sm font-medium text-gray-900"
        >
          {children}
        </LabelPrimitive.Label>
      )}
    </div>
  );
}
