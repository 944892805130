import { ChangeEvent, FC, memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, CloseIcon } from '@/components';
import { useAppDispatch } from '@/redux/hooks';
import { closeDialog } from '@/redux/reducers/dialog';

interface IHeatmapWeightRedefineProps {
  value: [number, number];
  onChange: (param: [number, number]) => void;
}

export const HeatmapWeightRedefine: FC<IHeatmapWeightRedefineProps> = memo(
  ({ value, onChange }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [minMax, setMinMax] = useState<[number, number]>(value);

    // Handler for close
    const handleClose = useCallback(() => {
      dispatch(closeDialog());
    }, [dispatch]);

    // Handler for change
    const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.name === 'min') {
        return setMinMax((cur) => [parseFloat(e.target.value), cur[1]]);
      }

      setMinMax((cur) => [cur[0], parseFloat(e.target.value)]);
    }, []);

    // Handler for done
    const handleDone = useCallback(() => {
      onChange(minMax);
      handleClose();
    }, [handleClose, minMax, onChange]);

    return (
      <>
        <div className="flex w-full flex-col">
          <div className="mb-8 flex w-full items-center">
            <p className="min-w-0 flex-grow text-xl font-bold text-product-gray900">
              {t('pleaseInputMinMax')}
            </p>
            <CloseIcon className="w-5 cursor-pointer fill-current" onClick={handleClose} />
          </div>
          <Input
            type="number"
            value={minMax[0]}
            name="min"
            onChange={handleChange}
            label={t('min')}
          />
          <Input
            type="number"
            value={minMax[1]}
            name="max"
            onChange={handleChange}
            label={t('max')}
          />
        </div>
        <div className="flex w-full justify-end gap-6">
          <button
            data-id="heatmapWeightRedefineCancelButton"
            className="rounded-md bg-transparent px-6 py-2 text-gray-900"
            onClick={handleClose}
          >
            {t('cancel')}
          </button>
          <button
            data-id="heatmapWeightRedefineDoneButton"
            className="rounded-md bg-gray-900 px-6 py-2 text-white"
            onClick={handleDone}
          >
            {t('done')}
          </button>
        </div>
      </>
    );
  },
);
