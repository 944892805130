import { TModelCache, TMaterialCache, TTextureCache } from '@/types/SpaceVisualizer';
import { disposeObject, disposeMaterial, disposeTexture } from './OtherHelper';

let textureCache: TTextureCache[] = [];
let materialCache: TMaterialCache[] = [];
let modelCache: TModelCache[] = [];

// Get texture from cache
export const getTexture = (key: string) => {
  const matching = textureCache.find((el) => el.key === key)?.texture || null;

  return matching;
};

// Append texture to cache
export const appendTexture = (newCache: TTextureCache) => {
  textureCache = [...textureCache, newCache];
};

// Dispose texture cache
export const disposeTextureCache = () => {
  for (const el of textureCache) {
    disposeTexture(el.texture);
  }
  textureCache = [];
};

// Get material from cache
export const getMaterial = (key: string) => {
  const matching = materialCache.find((el) => el.key === key)?.material || null;

  return matching;
};

// Append material to cache
export const appendMaterial = (newCache: TMaterialCache) => {
  materialCache = [...materialCache, newCache];
};

// Dispose material cache
export const disposeMaterialCache = () => {
  for (const el of materialCache) {
    disposeMaterial(el.material);
  }
  materialCache = [];
};

// Get model from cache
export const getModel = (key: string) => {
  const matching = modelCache.find((el) => el.key === key)?.model.clone() || null;

  return matching;
};

// Append model to cache
export const appendModel = (newCache: TModelCache) => {
  modelCache = [...modelCache, newCache];
};

// Dispose model cache
export const disposeModelCache = () => {
  for (const el of modelCache) {
    disposeObject(el.model);
  }
  modelCache = [];
};

// Dipose all cache
export const disposeCache = () => {
  disposeTextureCache();
  disposeMaterialCache();
  disposeModelCache();
};
