import { Mesh, Object3D, Shape, ShapeBufferGeometry, Vector2, Vector3 } from 'three';
import { CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer';
import SVRenderer, {
  RoomMaterial,
  dispatcher,
  Events,
  SensorModel,
} from '@/components/SpaceVisualizer/ThreeRenderer';
import { ESV_Tag, ESV_Vision_Options, ESV_VizMode, IDetections, TSV_Tag } from '@/types';
import { getRoomColor, hex2string } from '@/utils';
import {
  SpaceVisualizerRendererRoomFragment,
  SpaceVisualizerRendererSensorFragment,
} from '@/.generated/graphql';

export class RoomModel extends Object3D {
  public svRenderer: SVRenderer;
  public data: SpaceVisualizerRendererRoomFragment;
  public outerFP: number[][];
  public innerFP: number[][];
  public minCoord: number[];
  public maxCoord: number[];
  public centerCoord: number[];
  public borderWidth: number;

  private room: Mesh;
  private activitySensorContainer: Object3D; // Container of activity sensors
  private headcountSensorContainer: Object3D; // Container of headcount sensors
  private doorContainer: Object3D;
  private fixtureContainer: Object3D;
  private nameTagEl!: HTMLElement;
  private nameTag!: CSS3DObject;
  private occupancyTagEl!: HTMLElement;
  private occupancyTag: CSS3DObject | null;
  private dimension: Vector3;
  private fillCol: number;
  private fillOpacity: number;
  private borderCol: number;
  private nameTagFillCol: number;
  private nameTagCol: number;
  private occupancyNum: number;
  private headcountNum: number;
  private occupancyTagCol: number;

  constructor(
    svRenderer: SVRenderer,
    public activitySensors: SpaceVisualizerRendererSensorFragment[],
    public headcountSensors: SpaceVisualizerRendererSensorFragment[],
    data: SpaceVisualizerRendererRoomFragment,
  ) {
    super();

    this.svRenderer = svRenderer;
    this.data = data;
    this.outerFP = [];
    this.innerFP = [];
    this.minCoord = [];
    this.maxCoord = [];
    this.centerCoord = [];
    this.borderWidth = 0.05;
    this.room = new Mesh();
    this.activitySensorContainer = new Object3D();
    this.headcountSensorContainer = new Object3D();
    this.doorContainer = new Object3D();
    this.fixtureContainer = new Object3D();
    this.occupancyTag = null;
    this.dimension = new Vector3();
    this.fillCol = 0;
    this.fillOpacity = 0;
    this.borderCol = 0;
    this.nameTagFillCol = 0;
    this.nameTagCol = 0;
    this.occupancyTagCol = 0;
    this.occupancyNum = 0;
    this.headcountNum = Math.floor(Math.random() * 10); // TODO Choose reasonable solution. Can be from live detection or stats

    this.init();
  }

  /**
   * Initialize
   */
  init() {
    this.determineColors();
    this.determineSensorVisibility();
    this.initFloorPoints();
    this.initHierarchy();
    this.initTransformation();
  }

  /**
   * Initialize colors
   */
  determineColors() {
    const { options, latestOccupancyByRoom } = this.svRenderer;
    const { vizMode } = options;
    const {
      id,
      capacity: { max: maxCapacity },
    } = this.data;

    this.occupancyNum = latestOccupancyByRoom?.[id]?.value ?? 0;

    // Determine room color
    const occupancy = (this.occupancyNum / (maxCapacity || 1)) * 100;
    [
      this.fillCol,
      this.fillOpacity,
      this.borderCol,
      this.nameTagFillCol,
      this.nameTagCol,
      this.occupancyTagCol,
    ] = getRoomColor(occupancy, vizMode);
  }

  /**
   * Initialize sensor visibility
   */
  determineSensorVisibility() {
    const { options } = this.svRenderer;
    const { vizMode, visionOptions } = options;
    const sensorCoverage = visionOptions[ESV_Vision_Options.SENSOR_COVERAGE_AREAS];
    (
      [
        ...this.activitySensorContainer.children,
        ...this.headcountSensorContainer.children,
      ] as SensorModel[]
    ).forEach((sensor) => sensor.showCoverage(sensorCoverage));

    if (vizMode === ESV_VizMode.OCCUPANCY) {
      this.activitySensorContainer.visible = true;
      this.headcountSensorContainer.visible = false;
    } else {
      this.activitySensorContainer.visible = false;
      this.headcountSensorContainer.visible = true;
    }
  }

  /**
   * Initialize floor points
   */
  initFloorPoints() {
    const { coordinates: rawCoordinates } = this.data;
    const coordinates = this.enforceCoordinates(rawCoordinates);

    const xCoords = coordinates.map((p) => p?.[0] ?? 0);
    const yCoords = coordinates.map((p) => p?.[1] ?? 0);

    this.minCoord = [Math.min(...xCoords), Math.min(...yCoords)];
    this.maxCoord = [Math.max(...xCoords), Math.max(...yCoords)];
    this.centerCoord = [
      (this.minCoord[0] + this.maxCoord[0]) / 2,
      (this.minCoord[1] + this.maxCoord[1]) / 2,
    ];

    // Assuming the order of points is [top-left, top-right, bottom-right, bottom-left]
    this.outerFP = coordinates.map((p) => [p[0] - this.centerCoord[0], p[1] - this.centerCoord[1]]);
    this.innerFP = [
      [this.outerFP[0][0] + this.borderWidth, this.outerFP[0][1] - this.borderWidth],
      [this.outerFP[1][0] - this.borderWidth, this.outerFP[1][1] - this.borderWidth],
      [this.outerFP[2][0] - this.borderWidth, this.outerFP[2][1] + this.borderWidth],
      [this.outerFP[3][0] + this.borderWidth, this.outerFP[3][1] + this.borderWidth],
    ];
  }

  enforceCoordinates(
    coords: SpaceVisualizerRendererRoomFragment['coordinates'],
  ): [[number, number], [number, number], [number, number], [number, number]] {
    return [
      [coords?.[0]?.[0] ?? 0, coords?.[0]?.[1] ?? 0],
      [coords?.[1]?.[0] ?? 0, coords?.[1]?.[1] ?? 0],
      [coords?.[2]?.[0] ?? 0, coords?.[2]?.[1] ?? 0],
      [coords?.[3]?.[0] ?? 0, coords?.[3]?.[1] ?? 0],
    ];
  }

  /**
   * Initialize hierarchy
   */
  initHierarchy() {
    this.addRoom();
    this.addSensors();
    this.addNameTag();
    this.addOccupancyTag();
  }

  /**
   * Initialize transformation
   */
  initTransformation() {
    this.position.set(this.centerCoord[0], 0, -this.centerCoord[1]);
    this.rotateX(-Math.PI / 2);
    this.nameTag.position.set(0, this.dimension.y / 2, 0);
    this.nameTag.scale.set(0.025, 0.025, 0.025);

    this.matrixAutoUpdate = false;
    this.room.matrixAutoUpdate = false;
    this.nameTag.matrixAutoUpdate = false;
    this.doorContainer.matrixAutoUpdate = false;
    this.updateMatrix();
    this.nameTag.updateMatrix();
    this.doorContainer.updateMatrix();

    if (this.occupancyTag) {
      this.occupancyTag.position.set(-this.dimension.x / 2 + 0.5, this.dimension.y / 2 - 0.5, 0);
      this.occupancyTag.scale.set(0.025, 0.025, 0.025);
      this.occupancyTag.matrixAutoUpdate = false;
      this.occupancyTag.updateMatrix();
    }
  }

  /**
   * Add room mesh
   */
  addRoom() {
    // Update geometry
    const fpVec2Arr = this.outerFP.map((p) => new Vector2().fromArray(p));
    const shape = new Shape(fpVec2Arr);
    this.room.geometry = new ShapeBufferGeometry(shape);

    // TODO Revisit below part for updating uv. If room shape is polygon, below adjustment should not work correctly.
    const uvAttr = this.room.geometry.attributes.uv;
    for (let i = 0; i < uvAttr.count; i++) {
      let u = uvAttr.getX(i);
      let v = uvAttr.getY(i);
      u = u > 1 ? 1 : u < 0 ? 0 : u;
      v = v > 1 ? 1 : v < 0 ? 0 : v;

      uvAttr.setXY(i, u, v);
    }

    this.room.geometry.computeBoundingBox();
    this.room.geometry.boundingBox?.getSize(this.dimension);

    // Update material
    const resolution = new Vector2(this.dimension.x, this.dimension.y);
    this.room.material = new RoomMaterial({
      resolution,
      borderWidth: this.borderWidth,
      fillCol: this.fillCol,
      borderCol: this.borderCol,
      fillOpacity: this.fillOpacity,
    });
    this.room.name = this.data.id;
    // TODO Can be needed later, to enable navigation to selected room
    // this.svRenderer.interactionHelper.add(this.room, true);

    this.add(this.room);
  }

  /**
   * Add sensor meshes
   */
  addSensors() {
    this.activitySensors.forEach((s) => {
      const sensor = new SensorModel(this, s);
      this.activitySensorContainer.add(sensor);
    });

    this.headcountSensors.forEach((s) => {
      const sensor = new SensorModel(this, s);
      this.headcountSensorContainer.add(sensor);
    });

    this.add(this.activitySensorContainer);
    this.add(this.headcountSensorContainer);
  }

  /**
   * Add room name tag
   */
  addNameTag() {
    this.nameTagEl = document.createElement('p');
    this.nameTag = new CSS3DObject(this.nameTagEl);
    this.nameTagEl.className = `room-name-tag-${this.nameTag.uuid}`;

    this.add(this.nameTag);

    // Delay some time until dom is rendered, then dispatch to render the content
    this.dispatchNameTagUpdate();
  }

  /**
   * Add room occupancy tag
   */
  addOccupancyTag() {
    if (!this.activitySensors.length) return;

    this.occupancyTagEl = document.createElement('div');
    this.occupancyTag = new CSS3DObject(this.occupancyTagEl);
    this.occupancyTagEl.className = `occupancy-tag-${this.occupancyTag.uuid}`;

    this.add(this.occupancyTag);

    // Delay some time until dom is rendered, then dispatch to render the content
    this.dispatchHeadcountTagUpdate();
  }

  /**
   * Dispatch room tag update
   */
  dispatchNameTagUpdate = () => {
    dispatcher.dispatchEvent({
      type: Events.TAG_UPDATE,
      param: {
        id: this.data.id,
        element: this.nameTagEl,
        name: this.data.name,
        fillColor: hex2string(this.nameTagFillCol),
        color: hex2string(this.nameTagCol),
        tag: ESV_Tag.ROOM_NAME,
      } as TSV_Tag,
    });
  };

  /**
   * Dispatch occupancy tag update
   */
  dispatchHeadcountTagUpdate = () => {
    if (!this.activitySensors.length) return;

    dispatcher.dispatchEvent({
      type: Events.TAG_UPDATE,
      param: {
        id: this.data.id,
        element: this.occupancyTagEl,
        occupancy: this.occupancyNum,
        color: this.occupancyTagCol !== -1 && hex2string(this.occupancyTagCol),
        tag: ESV_Tag.ROOM_OCCUPANCY,
      } as TSV_Tag,
    });
  };

  /**
   * Update room visibility
   */
  showRoomLayout(show: boolean) {
    this.room.visible = show;
    this.showHeadcountTag(show);
  }

  /**
   * Update room occupancy tag visibility
   */
  showHeadcountTag(show: boolean) {
    if (!this.occupancyTag) return;

    this.occupancyTag.visible = show;
  }

  /**
   * Update fixture visibility
   */
  showFixtures(show: boolean) {
    this.fixtureContainer.visible = show;
  }

  /**
   * Update sensor object visibilities by orbit mode
   */
  showSensorOrbitModeObjects(show: boolean) {
    (
      [
        ...this.activitySensorContainer.children,
        ...this.headcountSensorContainer.children,
      ] as SensorModel[]
    ).forEach((sensor) => sensor.showOrbitModeObjects(show));
  }

  /**
   * Update history visibility
   */
  showHistory(show: boolean) {
    (
      [
        ...this.activitySensorContainer.children,
        ...this.headcountSensorContainer.children,
      ] as SensorModel[]
    ).forEach((sensor) => sensor.showHistory(show));
  }

  /**
   * Update heatmap visibility
   */
  showHeatmap(show: boolean) {
    (
      [
        ...this.activitySensorContainer.children,
        ...this.headcountSensorContainer.children,
      ] as SensorModel[]
    ).forEach((sensor) => sensor.showHeatmap(show));
  }

  /**
   * Update historic detections visibility
   */
  showHistoricDetections(show: boolean) {
    (
      [
        ...this.activitySensorContainer.children,
        ...this.headcountSensorContainer.children,
      ] as SensorModel[]
    ).forEach((sensor) => sensor.showHistoricDetections(show));
  }

  /**
   * Viz mode and stats update listener
   */
  onVizModeAndStatsUpdate() {
    this.determineColors();
    this.determineSensorVisibility();

    // Update room material
    (this.room.material as RoomMaterial).update({
      fillCol: this.fillCol,
      borderCol: this.borderCol,
      fillOpacity: this.fillOpacity,
    });

    // Update name tag
    this.dispatchNameTagUpdate();

    // Update headcount tag
    this.dispatchHeadcountTagUpdate();
  }

  /**
   * Detections update listener
   */
  onDetectionsUpdate(detections: IDetections) {
    (
      [
        ...this.activitySensorContainer.children,
        ...this.headcountSensorContainer.children,
      ] as SensorModel[]
    ).forEach((sensor) => sensor.onDetectionsUpdate(detections));
  }

  /**
   * Time update listener
   */
  onTimeUpdate(time: number) {
    (
      [
        ...this.activitySensorContainer.children,
        ...this.headcountSensorContainer.children,
      ] as SensorModel[]
    ).forEach((sensor) => sensor.onTimeUpdate(time));
  }

  /**
   * Heatmap legend min/mxx update listener
   */
  onHeatmapMinMaxChange(minMax: [number, number]) {
    (
      [
        ...this.activitySensorContainer.children,
        ...this.headcountSensorContainer.children,
      ] as SensorModel[]
    ).forEach((sensor) => sensor.onHeatmapMinMaxChange(minMax));
  }

  /**
   * Dispose
   */
  dispose() {
    this.nameTag.remove();
    this.occupancyTag?.remove();
  }
}
