import { useRef } from 'react';
import { useButton, AriaButtonProps } from '@react-aria/button';
import dayjs from 'dayjs';
import { DateRange } from '@react-types/datepicker';
import { ZonedDateTime } from '@internationalized/date';
import { useTranslation } from 'react-i18next';
import { formatCustomTimeRange } from '@/utils/common';
import clsx from 'clsx';
import { DropdownTrigger, DropdownTriggerIcon } from '@/components/primitives/Dropdown/Dropdown';
import { Icon } from '../icon/Icon';
import { TIME_RANGE_MODE } from '@/redux/reducers/time';

interface IPopoverButtonProps extends AriaButtonProps<'button'> {
  label: string;
  tz: string;
  timeRangeMode: TIME_RANGE_MODE;
  rangeValue: DateRange;
  rangeLabels: readonly string[];
  status?: 'error' | 'normal';
}

export function PopoverButton(props: IPopoverButtonProps) {
  const ref = useRef<HTMLButtonElement>(null);
  const { buttonProps } = useButton(props, ref);
  const {
    t,
    i18n: { language: currentLang },
  } = useTranslation();
  const { timeRangeMode, rangeValue, rangeLabels, status, tz } = props;
  const { start, end } = rangeValue;
  return (
    <DropdownTrigger
      className={clsx(
        'mr-2 h-8 min-w-[186px] items-center justify-between flex flex-row rounded bg-gray-50 pl-4 pr-2 text-xs',
        status === 'error' && 'border border-product-red500',
      )}
      {...buttonProps}
      ref={ref}
      data-id="date-range-picker-button"
    >
      <div className="flex items-center justify-self-start leading-4 text-gray-900">
        <Icon name="time" size={14} className="mr-1.5" />
        <div className="mr-1.5">
          {timeRangeMode === TIME_RANGE_MODE.CUSTOM
            ? formatCustomTimeRange(
                dayjs((start as ZonedDateTime).toDate()).tz(tz),
                dayjs((end as ZonedDateTime).toDate()).tz(tz),
                tz,
                t,
                new Intl.Locale(currentLang).language,
              )
            : t(rangeLabels[(timeRangeMode || TIME_RANGE_MODE.TODAY) - 1] as any)}
        </div>
      </div>
      <DropdownTriggerIcon />
    </DropdownTrigger>
  );
}
