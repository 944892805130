import {
  SelectButton,
  SelectContent,
  SelectItem,
  SelectRoot,
} from '@/components/primitives/Select/Select';
import { ESQUnit } from '@/types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

export interface MeasureUnitSelectProps {
  value: ESQUnit;
  onChange: (value: ESQUnit) => void;
  className?: string;
}

export function MeasureUnitSelect({ value, onChange, className }: MeasureUnitSelectProps) {
  const { t } = useTranslation();

  return (
    <SelectRoot value={value || ESQUnit.FEET} onValueChange={onChange}>
      {/*
          tweaking padding to make this blend in with other dropdowns in this
          panel until we consolidate our design system...
       */}
      <SelectButton className={clsx('py-4', className)} />
      <SelectContent>
        {[ESQUnit.METRIC, ESQUnit.FEET].map((unit) => (
          <SelectItem value={unit} key={unit}>
            {unit === ESQUnit.METRIC ? `${t('meters')} (m)` : `${t('feet')} (ft)`}
          </SelectItem>
        ))}
      </SelectContent>
    </SelectRoot>
  );
}
