import { TFunction } from 'i18next';
import dayjs, { Dayjs } from 'dayjs';
import {
  ESQUnit,
  EQuestionTooltipKey,
  IQuestionTooltipContent,
  ELanguage,
  IAxisDateLabel,
} from '@/types';
import { SQ_METRIC_TO_UNIT_CONVERSION_FEE, DATE_TIME_FORMAT } from '@/constants';
import { MutableRefObject, Ref } from 'react';

/**
 * Sqm to Sqi
 */
export const sqmToSqi = (sqmVal: number, targetUnit: ESQUnit) => {
  return (sqmVal * SQ_METRIC_TO_UNIT_CONVERSION_FEE[targetUnit]).toFixed(2);
};

/**
 * Area conversion
 * @param val
 * @param sourceUnit
 * @param targetUnit
 * @returns
 */
export const areaConvert = (val: number, sourceUnit: ESQUnit, targetUnit: ESQUnit): number => {
  return (
    (val * SQ_METRIC_TO_UNIT_CONVERSION_FEE[targetUnit]) /
    SQ_METRIC_TO_UNIT_CONVERSION_FEE[sourceUnit]
  );
};

/**
 * Remove duplication from object array
 * @param array
 * @returns
 */
export const removeDuplicates = <T>(array: T[]): T[] => {
  return array.filter((value: T, index) => {
    const _value = JSON.stringify(value);
    return (
      index ===
      array.findIndex((obj) => {
        return JSON.stringify(obj) === _value;
      })
    );
  });
};

/**
 * Sum of number array
 * @param array
 * @returns
 */
export const sumArray = (array: number[]): number => {
  return array.reduce((a, b) => {
    return a + b;
  }, 0);
};

/**
 * Convert date by timezone (String)
 * @param date
 * @param timezone
 * @returns
 */
export const convertDateByTZ = (
  date: Date,
  timezone: string,
  currentLang: string,
  t: TFunction<'translation', undefined>,
): string => {
  const dateAndTimeZoneString = new Intl.DateTimeFormat(currentLang, {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    timeZone: timezone,
    // @ts-ignore
    timeZoneName: navigator.platform.indexOf('Mac') > -1 ? 'long' : 'longGeneric', // TODO Revisit this line to fix timezone issue
  })
    .format(date)
    .replace(/,/g, '');
  const timeString = new Intl.DateTimeFormat(currentLang, {
    hour12: currentLang === ELanguage.ZH_HANS ? false : true,
    hour: 'numeric',
    minute: 'numeric',
    timeZone: timezone,
  })
    .format(date)
    .toLowerCase()
    .replace(' ', '');
  const hour = new Intl.DateTimeFormat(currentLang, {
    hour: 'numeric',
    hour12: false,
    timeZone: timezone,
  })
    .format(date)
    .toLowerCase();

  return `${
    currentLang === ELanguage.ZH_HANS ? t(parseInt(hour) < 12 ? 'am' : 'pm') : ''
  }${timeString.replace('am', ' AM').replace('pm', ' PM')} ${dateAndTimeZoneString}`;
};

export const formatCustomTimeRange = (
  start: Dayjs,
  end: Dayjs | null = null,
  tz: string,
  t: TFunction<'translation', undefined>,
  locale: string,
): string => {
  let dateTimeFormat;
  if (locale === 'zh') {
    dateTimeFormat = DATE_TIME_FORMAT.zh;
  } else {
    dateTimeFormat = DATE_TIME_FORMAT.en;
  }
  let startStr = start.locale(locale).format(dateTimeFormat.fullDateTime);

  if (end === null) return startStr;

  if (dayjs().tz(tz).isBefore(end)) {
    return startStr + ' - ' + t('now');
  }
  if (start.isSame(end, 'd')) {
    return startStr + ' - ' + end.locale(locale).format(dateTimeFormat.hourMinute);
  }
  return startStr + ' - ' + end.locale(locale).format(dateTimeFormat.fullDateTime);
};

/**
 * Convert timezone to timezone string
 * @param timezone
 * @returns
 */
export const convertTZString = (timezone: string, currentLang: string): string => {
  const dateAndTimeZoneArray = new Intl.DateTimeFormat(currentLang, {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    timeZone: timezone,
    timeZoneName: 'long',
  })
    .format(new Date())
    .split(',');

  return dateAndTimeZoneArray[dateAndTimeZoneArray.length - 1];
};

/**
 * Convert date by timezone (Date)
 * @param date
 * @param timezone
 * @returns
 */
export const convertTZ = (date: Date | string, timezone: string): Date => {
  return new Date(
    (typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', {
      timeZone: timezone,
    }),
  );
};

/**
 * Get tooltip content
 * @param t i18n TFunction
 * @param key tooltip key
 * @returns sorted sensors
 */
export const getTooltipContent = (
  t: TFunction<'translation', undefined>,
  key: EQuestionTooltipKey,
): IQuestionTooltipContent => {
  const content = {
    [EQuestionTooltipKey.DAILY_ENTRIES]: {
      beta: false,
      title: t('dailyEntries'),
      description: t('daily_entries'),
    },

    [EQuestionTooltipKey.DAILY_EXITS]: {
      beta: false,
      title: t('dailyExits'),
      description: t('daily_exits'),
    },

    [EQuestionTooltipKey.OCCUPANCY_TREND]: {
      beta: false,
      title: t('occupancyTrend'),
      description: t('occupancy_trend'),
    },

    [EQuestionTooltipKey.EST_OCCUPANCY]: {
      beta: true,
      title: t('estOccupancy'),
      description: t('est_occupancy'),
    },

    [EQuestionTooltipKey.EST_OCCUPANCY_TREND]: {
      beta: true,
      title: t('estOccupancyTrend'),
      description: t('est_occupancy_trend'),
    },

    [EQuestionTooltipKey.EST_OCCUPANCY_CHART]: {
      beta: true,
      title: '',
      description: t('est_occupancy_chart'),
    },
    [EQuestionTooltipKey.SPACE_VISUALIZATIONS_TOGGLE]: {
      beta: false,
      title: undefined,
      description: t('space_visualizations_toggle'),
    },
  }[key];

  return content;
};

export function divideArrayEvenly<T>(arr: T[], maxSize: number): T[][] {
  const result: T[][] = [];
  for (let i = 0; i < arr.length / maxSize; i++) {
    result.push(arr.slice(i * maxSize, (i + 1) * maxSize));
  }
  return result;
}

export function divideArrayAlmostEvenly<T>(arr: T[], sizeLimit: number): T[][] {
  let evenlyDividedArray: T[][] = [];
  for (let size = sizeLimit; size > 0; size--) {
    if (arr.length % size === 0) {
      evenlyDividedArray = divideArrayEvenly(arr, size);
      break;
    }
  }
  for (let size = sizeLimit; size > 1; size--) {
    for (let i = arr.length - 1; i > 0; i--) {
      const left = i;
      const right = arr.length - i;
      if (left % size === 0 && right % (size - 1) === 0) {
        const almostDividedArray = [
          ...divideArrayEvenly(arr.slice(0, i), size),
          ...divideArrayEvenly(arr.slice(i), size - 1),
        ];
        if (almostDividedArray[0].length > evenlyDividedArray[0].length) {
          return almostDividedArray;
        }
      }
    }
  }
  return evenlyDividedArray;
}

/**
 *
 * @param d the date to be converted to label
 * @param index index of the date
 * @param tickDates a list of tick dates
 * @returns axis label
 */
export function dateToAxisLabel(
  d: Date,
  index: number,
  tickDates: Date[],
  now: Dayjs,
  t: TFunction<'translation', undefined>,
  locale: string = 'en',
): IAxisDateLabel {
  const djs = dayjs(d).locale(locale);
  const startDayjs = dayjs(tickDates[0]).locale(locale);
  const stopDayjs = dayjs(tickDates[tickDates.length - 1]).locale(locale);
  let dateTimeFormat;
  if (locale === 'zh') {
    dateTimeFormat = DATE_TIME_FORMAT.zh;
  } else {
    dateTimeFormat = DATE_TIME_FORMAT.en;
  }
  const label: IAxisDateLabel = {};

  // Always show the first label
  if (index === 0) {
    if (d.getHours() === 0 && d.getMinutes() === 0) {
      label.date = djs.format(dateTimeFormat.monthDate);
    } else if (d.getMinutes() > 0) {
      label.date = djs.format(dateTimeFormat.monthDate);
      label.time = djs.format(dateTimeFormat.hourMinute);
    } else {
      label.date = djs.format(dateTimeFormat.monthDate);
      label.time = djs.format(dateTimeFormat.hour);
    }
    if ((startDayjs.hour() || startDayjs.minute()) && startDayjs.isSame(stopDayjs, 'd')) {
      label.date = '';
    }
    return label;
  }
  if (index === tickDates.length - 1) {
    if (!stopDayjs.isBefore(now.second(0))) {
      label.time = t('now');
    } else if (d.getMinutes() > 0) {
      label.time = djs.format(dateTimeFormat.hourMinute);
    } else {
      label.time = djs.format(dateTimeFormat.hour);
    }
    return label;
  }

  // Show month and day if different from previous
  if (!dayjs(tickDates[index - 1]).isSame(djs, 'd')) {
    label.date = djs.format(dateTimeFormat.monthDate);
  }
  if (d.getHours() === 0 && d.getMinutes() === 0) {
    return label;
  }
  if (d.getMinutes() === 0) {
    label.time = djs.format(dateTimeFormat.hour);
  } else {
    label.time = djs.format(dateTimeFormat.hourMinute);
  }
  return label;
}

/**
 * Convert Date to label string
 * @param d Date
 * @returns label
 */
export function dateToLabel(d: Date, locale: string): string {
  let dateTimeFormat;
  if (locale === 'zh') {
    dateTimeFormat = DATE_TIME_FORMAT.zh;
  } else {
    dateTimeFormat = DATE_TIME_FORMAT.en;
  }
  return dayjs(d).locale(locale).format(dateTimeFormat.fullDateTime);
}

/**
 * Convert date range to label string
 * @param start range start
 * @param stop range stop
 * @param tz timezone
 * @returns label
 */
export function dateRangeToLabel(
  start: Date,
  stop: Date,
  tz: string,
  t: TFunction<'translation', undefined>,
  locale: string,
): string {
  const startDayjs = dayjs(start);
  const stopDayjs = dayjs(stop);
  const startLabel = dateToLabel(start, locale);
  const stopLabel =
    stop.getTime() <= convertTZ(new Date(), tz).getTime() ? dateToLabel(stop, locale) : t('now');
  if (
    startDayjs.year() === stopDayjs.year() &&
    startDayjs.month() === stopDayjs.month() &&
    startDayjs.date() === stopDayjs.date()
  ) {
    const dateLabelToReplace = stopDayjs.format('MMM D YYYY');
    return startLabel.replace(dateLabelToReplace, '').trim() + ' - ' + stopLabel;
  }
  return startLabel + ' - ' + stopLabel;
}

export function mergeRefs<T>(...refs: Ref<T>[]): Ref<T> {
  return (value: T) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref) {
        (ref as MutableRefObject<T>).current = value;
      }
    });
  };
}
export function roundToDecimalPlaces(num: number, decimalPlaces: number): number {
  const factor = Math.pow(10, decimalPlaces);
  return Math.round((num + Number.EPSILON) * factor) / factor;
}

export function capitalizeString(s: string) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}
