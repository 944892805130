import { Color, ShaderMaterial } from 'three';
import { sensorConeFrag, sensorConeVert } from '@/components/SpaceVisualizer/ThreeRenderer';

export interface ISV_SensorConeMaterial {
  color?: number;
}

export class SensorConeMaterial extends ShaderMaterial {
  constructor({ color }: ISV_SensorConeMaterial) {
    super({
      uniforms: {
        color: { value: new Color(color) },
      },
      vertexShader: sensorConeVert,
      fragmentShader: sensorConeFrag,
      transparent: true,
      wireframe: true,
    });
  }

  /**
   * Update
   */
  update({ color }: ISV_SensorConeMaterial) {
    color && (this.uniforms.color.value = new Color(color));
  }
}
