import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
  ButtonGroup,
  HeatmapIcon,
  HorizontalDividerIcon,
  SpaceVisualizationOptions,
  HistoryGenerate,
  LoadingSpinner,
  IInnerButtonProps,
} from '@/components';
import { useGetDetectionsState, useGetDetectionsWithProgress, useSVConvertTime } from '@/hooks';
import { ESV_Vision_Options, ICommonComponentProps, ITime, TSV_Vision_Options } from '@/types';
import { useAppDispatch } from '@/redux/hooks';
import { setDialog } from '@/redux/reducers/dialog';
import { Icon } from '@/components/primitives/icon/Icon';

interface ISpaceVisualizationDropdownProps extends ICommonComponentProps {
  options: TSV_Vision_Options;
  onChange: (option: ESV_Vision_Options, value: boolean) => void;
}

export const SpaceVisualizationDropdown = ({
  className,
  style,
  options,
  onChange,
  ...rest
}: ISpaceVisualizationDropdownProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { progress: loadDetectionProgress, load: loadDetections } = useGetDetectionsWithProgress();
  const { isFetching: isFetchingDetections, isSuccess: isSuccessDetections } =
    useGetDetectionsState();
  const { timeLabel } = useSVConvertTime();

  // Disable generate
  const disableGenerate =
    !options[ESV_Vision_Options.UTILIZATION_HEATMAP] &&
    !options[ESV_Vision_Options.DETECTION_REPLAY];

  // Listener when generate button is clicked on dialog
  const onGenerateHeatmap = useCallback(
    (time: ITime) => {
      loadDetections(time);
    },
    [loadDetections],
  );

  // Listener when history button is clicked
  const handleOpenGenerateDialog = useCallback(() => {
    dispatch(
      setDialog({
        content: <HistoryGenerate onSubmit={onGenerateHeatmap} />,
      }),
    );
  }, [onGenerateHeatmap, dispatch]);

  return (
    <ButtonGroup
      className={clsx('!gap-0', className)}
      style={style}
      content={[
        {
          buttonContent: (
            <div className="flex gap-2 text-sm">
              {isFetchingDetections ? (
                <LoadingSpinner className="h-4 w-4 fill-current" />
              ) : (
                <HeatmapIcon className="w-4 fill-current" />
              )}
              <span>{t('spaceVisualizations')}</span>
              {isFetchingDetections && <span>{`(${loadDetectionProgress.toFixed(0)}%)`}</span>}
            </div>
          ),
          buttonProps: { 'aria-label': 'Space visualizations' },
          variant: 'secondary',
          popover: (
            <div className="flex w-52 flex-col items-end gap-2 rounded border border-gray-100 bg-white shadow-xl">
              <SpaceVisualizationOptions options={options} onChange={onChange} />
              {!isSuccessDetections && (
                <button
                  data-id="spaceVisualizationGenerateButton"
                  className={clsx(
                    'mr-5 mb-2 h-7 w-20 rounded bg-product-red500 text-xs text-gray-50 hover:bg-product-red300 active:bg-product-red400',
                    disableGenerate &&
                      'bg-product-red100 hover:bg-product-red100 active:bg-product-red100',
                  )}
                  onClick={handleOpenGenerateDialog}
                  disabled={disableGenerate}
                >
                  {t('generate')}
                </button>
              )}
            </div>
          ),
          placement: 'bottom-start',
          disabled: isFetchingDetections,
          disabledChevron: false,
          portalId: 'space-visualizer-container',
        },
        ...((isSuccessDetections || isFetchingDetections
          ? [
              {
                buttonContent: <HorizontalDividerIcon className="h-4 w-4 fill-current" />,
                disabledOnHover: true,
                portalId: 'space-visualizer-container',
              },
              {
                buttonContent: (
                  <div className="flex items-center gap-2 text-sm">
                    <Icon name="time" size={16} />
                    <p>{timeLabel}</p>
                  </div>
                ),
                buttonProps: { 'aria-label': 'Space visualizations' },
                variant: 'secondary',
                disabled: isFetchingDetections,
                onClick: handleOpenGenerateDialog,
                portalId: 'space-visualizer-container',
              },
            ]
          : []) as IInnerButtonProps[]),
      ]}
      {...rest}
    />
  );
};
