import clsx from 'clsx';
import React, { useEffect, useRef, useState, CSSProperties } from 'react';
import {
  arrow,
  flip,
  shift,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  FloatingPortal,
  offset,
  size,
  autoUpdate,
  Placement,
  safePolygon,
} from '@floating-ui/react';
import ClearIcon from '@/components/Icons/Clear';

export type AutoTooltipProps = {
  // Require either children or position
  className?: string;
  style?: CSSProperties;
  /** Variant style to use */
  variant?: 'black' | 'white' | 'chart';
  /** Contents of the tooltip */
  label?: React.ReactNode;
  /** Whether the tooltip can be opened or not */
  disabled?: boolean;
  /** Distance to move tooltip away from hover target */
  offsetSize?: number;
  /** Max width for the tooltip */
  maxWidth?: number;
  /** Max height for the tooltip */
  maxHeight?: number;
  /** When set to true, force the tooltip to be shown */
  forceOpen?: boolean;
  /** Size of the arrow */
  arrowSize?: number;
  /** Preferred placement of the tooltip */
  closeButton?: boolean;
  /** Whether the tooltip displays a click-to-close button */
  placement?: Placement;
  /** Disable behavior to show the tooltip when the element is focused */
  disableOnFocus?: boolean;
  /** Disable behavior to show the tooltip when the element is hovered */
  disableOnHover?: boolean;
  /** Whether to close polygon when leaving the floating element */
  keepOpenOnMouseOver?: boolean;
  /** Turns on more accurate but expensive auto-update of position on animation frame */
  autoUpdateOnAnimationFrame?: boolean;
  /** z index of the tooltip */
  zIndex?: number;
} & ( // Require either children or position
  | {
      /**
       * What to point the tooltip at. If you want to attach the tooltip directly, you can pass a
       * render prop. This will receive the ref that you can then attach to the component.
       */
      children:
        | React.ReactNode
        | ((
            props: {
              className?: string;
              style?: CSSProperties;
              ref: React.Ref<any>;
            } & Record<string, unknown>,
          ) => React.ReactElement);
      position?: never;
    }
  | {
      children?: never;
      /**
       * The x,y position to point the tooltip at (instead of an element)
       */
      position: { x: number | string; y: number | string };
    }
);

/**
 * A component that provides a tooltip around a component with a given label.
 * The tooltip will pop out of the context and will automatically position itself within the window.
 */
export function AutoTooltip({
  className,
  style,
  children,
  label,
  variant = 'black',
  disabled,
  offsetSize = 10,
  maxWidth,
  maxHeight,
  forceOpen,
  arrowSize = 9,
  position,
  closeButton = false,
  placement: preferredPlacement = 'bottom',
  disableOnFocus,
  disableOnHover,
  keepOpenOnMouseOver,
  autoUpdateOnAnimationFrame,
  zIndex = 1,
}: AutoTooltipProps) {
  const arrowRef = useRef(null);
  const [open, setOpen] = useState(false);

  const { x, y, strategy, refs, context, middlewareData, placement, update } = useFloating({
    placement: preferredPlacement,
    whileElementsMounted: (ref, floating, doUpdate) => {
      return autoUpdate(ref, floating, doUpdate, {
        animationFrame: autoUpdateOnAnimationFrame,
      });
    },
    open,
    onOpenChange: setOpen,
    middleware: [
      offset(offsetSize),
      shift(),
      flip(),
      size({
        apply: ({ availableWidth, availableHeight, elements }) => {
          Object.assign(elements.floating.style, {
            maxWidth: `${maxWidth ? Math.min(maxWidth, availableWidth) : availableWidth}px`,
            maxHeight: `${maxHeight ? Math.min(maxHeight, availableHeight) : availableHeight}px`,
          });
        },
      }),
      shift({ padding: 5 }),
      arrow({ element: arrowRef }),
    ],
  });
  const staticSide = {
    top: 'bottom',
    right: 'left',
    bottom: 'top',
    left: 'right',
  }[placement.split('-')[0]];

  const { x: arrowX, y: arrowY } = middlewareData.arrow ?? {};
  const { getReferenceProps, getFloatingProps } = useInteractions([
    useFocus(context, { enabled: !disableOnFocus }),
    useHover(context, {
      enabled: !disableOnHover,
      handleClose: keepOpenOnMouseOver ? safePolygon() : null,
    }),
  ]);

  // Sometimes the tooltip doesn't update when the position changes. So we trigger an update manually.
  useEffect(() => {
    if (position?.x && position?.y) update();
  }, [position?.x, position?.y, update]);
  return (
    <>
      {position ? (
        <span
          ref={refs.setReference}
          className={className}
          style={{
            ...style,
            ...{ position: 'absolute', left: position.x ?? 0, top: position.y ?? 0 },
          }}
        />
      ) : typeof children === 'function' ? (
        children({ className, style, ...getReferenceProps(), ref: refs.setReference })
      ) : (
        <span ref={refs.setReference} className={className} style={style} {...getReferenceProps()}>
          {children}
        </span>
      )}
      {/* Use app-root so that we get styles from <App />, including the font */}
      <FloatingPortal id="app-root">
        {(open || forceOpen) && !disabled && label && (
          <div
            role="tooltip"
            ref={refs.setFloating}
            className={clsx(
              'w-auto max-w-xs rounded',
              !keepOpenOnMouseOver && 'pointer-events-none',
              (variant === 'black' || variant === 'chart') && 'bg-gray-900 text-white',
              variant === 'white' && 'bg-white text-black shadow-tooltip',
              variant === 'chart' ? 'px-4 py-2' : 'px-3 py-2',
            )}
            style={{
              position: strategy,
              left: x ?? 0,
              top: y ?? 0,
              width: 'max-content',
              zIndex,
            }}
            {...getFloatingProps()}
          >
            <div
              ref={arrowRef}
              className={clsx(
                (variant === 'black' || variant === 'chart') && 'bg-gray-900 text-white',
                variant === 'white' && 'bg-white text-black',
              )}
              style={{
                position: 'absolute',
                width: arrowSize,
                height: arrowSize,
                left: arrowX,
                top: arrowY,
                ...(staticSide ? { [staticSide]: -arrowSize / 2 } : {}),
                transform: 'rotate(45deg)',
              }}
            />
            {closeButton && (
              <div className="flex cursor-pointer justify-end" onClick={() => setOpen?.(false)}>
                <ClearIcon height={12} width={12} />
              </div>
            )}
            <div className={clsx('whitespace-pre-wrap text-xs', closeButton ? '-mt-4' : '')}>
              {label}
            </div>
          </div>
        )}
      </FloatingPortal>
    </>
  );
}
