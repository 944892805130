import React, { FC, memo } from 'react';
import { LoadingIcon } from '@/components';
import { ICommonComponentProps } from '@/types';
import clsx from 'clsx';

interface ILoadingSpinnerProps extends ICommonComponentProps {
  size?: number;
}

export const LoadingSpinner: FC<ILoadingSpinnerProps> = memo(
  ({ className, size, style, ...rest }) => {
    return (
      <div
        data-id="loading-spinner"
        className={clsx('flex h-full w-full items-center justify-center', className)}
        style={style}
        {...rest}
      >
        <LoadingIcon className="animate-spin" width={size ?? 100} height={size ?? 100} />
      </div>
    );
  },
);
