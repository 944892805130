import { Tag } from 'antd';
import { useEffect, useState } from 'react';
import { InputTag } from './InputTag';
import TagSelect from './TagSelect';

type TagGroupProps = {
  options: string[];
  data?: string[];
  onAdd?: (tag: string) => void;
  onRemove?: (tag: string) => void;
  variant?: 'default' | 'dropdown';
};

export function TagGroup({ options, data, onAdd, onRemove, variant = 'default' }: TagGroupProps) {
  const initialTags = new Set(data);
  const [tags, setTags] = useState<Set<string>>(initialTags);
  useEffect(() => {
    setTags(new Set(data));
  }, [data]);
  // const [tagsArray, setTagsArray] = useState<String[]>(data || []);
  const validOptions = options.filter((option) => !tags.has(option));

  return (
    <div className="flex flex-wrap gap-1">
      {variant === 'dropdown' ? (
        <TagSelect
          tags={tags}
          options={validOptions}
          onSelect={(tag) => {
            onAdd?.(tag);
            setTags((items) => new Set(items.add(tag)));
          }}
          onDeselect={(tag) => {
            onRemove?.(tag);
            setTags((items) => {
              items.delete(tag);
              return new Set(items);
            });
          }}
        />
      ) : (
        <>
          {Array.from(tags).map((tag) => (
            <Tag
              key={tag}
              closable
              onClose={() => {
                onRemove?.(tag);
                setTags((items) => {
                  items.delete(tag);
                  return new Set(items);
                });
              }}
            >
              {tag}
            </Tag>
          ))}
          <InputTag
            options={validOptions}
            onChange={(tag) => {
              onAdd?.(tag);
              setTags((items) => new Set(items.add(tag)));
            }}
          />
        </>
      )}
    </div>
  );
}
