import { FC, useEffect, useRef, memo, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useResizeDetector } from 'react-resize-detector';
import { ChartTooltipContext } from '@/contexts';
import { TrafficChart } from '@/chart';
import {
  ETrafficMode,
  ISensor,
  ICommonComponentProps,
  TStatsTrafficDP,
  TStatsTrafficHM,
  ITimeRange,
} from '@/types';
import clsx from 'clsx';

interface ITrafficViewProps extends ICommonComponentProps {
  combinedData: TStatsTrafficDP[];
  individualData: TStatsTrafficHM;
  sensors: ISensor[];
  trafficMode: ETrafficMode;
  timeRange: ITimeRange;
  hideExitHeadcount: boolean;
  tz: string;
  currentLang: string;
}

export const TrafficView: FC<ITrafficViewProps> = memo(
  ({
    className,
    style,
    combinedData,
    individualData,
    sensors,
    trafficMode,
    timeRange,
    hideExitHeadcount,
    tz,
    currentLang,
    ...rest
  }) => {
    const { t } = useTranslation();
    const { handleChartTooltipHide, handleChartTooltipShow } = useContext(ChartTooltipContext);
    const chartRef = useRef<HTMLDivElement | null>(null);
    const chartInstanceRef = useRef<TrafficChart | null>(null);

    // Init/Dipose chart
    useEffect(() => {
      if (
        !handleChartTooltipHide ||
        !handleChartTooltipShow ||
        !chartRef.current ||
        chartInstanceRef.current
      )
        return;

      chartInstanceRef.current = new TrafficChart({
        container: chartRef.current,
        combinedData,
        individualData,
        sensors,
        trafficMode,
        timeRange,
        tz,
        hideExitHeadcount,
        t,
        currentLang,
      });
      chartInstanceRef.current.onMouseOut = () => {
        // Remove existing range shadow
        chartInstanceRef.current?.svg.selectAll('#selected-traffic-range').remove();
        handleChartTooltipHide();
      };
      chartInstanceRef.current.onMouseMove = handleChartTooltipShow;
      chartInstanceRef.current.init();

      return () => {
        chartInstanceRef.current?.dispose();
      };
    }, [
      handleChartTooltipHide,
      handleChartTooltipShow,
      combinedData,
      individualData,
      sensors,
      trafficMode,
      timeRange,
      hideExitHeadcount,
      t,
      currentLang,
      tz,
    ]);

    // Update chart data
    useEffect(() => {
      chartInstanceRef.current?.update({
        combinedData,
        individualData,
        sensors,
        trafficMode,
        timeRange,
        t,
        currentLang,
        tz,
      });
    }, [combinedData, individualData, sensors, trafficMode, timeRange, t, currentLang, tz]);

    // Listen when container is resized
    const handleResize = useCallback(() => {
      chartInstanceRef.current?.onResize();
    }, []);

    const { ref } = useResizeDetector({ onResize: handleResize });

    return (
      <div
        ref={ref}
        className={clsx('relative w-full', className)}
        style={style}
        data-id="traffic-view"
        {...rest}
      >
        <div ref={chartRef} className="h-full w-full" />
      </div>
    );
  },
);
