import { combineReducers } from 'redux';
import timeReduce from './time';
import dialogReducer from './dialog';
import superuserReducer from './superuser';
import { baseApi } from '@/hooks/api/base';
import studioReducer from '@/components/studio/ScopingEditor/reducer';

const rootReducer = combineReducers({
  time: timeReduce,
  [baseApi.reducerPath]: baseApi.reducer,
  studio: studioReducer,
  dialog: dialogReducer,
  superuser: superuserReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
