import {
  PerspectiveCamera,
  Scene,
  Color,
  WebGLRenderer,
  sRGBEncoding,
  DirectionalLight,
  AmbientLight,
  MOUSE,
  Object3D,
  GridHelper,
  Box3,
  Vector3,
  Event,
  OrthographicCamera,
  Camera,
  Mesh,
  MeshBasicMaterial,
  PlaneBufferGeometry,
} from 'three';
import { clamp } from 'three/src/math/MathUtils';
import { CSS2DRenderer } from 'three/examples/jsm/renderers/CSS2DRenderer';
import { CSS3DRenderer } from 'three/examples/jsm/renderers/CSS3DRenderer';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import Stats from 'three/examples/jsm/libs/stats.module';
import gsap from 'gsap';
import { isEqual } from 'lodash';
import {
  dispatcher,
  Events,
  InteractionHelper,
  disposeObject,
  loadTexture,
  removeAllChildren,
} from './helpers';
import { RoomModel, SensorModel } from './models';
import {
  ISV_Options,
  ESV_Vision_Options,
  ESV_VizMode,
  IDetections,
  ReportingDP,
  IFloorplan,
} from '@/types';
import { isDev } from '@/constants';
import type {
  SpaceVisualizerRendererRoomFragment,
  SpaceVisualizerRendererSensorFragment,
} from '@/.generated/graphql';
import { gql } from '@apollo/client';

export default class SVRenderer {
  static fragments = {
    Room: gql`
      fragment SpaceVisualizerRendererRoom on Room {
        id: room_id
        capacity {
          max
        }
        coordinates
        name
      }
    `,
    Sensor: gql`
      fragment SpaceVisualizerRendererSensor on Sensor {
        id: sensor_id
        ...SpaceVisualizerSensorModel
        roomId: room_id
      }
      ${SensorModel.fragments.Sensor}
    `,
  };

  public time: number;
  public heatmapMinMax: [number, number];
  public rooms: SpaceVisualizerRendererRoomFragment[];
  public detections: IDetections;
  public container: HTMLDivElement; // Cavas element
  public options: ISV_Options; // Options for space visualizer
  public scene!: Scene; // Scene
  public perspectiveCamera!: PerspectiveCamera; // Perspective camera
  public orthographicCamera!: OrthographicCamera; // Orthographic camera
  public activeCamera!: Camera; // Current active camera
  public interactionHelper!: InteractionHelper; // Interaction helper
  public latestOccupancyByRoom: Record<string, ReportingDP | null> = {}; // Latest occupancy by room

  private renderer!: WebGLRenderer; // Webgl renderer
  private css2DRenderer!: CSS2DRenderer; // Css 2d renderer
  private css3DRenderer!: CSS3DRenderer; // Css 3d renderer
  private stats: Stats | null; // Stats
  private width: number; // Canvas width
  private height: number; // Canvas height
  private pixelRatio: number; // Display ratio
  private aspect: number; // Camera aspect
  private camControlHelper!: OrbitControls; // Camera control helper
  private viewContainer: Object3D; // Container of all view elements
  private floorplanContainer: Object3D; // Floorplan mesh
  private roomContainer: Object3D; // Container of rooms
  private activitySensorContainer: Object3D; // Container of activity sensors
  private headcountSensorContainer: Object3D; // Container of headcount sensors
  private viewBox: Box3; // Box3 of room container
  private viewBoxSize: number; // Size of viewBox
  private initialCameraDist: number; // Initial distance from camera to lookat point when the first rendering is done
  private maxPerspectiveCameraZoom: number;
  private minPerspectiveCameraZoom: number;
  private maxOrthographicCameraZoom: number;
  private minOrthographicCameraZoom: number;

  constructor(
    container: HTMLDivElement,
    rooms: SpaceVisualizerRendererRoomFragment[],
    public activitySensors: SpaceVisualizerRendererSensorFragment[],
    public headcountSensors: SpaceVisualizerRendererSensorFragment[],
    options: ISV_Options,
  ) {
    this.container = container;
    this.stats = null;
    this.rooms = rooms;
    this.options = options;
    this.heatmapMinMax = [0, 0];
    this.pixelRatio = window.devicePixelRatio;
    this.width = this.container.offsetWidth || 1;
    this.height = this.container.offsetHeight || 1;
    this.aspect = this.width / this.height;
    this.viewContainer = new Object3D();
    this.floorplanContainer = new Object3D();
    this.roomContainer = new Object3D();
    this.activitySensorContainer = new Object3D();
    this.headcountSensorContainer = new Object3D();
    this.viewBox = new Box3();
    this.viewBoxSize = 0;
    this.initialCameraDist = 0;
    this.maxPerspectiveCameraZoom = 400;
    this.minPerspectiveCameraZoom = 10;
    this.maxOrthographicCameraZoom = 400;
    this.minOrthographicCameraZoom = 50;
    this.latestOccupancyByRoom = {};
    this.detections = {};
    this.time = 0;

    this.init();
  }

  /**
   * Initialize
   */
  init() {
    this.initRenderer();
    this.initScene();
    this.initCamera();
    this.initStats();
    this.initCameraControlHelper();
    this.initInteractionHelper();
    this.initHierarchy();
    this.initTransformation();
    this.initEventListeners();

    gsap.ticker.add(this.tick);
  }

  /**
   * Initialize renderer
   */
  initRenderer = () => {
    // WebGL renderer
    this.renderer = new WebGLRenderer({
      antialias: !(this.pixelRatio > 1),
      powerPreference: 'high-performance',
      logarithmicDepthBuffer: true,
    });

    this.renderer.setPixelRatio(this.pixelRatio);
    this.renderer.setSize(this.width, this.height);
    this.renderer.outputEncoding = sRGBEncoding;
    this.renderer.physicallyCorrectLights = true;
    this.container.appendChild(this.renderer.domElement);
    // CSS2D renderer
    this.css2DRenderer = new CSS2DRenderer();
    this.css2DRenderer.setSize(this.width, this.height);
    this.css2DRenderer.domElement.style.position = 'absolute';
    this.css2DRenderer.domElement.style.top = '0px';
    this.css2DRenderer.domElement.style.left = '0px';
    this.container.appendChild(this.css2DRenderer.domElement);
    // CSS3D renderer
    this.css3DRenderer = new CSS3DRenderer();
    this.css3DRenderer.setSize(this.width, this.height);
    this.css3DRenderer.domElement.style.position = 'absolute';
    this.css3DRenderer.domElement.style.top = '0px';
    this.css3DRenderer.domElement.style.left = '0px';
    this.container.appendChild(this.css3DRenderer.domElement);
  };

  /**
   * Initialize scene
   */
  initScene() {
    this.scene = new Scene();
    this.scene.background = new Color(0xf9fafa);
  }

  /**
   * Initialize scene hierarchy
   */
  initHierarchy() {
    this.determineSensorVisibility();
    this.addLights();
    this.addMeshes();
  }

  /**
   * Initialize camera
   */
  initCamera() {
    this.perspectiveCamera = new PerspectiveCamera(45, this.aspect, 0.1, 1000);
    this.orthographicCamera = new OrthographicCamera(
      this.width / -2,
      this.width / 2,
      this.height / 2,
      this.height / -2,
      0.1,
      1000,
    );
    this.scene.add(this.perspectiveCamera);
    this.scene.add(this.orthographicCamera);
    this.activeCamera = this.orthographicCamera;
  }

  /**
   * Initialize stats
   */
  initStats() {
    if (!isDev) return;

    const stats = Stats();
    stats.dom.style.bottom = '0px';
    stats.dom.style.top = 'auto';
    document.body.appendChild(stats.dom);

    this.stats = stats;
  }

  /**
   * Initialize camera control helper
   */
  initCameraControlHelper() {
    this.camControlHelper = new OrbitControls(this.activeCamera, this.container);

    // Change controls options
    this.camControlHelper.mouseButtons = {
      LEFT: MOUSE.PAN,
      MIDDLE: MOUSE.DOLLY,
      RIGHT: MOUSE.PAN,
    };

    this.camControlHelper.enableZoom = false;
    this.camControlHelper.enableRotate = this.options.orbitMode;
    this.camControlHelper.maxPolarAngle = (Math.PI * 2) / 5;
  }

  /**
   * Initialize interaction helper
   */
  initInteractionHelper() {
    this.interactionHelper = new InteractionHelper(this);
  }

  /**
   * Initialize transformations
   */
  initTransformation() {
    this.viewBoxSize = this.viewBox.getSize(new Vector3()).length();
    const viewBoxCenter = this.viewBox.getCenter(new Vector3());
    this.viewContainer.position.sub(viewBoxCenter).y = 0;
    this.camControlHelper.maxDistance = this.viewBoxSize * 10;
    this.floorplanContainer.matrixAutoUpdate = false;
    this.roomContainer.matrixAutoUpdate = false;
    this.activitySensorContainer.matrixAutoUpdate = false;
    this.headcountSensorContainer.matrixAutoUpdate = false;

    this.orthographicCamera.position.set(0, 100, 0);
    this.orthographicCamera.left = (this.viewBoxSize * this.aspect) / -2;
    this.orthographicCamera.right = (this.viewBoxSize * this.aspect) / 2;
    this.orthographicCamera.top = this.viewBoxSize / 2;
    this.orthographicCamera.bottom = this.viewBoxSize / -2;
    this.orthographicCamera.updateProjectionMatrix();

    this.perspectiveCamera.near = this.viewBoxSize / 100;
    this.perspectiveCamera.far = this.viewBoxSize * 100;
    this.perspectiveCamera.position.set(0, this.viewBoxSize * 1.1, 0);
    this.perspectiveCamera.lookAt(viewBoxCenter);
    this.perspectiveCamera.updateProjectionMatrix();
    this.initialCameraDist = this.perspectiveCamera.position.distanceTo(
      this.camControlHelper.target,
    );
  }

  /**
   * Initialize event listeners
   */
  initEventListeners = () => {
    window.addEventListener('resize', this.onWindowResize, false);
    window.addEventListener('keypress', this.onKeyPress, false);
    this.container.addEventListener('wheel', this.onWheel, false);
    dispatcher.addEventListener(Events.OPTIONS_UPDATE, this.onOptionsUpdate);
  };

  /**
   * Dispose event listeners
   */
  disposeEventListeners = () => {
    window.removeEventListener('resize', this.onWindowResize, false);
    window.removeEventListener('keypress', this.onKeyPress, false);
    this.container.removeEventListener('wheel', this.onWheel, false);
    dispatcher.removeEventListener(Events.OPTIONS_UPDATE, this.onOptionsUpdate);
  };

  /**
   * Initialize sensor visibility
   */
  determineSensorVisibility() {
    const { vizMode, visionOptions } = this.options;
    const sensorCoverage = visionOptions[ESV_Vision_Options.SENSOR_COVERAGE_AREAS];
    (
      [
        ...this.activitySensorContainer.children,
        ...this.headcountSensorContainer.children,
      ] as SensorModel[]
    ).forEach((sensor) => sensor.showCoverage(sensorCoverage));

    if (vizMode === ESV_VizMode.OCCUPANCY) {
      this.activitySensorContainer.visible = true;
      this.headcountSensorContainer.visible = false;
    } else {
      this.activitySensorContainer.visible = false;
      this.headcountSensorContainer.visible = true;
    }
  }

  /**
   * Add lights
   */
  addLights() {
    const directLight = new DirectionalLight(0xffffff, 0.5);
    const ambientLight = new AmbientLight(0x404040);
    this.scene.add(directLight);
    this.scene.add(ambientLight);
  }

  /**
   * Add grid
   */
  addGrid() {
    const grid = new GridHelper(10000, 1000, 0x5555ff, 0xccccff);
    this.scene.add(grid);
  }

  /**
   * Add meshes
   */
  addMeshes() {
    // Add rooms
    this.rooms.forEach((roomData) => {
      const roomActivitySensors = this.activitySensors.filter((s) => s.roomId === roomData.id);
      const roomHeadcountSensors = this.headcountSensors.filter((s) => s.roomId === roomData.id);
      const room = new RoomModel(this, roomActivitySensors, roomHeadcountSensors, roomData);
      this.roomContainer.add(room);
    });

    // Add non-room sensors
    const activitySensors = this.activitySensors.filter((s) => !s.roomId);
    const headcountSensors = this.headcountSensors.filter((s) => !s.roomId);
    activitySensors.forEach((s) => {
      const sensor = new SensorModel(this, s);
      this.activitySensorContainer.add(sensor);
    });
    headcountSensors.forEach((s) => {
      const sensor = new SensorModel(this, s);
      this.headcountSensorContainer.add(sensor);
    });

    this.viewContainer.add(this.roomContainer);
    this.viewContainer.add(this.activitySensorContainer);
    this.viewContainer.add(this.headcountSensorContainer);
    this.scene.add(this.viewContainer);

    // Box3 involving all view elements
    this.viewBox.setFromObject(this.viewContainer);
  }

  /**
   * Add floorplan
   */
  addFloorplan(floorplans: IFloorplan[], onLoaded?: () => void, onError?: () => void) {
    // Clean up existing floorplans first
    removeAllChildren(this.floorplanContainer);
    this.viewContainer.add(this.floorplanContainer);

    // Fetch floorplans asynchronously
    return Promise.all(
      floorplans.map(({ id, url, coordinates }) => {
        return loadTexture(
          url,
          id,
          (texture) => {
            // Get w/h/center
            const xCoords = [...new Set(coordinates.map(([x]) => x))];
            const yCoords = [...new Set(coordinates.map(([, y]) => y))];
            const w = Math.abs(xCoords[0] - xCoords[1]);
            const h = Math.abs(yCoords[0] - yCoords[1]);
            const center = [(xCoords[0] + xCoords[1]) / 2, (yCoords[0] + yCoords[1]) / 2];

            // Add mesh
            const floorplan = new Mesh();
            floorplan.geometry = new PlaneBufferGeometry(w, h);
            floorplan.material = new MeshBasicMaterial({ map: texture });
            floorplan.matrixAutoUpdate = false;
            floorplan.rotateX(-Math.PI / 2);
            floorplan.position.set(center[0], -0.2, -center[1]);
            floorplan.updateMatrix();

            this.floorplanContainer.add(floorplan);
            onLoaded?.();
          },
          undefined,
          onError,
        );
      }),
    );
  }

  /**
   * Update floorplan visibility
   */
  showFloorplan(show: boolean) {
    this.floorplanContainer.visible = show;
  }

  /**
   * Zoom camera
   */
  zoomTo(newZoom: number) {
    const perspectiveZoom = clamp(
      newZoom,
      this.minPerspectiveCameraZoom,
      this.maxPerspectiveCameraZoom,
    );
    const orthographicZoom = clamp(
      newZoom,
      this.minOrthographicCameraZoom,
      this.maxOrthographicCameraZoom,
    );
    const zoom =
      this.activeCamera instanceof PerspectiveCamera ? perspectiveZoom : orthographicZoom;
    const camPos = this.perspectiveCamera.position.clone();
    const targetPos = this.camControlHelper.target.clone();
    const dir = camPos.clone().sub(targetPos).normalize();
    const newCamPos = targetPos.add(dir.multiplyScalar(this.initialCameraDist * (100 / zoom)));
    this.perspectiveCamera.position.copy(newCamPos);
    this.orthographicCamera.zoom = zoom / 100;
    this.orthographicCamera.updateProjectionMatrix();

    this.options.zoom = zoom;

    dispatcher.dispatchEvent({
      type: Events.ZOOM_UPDATE,
      param: zoom,
    });
  }

  /**
   * Window resize listener
   */
  onWindowResize = () => {
    this.width = this.container.offsetWidth;
    this.height = this.container.offsetHeight;
    const newAspect = this.width / this.height;
    const change = this.aspect / newAspect;
    const newSize = this.viewBoxSize * change;
    this.aspect = newAspect;

    this.orthographicCamera.left = (-this.aspect * newSize) / 2;
    this.orthographicCamera.right = (this.aspect * newSize) / 2;
    this.orthographicCamera.top = newSize / 2;
    this.orthographicCamera.bottom = -newSize / 2;
    this.orthographicCamera.updateProjectionMatrix();

    this.perspectiveCamera.aspect = this.aspect;
    this.perspectiveCamera.updateProjectionMatrix();
    this.renderer.setSize(this.width, this.height);
    this.css2DRenderer.setSize(this.width, this.height);
    this.css3DRenderer.setSize(this.width, this.height);
  };

  /**
   * Key press listener
   */
  onKeyPress = () => {
    // TODO Listener for key press
  };

  /**
   * Wheel listener
   */
  onWheel = (e: WheelEvent) => {
    e.preventDefault();
    this.zoomTo(Math.floor(this.options.zoom - e.deltaY));
  };

  /**
   * Occupancy update listener
   */
  onOccupancyUpdate = (latestOccupancyByRoom: Record<string, ReportingDP | null>) => {
    this.latestOccupancyByRoom = latestOccupancyByRoom;
    (this.roomContainer.children as RoomModel[]).forEach((room) => room.onVizModeAndStatsUpdate());
  };

  /**
   * Detections update listener
   */
  onDetectionsUpdate = (detections: IDetections = {}) => {
    this.detections = detections;

    (this.roomContainer.children as RoomModel[]).forEach((room) =>
      room.onDetectionsUpdate(detections),
    );
    (
      [
        ...this.activitySensorContainer.children,
        ...this.headcountSensorContainer.children,
      ] as SensorModel[]
    ).forEach((sensor) => sensor.onDetectionsUpdate(detections));
  };

  /**
   * Time update listener
   */
  onTimeUpdate = (time: number) => {
    this.time = time;

    (this.roomContainer.children as RoomModel[]).forEach((room) => room.onTimeUpdate(time));
    (
      [
        ...this.activitySensorContainer.children,
        ...this.headcountSensorContainer.children,
      ] as SensorModel[]
    ).forEach((sensor) => sensor.onTimeUpdate(time));
  };

  /**
   * Heatmap legend min/mxx update listener
   */
  onHeatmapMinMaxChange = (minMax: [number, number] = [0, 0]) => {
    this.heatmapMinMax = minMax;

    (this.roomContainer.children as RoomModel[]).forEach((room) =>
      room.onHeatmapMinMaxChange(minMax),
    );
    (
      [
        ...this.activitySensorContainer.children,
        ...this.headcountSensorContainer.children,
      ] as SensorModel[]
    ).forEach((sensor) => sensor.onHeatmapMinMaxChange(minMax));
  };

  /**
   * Options update listener
   */
  onOptionsUpdate = (e: Event) => {
    const { newOptions } = e;

    if (isEqual(this.options, newOptions)) return;

    const { zoom, vizMode, orbitMode, presentMode, visionOptions } = this.options;
    const {
      zoom: newZoom,
      vizMode: newVizMode,
      orbitMode: newOrbitMode,
      presentMode: newPresentMode,
      visionOptions: newVisionOptions,
    } = newOptions;

    // Zoom changed
    if (zoom !== newZoom) {
      this.zoomTo(newZoom);
    }

    // Viz mode changed
    if (vizMode !== newVizMode) {
      this.options.vizMode = newVizMode;
      (this.roomContainer.children as RoomModel[]).forEach((room) =>
        room.onVizModeAndStatsUpdate(),
      );
      this.determineSensorVisibility();
    }

    // Orbit mode toggled
    if (orbitMode !== newOrbitMode) {
      this.options.orbitMode = newOrbitMode;
      this.camControlHelper.mouseButtons = {
        LEFT: newOrbitMode ? MOUSE.ROTATE : MOUSE.PAN,
        MIDDLE: MOUSE.DOLLY,
        RIGHT: newOrbitMode ? MOUSE.ROTATE : MOUSE.PAN,
      };
      this.camControlHelper.enableRotate = newOrbitMode;

      // Rotate camera a bit, if orbit mode is activated
      if (newOrbitMode) {
        this.activeCamera.position.add(new Vector3(150, 0, 150));
      } else {
        this.activeCamera.position.copy(this.camControlHelper.target);
        this.activeCamera.position.y += 100;
      }
      this.orthographicCamera.updateProjectionMatrix();

      // Toggling objects of orbit mode
      (
        [
          ...this.activitySensorContainer.children,
          ...this.headcountSensorContainer.children,
        ] as SensorModel[]
      ).forEach((sensor) => sensor.showOrbitModeObjects(newOrbitMode));
      (this.roomContainer.children as RoomModel[]).forEach((room) =>
        room.showSensorOrbitModeObjects(newOrbitMode),
      );
    }

    // Presentation mode toggled
    if (presentMode !== newPresentMode) {
      this.options.presentMode = newPresentMode;
      this.onWindowResize();
    }

    // Vision options are updated
    if (!isEqual(visionOptions, newVisionOptions)) {
      const {
        [ESV_Vision_Options.ROOMS]: rooms,
        [ESV_Vision_Options.FLOOR_PLAN_IMAGE]: floorplanImage,
        [ESV_Vision_Options.SENSOR_COVERAGE_AREAS]: sensorCoverage,
        [ESV_Vision_Options.SPACE_VISUALIZATIONS]: spaceVisualizations,
        [ESV_Vision_Options.UTILIZATION_HEATMAP]: utilizationHeatmap,
        [ESV_Vision_Options.DETECTION_REPLAY]: detectionsReplay,
      } = visionOptions;
      const {
        [ESV_Vision_Options.ROOMS]: newRooms,
        [ESV_Vision_Options.FLOOR_PLAN_IMAGE]: newFloorplanImage,
        [ESV_Vision_Options.SENSOR_COVERAGE_AREAS]: newSensorCoverage,
        [ESV_Vision_Options.SPACE_VISUALIZATIONS]: newSpaceVisualizations,
        [ESV_Vision_Options.UTILIZATION_HEATMAP]: newUtilizationHeatmap,
        [ESV_Vision_Options.DETECTION_REPLAY]: newDetectionsReplay,
      } = newVisionOptions;

      // Show/hide rooms?
      if (rooms !== newRooms) {
        this.options.visionOptions[ESV_Vision_Options.ROOMS] = newRooms;

        (this.roomContainer.children as RoomModel[]).forEach((room) =>
          room.showRoomLayout(newRooms),
        );
      }

      // Show/hide rooms?
      if (floorplanImage !== newFloorplanImage) {
        this.options.visionOptions[ESV_Vision_Options.FLOOR_PLAN_IMAGE] = newFloorplanImage;

        this.showFloorplan(newFloorplanImage);
      }

      // Show/hide heatmap?
      if (sensorCoverage !== newSensorCoverage) {
        this.options.visionOptions[ESV_Vision_Options.SENSOR_COVERAGE_AREAS] = newSensorCoverage;

        this.determineSensorVisibility();

        (this.roomContainer.children as RoomModel[]).forEach((room) =>
          room.determineSensorVisibility(),
        );
      }

      // Show/hide space visualizations
      if (spaceVisualizations !== newSpaceVisualizations) {
        this.options.visionOptions[ESV_Vision_Options.SPACE_VISUALIZATIONS] =
          newSpaceVisualizations;

        (this.roomContainer.children as RoomModel[]).forEach((room) =>
          room.showHistory(newSpaceVisualizations),
        );
        (
          [
            ...this.activitySensorContainer.children,
            ...this.headcountSensorContainer.children,
          ] as SensorModel[]
        ).forEach((sensor) => sensor.showHistory(newSpaceVisualizations));
      } else {
        // Show/hide heatmap
        if (utilizationHeatmap !== newUtilizationHeatmap) {
          this.options.visionOptions[ESV_Vision_Options.UTILIZATION_HEATMAP] =
            newUtilizationHeatmap;

          (this.roomContainer.children as RoomModel[]).forEach((room) =>
            room.showHeatmap(newUtilizationHeatmap),
          );
          (
            [
              ...this.activitySensorContainer.children,
              ...this.headcountSensorContainer.children,
            ] as SensorModel[]
          ).forEach((sensor) => sensor.showHeatmap(newUtilizationHeatmap));
        }
        // Show/hide historic detections
        if (detectionsReplay !== newDetectionsReplay) {
          this.options.visionOptions[ESV_Vision_Options.DETECTION_REPLAY] = newDetectionsReplay;

          (this.roomContainer.children as RoomModel[]).forEach((room) =>
            room.showHistoricDetections(newDetectionsReplay),
          );
          (
            [
              ...this.activitySensorContainer.children,
              ...this.headcountSensorContainer.children,
            ] as SensorModel[]
          ).forEach((sensor) => sensor.showHistoricDetections(newDetectionsReplay));
        }
      }
    }
  };

  /**
   * Tick
   */
  tick = () => {
    this.render();
    this.camControlHelper.update();
    this.interactionHelper?.tick();
    this.stats?.update();
  };

  /**
   * Render
   */
  render() {
    this.renderer.render(this.scene, this.activeCamera);
    this.css2DRenderer.render(this.scene, this.activeCamera);
    this.css3DRenderer.render(this.scene, this.activeCamera);
  }

  /**
   * Dispose
   */
  dispose() {
    gsap.ticker.remove(this.tick);
    this.stats?.dom.remove();
    this.disposeEventListeners();
    this.camControlHelper.dispose();
    disposeObject(this.scene);
    this.roomContainer.children.forEach((room) => (room as RoomModel).dispose());
    this.activitySensorContainer.children.forEach((sensor) => (sensor as SensorModel).dispose());
    this.headcountSensorContainer.children.forEach((sensor) => (sensor as SensorModel).dispose());
  }
}

export * from './helpers';
export * from './models';
export * from './materials';
export * from './glsls';
