import { IClients, IGetClientBySuperuserQueryResponse } from '@/types/User';
import { baseApi } from './base';

export const usersApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserPreferences: builder.query<{ language: string }, void>({
      query: () => ({ url: '/api/v2/users/preferences' }),
      providesTags: ['User'],
    }),
    postUserPreferences: builder.mutation<{ language: string }, { language: string }>({
      query: (body) => ({ method: 'POST', url: '/api/v2/users/preferences', body }),
      invalidatesTags: ['User'],
    }),
    getUserSupportedLanguages: builder.query<
      { default: string; languages: Record<string, string> },
      void
    >({
      query: () => ({ url: '/api/v2/resources/supported-languages' }),
      providesTags: ['User'],
    }),
    getSuperuserClients: builder.query<IClients, void>({
      query: () => ({ url: '/api/v2/superusers/clients' }),
      transformResponse: (response: IGetClientBySuperuserQueryResponse) => response.databases,
      providesTags: ['User'],
    }),
  }),
});

export const {
  useGetUserPreferencesQuery,
  usePostUserPreferencesMutation,
  useGetUserSupportedLanguagesQuery,
  useGetSuperuserClientsQuery,
} = usersApi;
