import React, { FC, memo, useState } from 'react';
import { Country, CountryCode, getAllCountries, getCountry } from 'countries-and-timezones';
import { SearchIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';
import { ICommonComponentProps } from '@/types';
import clsx from 'clsx';

const getCountriesArray = () => {
  return Object.values(getAllCountries());
};

interface ICountryDropdownContentProps extends ICommonComponentProps {
  code?: CountryCode;
  onSelect?: (country: Country) => void;
}

export const CountryDropdownContent: FC<ICountryDropdownContentProps> = memo(
  ({ className, style, code = '', onSelect, ...rest }) => {
    const { t } = useTranslation();
    const [searchText, setSearchText] = useState<string>('');
    const reorderedData = getCountriesArray().filter((country) => country.id !== code);
    const selectedCountry = getCountry(code);
    if (selectedCountry) {
      reorderedData.unshift(selectedCountry);
    }

    return (
      <div
        className={clsx(
          'max-h-96 overflow-auto overscroll-contain text-sm flex flex-col',
          className,
        )}
        style={style}
        {...rest}
      >
        <div className="sticky top-0 bg-white px-2 py-2 shadow-sm">
          <div className="flex justify-between rounded-full bg-product-gray100 px-4 py-2">
            <SearchIcon className="mr-2 w-4 text-product-gray400" />
            <input
              className="outline-none bg-product-gray100"
              style={{ flexGrow: 1 }}
              placeholder={t('typeToSearchCountry')}
              value={searchText}
              onKeyDown={(e) => {
                if (e.key === ' ') {
                  e.stopPropagation();
                }
              }}
              onChange={(e) => {
                setSearchText(e.currentTarget.value);
              }}
            />
          </div>
        </div>
        {reorderedData
          .filter(({ name, id }) => {
            return (
              name.toLowerCase().includes(searchText.toLocaleLowerCase()) ||
              id.toLowerCase().includes(searchText.toLowerCase())
            );
          })
          .map((country) => (
            <button
              key={country.id}
              className="cursor-pointer border-t border-gray-200 px-4 py-2 hover:bg-product-gray100 text-left"
              onClick={() => onSelect?.(country)}
            >
              <span className={code === country.id ? 'text-product-red500' : ''}>
                {country.name}
              </span>
            </button>
          ))}
      </div>
    );
  },
);
