import {
  ConeBufferGeometry,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  PlaneBufferGeometry,
  Shape,
  ShapeBufferGeometry,
  SphereBufferGeometry,
  Vector2,
} from 'three';
import SVRenderer, {
  RoomModel,
  SensorHeatmapMaterial,
  SensorConeMaterial,
  loadTexture,
  removeAllChildren,
} from '@/components/SpaceVisualizer/ThreeRenderer';
import { ESV_Vision_Options, IDetections, ISV_Options } from '@/types';
import { getSensorEffectiveCoverageWidth } from '@/utils';
import { DETECTIONS_WINDOW, HEATMAP_SIZE } from '@/constants';
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';
import { clamp, degToRad } from 'three/src/math/MathUtils';
import DIRECTION_ARROW from '/sensorDirection.png';
import { gql } from '@apollo/client';
import { SpaceVisualizerSensorModelFragment } from '@/.generated/graphql';

const EMPTY_HEATMAP_GRID_DATA = new Array(HEATMAP_SIZE * HEATMAP_SIZE).fill(0);

export class SensorModel extends Object3D {
  static fragments = {
    Sensor: gql`
      fragment SpaceVisualizerSensorModel on Sensor {
        id: sensor_id
        center
        height
        macAddress: mac_address
        orientation
        fieldOfView: field_of_view
      }
    `,
  };

  public parentModel: SVRenderer | RoomModel;
  public detections: IDetections;
  public localDetectionCoords: { [key: number]: Array<{ coord: Vector2; pixelId: number }> };
  public time: number;
  public heatmapMinMax: [number, number];
  public minSampleCount: number;
  public maxSampleCount: number;
  public heatmapPixelSize: number;
  public heatmapGridSize: Vector2;
  public heatmapGridData: number[];
  public maxDetections: number;
  public floorPoints: number[][];
  public expectedSize: number;
  public centerCoord: number[];

  private coverage: Object3D;
  private covPlane: Mesh;
  private heatmap: Mesh;
  private cone: Mesh;
  private monoDetection: Mesh;
  private direction: Mesh;
  private iconTagEl!: HTMLElement;
  private detectionContainer!: Object3D;
  private iconTag!: CSS2DObject;
  private rotationZ: number;

  constructor(
    parentModel: SVRenderer | RoomModel,
    public data: SpaceVisualizerSensorModelFragment,
  ) {
    super();

    this.parentModel = parentModel;
    this.detections = {};
    this.localDetectionCoords = {};
    this.time = 0;
    this.minSampleCount = 0;
    this.maxSampleCount = 0;
    this.heatmapMinMax = [0, 0];
    this.heatmapGridSize = new Vector2(HEATMAP_SIZE, HEATMAP_SIZE);
    this.heatmapGridData = [...EMPTY_HEATMAP_GRID_DATA];
    this.maxDetections = 0;
    this.floorPoints = [];
    this.expectedSize = getSensorEffectiveCoverageWidth(data.fieldOfView, data.height);
    this.heatmapPixelSize = this.expectedSize / HEATMAP_SIZE;
    this.centerCoord = [];
    this.coverage = new Object3D();
    this.covPlane = new Mesh();
    this.heatmap = new Mesh();
    this.cone = new Mesh();
    const detectionGeo = new SphereBufferGeometry(0.1, 8, 16);
    const detectionMat = new MeshBasicMaterial({ color: 0x0000ff });
    this.monoDetection = new Mesh(detectionGeo, detectionMat);
    this.direction = new Mesh();
    this.detectionContainer = new Object3D();
    this.rotationZ = -degToRad(data.orientation?.[1] || 0);

    this.init();
  }

  // Getter of options
  get options(): ISV_Options {
    if (this.parentModel instanceof SVRenderer) {
      return this.parentModel.options;
    }

    return this.parentModel.svRenderer.options;
  }

  /**
   * Initialize
   */
  init() {
    this.initFloorPoints();
    this.initHierarchy();
    this.initTransformation();
  }

  /**
   * Initialize floor points
   */
  initFloorPoints() {
    const { center: rawCenter } = this.data;
    const center = rawCenter || [0, 0];
    const hESize = this.expectedSize / 2;

    if (this.parentModel instanceof RoomModel) {
      const { innerFP: roomIFP, centerCoord: roomCC } = this.parentModel;
      this.centerCoord = [center[0] - roomCC[0], center[1] - roomCC[1]];

      // TODO Revisit because below code block will not work with random polygons
      let [cornerTL, cornerTR, cornerBR, cornerBL] = [
        [this.centerCoord[0] - hESize, this.centerCoord[1] + hESize],
        [this.centerCoord[0] + hESize, this.centerCoord[1] + hESize],
        [this.centerCoord[0] + hESize, this.centerCoord[1] - hESize],
        [this.centerCoord[0] - hESize, this.centerCoord[1] - hESize],
      ];
      cornerTL = [
        cornerTL[0] < roomIFP[0][0] ? roomIFP[0][0] : cornerTL[0],
        cornerTL[1] > roomIFP[0][1] ? roomIFP[0][1] : cornerTL[1],
      ];
      cornerTR = [
        cornerTR[0] > roomIFP[1][0] ? roomIFP[1][0] : cornerTR[0],
        cornerTR[1] > roomIFP[1][1] ? roomIFP[1][1] : cornerTR[1],
      ];
      cornerBR = [
        cornerBR[0] > roomIFP[2][0] ? roomIFP[2][0] : cornerBR[0],
        cornerBR[1] < roomIFP[2][1] ? roomIFP[2][1] : cornerBR[1],
      ];
      cornerBL = [
        cornerBL[0] < roomIFP[3][0] ? roomIFP[3][0] : cornerBL[0],
        cornerBL[1] < roomIFP[3][1] ? roomIFP[3][1] : cornerBL[1],
      ];

      this.floorPoints = [
        [cornerTL[0] - this.centerCoord[0], cornerTL[1] - this.centerCoord[1]],
        [cornerTR[0] - this.centerCoord[0], cornerTR[1] - this.centerCoord[1]],
        [cornerBR[0] - this.centerCoord[0], cornerBR[1] - this.centerCoord[1]],
        [cornerBL[0] - this.centerCoord[0], cornerBL[1] - this.centerCoord[1]],
      ];
    } else {
      this.centerCoord = center;
      this.floorPoints = [
        [-hESize, hESize],
        [hESize, hESize],
        [hESize, -hESize],
        [-hESize, -hESize],
      ];
    }
  }
  /**
   * Initialize hierarchy
   */
  initHierarchy() {
    this.addCoverage();
    this.addHeatmap();
  }

  /**
   * Initialize transformation
   */
  initTransformation() {
    if (this.parentModel instanceof RoomModel) {
      this.position.set(this.centerCoord[0], this.centerCoord[1], Math.random() * 0.01 + 0.01);
    } else {
      this.position.set(this.centerCoord[0], -Math.random() * 0.01 - 0.01, -this.centerCoord[1]);
      this.rotateX(-Math.PI / 2);
    }
    this.direction.rotateZ(this.rotationZ);
    this.direction.position.set(0, 0, 0.3);
    this.heatmap.position.set(0, 0, 0.2);
    this.coverage.position.set(0, 0, 0.1);
    this.matrixAutoUpdate = false;
    this.heatmap.matrixAutoUpdate = false;
    this.coverage.matrixAutoUpdate = false;
    this.updateMatrix();
    this.heatmap.updateMatrix();
    this.coverage.updateMatrix();
    this.direction.updateMatrix();
    this.detectionContainer.updateMatrix();
  }

  /**
   * Initialize detections data
   */
  initDetectionsData() {
    const localDetectionDataArr = this.detections.detections_local?.[this.data.macAddress] || [];
    this.localDetectionCoords = {};
    const centerCoord = new Vector2();
    // Convert local detection array data to sensor-relative coord and pixel id array
    for (const el of localDetectionDataArr) {
      const coords: Array<{ coord: Vector2; pixelId: number }> = [];
      for (const [y, x] of el.value) {
        const localX = (x - 0.5) * this.expectedSize;
        const localY = (y - 0.5) * this.expectedSize;
        const coord = new Vector2(localX, localY);
        coord.rotateAround(centerCoord, this.rotationZ);
        const pixelId =
          Math.floor((coord.x + this.expectedSize / 2) / this.heatmapPixelSize) +
          Math.floor((coord.y + this.expectedSize / 2) / this.heatmapPixelSize) * HEATMAP_SIZE;
        coords.push({ coord, pixelId });
      }

      this.localDetectionCoords[el.start_time] = coords;
    }

    // Max count of detections
    this.maxDetections = Math.max(0, ...localDetectionDataArr.map(({ value }) => value.length));
  }

  /**
   * Initialize heatmap legend data
   */
  initHeatmapLegendData() {
    const [min, max] = this.heatmapMinMax;
    this.minSampleCount = Math.ceil(min / DETECTIONS_WINDOW);
    this.maxSampleCount = Math.ceil(max / DETECTIONS_WINDOW);
  }

  /**
   * Initialize heatmap data
   */
  initHeatmapData() {
    const rawHeatmapGridData = [...EMPTY_HEATMAP_GRID_DATA];

    for (const [time, coords] of Object.entries(this.localDetectionCoords)) {
      if (parseInt(time) > this.time) break;

      for (const { pixelId } of coords) {
        // if (rawHeatmapGridData[pixelId] === i + 1) continue;

        rawHeatmapGridData[pixelId] += 1;
      }
    }

    // TODO Multiplying 10 is still manual parameter.
    this.heatmapGridData = rawHeatmapGridData.map((el) =>
      clamp(((el - this.minSampleCount) / (this.maxSampleCount - this.minSampleCount)) * 10, 0, 1),
    );
  }

  /**
   * Add coverage
   */
  addCoverage() {
    // Add cone mesh
    const hESize = this.expectedSize / 2;
    const coneR = Math.sqrt(Math.pow(hESize, 2) * 2);
    this.cone.geometry = new ConeBufferGeometry(coneR, this.data.height, 4);
    this.cone.material = new SensorConeMaterial({ color: 0x0000ff });
    this.cone.matrixAutoUpdate = false;
    this.coverage.add(this.cone);
    this.cone.position.set(0, 0, this.data.height / 2 + 0.01);
    this.cone.rotateX(Math.PI / 2);
    this.cone.rotateY(Math.PI / 4);
    this.cone.updateMatrix();
    this.cone.visible = false;

    // TODO Add macaddress later?
    // this.nameTagEl = document.createElement('div');
    // this.nameTag = new CSS3DObject(this.nameTagEl);
    // this.nameTagEl.className = `sensor-name-tag-${this.nameTag.uuid}`;
    // this.coverage.add(this.nameTag);

    //   this.nameTag.position.set(0, -this.expectedSize / 2 + 0.4, 0);
    //   this.nameTag.scale.set(0.025, 0.025, 0.025);
    //   this.nameTag.matrixAutoUpdate = false;
    //   this.nameTag.updateMatrix();

    // Add coverage plane
    const fpVec2Arr = this.floorPoints.map((p) => new Vector2().fromArray(p));
    const covShape = new Shape(fpVec2Arr);
    this.covPlane.geometry = new ShapeBufferGeometry(covShape);

    // TODO Revisit below part for updating uv. If room shape is polygon, below adjustment should not work correctly.
    const uvAttr = this.covPlane.geometry.attributes.uv;
    for (let i = 0; i < uvAttr.count; i++) {
      let u = uvAttr.getX(i);
      let v = uvAttr.getY(i);
      u = u / this.expectedSize + 0.5;
      v = v / this.expectedSize + 0.5;

      uvAttr.setXY(i, u, v);
    }
    this.covPlane.material = new MeshBasicMaterial({ color: 0xdbdbe1 });
    this.covPlane.matrixAutoUpdate = false;
    this.coverage.add(this.covPlane);

    // Add direction arrow
    this.direction.geometry = new PlaneBufferGeometry(0.6, 0.6);
    loadTexture(DIRECTION_ARROW, null, (texture) => {
      this.direction.material = new MeshBasicMaterial({ map: texture, transparent: true });
      this.direction.material.needsUpdate = true;
    });
    this.direction.matrixAutoUpdate = false;
    this.coverage.add(this.direction);

    // Add icons
    this.iconTagEl = document.createElement('div');
    this.iconTagEl.innerHTML = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.8 6.3125H10.2C6.96913 6.3125 4.35 8.85888 4.35 12C4.35 15.1411 6.96913 17.6875 10.2 17.6875H13.8C17.0309 17.6875 19.65 15.1411 19.65 12C19.65 8.85888 17.0309 6.3125 13.8 6.3125ZM10.2 5C6.22355 5 3 8.13401 3 12C3 15.866 6.22355 19 10.2 19H13.8C17.7764 19 21 15.866 21 12C21 8.13401 17.7764 5 13.8 5H10.2ZM13.8 8.9375H10.2C8.4603 8.9375 7.05 10.3086 7.05 12C7.05 13.6914 8.4603 15.0625 10.2 15.0625H13.8C15.5397 15.0625 16.95 13.6914 16.95 12C16.95 10.3086 15.5397 8.9375 13.8 8.9375ZM10.2 7.625C7.71472 7.625 5.7 9.58375 5.7 12C5.7 14.4162 7.71472 16.375 10.2 16.375H13.8C16.2853 16.375 18.3 14.4162 18.3 12C18.3 9.58375 16.2853 7.625 13.8 7.625H10.2Z" fill="#A8AAB8"/>
      <rect x="11" y="11" width="2" height="2" fill="#A8AAB8"/>
    </svg>`;
    this.iconTag = new CSS2DObject(this.iconTagEl);
    this.iconTagEl.className = `sensor-icon-tag-${this.iconTag.uuid}`;
    this.iconTag.matrixAutoUpdate = false;
    this.coverage.add(this.iconTag);
    this.iconTag.position.z = this.data.height;
    this.iconTag.scale.set(0.02, 0.02, 0.02);
    this.iconTag.updateMatrix();
    this.iconTag.visible = false;

    this.add(this.coverage);
  }

  /**
   * Add heatmap mesh
   */
  addHeatmap() {
    this.heatmap.geometry = this.covPlane.geometry.clone();
    this.heatmap.material = new SensorHeatmapMaterial({
      gridSize: new Vector2(HEATMAP_SIZE, HEATMAP_SIZE),
      gridData: this.heatmapGridData,
    });
    this.heatmap.name = this.data.id;

    this.add(this.heatmap);

    this.updateHeatmap();
  }

  /**
   * Add detection meshes
   */
  addDetections() {
    // Clean up existing detections first
    removeAllChildren(this.detectionContainer);

    // Add detections as max count
    for (let i = 0; i < this.maxDetections; i++) {
      const cloneDetection = this.monoDetection.clone();

      this.detectionContainer.add(cloneDetection);
    }

    this.add(this.detectionContainer);
  }

  /**
   * Update heatmap
   */
  updateHeatmap() {
    (this.heatmap.material as SensorHeatmapMaterial).update({ gridData: this.heatmapGridData });
  }

  /**
   * Update detections
   */
  updateDetections() {
    const coords = this.localDetectionCoords[this.time] || [];

    for (let i = 0; i < this.maxDetections; i++) {
      const detectionObj = this.detectionContainer.children[i];

      if (coords[i]) {
        detectionObj.visible = true;
        detectionObj.position.set(coords[i].coord.x, coords[i].coord.y, 0.2);
        detectionObj.matrixAutoUpdate = false;
        detectionObj.updateMatrix();
      } else {
        detectionObj.visible = false;
      }
    }
  }

  /**
   * Update sensor coverage visibility
   */
  showCoverage(show: boolean) {
    this.coverage.visible = show;
    this.showDirTag(show);

    // Toggle visibility of objects for orbit mode
    if (this.options.orbitMode) {
      this.showIconTag(show);
    }
  }

  /**
   * Update sensor object visibilities by orbit mode
   */
  showOrbitModeObjects(show: boolean) {
    this.cone.visible = show;

    // Toggle visibility of coverage objects
    if (this.options.visionOptions[ESV_Vision_Options.SENSOR_COVERAGE_AREAS]) {
      this.showIconTag(show);
    }
  }

  /**
   * Update sensor icon tag visibility
   */
  showIconTag(show: boolean) {
    this.iconTag.visible = show;
  }

  /**
   * Update sensor direction tag visibility
   */
  showDirTag(show: boolean) {
    this.direction.visible = show;
  }

  /**
   * Update history visibility
   */
  showHistory(show: boolean) {
    this.showHeatmap(show);
    this.showHistoricDetections(show);
  }

  /**
   * Update heatmap visibility
   */
  showHeatmap(show: boolean) {
    this.heatmap.visible = show;
  }

  /**
   * Update historic detections visibility
   */
  showHistoricDetections(show: boolean) {
    this.detectionContainer.visible = show;
  }

  /**
   * Detections update listener
   */
  onDetectionsUpdate(detections: IDetections) {
    this.detections = detections;

    this.initDetectionsData();
    this.addDetections();
  }

  /**
   * Time update listener
   */
  onTimeUpdate(time: number) {
    this.time = time;

    this.initHeatmapData();
    this.updateDetections();
    this.updateHeatmap();
  }

  /**
   * Heatmap legend min/mxx update listener
   */
  onHeatmapMinMaxChange(minMax: [number, number]) {
    this.heatmapMinMax = minMax;

    this.initHeatmapLegendData();
    this.initHeatmapData();
    this.updateHeatmap();
  }

  /**
   * Dispose
   */
  dispose() {
    this.iconTag?.remove();
  }
}
