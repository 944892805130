import type { RootState } from '@/redux/reducers';
import type { AppDispatch } from '@/redux/store';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useDialog = () => useAppSelector((state) => state.dialog);
export const useSuperUser = () => useAppSelector((state) => state.superuser);
export const useTime = () => useAppSelector((state) => state.time);
