import React from 'react';
import ClearIcon from '@/components/Icons/Clear';
import { useTranslation } from 'react-i18next';

type Props = {
  onClick?: () => void;
  message?: string;
};

export const UserMessageComponent: React.FC<Props> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="flex cursor-pointer justify-end" data-id="close-button" onClick={onClick}>
        <ClearIcon height={12} width={12} />
      </div>
      {t('multipleClientError')}
    </div>
  );
};
