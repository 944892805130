import clsx from 'clsx';
import { CheckIcon, EDateRangePickerType } from '@/components';
import { Icon } from '../icon/Icon';

export function QuickRangeButtonAlternate(props: {
  type: EDateRangePickerType;
  label: string;
  id: number;
  isSelected: boolean;
  onClose: () => void;
  onSelected?: () => void;
}) {
  return (
    <div
      data-id={`Quick range button ${props.id}`}
      role="button"
      tabIndex={props.id}
      className={clsx(
        'flex h-8 cursor-pointer justify-between items-center mr-3 pr-3.5 pl-3.5 hover:bg-gray-300 hover:text-gray-900 rounded-[30px]',
        props.isSelected ? 'bg-gray-900 text-white' : 'text-gray-900',
      )}
      onClick={() => props.onSelected && props.onSelected()}
    >
      <div className="flex items-center justify-start text-sm font-normal leading-[17px] w-full">
        {props.label === 'Today' ? <Icon name="calendar" className="mr-1" /> : null}
        {props.label}
      </div>
      {props.isSelected ? <CheckIcon className="h-4 w-4 fill-current" /> : <></>}
    </div>
  );
}
