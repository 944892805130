import React from 'react';
import clsx from 'clsx';
import { Checkbox } from '@/components';
import { ESV_Vision_Options, ICommonComponentProps, TSV_Vision_Options } from '@/types';
import { useTranslation } from 'react-i18next';

interface ISpaceVisualizationOptionsProps extends ICommonComponentProps {
  options: TSV_Vision_Options;
  optionClassName?: string;
  onChange: (option: ESV_Vision_Options, value: boolean) => void;
}

export const SpaceVisualizationOptions = ({
  className,
  style,
  options,
  optionClassName,
  onChange,
  ...rest
}: ISpaceVisualizationOptionsProps) => {
  const { t } = useTranslation();

  return (
    <div className={clsx('flex w-full flex-col', className)} style={style} {...rest}>
      {[ESV_Vision_Options.UTILIZATION_HEATMAP, ESV_Vision_Options.DETECTION_REPLAY].map(
        (subOption) => {
          return (
            <Checkbox
              key={subOption}
              className={clsx('w-3 h-3 p-2 m-2 hover:bg-gray-100', optionClassName)}
              data-option={subOption}
              checked={options[subOption]}
              onCheckedChange={(value: boolean) => onChange(subOption, value)}
            >
              {t(subOption)}
            </Checkbox>
          );
        },
      )}
    </div>
  );
};
