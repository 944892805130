import { TagGroup } from '@/components/Tags/TagGroup';
import { gql, useMutation } from '@apollo/client';
import { useGetTagsQuery } from '@/.generated/graphql';
import toast from 'react-hot-toast';

type TagContainerProps = {
  associatedTags?: string[];
  variant?: 'default' | 'dropdown';
  itemId?: string;
  queryToRefetch?: { query: any; variables: any }[];
};

export function TagContainer({
  associatedTags,
  itemId,
  variant = 'default',
  queryToRefetch,
}: TagContainerProps) {
  const { data: availableTags, loading: tagsLoading } = useGetTagsQuery();
  //preselect tags that are already associated with the space, room, or zone (API not ready yet)
  //new tag creation
  const [createTag] = useMutation(CREATE_TAG_MUTATION, {
    refetchQueries: [{ query: GET_TAGS_QUERY }],
  });

  const [associateTag] = useMutation(ASSOCIATE_TAG_MUTATION, {
    refetchQueries: queryToRefetch,
  });

  const [deassociateTag] = useMutation(DISASSOCIATE_TAG_MUTATION, {
    refetchQueries: queryToRefetch,
  });

  const handleAssociateTag = async (tagToAssociate: { ids: string[]; tag_id: string }) => {
    try {
      await associateTag({ variables: { input: tagToAssociate } });
      toast.success(`Tag associated successfully`, { duration: 5000 });
    } catch {
      toast.error('Error adding tag', {
        id: 'dashboard-error',
        duration: 5000,
      });
    }
  };

  const handleAddTag = async (newTag: string) => {
    const existingTagSelected = availableTags?.tags?.find((tag) => tag.name === newTag);
    const alreadyAssociated = associatedTags?.find((tag) => tag === newTag);
    if (alreadyAssociated) {
      toast.error(`This tag has already been added to this space.`, { duration: 5000 });
      return;
    }
    if (existingTagSelected) {
      const tagToAssociate = { ids: [itemId ?? ''], tag_id: existingTagSelected.id };
      handleAssociateTag(tagToAssociate);
    } else {
      try {
        const response = await createTag({ variables: { input: { name: newTag } } });
        const tagToAssociate = { ids: [itemId ?? ''], tag_id: response.data.createTag.id };
        handleAssociateTag(tagToAssociate);
      } catch (error) {
        toast.error("Error: Ensure the tag name doesn't already exist.", {
          id: 'dashboard-error',
          duration: 5000,
        });
        console.error('Error creating the tag: ', "Ensure the tag doesn't already exist", error);
      }
    }
  };

  //update to unassociate tag here when query is available
  const handleRemoveTag = async (tagName: string) => {
    const existingTagSelected = availableTags?.tags?.find((tag) => tag.name === tagName);
    if (!existingTagSelected) {
      //make sure this tag is also already associated before trying to deassociate
      return;
    }
    const tagToDisAssociate = { ids: [itemId], tag_id: existingTagSelected.id };
    try {
      await deassociateTag({ variables: { input: tagToDisAssociate } });
      toast.success(`Tag successfully removed`, { duration: 5000 });
    } catch {
      toast.error('Error removing tag', {
        id: 'dashboard-error',
      });
    }
  };

  return (
    <>
      {!tagsLoading && (
        <TagGroup
          options={availableTags?.tags?.map((tag) => tag.name) || []}
          data={associatedTags}
          onAdd={(tag) => {
            handleAddTag(tag);
          }}
          onRemove={(tag) => {
            handleRemoveTag(tag);
          }}
          variant={variant}
        />
      )}
    </>
  );
}
// Get tags
export const GET_TAGS_QUERY = gql`
  query GetTags {
    tags {
      id
      name
      organization_id
    }
  }
`;

// Create a new tag
export const CREATE_TAG_MUTATION = gql`
  mutation CreateTag($input: CreateTagInput!) {
    createTag(input: $input) {
      id
      name
    }
  }
`;

// Create new tags
export const CREATE_TAGS_MUTATION = gql`
  mutation CreateTags($input: [CreateTagInput!]) {
    createTags(input: $input) {
      name
      # id
    }
  }
`;

// Delete tags
export const DELETE_TAGS_MUTATION = gql`
  mutation DeleteTags($ids: [String!]) {
    deleteTags(ids: $ids)
  }
`;

// Associate tags
export const ASSOCIATE_TAG_MUTATION = gql`
  mutation AssociateTag($input: AssociateTagInput!) {
    associateTag(input: $input) {
      tag {
        id
      }
    }
  }
`;
// Disassociate tags
export const DISASSOCIATE_TAG_MUTATION = gql`
  mutation DisassociateTag($input: AssociateTagInput!) {
    disassociateTag(input: $input) {
      tag {
        id
      }
    }
  }
`;

// Update tags
export const UPDATE_TAGS_MUTATION = gql`
  mutation UpdateTags($input: [UpdateTagInput!]) {
    updateTags(input: $input) {
      id
      name
    }
  }
`;
