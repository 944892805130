import { CaretDownIcon, CaretUpIcon, CheckIcon, ChevronDownIcon } from '@/components/Icons';
import * as Select from '@radix-ui/react-select';
import { withClassName } from 'hocs/withClassName';
import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

/**
 * Select provides primitive components to construct a select menu. Unlike a DropdownMenu,
 * a Select shows the current value in a styled trigger button and exposes props to
 * make the component value "controlled" ('value' and 'onValueChange'). It is more opinionated
 * toward usage in forms. Select allows you to specify underlying values for each item
 * which differ from their displayed text, making it easier to work with API values while
 * displaying human-readable labels.
 *
 * The basic structure of a Select is:
 *
 * <SelectRoot value={value} onValueChange={handleValueChange}>
 *  <SelectButton />
 *  <SelectContent>
 *    <SelectItem value="1">Item 1</SelectItem>
 *    <SelectItem value="2">Item 2</SelectItem>
 *  </SelectContent>
 * </SelectRoot>
 *
 * For further customization of individual elements, more low-level components
 * are exposed:
 *
 * - SelectButtonRoot: the root element of the button, allowing you to wrap complex contents or utilize asChild to attach to a child component
 * - SelectButtonIcon: the chevron icon that indicates the dropdown is open. automatically rotates.
 * - SelectValue: the text that is displayed in the button. Copies the text of the selected item for you.
 * - SelectItemRoot: the root element of each item, allowing you to wrap complex contents or utilize asChild to attach to a child component
 * - SelectItemText: the visible text label for an item. Using this inside SelectItemRoot lets you be more specific about which part of text is considered the item label.
 * - SelectItemIndicator: the checkmark icon that indicates the item is selected. automatically appears when the item is selected. Using this inside SelectItemRoot lets you customize the indicator icon.
 */
export const SelectRoot = Select.Root;
export const SelectButton = forwardRef<HTMLButtonElement, Select.SelectTriggerProps>(
  function SelectButton(props, ref) {
    return (
      <SelectButtonRoot {...props} ref={ref}>
        <Select.Value />
        <SelectButtonIcon />
      </SelectButtonRoot>
    );
  },
);
export const SelectButtonIcon = forwardRef<HTMLDivElement, Select.SelectIconProps>(
  function SelectButtonIcon({ className, ...props }, ref) {
    return (
      <Select.Icon
        asChild
        ref={ref}
        {...props}
        className={twMerge(
          'justify-self-end h-5 w-5 group-radix-state-open:rotate-180 transition-transform',
          className,
        )}
      >
        <ChevronDownIcon className="w-[12px] h-[12px]" />
      </Select.Icon>
    );
  },
);
export const SelectButtonRoot = withClassName(
  Select.Trigger,
  'group cursor-pointer inline-flex flex-row gap-2 items-center justify-between rounded-md py-2 px-4 text-sm focus:outline-none focus-visible:outline w-full font-medium bg-product-gray100 hover:bg-product-gray200',
);
export const SelectValue = Select.Value;
export const SelectContent = forwardRef<HTMLDivElement, Select.SelectContentProps>(
  function SelectContent({ className, children, ...props }, ref) {
    return (
      <Select.Portal>
        <Select.Content
          position="popper"
          {...props}
          ref={ref}
          className={twMerge(
            'focus:outline-none mt-2 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 flex flex-col origin-radix-popover radix-state-open:animate-popoverIn radix-state-closed:animate-popoverOut z-50',
            'min-w-[var(--radix-select-trigger-width)] max-h-[var(--radix-select-content-available-height)]',
            className,
          )}
        >
          <Select.ScrollUpButton className="flex items-center justify-center bg-white">
            <CaretUpIcon className="w-[25px] h-[25px]" />
          </Select.ScrollUpButton>
          <Select.Viewport>{children}</Select.Viewport>
          <Select.ScrollDownButton className="flex items-center justify-center bg-white">
            <CaretDownIcon className="w-[25px] h-[25px]" />
          </Select.ScrollDownButton>
        </Select.Content>
      </Select.Portal>
    );
  },
);
export const SelectItem = forwardRef<HTMLDivElement, Select.SelectItemProps>(function SelectItem(
  { children, ...props },
  ref,
) {
  return (
    <SelectItemRoot ref={ref} {...props}>
      <SelectItemText>{children}</SelectItemText>
      <SelectItemIndicator />
    </SelectItemRoot>
  );
});
export const SelectItemRoot = withClassName(
  Select.Item,
  'flex w-full rounded-md p-3 text-sm items-center gap-2 font-normal radix-highlighted:bg-gray-50 focus:outline-none focus-visible:outline',
);
export const SelectItemIndicator = forwardRef<HTMLDivElement, Select.SelectItemIndicatorProps>(
  function SelectItemIndicator({ className, children, ...rest }, ref) {
    if (children) {
      return (
        <Select.ItemIndicator ref={ref} {...rest} className={twMerge('ml-auto', className)}>
          {children}
        </Select.ItemIndicator>
      );
    }
    return (
      <Select.ItemIndicator
        asChild
        ref={ref}
        className={twMerge('h-3 w-3 ml-auto', className)}
        {...rest}
      >
        <CheckIcon className="h-3 w-3 ml-auto" />
      </Select.ItemIndicator>
    );
  },
);
export const SelectItemText = withClassName(Select.ItemText, 'flex');
// TODO: support other Select primitives like Group, Label, different item types
// SEE: https://www.radix-ui.com/primitives/docs/components/dropdown-menu
