import { FC } from 'react';

import OrganizationLogo from '@/components/OrganizationLogo';
import { PageLayoutSidebar, PageLayoutSidebarLogoContainer } from '@/components/PageLayout';
import { useSuperUser } from '@/redux/hooks';
import SelectOrgDropdown from './SelectOrgDropdown';

import { Sidebar as SidebarImpl } from '@/components/sidebar/Sidebar';

export const Sidebar: FC = () => {
  const { isSuperUserDashboard } = useSuperUser();

  if (isSuperUserDashboard) {
    return (
      <PageLayoutSidebar className="text-xs text-gray-800">
        <PageLayoutSidebarLogoContainer>
          <OrganizationLogo />
        </PageLayoutSidebarLogoContainer>

        <div className="p-3">
          <SelectOrgDropdown />
        </div>
      </PageLayoutSidebar>
    );
  }

  return <SidebarImpl />;
};
