import React, { FC, SVGProps } from 'react';

const Hive: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.3999 15.3787C2.3999 17.5458 4.00417 19.1998 6.28257 19.1998H17.7172C19.9956 19.1998 21.5999 17.5458 21.5999 15.3787C21.5999 14.733 21.4122 14.1492 21.2074 13.6185L18.4596 6.85189C17.922 5.50742 16.8127 4.7998 15.3535 4.7998H8.65484C7.1871 4.7998 6.0863 5.50742 5.54017 6.85189L2.80097 13.6008C2.58764 14.1403 2.3999 14.7241 2.3999 15.3787ZM5.10497 11.7521L5.40234 10.9882H18.5904L18.8863 11.7521C18.5279 11.6195 18.1439 11.5487 17.7172 11.5487H6.28257C5.8559 11.5487 5.4719 11.6195 5.10497 11.7521ZM18.0325 9.54824H5.96291L6.87137 7.21455C7.1615 6.44501 7.81857 6.04698 8.70604 6.04698H15.2938C16.1898 6.04698 16.8383 6.44501 17.1284 7.21455L18.0325 9.54824ZM3.7823 15.3787C3.7823 14.0077 4.7807 12.9816 6.28257 12.9816H17.7172C19.2191 12.9816 20.2175 14.0077 20.2175 15.3787C20.2175 16.9001 19.2191 17.7669 17.7172 17.7669H6.28257C4.7807 17.7669 3.7823 16.7408 3.7823 15.3787ZM5.2999 14.9998C5.02376 14.9998 4.7999 15.2237 4.7999 15.4998V16.2998C4.7999 16.5759 5.02376 16.7998 5.2999 16.7998H6.0999C6.37604 16.7998 6.5999 16.5759 6.5999 16.2998V15.4998C6.5999 15.2237 6.37604 14.9998 6.0999 14.9998H5.2999Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Hive;
