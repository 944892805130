import { datadogRum } from '@datadog/browser-rum';

if (
  !import.meta.env.VITEST &&
  window.location.hostname !== 'localhost' &&
  import.meta.env.MODE !== 'test' &&
  import.meta.env.MODE !== 'development'
) {
  datadogRum.init({
    applicationId: 'b9cd9a5d-675d-4203-8259-103c67bd082e',
    clientToken: 'pub1db9b28a5bb3b5d8bb1726f1cae8bbfc',
    site: 'datadoghq.com',
    service: 'butlr-dashboard',
    env: import.meta.env.MODE,
    version: import.meta.env.VITE_APP_GIT_SHA,
    sampleRate: 100,
    sessionReplaySampleRate: 100,
    trackInteractions: true,
    trackFrustrations: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    silentMultipleInit: true,
  });

  datadogRum.startSessionReplayRecording();
}
