import React, { ReactElement, FC, memo, HTMLAttributes, useMemo } from 'react';
import clsx from 'clsx';
import { AutoTooltip, Popover, ChevronDownIcon, ChevronUpIcon } from '@/components';
import { ICommonComponentProps } from '@/types';
import { UseFloatingProps } from '@floating-ui/react';
import { useToggle } from '@/hooks';

export interface IInnerButtonProps
  extends Partial<
    Omit<
      UseFloatingProps,
      'onOpenChange' | 'middleware' | 'strategy' | 'platform' | 'nodeId' | 'whileElementsMounted'
    >
  > {
  buttonContent: ReactElement | string;
  tooltipLabel?: string;
  popover?: ReactElement;
  portalId?: string;
  variant?: 'primary' | 'secondary' | 'third';
  active?: boolean;
  disabled?: boolean;
  disabledOnHover?: boolean;
  disabledChevron?: boolean;
  buttonProps?: HTMLAttributes<HTMLButtonElement>;
  onClick?: () => void;
}

function InnerButton({
  buttonContent,
  tooltipLabel,
  popover,
  portalId = 'app-root',
  variant = 'primary',
  open = false,
  placement = 'top-start',
  disabledOnHover = false,
  active,
  disabled = false,
  disabledChevron = true,
  buttonProps,
  onClick,
}: IInnerButtonProps) {
  const [isPopoverOpen, { toggleOn: toggleOnIsPopoverOpen, toggleOff: toggleOffIsPopoverOpen }] =
    useToggle(open);

  const [normalClassName, hoverClassName, activeClassName, disableClassName] = useMemo(() => {
    if (variant === 'secondary') {
      return [
        'rounded p-1 text-product-gray900',
        'hover:bg-gray-100',
        'bg-gray-200',
        'opacity-25 pointer-events-none',
      ];
    }
    if (variant === 'third') {
      return [
        'rounded-3xl px-2 py-1 text-product-red500 bg-product-red50',
        'hover:bg-product-red200',
        '!bg-product-red200',
        '!bg-product-red50 !text-product-red200 pointer-events-none',
      ];
    }
    return [
      'rounded p-2 text-product-gray900',
      'hover:bg-product-red50 hover:text-product-red500',
      'bg-product-red50 !text-product-red500',
      'opacity-25 pointer-events-none',
    ];
  }, [variant]);

  return (
    <Popover
      className="h-full"
      portalId={portalId}
      popoverContent={popover}
      offsetSize={16}
      open={open}
      placement={placement}
      disabled={disabled}
      disabledOnHover
      disabledOnFocus
      disabledArrow
      onOpen={toggleOnIsPopoverOpen}
      onClose={toggleOffIsPopoverOpen}
    >
      <AutoTooltip label={!isPopoverOpen && tooltipLabel} variant="black" placement="top">
        <button
          className={clsx(
            'group flex h-full items-center justify-center fill-current',
            normalClassName,
            !disabled && !disabledOnHover ? hoverClassName : 'cursor-default',
            !disabled && (isPopoverOpen || active) && activeClassName,
            disabled && disableClassName,
          )}
          onClick={onClick}
          {...buttonProps}
        >
          <div className="flex items-center gap-3">
            {buttonContent}
            {!disabledChevron &&
              (isPopoverOpen ? (
                <ChevronUpIcon className="w-3 fill-current" />
              ) : (
                <ChevronDownIcon className="w-3 fill-current" />
              ))}
          </div>
        </button>
      </AutoTooltip>
    </Popover>
  );
}

interface IButtonGroupProps extends ICommonComponentProps {
  content: IInnerButtonProps[];
  size?: 'sm' | 'md' | 'lg';
}

export const ButtonGroup: FC<IButtonGroupProps> = memo(
  ({ className, style, content = [], size = 'md', ...rest }: IButtonGroupProps) => {
    return (
      <div
        className={clsx(
          'flex select-none items-center justify-center gap-2 rounded-md border border-gray-100 bg-white shadow-xl',
          size === 'sm' && 'h-11 p-2',
          size === 'md' && 'h-12 p-2',
          size === 'lg' && 'h-[52px] p-3',
          className,
        )}
        style={style}
        {...rest}
      >
        {content.map((buttonProps, index) => (
          <InnerButton key={index} {...buttonProps} />
        ))}
      </div>
    );
  },
);
