import { Toaster as BaseToaster, ToastBar, ToastType } from 'react-hot-toast';
import { IconName } from './icon/generated/iconNames';
import { Icon } from './icon/Icon';
import { twMerge } from 'tailwind-merge';

// unfortunately, css specificity is hard to accommodate with this toast library, so all these
// are marked important.
const baseClass =
  '!flex !items-center !justify-center !gap-2 !rounded !px-3.5 !py-2 !text-black !text-center !text-xs !leading-5 !text-gray-900 !shadow';

export function Toaster() {
  return (
    <BaseToaster
      position="bottom-center"
      reverseOrder={false}
      toastOptions={{
        className: '',
        duration: 10000,
        success: {
          className: twMerge(baseClass, '!bg-green-100'),
        },
        error: {
          className: twMerge(baseClass, '!bg-red-100'),
        },
        blank: {
          className: twMerge(baseClass, '!bg-white'),
        },
        custom: {
          className: twMerge(baseClass, '!bg-white'),
        },
      }}
    >
      {(toast) => {
        return (
          <ToastBar toast={toast} position="bottom-center">
            {({ message }) => (
              <>
                <Icon name={icons[toast.type]} size={16} />
                {message}
              </>
            )}
          </ToastBar>
        );
      }}
    </BaseToaster>
  );
}

const icons: Record<ToastType, IconName> = {
  error: 'warning',
  success: 'check',
  blank: 'suggest',
  custom: 'suggest',
  loading: 'loading',
};
