import { FC, memo, ReactNode } from 'react';
import { ICommonComponentProps } from '@/types';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

interface ILabelValuePair extends ICommonComponentProps {
  label: ReactNode;
  value: ReactNode;
  multiline?: boolean;
  labelClassName?: string;
  valueClassName?: string;
  /**
   * Disable semantic labeling of contents. Important for accessibility when
   * content contains multiple inputs or controls.
   */
  notSemantic?: boolean;
  /**
   * Assign the label to a specific input by ID
   */
  inputId?: string;
}

export const LabelValuePair: FC<ILabelValuePair> = memo(
  ({
    className,
    style,
    label,
    value,
    labelClassName,
    valueClassName,
    multiline,
    notSemantic,
    inputId,
    ...rest
  }) => {
    const Container = notSemantic || inputId ? 'div' : 'label';
    const TextContainer = inputId ? 'label' : 'div';
    return (
      <Container
        className={twMerge(
          'px-5 max-w-full flex',
          multiline
            ? 'flex-col gap-2 items-start'
            : 'flex w-full flex-row justify-between gap-5 items-center',
          className,
        )}
        style={style}
        {...rest}
      >
        <TextContainer
          htmlFor={inputId}
          className={clsx(
            'text-sm leading-[22px] text-gray-900 min-w-[min-content] font-semibold whitespace-nowrap',
            !multiline && 'flex-shrink',
            labelClassName,
          )}
        >
          {label}
        </TextContainer>
        <div
          className={clsx(
            'text-sm text-gray-500 min-w-[20px]',
            multiline
              ? 'max-w-full self-start text-start w-full'
              : 'text-end flex flex-row justify-end flex-grow max-w-[80%]',
            valueClassName,
          )}
        >
          {value}
        </div>
      </Container>
    );
  },
);
