import { HeatmapLegendBar, SpaceVisualizationDropdown } from '@/components';
import { useSpaceVisualizerActivitySensors, useGetDetectionsState } from '@/hooks';
import { ESV_Vision_Options, ICommonComponentProps, ISV_Options } from '@/types';
import clsx from 'clsx';

interface ITopActionBarProps extends ICommonComponentProps {
  isPresentationIdle: boolean;
  options: ISV_Options;
  onVizItemToggle: (option: ESV_Vision_Options, value: boolean) => void;
}

export const TopActionBar = ({
  className,
  style,
  isPresentationIdle,
  options,
  onVizItemToggle,
  ...rest
}: ITopActionBarProps) => {
  const { isSuccess: isSuccessDetections, isFetching: isFetchingDetections } =
    useGetDetectionsState();
  const sensors = useSpaceVisualizerActivitySensors();

  return (
    <div
      className={clsx(
        'pointer-events-auto flex w-full items-center justify-between  px-8 py-5',
        className,
      )}
      style={style}
      {...rest}
    >
      {/* Dropdown */}
      <div className="flex gap-2">
        {!isPresentationIdle &&
          options.visionOptions[ESV_Vision_Options.SPACE_VISUALIZATIONS] &&
          !!sensors.length && (
            <SpaceVisualizationDropdown
              options={options.visionOptions}
              onChange={onVizItemToggle}
            />
          )}
      </div>
      {/* Heatmap legend bar */}
      {options.visionOptions[ESV_Vision_Options.UTILIZATION_HEATMAP] &&
        isSuccessDetections &&
        !isFetchingDetections && <HeatmapLegendBar />}
    </div>
  );
};
