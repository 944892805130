import { FC, memo, useEffect, useRef, useState } from 'react';
import {
  UseFloatingProps,
  FloatingArrow,
  FloatingPortal,
  arrow,
  autoUpdate,
  flip,
  offset,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useClick,
} from '@floating-ui/react';
import { ICommonComponentProps } from '@/types';

export interface IPopoverProps extends ICommonComponentProps, Partial<UseFloatingProps> {
  children: JSX.Element; // Trigger element
  popoverContent?: JSX.Element; // Popover content element
  portalId?: string;
  offsetSize?: number;
  paddingSize?: number;
  arrowClassName?: string;
  disabledOnHover?: boolean;
  disabledOnFocus?: boolean;
  disabledOnClick?: boolean;
  disabledOnDismiss?: boolean;
  disabledArrow?: boolean;
  disabled?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
}

export const Popover: FC<IPopoverProps> = memo(
  ({
    className,
    style,
    children,
    popoverContent,
    portalId = 'app-root',
    offsetSize = 0,
    paddingSize = 12,
    arrowClassName,
    open = false,
    disabledOnHover = false,
    disabledOnFocus = false,
    disabledOnClick = false,
    disabledOnDismiss = false,
    disabledArrow = false,
    disabled = false,
    onOpen,
    onClose,
    ...props
  }) => {
    const [isOpen, setIsOpen] = useState(open);
    const arrowRef = useRef(null);

    const { x, y, strategy, refs, context } = useFloating({
      open: isOpen,
      onOpenChange: setIsOpen,
      whileElementsMounted: autoUpdate,
      middleware: [
        offset(offsetSize),
        shift({ padding: paddingSize }),
        flip(),
        arrow({ element: arrowRef }),
      ],
      ...props,
    });

    const hover = useHover(context, {
      enabled: !disabled && !disabledOnHover,
    });
    const focus = useFocus(context, { enabled: !disabled && !disabledOnFocus });
    const click = useClick(context, { enabled: !disabled && !disabledOnClick });
    const dismiss = useDismiss(context, {
      enabled: !disabled && !disabledOnDismiss,
    });
    const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus, click, dismiss]);

    // Callback whenever popover is opened/closed
    useEffect(() => {
      if (!popoverContent) return;

      if (isOpen) {
        onOpen?.();
      } else {
        onClose?.();
      }
    }, [onClose, onOpen, isOpen, popoverContent]);

    return (
      <>
        <span ref={refs.setReference} className={className} style={style} {...getReferenceProps()}>
          {children}
        </span>
        <FloatingPortal id={portalId}>
          {isOpen && popoverContent && (
            <div
              ref={refs.setFloating}
              className="z-10"
              style={{ position: strategy, top: y ?? 0, left: x ?? 0 }}
              {...getFloatingProps()}
            >
              {!disabledArrow && (
                <FloatingArrow ref={arrowRef} context={context} className={arrowClassName} />
              )}
              {popoverContent}
            </div>
          )}
        </FloatingPortal>
      </>
    );
  },
);
