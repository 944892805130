import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { SummaryTile, LoadingSpinner } from '@/components';
import { IQuestionTooltipContent, ICommonComponentProps } from '@/types';
import { PercentDisplay } from '../PercentDisplay';

interface IDailyEntrywayTrafficSummaryTileProps extends ICommonComponentProps {
  title?: string;
  tooltip?: IQuestionTooltipContent;
  entryValue?: number;
  exitValue?: number;
  entryPercent?: number;
  exitPercent?: number;
  loading?: boolean;
  error?: string;
}

export const DailyEntrywayTrafficSummaryTile: FC<IDailyEntrywayTrafficSummaryTileProps> = memo(
  ({
    className,
    style,
    title,
    tooltip,
    entryValue,
    exitValue,
    entryPercent,
    exitPercent,
    loading = false,
    error = '',
    ...rest
  }) => {
    const { t } = useTranslation();
    return (
      <SummaryTile className={className} style={style} title={title} tooltip={tooltip} {...rest}>
        {loading ? (
          <div className="absolute inset-0 z-10 mr-1 flex h-full w-full items-center justify-center truncate rounded-2xl bg-white text-xs font-semibold">
            <LoadingSpinner />
          </div>
        ) : error ? (
          <div className="absolute inset-0 z-10 mr-1 flex h-full w-full items-center justify-center truncate rounded-2xl bg-white text-xs font-semibold">
            {/* <Error message={error} size={ESize.sm} /> */}
          </div>
        ) : (
          <>
            <div className="grid grid-cols-2 items-center text-4xl font-light text-product-gray700">
              <div>
                <div className="mb-2 h-5 text-xs font-normal text-product-gray900">
                  {t('entrances')}
                </div>
                <div className="flex">
                  <div className="mr-2.5 font-semibold text-product-gray900">{entryValue}</div>
                  {entryPercent !== undefined && <PercentDisplay percent={entryPercent} />}
                </div>
              </div>
              <div className="border-l-[1px] pl-4 border-l-gray-200">
                <div className="mb-2 h-5 text-xs font-normal text-product-gray900">
                  {t('exits')}
                </div>
                <div className="flex">
                  <div className="mr-2.5 font-semibold text-product-gray900">{exitValue}</div>
                  {exitPercent !== undefined && <PercentDisplay percent={exitPercent} />}
                </div>
              </div>
            </div>
            {(entryPercent !== undefined || exitPercent !== undefined) && (
              <div className="mt-3 flex text-xs font-normal text-product-gray600">
                <p className="w-9/12 truncate">{t('comparedFromLastWeek')}</p>
              </div>
            )}
          </>
        )}
      </SummaryTile>
    );
  },
);
