import { useState, useEffect, useRef, useCallback, useMemo, MutableRefObject } from 'react';
import { useHistory } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import { Event } from 'three';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useSVRendererContext } from '@/contexts';
import {
  CheckIcon,
  CloseIcon,
  DETECTION_QUICK_RANGE_BUTTON_LABELS,
  dispatcher,
  Events,
} from '@/components';
import SVRenderer from '@/components/SpaceVisualizer/ThreeRenderer';
import {
  useToggle,
  useSRZId,
  useTimezone,
  useGetDetectionsState,
  useSpaceVisualizerActivitySensors,
} from '.';
import { ESV_VizMode, ISV_Options, ESV_Vision_Options, TSV_Tags, TSV_Tag } from '@/types';
import { useGetLatestGroupedQuery } from './api/reporting';
import { enterFullscreen, exitFullscreen, formatCustomTimeRange } from '@/utils';
import { DEFAULT_SV_OPTIONS, HEATMAP_LEGEND_BAR_LABEL_COUNT } from '@/constants';
import { IGetFloorplanQueryParams, IGetFloorplanQueryResponse } from '@/types';
import { gql, useQuery } from '@apollo/client';
import {
  SpaceVisualizerRendererRoomFragment,
  useSpaceVisualizerRoomsQuery,
  useSpaceVisualizerSpacesQuery,
} from '@/.generated/graphql';
import { useTime } from '@/redux/hooks';
import { TIME_RANGE_MODE } from '@/redux/reducers/time';
import toast from 'react-hot-toast';

export const GET_FLOOR_PLAN = gql`
  query GetFloorPlan($ids: [String!]!) {
    floors(ids: $ids) {
      data {
        id: floor_id
        name
        timezone
        floorPlans: floor_plans {
          id: floor_plan_id
          url
          name
          coordinates
        }
      }
    }
  }
`;

export const useGetImagesQuery = ({ spaceId }: IGetFloorplanQueryParams) => {
  const { data, ...rest } = useQuery<IGetFloorplanQueryResponse>(GET_FLOOR_PLAN, {
    variables: { ids: [spaceId] },
    skip: !spaceId,
  });

  return {
    data: data?.floors?.data[0] ?? null,
    ...rest,
  };
};

export const useSVConvertTime = (): {
  startTime: number;
  endTime: number;
  timeLabel: string;
  startTimeLabel: string;
  endTimeLabel: string;
} => {
  const {
    t,
    i18n: { language: currentLang },
  } = useTranslation();
  const language = currentLang ? new Intl.Locale(currentLang).language : 'en';
  const tz = useTimezone();
  const time = useTime();

  // Start/end time as milliseconds
  const startTime = time?.detection.timeRange.startEpoch ?? 0;
  const endTime = time?.detection.timeRange?.endEpoch ?? 0;

  // Convert time by current timezone
  const start = dayjs(startTime).tz(tz);
  const end = dayjs(endTime).tz(tz);

  // Time labels
  const timeLabel =
    time?.detection.timeRangeMode === TIME_RANGE_MODE.CUSTOM
      ? formatCustomTimeRange(start, end, tz, t, language)
      : t(
          DETECTION_QUICK_RANGE_BUTTON_LABELS[
            (time?.detection.timeRangeMode || TIME_RANGE_MODE.TODAY) - 1
          ],
        );
  const startTimeLabel = formatCustomTimeRange(start, null, tz, t, language);
  const endTimeLabel = formatCustomTimeRange(end, null, tz, t, language);

  return { startTime, endTime, timeLabel, startTimeLabel, endTimeLabel };
};

/**
 * Hooks for getting rooms in space visualizer
 */
export const useGetSVRooms = () => {
  const { roomId, spaceId } = useSRZId();
  const { data: spaceResult } = useSpaceVisualizerSpacesQuery({
    // asserted by skip below
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { spaceId: spaceId! },
    skip: !spaceId,
  });
  const space = spaceResult?.floors?.data?.[0] ?? null;
  const { data: roomResult } = useSpaceVisualizerRoomsQuery({
    // asserted by skip below
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { roomId: roomId! },
    skip: !roomId,
  });
  const room = roomResult?.rooms?.data?.[0] ?? null;

  const rooms: SpaceVisualizerRendererRoomFragment[] = useMemo(
    () => (room ? [room] : space?.rooms || []),
    [room, space?.rooms],
  );

  return { rooms };
};
useGetSVRooms.queries = {
  Spaces: gql`
    query SpaceVisualizerSpaces($spaceId: String!) {
      floors(ids: [$spaceId]) {
        data {
          id: floor_id
          rooms {
            id: room_id
            ...SpaceVisualizerRendererRoom
          }
        }
      }
    }
    ${SVRenderer.fragments.Room}
  `,
  Rooms: gql`
    query SpaceVisualizerRooms($roomId: String!) {
      rooms(ids: [$roomId]) {
        data {
          id: room_id
          ...SpaceVisualizerRendererRoom
        }
      }
    }
    ${SVRenderer.fragments.Room}
  `,
};

/**
 * TODO Currently room interaction is disabled, we may need to activate this func later.
 * Hooks for room interaction on space visualizer
 */
export const useSVRoomInteraction = (): { hoveredRoomId?: string } => {
  const history = useHistory();
  const { spaceId } = useSRZId();
  const [hoveredRoomId, setHoveredRoomId] = useState<string>();

  // Listener when room is hovered
  const onRoomHover = useCallback((e: Event) => {
    setHoveredRoomId(e.param);
  }, []);

  // Listener when room is clicked
  const onRoomPointerUp = useCallback(
    (e: Event) => {
      if (!spaceId) return;

      history.push(`/?spaceId=${spaceId}&roomId=${e.param}`);
    },
    [history, spaceId],
  );

  useEffect(() => {
    dispatcher.addEventListener(Events.ROOM_HOVER, onRoomHover);
    dispatcher.addEventListener(Events.ROOM_POINTER_UP, onRoomPointerUp);

    return () => {
      dispatcher.removeEventListener(Events.ROOM_HOVER, onRoomHover);
      dispatcher.removeEventListener(Events.ROOM_POINTER_UP, onRoomPointerUp);
    };
  }, [onRoomHover, onRoomPointerUp]);

  return { hoveredRoomId };
};

/**
 * Hooks for updating room occupancy
 */
export const useSVRoomOccupancy = () => {
  const { svInstance } = useSVRendererContext();
  const time = useTime();
  const rooms = svInstance?.rooms ?? [];
  const { data: latestOccupancyByRoom } = useGetLatestGroupedQuery({
    type: 'room',
    ids: rooms.map((r) => r.id),
    // if no time is available, stabilize to start of today
    currentTime: time ? dayjs(time.currentDate) : dayjs().startOf('day'),
  });

  // Update occupancy on space visualizer
  useEffect(() => {
    if (!svInstance || !latestOccupancyByRoom) return;

    svInstance.onOccupancyUpdate(latestOccupancyByRoom);
  }, [svInstance, latestOccupancyByRoom]);
};

/**
 * Hooks for toast in space visualizer
 */
export const useSVToasts = () => {
  const { t } = useTranslation();
  const {
    isError: isErrorDetections,
    isSuccess: isSuccessDetections,
    isFetching: isFetchingDetections,
  } = useGetDetectionsState();

  // Determine toast content
  const toastContent = useMemo(() => {
    if (isFetchingDetections) return null;

    let content = null;

    // Success message
    if (isSuccessDetections) {
      content = (
        <>
          <CheckIcon className="h-4 w-4 rounded-full bg-green-500 p-0.5 text-white" />
          {t('successSpaceVisualizationGeneration')}
        </>
      );
    }
    // Error message
    else if (isErrorDetections) {
      content = (
        <>
          <CloseIcon className="h-4 w-4 rounded-full bg-red-500 p-0.5 text-white" />
          {t('failSpaceVisualizationGeneration')}
        </>
      );
    }

    return content ? (
      <div
        className="flex h-6 min-w-165px items-center justify-center gap-2"
        role="alert"
        aria-label="Heatmap generation alert"
      >
        {content}
      </div>
    ) : null;
  }, [isErrorDetections, isFetchingDetections, isSuccessDetections, t]);

  useEffect(() => {
    if (!toastContent) return;

    toast(toastContent, { duration: 2000 });
  }, [toastContent]);
};

/**
 * Hooks for floorplan in space visualizer
 */
export const useSVFloorplan = (): { hasFloorplan: boolean } => {
  const { svInstance } = useSVRendererContext();
  const { spaceId } = useSRZId();
  const { data: spaceFloorplan } = useGetImagesQuery({ spaceId });
  const [hasFloorplan, { toggleOn: toggleOnHasFloorplan, toggleOff: toggleOffHasFloorplan }] =
    useToggle(false);

  // Add floorplans
  useEffect(() => {
    if (!svInstance || !spaceFloorplan) return;

    svInstance.addFloorplan(
      spaceFloorplan.floorPlans || [],
      toggleOnHasFloorplan,
      toggleOffHasFloorplan,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaceFloorplan?.id, svInstance, toggleOnHasFloorplan, toggleOffHasFloorplan]);

  return { hasFloorplan };
};

/**
 * Hooks for detections in space visualizer
 */
export const useSVDetections = () => {
  const { svInstance } = useSVRendererContext();
  const result = useGetDetectionsState();
  const { data: detections, isFetching: isFetchingDetections, isError: isErrorDetections } = result;

  // Update detections on space visualizer
  useEffect(() => {
    if (!svInstance) return;

    // Don't add detections if loading detections is in progress or error occurred during the process
    if (isFetchingDetections || isErrorDetections || !detections) {
      return svInstance.onDetectionsUpdate();
    }

    // Otherwise add detections
    svInstance.onDetectionsUpdate(detections);
  }, [svInstance, detections, isFetchingDetections, isErrorDetections]);
};

/**
 * Hooks for options in space visualizer
 */
export const useSVOptions = (
  containerRef: MutableRefObject<HTMLDivElement | null>,
): {
  options: ISV_Options;
  onVizModeUpdate: (value: ESV_VizMode) => void;
  onPresentationModeToggle: () => void;
  onZoomIn: () => void;
  onZoomOut: () => void;
  onOrbitModeToggle: () => void;
  onVizItemToggle: (option: ESV_Vision_Options, value: boolean) => void;
} => {
  const [options, setOptions] = useState<ISV_Options>(DEFAULT_SV_OPTIONS);

  // Update options
  const updateOptions = useCallback((newOptions: ISV_Options) => {
    setOptions(newOptions);

    // Dispatch option change
    dispatcher.dispatchEvent({
      type: Events.OPTIONS_UPDATE,
      newOptions,
    });
  }, []);

  // Listener when viz mode is changed
  const onVizModeUpdate = useCallback(
    (value: ESV_VizMode) => {
      updateOptions({ ...options, vizMode: value });
    },
    [options, updateOptions],
  );

  // Listener when presentation button clicked
  const onPresentationModeToggle = useCallback(() => {
    if (!containerRef.current) return;

    const container = containerRef.current as HTMLElement;

    if (options.presentMode) {
      exitFullscreen();
      container.style.position = 'relative';
      container.style.width = '0';
      container.style.zIndex = '0';
    } else {
      container.style.position = 'fixed';
      container.style.width = '100%';
      container.style.top = '0';
      container.style.bottom = '0';
      container.style.left = '0';
      container.style.right = '0';
      container.style.zIndex = '49';
      enterFullscreen(document.getElementById('root'));
    }

    updateOptions({ ...options, presentMode: !options.presentMode });
  }, [containerRef, options, updateOptions]);

  // Listener when zoom out button clicked
  const onZoomOut = useCallback(() => {
    // Dispatch option change
    dispatcher.dispatchEvent({
      type: Events.OPTIONS_UPDATE,
      newOptions: { ...options, zoom: options.zoom - 10 },
    });
  }, [options]);

  // Listener when zoom in button clicked
  const onZoomIn = useCallback(() => {
    // Dispatch option change
    dispatcher.dispatchEvent({
      type: Events.OPTIONS_UPDATE,
      newOptions: { ...options, zoom: options.zoom + 10 },
    });
  }, [options]);

  // Listener when rotate button clicked
  const onOrbitModeToggle = useCallback(() => {
    updateOptions({ ...options, orbitMode: !options.orbitMode });
  }, [options, updateOptions]);

  // Listener when vision toggle button clicked
  const onVizItemToggle = useCallback(
    (option: ESV_Vision_Options, value: boolean) => {
      const oldVisionOptions = options.visionOptions;
      const newVisionOptions = {
        ...oldVisionOptions,
        [option as string]: value,
      };
      updateOptions({ ...options, visionOptions: newVisionOptions });
    },
    [options, updateOptions],
  );

  // Listener when zoom is updated by scroll
  const onZoom = useCallback((e: Event) => {
    setOptions((cur) => ({ ...cur, zoom: parseFloat(e.param) }));
  }, []);

  useEffect(() => {
    dispatcher.addEventListener(Events.ZOOM_UPDATE, onZoom);

    return () => {
      dispatcher.removeEventListener(Events.ZOOM_UPDATE, onZoom);
    };
  }, [onZoom]);

  return {
    options,
    onVizModeUpdate,
    onPresentationModeToggle,
    onZoomIn,
    onZoomOut,
    onOrbitModeToggle,
    onVizItemToggle,
  };
};

/**
 * Hooks for tags in space visualizer
 */
export const useSVTags = (): { tags: TSV_Tags } => {
  const [tags, setTags] = useState<TSV_Tags>({});

  // Listener when tag is updated from space visualizer
  const onTagUpdate = useCallback((e: Event) => {
    const newTag = e.param as TSV_Tag;
    const { id, tag } = newTag;

    setTags((cur) => {
      const oldVal = cur[tag]?.filter((el) => el.id !== id) || [];

      return {
        ...cur,
        [tag]: [...oldVal, newTag],
      };
    });
  }, []);

  useEffect(() => {
    dispatcher.addEventListener(Events.TAG_UPDATE, onTagUpdate);

    return () => {
      dispatcher.removeEventListener(Events.TAG_UPDATE, onTagUpdate);
    };
  }, [onTagUpdate]);

  return { tags };
};

/**
 * Hooks for idle handler in space visualizer
 */
export const useSVPresentationIdle = (presentMode: boolean): { isPresentationIdle: boolean } => {
  const [isIdle, { toggleOn: toggleOnIsIdle, toggleOff: toggleOffIsIdle }] = useToggle(false);

  // Determine idle status only on presentation mode
  const onIdle = useCallback(() => {
    if (!presentMode) return;

    toggleOnIsIdle();
  }, [presentMode, toggleOnIsIdle]);

  const onActive = useCallback(() => {
    toggleOffIsIdle();
  }, [toggleOffIsIdle]);

  useIdleTimer({
    onIdle,
    onActive,
    timeout: 3000,
    throttle: 500,
  });

  return { isPresentationIdle: isIdle };
};

/**
 * Hooks for heatmap legend bar
 */
export const useSVHeatmapLegendBar = (): {
  labels: string[];
  range: [number, number];
  setRange: (range: [number, number]) => void;
} => {
  const { svInstance } = useSVRendererContext();
  const time = useTime();
  const [range, setRange] = useState<[number, number]>([20, 900]);

  // Determine labels
  const labels = useMemo(() => {
    const [min, max] = range;
    const interval = (max - min) / (HEATMAP_LEGEND_BAR_LABEL_COUNT - 1);

    // Construct labels and remove duplicated ones
    return Array.from(
      new Set(
        [...Array(HEATMAP_LEGEND_BAR_LABEL_COUNT)].map((_, index) => {
          const val = min + interval * index;

          if (val < 60) {
            return Math.round(val) + 's';
          } else if (val < 3600) {
            return Math.round(val / 60) + 'm';
          }

          return (
            Math.round((index === HEATMAP_LEGEND_BAR_LABEL_COUNT - 1 ? max : val) / 3600) + 'h'
          );
        }),
      ),
    );
  }, [range]);

  // Whenever time range is updated, reset heatmap legend min/max too
  useEffect(() => {
    if (!time) return;

    const { startEpoch, endEpoch } = time.detection.timeRange;
    const diff = dayjs(endEpoch).diff(dayjs(startEpoch)) / 1000; // Diff as seconds
    let min: number = 20;
    let max: number = 20;

    // Less than 5 mins?
    if (diff <= 300) {
      min = 20; // 20 seconds as min
      max = 300; // 5 mins as max
    }
    // Less than 1 hour?
    else if (diff <= 3600) {
      min = 300; // 5 mins as min
      max = 3600; // 1 hour as max
    }
    // Less than 1 day? between 00:00 and 23:58. 1 min is padding
    else if (diff < 86340) {
      min = 300; // 5 mins as min
      max = Math.round(diff / 3600) * 3600; // Rounded value as max
    }
    // Larger than 1 day?
    else {
      min = 300; // 5 mins as min
      const days = Math.round(diff / 86400);
      max = 8 * 3600 * days; // Rounded value as max
    }

    setRange([min, max]);
  }, [time]);

  // Whenever range is updated, pass to space visualizer too
  useEffect(() => {
    svInstance?.onHeatmapMinMaxChange(range);
  }, [range, svInstance]);

  return { labels, range, setRange };
};

/**
 * Hooks for detection replay bar
 */
export const useSVReplayBar = (
  toolsRef: MutableRefObject<HTMLDivElement | null>,
  detailPanelOpen: boolean,
): {
  isPlaying: boolean;
  shouldFill: boolean;
  startTime: number;
  endTime: number;
  selectedTime: number;
  startTimeLabel: string;
  endTimeLabel: string;
  selectedTimeLabel: string;
  multiply: number;
  onPlay: () => void;
  onPause: () => void;
  onMultiplyChange: (value: number) => void;
  onTimeChange: (value: number) => void;
} => {
  const { svInstance } = useSVRendererContext();
  const {
    t,
    i18n: { language: currentLang },
  } = useTranslation();
  const tz = useTimezone();
  const { startTime, endTime, startTimeLabel, endTimeLabel } = useSVConvertTime();
  const { isFetching: isFetchingDetections } = useGetDetectionsState();
  const [selectedTime, setSelectedTime] = useState<number>(startTime);
  const [multiply, setMultiply] = useState<number>(1);
  const [isPlaying, { toggleOn: toggleOnIsPlaying, toggleOff: toggleOffIsPlaying }] =
    useToggle(false);
  const [shouldFill, { toggleOn: toggleOnShouldFill, toggleOff: toggleOffShouldFill }] =
    useToggle(false);
  const playIntervalRef = useRef<NodeJS.Timer>();

  // Whenever startTime is updated, set selected time with that
  useEffect(() => {
    setSelectedTime(startTime);
  }, [startTime]);

  // Label of selected time
  const selectedTimeLabel = useMemo(() => {
    return formatCustomTimeRange(
      dayjs(selectedTime).tz(tz),
      null,
      tz,
      t,
      new Intl.Locale(currentLang).language,
    );
  }, [selectedTime, tz, t, currentLang]);

  // Handler for changing multiply
  const onMultiplyChange = useCallback((value: number) => {
    setMultiply(value);
  }, []);

  // Handler for changing current time
  const onTimeChange = useCallback((value: number) => {
    setSelectedTime(value);
  }, []);

  // Handler for pausing replay mode
  const onPause = useCallback(() => {
    toggleOffIsPlaying();

    if (playIntervalRef.current) {
      clearInterval(playIntervalRef.current);
    }
  }, [toggleOffIsPlaying]);

  // Handler for playing replay mode
  const onPlay = useCallback(() => {
    // If the seed is end time, start from first sample
    if (selectedTime === endTime) {
      setSelectedTime(startTime);
    }

    playIntervalRef.current = setInterval(() => {
      setSelectedTime((cur) => {
        if (cur >= endTime) {
          onPause();
          return endTime;
        } else {
          return cur + 20000 * multiply;
        }
      });
    }, 50);

    toggleOnIsPlaying();
  }, [toggleOnIsPlaying, selectedTime, startTime, endTime, onPause, multiply]);

  // If new detections are being loaded, stop current replay mode
  useEffect(() => {
    if (!isFetchingDetections) return;

    onPause();
  }, [isFetchingDetections, onPause]);

  // Try to pass selected time to space visualizer
  useEffect(() => {
    svInstance?.onTimeUpdate(selectedTime);
  }, [selectedTime, svInstance]);

  // Determine spot of replay bar
  const determineReplayBarSpot = useCallback(() => {
    if (!svInstance?.container || !toolsRef.current) return;

    const { width: containerWidth } = svInstance.container.getBoundingClientRect();
    const { width: toolsWidth } = toolsRef.current.getBoundingClientRect();
    const gap = 8;
    const padding = 32;
    const expReplayBarWidth = 600;

    if (containerWidth / 2 - toolsWidth - gap - padding - expReplayBarWidth / 2 >= 0) {
      toggleOffShouldFill();
    } else {
      toggleOnShouldFill();
    }
  }, [svInstance, toggleOffShouldFill, toggleOnShouldFill, toolsRef]);

  useEffect(() => {
    window.addEventListener('resize', determineReplayBarSpot);

    return () => {
      window.removeEventListener('resize', determineReplayBarSpot);
    };
  }, [determineReplayBarSpot]);

  useEffect(() => {
    determineReplayBarSpot();
  }, [determineReplayBarSpot, detailPanelOpen]);

  return {
    isPlaying,
    shouldFill,
    startTime,
    endTime,
    selectedTime,
    startTimeLabel,
    endTimeLabel,
    selectedTimeLabel,
    multiply,
    onPlay,
    onPause,
    onMultiplyChange,
    onTimeChange,
  };
};

/**
 * Hooks for space visualizer instance
 */
export const useSVInstance = (
  detailPanelOpen: boolean,
): {
  containerRef: MutableRefObject<HTMLDivElement | null>;
  svRef: MutableRefObject<HTMLDivElement | null>;
  loading: boolean;
} => {
  const { svInstance, setSVInstance } = useSVRendererContext();
  const { rooms } = useGetSVRooms();
  const sensors = useSpaceVisualizerActivitySensors();
  const [loading, { toggleOff: toggleOffLoading }] = useToggle(true);

  // Refs
  const containerRef = useRef<HTMLDivElement | null>(null);
  const svRef = useRef<HTMLDivElement | null>(null);

  // Initialize space visualizer instance
  useEffect(() => {
    if (!svRef.current || svInstance || !sensors) return;

    const sv = new SVRenderer(
      svRef.current,
      rooms,
      sensors,
      [], // TODO Replace with "headcountSensors" once traffic is ready for space visualizer
      structuredClone(DEFAULT_SV_OPTIONS),
    );
    setSVInstance(sv);
    toggleOffLoading();
  }, [rooms, svInstance, sensors, setSVInstance, toggleOffLoading]);

  // Dispose space visualizer instance
  useEffect(() => {
    return () => {
      if (!svInstance) return;

      svInstance.dispose();
      setSVInstance();
    };
  }, [setSVInstance, svInstance]);

  // Whenever side panel is opened, the renderer size should be changed
  useEffect(() => {
    if (!svInstance) return;

    svInstance.onWindowResize();
  }, [svInstance, detailPanelOpen]);

  return {
    containerRef,
    svRef,
    loading,
  };
};
