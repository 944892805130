import { Button, ButtonProps } from '@/components/primitives/button/Button';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

export interface IconButtonProps extends ButtonProps {}

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(function IconButton(
  { className, size = 'md', ...props },
  ref,
) {
  return (
    <Button
      ref={ref}
      {...props}
      size={size}
      className={twMerge(
        // reset padding, center contents, and specify size.
        clsx('px-0 py-0 items-center justify-center', {
          'w-8 h-8': size === 'md',
          'w-7 h-7': size === 'sm',
          'w-6 h-6': size === 'xs',
        }),
        className,
      )}
    />
  );
});
