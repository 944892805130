import * as d3 from 'd3';
import { TFunction } from 'i18next';
import dayjs from 'dayjs';
import { Common } from '@/chart';
import { ISensor, TStatsTrafficDP, TStatsTrafficHM, ETrafficMode, ITimeRange } from '@/types';
import { convertTZ, dateRangeToLabel } from '@/utils';
import { PersonFilledIcon } from '@/components/Icons';
import { groupBy, mapValues, sortBy } from 'lodash';

interface TrafficChartOptions {
  container: HTMLDivElement;
  combinedData: TStatsTrafficDP[];
  individualData: TStatsTrafficHM;
  sensors: ISensor[];
  trafficMode: ETrafficMode;
  timeRange: ITimeRange;
  tz: string;
  hideExitHeadcount?: boolean;
  t: TFunction<'translation', undefined>;
  currentLang: string;
}

/**
 * Calculates the maximum in/out of all traffic data for each timestamp (across all sensors).
 * This is used to determine the position of the tooltip in individual mode.
 */
export function calcMaxIndividualTrafficData(individualData: TStatsTrafficHM): TStatsTrafficDP[] {
  const dataPointsByTime = groupBy(Object.values(individualData).flat(), 'stop');
  const maxValuesByTime = mapValues(dataPointsByTime, (v) =>
    v.reduce((a, b) => ({
      start: a.start,
      stop: a.stop,
      in: Math.max(a.in, b.in),
      out: Math.max(a.out, b.out),
    })),
  );
  return sortBy(Object.values(maxValuesByTime), 'stop');
}

export class TrafficChart extends Common {
  private combinedData: TStatsTrafficDP[];
  private individualData: TStatsTrafficHM;
  private sensorColorMap: { [key: string]: string };
  private trafficMode: ETrafficMode;
  private hideExitHeadcount?: boolean;
  private sensors: ISensor[];
  private barMaxWidth = 4;
  private combinedBarWidth = 12;
  private distTick = 0;

  constructor(options: TrafficChartOptions) {
    super({
      ...options,
      ...{ xAxisIntervalSeconds: options.timeRange.window, shouldDrawVerticalLine: false },
    });
    this.combinedData = options.combinedData;
    this.individualData = options.individualData;
    this.sensors = options.sensors;
    this.trafficMode = options.trafficMode;
    this.hideExitHeadcount = options.hideExitHeadcount;
    this.sensorColorMap = this.sensors.reduce(
      (prev, { mac_address, color }) => ({ ...prev, [mac_address]: color ?? '#ff0000' }),
      {},
    );
  }

  /**
   * Set tick interval
   */
  setXTickInterval(): void {
    const { width, margin } = this.dimensions;
    const { start, end, window } = this.timeRange;
    const visibleWidth = width - margin.right - margin.left;
    this.distTick = (visibleWidth * window) / end.diff(start, 's');
  }

  /**
   * Set axes scale
   */
  setAxesScale(): void {
    const { width, height, margin } = this.dimensions;
    // X&Y extents
    let [minX, maxX, maxY] = this.getMinMax();
    this.xExtent = [minX, maxX];
    maxY = maxY + (this.yAxisSegmentCount - (maxY % this.yAxisSegmentCount));
    this.yExtent = [this.hideExitHeadcount ? 0 : maxY * -1, maxY];
    // X&Y scales
    this.x = d3
      .scaleTime()
      .domain(this.xExtent)
      .range([margin.left, width - margin.right]);
    this.y = d3
      .scaleLinear()
      .domain(this.yExtent)
      .nice(this.yAxisSegmentCount)
      .range([height - margin.bottom, margin.top]);
  }

  /**
   * Get max among all in/out values
   * @param d TStatsTrafficDP[]
   * @returns max value
   */
  getMaxInOut(d: TStatsTrafficDP[]): number {
    let result = 0;

    d.forEach(({ in: inVal, out: outVal }) => {
      const localMax = inVal > outVal ? inVal : outVal;

      result = result < localMax ? localMax : result;
    });

    return result;
  }

  /**
   * Get min/max for x, y axes
   * @returns [minX, maxX, maxY]
   */
  getMinMax(): [Date, Date, number] {
    let maxY = 0;
    if (this.trafficMode === ETrafficMode.INDIVIDUAL) {
      maxY = this.getMaxInOut(Object.values(this.individualData).flat());
    } else {
      maxY = this.getMaxInOut(this.combinedData);
    }
    const minX = convertTZ(this.timeRange.start.toDate(), this.tz);
    const maxX = convertTZ(this.timeRange.end.toDate(), this.tz);

    return [minX, maxX, maxY];
  }

  /**
   * Draw chart
   */
  drawChart() {
    // highlight central axis for traffic chart
    const hTickCount = this.hGrid.selectAll('.tick').size();
    this.hGrid
      .selectAll(`.tick:nth-of-type(${Math.ceil(hTickCount / 2)}) line`)
      .attr('id', 'd3-h-grid-center');
    const { width, height, margin } = this.dimensions;
    let visibleWidth = width - margin.right - margin.left;
    let visibleHalfHeight = (height - margin.bottom - margin.top) / 2;

    // Define clip paths
    this.svg
      .append('defs')
      .append('clipPath')
      .attr('id', 'clip-in')
      .append('rect')
      .attr('x', margin.left)
      .attr('y', margin.top)
      .attr('width', visibleWidth > 0 ? visibleWidth : 0)
      .attr(
        'height',
        visibleHalfHeight > 0
          ? this.hideExitHeadcount
            ? visibleHalfHeight * 2
            : visibleHalfHeight
          : 0,
      );
    this.svg
      .append('defs')
      .append('clipPath')
      .attr('id', 'clip-out')
      .append('rect')
      .attr('x', margin.left)
      .attr('y', margin.top + visibleHalfHeight)
      .attr('width', visibleWidth > 0 ? visibleWidth : 0)
      .attr('height', visibleHalfHeight > 0 ? (this.hideExitHeadcount ? 0 : visibleHalfHeight) : 0);

    // Add in/out chart
    let barWidth = 0;
    let offsetX = 0;
    let color = '';
    if (this.trafficMode === ETrafficMode.COMBINED) {
      this.drawBars(
        this.combinedData,
        this.combinedBarWidth,
        0.5 * this.distTick - this.combinedBarWidth / 2,
        '#3C3D49',
      );
    } else {
      const individualDataArr = Object.entries(this.individualData);
      // Filter out data array with zero only
      const totalBars = Object.values(this.individualData).filter(
        (data) => data.find((dp) => dp.in > 0 || dp.out > 0) !== undefined,
      ).length;

      // Calculate gap between bars (unit: px)
      let gap: number;
      if (totalBars * this.barMaxWidth + (totalBars - 1) * 2 <= this.distTick) {
        gap = 2;
      } else if (totalBars * this.barMaxWidth + (totalBars - 1) * 1 <= this.distTick) {
        gap = 1;
      } else {
        gap = 0;
      }
      const margin = (this.distTick - totalBars * this.barMaxWidth - gap * (totalBars - 1)) / 2;
      let drawnBarNumber = 0;
      individualDataArr.forEach(([mac_address, data]) => {
        // Ignore data that consists of zero only
        if (!data.find((dp) => dp.in > 0 || dp.out > 0)) {
          return;
        }
        offsetX = margin + drawnBarNumber * (gap + this.barMaxWidth);
        drawnBarNumber += 1;
        color = this.sensorColorMap[mac_address];

        this.drawBars(data, this.barMaxWidth, offsetX, color);
      });
    }
    this.barWidth = barWidth;
  }

  /**
   * Draw bars per sensor data
   * @param data
   * @param width
   * @param offsetX
   * @param color
   */
  drawBars(data: TStatsTrafficDP[], width: number, offsetX: number, color: string): void {
    this.svg
      .append('g')
      .attr('id', 'in-chart')
      .attr('clip-path', 'url(#clip-in)')
      .selectAll('bar')
      .data(data)
      .enter()
      .append('rect')
      .attr('x', (d) => this.x(convertTZ(dayjs(d.start).toDate(), this.tz)) + offsetX)
      .attr('y', (d) => this.y(d.in))
      .attr('width', width)
      .attr('height', (d) => this.y(0) - this.y(d.in) + 5)
      .attr('fill', color)
      .attr('opacity', 0.6)
      .attr('pointer-events', 'none');
    this.svg
      .append('g')
      .attr('id', 'out-chart')
      .attr('clip-path', 'url(#clip-out)')
      .selectAll('bar')
      .data(data)
      .enter()
      .append('rect')
      .attr('x', (d) => this.x(convertTZ(dayjs(d.start).toDate(), this.tz)) + offsetX)
      .attr('y', this.y(0) - 5)
      .attr('width', width)
      .attr('height', (d) => this.y(-d.out) - this.y(0) + 5)
      .attr('fill', `${color}55`)
      .attr('opacity', 0.6)
      .attr('pointer-events', 'none');
  }

  /**
   * Listener mousemove event for overlay
   */
  onOverlayMouseMove = (event: MouseEvent) => {
    const { height } = this.dimensions;
    const { left, top } = this.container.getBoundingClientRect();
    const data =
      this.trafficMode === ETrafficMode.COMBINED
        ? this.combinedData
        : calcMaxIndividualTrafficData(this.individualData);
    const [offsetX, offsetY, d, dId] = this.getMouseOffsets(event, data);

    if (!d) return;
    this.drawSelectedRangeShadow(d as TStatsTrafficDP);
    this.focus.selectAll('.d3-focus-line').attr('transform', `translate(${offsetX},${height})`);
    this.focus.selectAll('.d3-focus-circle').attr('transform', `translate(${offsetX},${offsetY})`);
    this.focus.style('display', 'block');

    let tooltipContent: JSX.Element;

    if (this.trafficMode === ETrafficMode.COMBINED) {
      tooltipContent = (
        <>
          <span className="whitespace-nowrap text-xxs text-gray-400">
            {dateRangeToLabel(
              convertTZ(dayjs(d.start).toDate(), this.tz),
              convertTZ(dayjs(d.stop).toDate(), this.tz),
              this.tz,
              this.t,
              new Intl.Locale(this.currentLang).language,
            )}
          </span>
          <span className="flex flex-nowrap items-center whitespace-nowrap py-0.5 text-xs">
            Total:&nbsp;
            <span className="chart-tooltip-value">{(d as TStatsTrafficDP).in || 0}</span>
            <PersonFilledIcon className="ml-1.5 mb-0.5 h-3.5" fill="#A8AAB8" />
            &nbsp;
            <span className="text-gray-400">{this.t('in')}</span>
            {this.hideExitHeadcount ? (
              ''
            ) : (
              <>
                &nbsp;&nbsp;
                <span className="chart-tooltip-value">{(d as TStatsTrafficDP).out || 0}</span>
                <PersonFilledIcon className="ml-1.5 mb-0.5 h-3.5" fill="#A8AAB8" />
                &nbsp;<span className="text-gray-400">{this.t('out')}</span>
              </>
            )}
          </span>
        </>
      );
    } else {
      tooltipContent = (
        <>
          <span className="whitespace-nowrap text-xxs text-gray-400">
            {dateRangeToLabel(
              convertTZ(dayjs(d.start).toDate(), this.tz),
              convertTZ(dayjs(d.stop).toDate(), this.tz),
              this.tz,
              this.t,
              new Intl.Locale(this.currentLang).language,
            )}
          </span>
          {this.sensors.map(({ name, mac_address, color }) => {
            return (
              <span
                key={name}
                className="flex flex-nowrap items-center whitespace-nowrap py-0.5 text-xs"
              >
                <span className="mr-1 h-2 w-2 rounded" style={{ backgroundColor: color }}></span>
                {name || mac_address.substr(mac_address.length - 8, 8)}:&nbsp;
                <span className="chart-tooltip-value">
                  {this.individualData[mac_address]?.[dId]?.in || 0}
                </span>
                <PersonFilledIcon className="ml-1.5 mb-0.5 h-3.5" fill="#A8AAB8" />
                &nbsp;
                <span className="text-gray-400">{this.t('in')}</span>
                {this.hideExitHeadcount ? (
                  ''
                ) : (
                  <>
                    &nbsp;&nbsp;
                    <span className="chart-tooltip-value">
                      {this.individualData[mac_address]?.[dId]?.out || 0}
                    </span>
                    <PersonFilledIcon className="ml-1.5 mb-0.5 h-3.5" fill="#A8AAB8" />
                    &nbsp;<span className="text-gray-400">{this.t('out')}</span>
                  </>
                )}
              </span>
            );
          })}
        </>
      );
    }

    this.onMouseMove({ x: left + offsetX, y: top + offsetY }, tooltipContent); // Inverse transformation
  };

  /**
   * Draw shadow in selected range
   */
  drawSelectedRangeShadow(d: TStatsTrafficDP) {
    // Remove existing range shadow
    this.svg.selectAll('#selected-traffic-range').remove();
    // Append new range shadow
    const startX = this.x(convertTZ(dayjs(d.start).toDate(), this.tz));
    const endX = this.x(convertTZ(dayjs(d.stop).toDate(), this.tz));
    this.svg
      .insert('rect', '.d3-grid.d3-v-grid')
      .attr('id', 'selected-traffic-range')
      .attr('x', startX)
      .attr('y', this.dimensions.margin.top)
      .attr(
        'height',
        this.dimensions.height - this.dimensions.margin.top - this.dimensions.margin.bottom,
      )
      .attr('width', endX - startX)
      .attr('fill', '#F9F9FA');
  }

  /**
   * Update data
   */
  update({
    combinedData,
    individualData,
    sensors,
    trafficMode,
    timeRange,
    t,
    currentLang,
    tz,
  }: {
    combinedData: TStatsTrafficDP[];
    individualData: TStatsTrafficHM;
    sensors: ISensor[];
    trafficMode: ETrafficMode;
    timeRange: ITimeRange;
    t: TFunction<'translation', undefined>;
    currentLang: string;
    tz: string;
  }): void {
    this.combinedData = combinedData;
    this.individualData = individualData;
    this.sensors = sensors;
    this.trafficMode = trafficMode;
    this.timeRange = timeRange;
    this.t = t;
    this.tz = tz;
    this.currentLang = currentLang;
    this.svg?.remove();
    this.setXTickInterval();
    this.setAxesScale();
    this.draw();
  }
}
