import clsx from 'clsx';
import { CheckIcon, EDateRangePickerType } from '@/components';
import { TIME_RANGE_MODE } from '@/redux/reducers/time';

export function QuickRangeButton(props: {
  type: EDateRangePickerType;
  label: string;
  id: number;
  isSelected: boolean;
  onClose: () => void;
  onTimeRangeModeChange: (timeRangeMode: TIME_RANGE_MODE) => void;
}) {
  return (
    <div
      data-id={`Quick range button ${props.id}`}
      role="button"
      tabIndex={props.id}
      className={clsx(
        'flex h-11 cursor-pointer justify-between pt-3.5 pb-3.5 pl-5 pr-3.5 hover:bg-red-50 ',
        props.isSelected && 'bg-red-100',
        !props.isSelected && 'bg-while',
      )}
      onClick={() => props.onTimeRangeModeChange(props.id as TIME_RANGE_MODE)}
    >
      <div
        className={
          'items-center justify-start text-sm font-normal leading-[17px] ' +
          (props.isSelected ? 'text-product-red500' : 'text-gray-600')
        }
      >
        {props.label}
      </div>
      {props.isSelected ? <CheckIcon className="h-4 w-4 fill-current text-red-500" /> : <></>}
    </div>
  );
}
