import '@/translations/i18n';
import './index.css';
import { createRoot } from 'react-dom/client';
import { Auth0Provider } from '@/components/auth/Auth0Provider';
import { Provider } from 'react-redux';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import minMax from 'dayjs/plugin/minMax';
import { setupStore } from '@/redux/store';
import App from './App';
import { ApolloProvider } from '@/components/apollo/ApolloProvider';
import { UserContextProvider } from './contexts/UserContext';
import StatsigProvider from './StatsigProvider';
import { BrowserRouter as Router } from 'react-router-dom';
import { ConfigProvider } from 'antd';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
dayjs.extend(minMax);
dayjs.extend(updateLocale);
dayjs.extend(customParseFormat);
dayjs.updateLocale('en', {
  weekStart: 1,
});

const store = setupStore();

const container = document.getElementById('root');
if (!container) {
  throw new Error('No root element found');
}
const root = createRoot(container);

root.render(
  <Router
    basename={import.meta.env.BASE_URL}
    getUserConfirmation={() => {
      /* Empty callback to block the default browser prompt */
    }}
  >
    <Auth0Provider>
      <ApolloProvider>
        <UserContextProvider>
          <StatsigProvider>
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: '#ff0000',
                },
              }}
            >
              <Provider store={store}>
                <App />
              </Provider>
            </ConfigProvider>
          </StatsigProvider>
        </UserContextProvider>
      </ApolloProvider>
    </Auth0Provider>
  </Router>,
);

if (import.meta.hot && import.meta.env.MODE !== 'test') {
  import.meta.hot.accept();
}

// expose current build version to the window object
// which we can use to quickly determine exact code running in an
// environment
if (import.meta.env.VITE_APP_GIT_SHA) {
  // rather than adding a typing for this to Window, accept this
  // as a TS error - we don't actually want any code to reference
  // or rely on this so typing is actually unhelpful.
  // @ts-expect-error
  window.__GIT_SHA__ = import.meta.env.VITE_APP_GIT_SHA;
}
