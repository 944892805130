import { useEffect, useRef } from 'react';

/** Returns the value stored from the previous render */
export function usePrevious<T>(value: T): T | null {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current ?? null;
}
