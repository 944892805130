import React, { ReactNode, FC, memo } from 'react';
import { ICommonComponentProps } from '@/types';
import clsx from 'clsx';

interface ICardProps extends ICommonComponentProps {
  children: ReactNode;
}

export const Card: FC<ICardProps> = memo(({ className, style, children, ...rest }: ICardProps) => {
  return (
    <div
      className={clsx('card relative rounded-2xl border border-gray-100 bg-white', className)}
      style={style}
      {...rest}
    >
      {children}
    </div>
  );
});
