import { TRectCoords, TRectangle, TXYPoint } from '@/types';
import { Polygon, point, vector } from '@flatten-js/core';
import { clampAngleDegrees } from '../utils/utils';
import type { PhysicalObject } from './types';
import type { AugmentedFloorPlan } from '../ScopingEditor/reducer';

/**
 * Represents a floor plan and provides functionality for its behavior and properties.
 */
export class FloorPlanObject implements PhysicalObject {
  private _coordinates: TRectCoords;
  private _rotation: number;

  constructor(coordinates: TRectCoords, rotation: number) {
    this._coordinates = coordinates;
    this._rotation = rotation;
  }

  static fromFloorPlan(fp: Pick<AugmentedFloorPlan, 'coordinates' | 'rotation'>): FloorPlanObject {
    return new FloorPlanObject(fp.coordinates, fp.rotation);
  }

  get coordinates(): TRectCoords {
    return this._coordinates;
  }

  get center(): TXYPoint {
    const { x, y } = new Polygon(this._coordinates).box.center;
    return [x, y];
  }

  get rotation(): number {
    return this._rotation;
  }

  get bbox(): TRectangle {
    const { xmin, ymin, width, height } = new Polygon(this._coordinates).rotate(
      -(this._rotation * Math.PI) / 180,
      point(this.center),
    ).box;
    return [xmin, ymin, width, height];
  }

  get width(): number {
    return this.bbox[2];
  }

  get height(): number {
    return this.bbox[3];
  }

  rotate(angle: number, center: TXYPoint = this.center): FloorPlanObject {
    const nextCoordinates = new Polygon(this._coordinates).rotate(
      -(angle * Math.PI) / 180,
      point(center),
    );
    this._coordinates = nextCoordinates.vertices.map(({ x, y }) => [x, y]) as TRectCoords;
    this._rotation = clampAngleDegrees(this._rotation + angle);
    return this;
  }

  translate(dx: number, dy: number): FloorPlanObject {
    const nextCoordinates = new Polygon(this._coordinates).translate(vector(dx, dy));
    this._coordinates = nextCoordinates.vertices.map(({ x, y }) => [x, y]) as TRectCoords;
    return this;
  }

  /**
   * Scales the floor plan with an x and y factor.
   * By default, the floor plan will be scaled along the x and y axis from its center.
   * @param sx The scaling factor along the x-axis. A value of 1 will keep the floor plan the same size.
   * @param sy The scaling factor along the y-axis. A value of 1 will keep the floor plan the same size.
   * @param anchor The point to scale from. This point will stay fixed as the rest of the floor plan scales.
   * @param rotation The rotation to scale from. If you want to scale the floor plan along its width/height, you should provide the room's rotation.
   *
   * @returns The floor plan object after scaling.
   */
  scale(
    sx: number,
    sy: number,
    [anchorX, anchorY]: TXYPoint = this.center,
    rotation: number = 0,
  ): FloorPlanObject {
    let polygon = new Polygon(this._coordinates);
    polygon = polygon.rotate((rotation * Math.PI) / 180, point(this.center));
    polygon = polygon.translate(vector(-anchorX, -anchorY));
    polygon = polygon.scale(sx, sy);
    polygon = polygon.translate(vector(anchorX, anchorY));
    polygon = polygon.rotate(-(rotation * Math.PI) / 180, point(this.center));
    this._coordinates = polygon.vertices.map(({ x, y }) => [x, y]) as TRectCoords;
    return this;
  }

  /** Sets the rotation to an exact value. */
  setRotation(nextRotation: number) {
    const initialRotation = this.rotation;
    return this.rotate(nextRotation - initialRotation);
  }

  /** Sets the width to an exact value. */
  setWidth(nextWidth: number) {
    const width = this.bbox[2];
    const rotation = this.rotation;
    return this.scale(nextWidth / width, 1, this.center, rotation);
  }

  /** Sets the height to an exact value. */
  setHeight(nextHeight: number) {
    const height = this.bbox[3];
    const rotation = this.rotation;
    return this.scale(1, nextHeight / height, this.center, rotation);
  }
}
