import { forwardRef, HTMLAttributes } from 'react';
import clsx from 'clsx';
import { IconName } from './generated/iconNames.js';

export interface IconProps extends HTMLAttributes<SVGSVGElement> {
  name: IconName;
  size?: number;
}

export const Icon = forwardRef<SVGSVGElement, IconProps>(function Icon(
  { name, size = 20, className, ...rest },
  ref,
) {
  return (
    <svg
      ref={ref}
      className={clsx(
        // disable flex shrink to avoid icons getting squashed in flexboxes
        'flex-shrink-0 fill-current',
        className,
      )}
      width={size}
      height={size}
      {...rest}
    >
      {/*
        An icon is really a reference to the icon spritesheet in /public. It renders the appropriate glyph
        from that sheet. Because the icon spritesheet is all one big file, it does have a slightly
        longer initial load, but it can be cached by the browser.
       */}
      <use xlinkHref={`${import.meta.env.BASE_URL}icons-a8279b36.svg#icon-${name}`} />
    </svg>
  );
});
