import React, { FC, SVGProps } from 'react';

const Check: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.77091 10.6751C5.60848 10.6751 5.45272 10.6105 5.33788 10.4957L2.88788 8.04565C2.77474 7.93045 2.71168 7.77523 2.71241 7.61376C2.71314 7.4523 2.77761 7.29766 2.89178 7.18348C3.00595 7.06931 3.1606 7.00484 3.32206 7.00411C3.48353 7.00338 3.63875 7.06644 3.75395 7.17958L5.74756 9.17319L10.8282 3.5279C10.9369 3.40718 11.0891 3.33456 11.2513 3.32604C11.4135 3.31752 11.5724 3.37378 11.6931 3.48245C11.8139 3.59111 11.8865 3.74329 11.895 3.9055C11.9035 4.0677 11.8473 4.22665 11.7386 4.34738L6.2261 10.4724C6.17046 10.5341 6.10286 10.584 6.02739 10.6188C5.95192 10.6537 5.87016 10.6728 5.78706 10.6751H5.77091Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Check;
