import { createContext, ReactNode, FC, useState, Dispatch, SetStateAction, useEffect } from 'react';
import clsx from 'clsx';
import { TXYPoint } from '../types';

interface IPopupConfiguration {
  show: boolean;
  content?: ReactNode;
  timeoutOption?: TimeoutOption;
  className?: string;
  position?: TXYPoint;
}

interface IPopupContextProps {
  setConfig?: Dispatch<SetStateAction<IPopupConfiguration>>;
}

type TimeoutOption = {
  enable: boolean;
  timeout: number;
} | null;

interface IPopupContent extends IPopupConfiguration {
  setConfig: Dispatch<SetStateAction<IPopupConfiguration>>;
}

interface IPopupContextProviderProps {
  children: ReactNode;
}

const PopupContent = ({
  show,
  content,
  setConfig,
  timeoutOption,
  className,
  position,
}: IPopupContent) => {
  useEffect(() => {
    if (show && timeoutOption?.enable) {
      const timeout = setTimeout(
        () => setConfig((config) => ({ ...config, show: false })),
        timeoutOption.timeout,
      );
      return () => clearTimeout(timeout);
    }
  }, [show, content]);

  return (
    <div
      className={clsx(
        'min-h-9 fixed z-50 flex min-w-[146px] rounded bg-white py-2 text-center text-xs leading-5 text-gray-900 shadow',
        !position && 'top-6 right-1/2 translate-x-1/2',
        show ? 'flex' : 'hidden',
        className,
      )}
      role="alert"
      aria-label="Contextual Popup"
      style={position ? { left: position[0], top: position[1] } : {}}
    >
      {content}
    </div>
  );
};

export const PopupContextProvider: FC<IPopupContextProviderProps> = ({ children }) => {
  const [config, setConfig] = useState<IPopupConfiguration>({ show: false });
  const { show, content, timeoutOption, className, position } = config;
  return (
    <PopupContext.Provider value={{ setConfig }}>
      {children}
      <PopupContent
        show={show}
        setConfig={setConfig}
        content={content}
        timeoutOption={timeoutOption}
        className={className}
        position={position}
      />
    </PopupContext.Provider>
  );
};

export const PopupContext = createContext<IPopupContextProps>({});
