import * as THREE from 'three';
import { TXYPoint } from './Common';

export enum ESV_Pointer {
  POINTER = 'pointer',
  AUTO = 'auto',
  GRAB = 'grab',
}

export type TSV_Door = {
  name: string;
  position: TXYPoint;
  length: number;
  rotation: number;
  headcountIn: number;
  headcountOut: number;
};

export type TSV_Visitor = { position: TXYPoint; opacity: number };

export enum ESV_Tag {
  ROOM_NAME = 'roomName',
  ROOM_OCCUPANCY = 'roomOccupancy',
}

export type TSV_Tag = {
  id: string;
  element: HTMLElement;
  tag: ESV_Tag;
  name?: string;
  occupancy?: number;
  fillColor?: string;
  color?: string;
};

export type TSV_Tags = {
  [key in ESV_Tag]?: TSV_Tag[];
};

export type TTextureCache = {
  key: string;
  texture: THREE.Texture;
};

export type TMaterialCache = {
  key: string;
  material: THREE.Material;
};

export type TModelCache = {
  key: string;
  model: THREE.Object3D;
};

export enum ESV_VizMode {
  OCCUPANCY = 'occupancy',
  TRAFFIC = 'traffic',
}

export enum ESV_Vision_Options {
  ROOMS = 'rooms',
  SENSOR_COVERAGE_AREAS = 'sensorCoverageAreas',
  SPACE_VISUALIZATIONS = 'spaceVisualizations',
  UTILIZATION_HEATMAP = 'utilizationHeatmap',
  DETECTION_REPLAY = 'detectionReplay',
  FLOOR_PLAN_IMAGE = 'floorPlanImage',
  // TODO Consider below options later
  // FIXTURES = 'fixtures',
  // OCCUPANCY = 'presence',
  // OCCUPANCY_CHART = 'presenceChart',
  // EST_OCCUPANCY = 'estOccupancy',
  // EST_OCCUPANCY_CHART = 'estOccupancyChart',
  // LIVE_DETECTIONS = 'liveDetections',
}

export type TSV_Vision_Options = {
  [key in ESV_Vision_Options]: boolean;
};

export interface ISV_Options {
  vizMode: ESV_VizMode;
  presentMode: boolean;
  zoom: number;
  orbitMode: boolean;
  visionOptions: TSV_Vision_Options;
}
export interface ISV_TweenAnim {
  uuid: string;
  // eslint-disable-next-line no-undef
  tween: GSAPTween;
}
