import React, { memo, FC } from 'react';
import clsx from 'clsx';
import {
  useSVInstance,
  useSVOptions,
  useSVPresentationIdle,
  useSVFloorplan,
  useSVTags,
  useSVDetections,
  useSVToasts,
  useSVRoomInteraction,
  useSVRoomOccupancy,
} from '@/hooks';
import {
  RoomNameTagContainer,
  RoomOccupancyTagContainer,
  TopActionBar,
  BottomActionBar,
  LoadingSpinner,
} from '@/components';
import { ESV_VizMode, ESV_Vision_Options } from '@/types';

interface ISpaceVisualizerProps {
  className?: string;
  detailPanelOpen: boolean;
  isRoom: boolean;
  isZone: boolean;
}

const SpaceVisualizer: FC<ISpaceVisualizerProps> = memo(({ className, detailPanelOpen }) => {
  const { containerRef, svRef, loading } = useSVInstance(detailPanelOpen);
  const {
    options,
    onPresentationModeToggle,
    onZoomIn,
    onZoomOut,
    onOrbitModeToggle,
    onVizItemToggle,
  } = useSVOptions(containerRef);
  const { isPresentationIdle } = useSVPresentationIdle(options.presentMode);
  const { hasFloorplan } = useSVFloorplan();
  const { tags } = useSVTags();
  const { hoveredRoomId } = useSVRoomInteraction();
  useSVRoomOccupancy();
  useSVDetections();
  useSVToasts();

  return (
    <div
      id="space-visualizer-container"
      ref={containerRef}
      className={clsx('relative h-full flex-grow', className)}
    >
      {loading && <LoadingSpinner className="bg-white" />}
      {/* Action and overlay ui sections */}
      <div
        className="pointer-events-none relative z-10 h-full w-full cursor-default overflow-hidden p-3"
        data-id="space-viz-pane"
      >
        <TopActionBar
          isPresentationIdle={isPresentationIdle}
          options={options}
          onVizItemToggle={onVizItemToggle}
        />
        <BottomActionBar
          detailPanelOpen={detailPanelOpen}
          isPresentationIdle={isPresentationIdle}
          options={options}
          hasFloorplan={hasFloorplan}
          onVizItemToggle={onVizItemToggle}
          onOrbitModeToggle={onOrbitModeToggle}
          onZoomOut={onZoomOut}
          onZoomIn={onZoomIn}
          onPresentationModeToggle={onPresentationModeToggle}
        />
      </div>
      {/* Renderer */}
      <div ref={svRef} className="absolute top-0 left-0 bottom-0 right-0 z-0" />
      {/* Tags with CSS 3D portals */}
      {options.visionOptions[ESV_Vision_Options.ROOMS] && (
        <RoomNameTagContainer tags={tags.roomName} hovered={hoveredRoomId} />
      )}
      {options.vizMode === ESV_VizMode.OCCUPANCY && (
        <RoomOccupancyTagContainer tags={tags.roomOccupancy} />
      )}
    </div>
  );
});

export * from './Dialogs';
export * from './SpaceVisualizationDropdown';
export * from './SpaceVisualizationOptions';
export * from './RoomNameTagContainer';
export * from './RoomOccupancyTagContainer';
export * from './HeatmapLegendBar';
export * from './TopActionBar';
export * from './BottomActionBar';
export * from './ReplayBar';
export * from './ThreeRenderer';
export { default as SVRenderer } from './ThreeRenderer';
export default SpaceVisualizer;
