import React, { FC, SVGProps } from 'react';

/** @deprecated use <Icon name="..." /> (import from @/components/primitives/icons/Icon) instead. */
const SvgComponent: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      aria-hidden="true"
      data-prefix="far"
      data-icon="vision"
      className="prefix__svg-inline--fa prefix__fa-play prefix__fa-w-14"
      viewBox="0 0 14 18"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M13.2364 8.3489L1.9803 0.326343C1.31833 -0.145462 0.399904 0.327778 0.399904 1.14068L0.399903 16.8993C0.399903 17.7044 1.30279 18.1794 1.96631 17.7234L13.2224 9.98737C13.7946 9.59414 13.8018 8.75185 13.2364 8.3489Z" />
    </svg>
  );
};

export default SvgComponent;
