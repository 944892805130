import { Tag, type TagProps } from 'antd';
import { useState } from 'react';
import { TagDropdown } from './TagDropdown';

export function InputTag({
  options,
  onChange,
  ...rest
}: Omit<TagProps, 'onChange'> & { options: string[]; onChange: (item: string) => void }) {
  const [showInput, setShowInput] = useState(false);

  return (
    <>
      {showInput ? (
        <TagDropdown
          style={{ width: 100, height: 22, lineHeight: '20px' }}
          options={options}
          placeholder="Add a tag"
          createFromInput={(t) => t}
          onChange={(item) => {
            setShowInput(false);
            if (!item) return;
            onChange?.(item);
          }}
          onBlur={() => {
            setShowInput(false);
          }}
        />
      ) : (
        <Tag
          className="border-dashed bg-white inline-flex min-w-[80px] m-0 cursor-pointer hover:border-gray-600"
          onClick={() => setShowInput(true)}
          {...rest}
        >
          + Add Tag
        </Tag>
      )}
    </>
  );
}
