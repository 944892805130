import { FC, forwardRef, memo, ReactNode, useCallback, useRef } from 'react';
import { useOnClickOutside } from '@/hooks';
import { ICommonComponentProps } from '@/types';
import clsx from 'clsx';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { twMerge } from 'tailwind-merge';
import { withClassName } from '@/hocs/withClassName';

export type TDialog = {
  content: ReactNode;
  options?: {
    outsideClickToClose?: boolean;
    className?: string;
  };
  fixedWidth?: number;
  radius?: string;
};

interface IDialogProps extends ICommonComponentProps {
  dialog: TDialog;
  setDialog: (s: TDialog | null) => void;
  closeDialog: () => void;
}

/** @deprecated - use DialogRoot, DialogTrigger, DialogContent, DialogClose components exported from this module */
export const Dialog: FC<IDialogProps> = memo(
  ({
    className,
    style,
    dialog: { content, options, fixedWidth },
    setDialog,
    closeDialog,
    ...rest
  }) => {
    const contentRef = useRef<HTMLDivElement | null>(null);

    // Handler for close
    const handleClose = useCallback(() => {
      if (!options?.outsideClickToClose) return;

      closeDialog();
    }, [options?.outsideClickToClose]);

    // Close dialog when click outside of content
    useOnClickOutside(contentRef, handleClose);

    return (
      <div
        className={clsx(
          'fixed top-0 bottom-0 right-0 left-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-40',
          className,
        )}
        style={style}
        {...rest}
      >
        <div
          style={{
            minWidth: fixedWidth,
          }}
          role="dialog"
          ref={contentRef}
          className={twMerge(
            options?.className,
            'relative flex flex-col gap-4 bg-white p-6 shadow-xl',
          )}
        >
          {content}
        </div>
      </div>
    );
  },
);

/**
 * Wraps a set of Dialog___ components to provide
 * state for opening and closing the dialog.
 *
 * @example
 * <DialogRoot>
 *  <DialogTrigger asChild><Button>Open Dialog</Button></DialogTrigger>
 *  <DialogContent>
 *    <DialogTitle>Hello world</DialogTitle>
 *    <div>Dialog content</div>
 *    <DialogClose asChild><Button>Close Dialog</Button></DialogClose>
 *  </DialogContent>
 * </DialogRoot>
 */
export const DialogRoot = DialogPrimitive.Root;
/**
 * Recommended usage:
 * @example <DialogTrigger asChild><Button>Open Dialog</Button></DialogTrigger>
 */
export const DialogTrigger = DialogPrimitive.Trigger;
/**
 * Recommended usage:
 * @example <DialogClose asChild><Button>Close Dialog</Button></DialogClose>
 */
export const DialogClose = DialogPrimitive.Close;
/**
 * Renders an accessible title for the dialog.
 */
export const DialogTitle = withClassName(
  DialogPrimitive.Title,
  'text-lg font-semibold leading-loose',
);
/**
 * Wraps content in a portal and displays it as a modal dialog above
 * other content.
 */
export const DialogContent = forwardRef<HTMLDivElement, DialogPrimitive.DialogContentProps>(
  function DialogContent({ className, ...props }, ref) {
    return (
      <DialogPrimitive.Portal>
        <DialogPrimitive.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-40 radix-state-open:animate-appear z-40" />
        <DialogPrimitive.Content
          {...props}
          ref={ref}
          className={twMerge(
            'fixed top-1/2 left-1/2 max-h-[85vh] w-[90vw] max-w-[450px] -translate-x-1/2 -translate-y-1/2 rounded p-6 shadow-2xl focus:outline-none bg-white z-50',
            'radix-state-open:animate-dialogIn radix-state-closed:animate-dialogOut',
            className,
          )}
        />
      </DialogPrimitive.Portal>
    );
  },
);
