import React, { FC, SVGProps } from 'react';

/** @deprecated use <Icon name="..." /> (import from @/components/primitives/icons/Icon) instead. */
const SvgComponent: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      aria-hidden="true"
      data-prefix="far"
      data-icon="hand"
      className="prefix__svg-inline--fa prefix__fa-user prefix__fa-w-14"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M5.85261 19.9511L11.3159 23.1711C11.8887 23.4992 12.4114 23.4992 12.9842 23.1711L18.4475 19.9511C19.1635 19.5315 19.5 19.0813 19.5 18.0207V11.7257C19.5 10.9016 19.2136 10.3675 18.5406 9.9707L13.5356 7.01778C12.6334 6.48366 11.6667 6.48366 10.7717 7.01778L5.76668 9.9707C5.09362 10.3675 4.80005 10.9016 4.80005 11.7257V18.0207C4.80005 19.0813 5.13658 19.5315 5.85261 19.9511ZM12.1536 14.0529L7.06985 11.0542L11.4233 8.47516C11.9173 8.18521 12.3828 8.17758 12.8768 8.47516L17.2302 11.0542L12.1536 14.0529ZM6.74764 18.6311C6.38246 18.4098 6.24642 18.1733 6.24642 17.7918V12.4124L11.3946 15.4721V21.4009L6.74764 18.6311ZM17.5525 18.6311L12.9055 21.4009V15.4721L18.0537 12.4124V17.7918C18.0537 18.1733 17.9248 18.4098 17.5525 18.6311Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1507 5.53589C9.91308 5.53588 9.76979 5.27283 9.89863 5.0732L11.7481 2.20783C11.8663 2.02474 12.1341 2.02475 12.2522 2.20785L14.1014 5.07336C14.2302 5.273 14.0869 5.53604 13.8493 5.53603L12.6235 5.53598L12.6235 10.7936C12.6235 11.138 12.3444 11.4172 12 11.4172C11.6556 11.4172 11.3765 11.138 11.3765 10.7936L11.3765 5.53595L10.1507 5.53589Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.6254 17.8824C20.7513 17.6809 21.0503 17.6987 21.1513 17.9138L22.6013 21.0006C22.694 21.1978 22.552 21.4249 22.3342 21.4281L18.9241 21.4779C18.6866 21.4814 18.5394 21.2205 18.6653 21.019L19.3149 19.9795L15.8738 17.8293C15.5817 17.6468 15.4929 17.2621 15.6754 16.9701C15.8579 16.6781 16.2426 16.5892 16.5346 16.7717L19.9758 18.9219L20.6254 17.8824Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.4491 17.8824C3.32319 17.6809 3.02417 17.6987 2.92315 17.9138L1.47315 21.0006C1.3805 21.1978 1.52241 21.4249 1.74031 21.4281L5.15033 21.4779C5.3879 21.4814 5.53503 21.2205 5.40912 21.019L4.75953 19.9795L8.20068 17.8293C8.49273 17.6468 8.58155 17.2621 8.39906 16.9701C8.21658 16.6781 7.8319 16.5892 7.53986 16.7717L4.09868 18.9219L3.4491 17.8824Z"
      />
    </svg>
  );
};

export default SvgComponent;
