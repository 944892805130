import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import TRANSLATIONS_EN from './en/translations';
import TRANSLATIONS_CN from './cn/translations';
import { ELanguage } from '@/types';

export const defaultNS = 'translation';

export const resources = {
  [ELanguage.EN_US]: {
    translation: TRANSLATIONS_EN,
  },
  [ELanguage.ZH_HANS]: {
    translation: TRANSLATIONS_CN,
  },
} as const;

i18n.use(initReactI18next).init({
  resources,
  defaultNS,
  ns: [defaultNS],
  pluralSeparator: '_',
});

export default i18n;
