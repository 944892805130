import React, { FC, SVGProps } from 'react';

/** @deprecated use <Icon name="..." /> (import from @/components/primitives/icons/Icon) instead. */
const SvgComponent: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      aria-hidden="true"
      data-prefix="far"
      data-icon="user"
      className="prefix__svg-inline--fa prefix__fa-user prefix__fa-w-14"
      viewBox="0 0 8 9"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M3.83838 4.7876C4.79639 4.7876 5.60938 3.93506 5.60938 2.83203C5.60938 1.75537 4.79199 0.929199 3.83838 0.929199C2.88037 0.929199 2.05859 1.76855 2.06299 2.84082C2.06299 3.93506 2.87598 4.7876 3.83838 4.7876ZM1.09619 8.87451H6.57178C7.29688 8.87451 7.54736 8.65479 7.54736 8.25049C7.54736 7.12109 6.11475 5.56543 3.83398 5.56543C1.55762 5.56543 0.120605 7.12109 0.120605 8.25049C0.120605 8.65479 0.371094 8.87451 1.09619 8.87451Z" />
    </svg>
  );
};

export default SvgComponent;
