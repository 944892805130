import React, { FunctionComponent, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { QuestionCircleOutlineIcon, AutoTooltip } from '@/components';
import { ICommonComponentProps } from '@/types';
import { COLORS } from '@/constants';
import clsx from 'clsx';

interface IQuestionMenuProps extends ICommonComponentProps {}

export const QuestionMenu: FunctionComponent<IQuestionMenuProps> = memo(
  ({ className, style, ...rest }) => {
    const { t } = useTranslation();

    return (
      <div
        className={clsx('relative z-30 inline-block text-left', className)}
        style={style}
        {...rest}
      >
        <AutoTooltip label={t('helpCenter')}>
          <a
            href="http://support.butlr.io"
            target="_blank"
            rel="noreferrer"
            data-id="supportMenuButton"
            className="border-full flex flex-row items-center justify-end p-2 text-sm hover:bg-gray-800 active:bg-gray-700 rounded-full"
          >
            <span className="sr-only">Visit Support Center</span>
            <QuestionCircleOutlineIcon width={18} fill={COLORS.white} />
          </a>
        </AutoTooltip>
      </div>
    );
  },
);
