import { useRef } from 'react';
import { useButton, AriaButtonProps } from '@react-aria/button';
import { DateRange } from '@react-types/datepicker';
import clsx from 'clsx';
import { DropdownTrigger, DropdownTriggerIcon } from '@/components/primitives/Dropdown/Dropdown';
import { Icon } from '../icon/Icon';
import { TIME_RANGE_MODE } from '@/redux/reducers/time';

interface IPopoverButtonProps extends AriaButtonProps<'button'> {
  label: string;
  tz: string;
  timeRangeMode: TIME_RANGE_MODE;
  rangeValue: DateRange;
  rangeLabels: readonly string[];
  status?: 'error' | 'normal';
  isSelected?: boolean;
}

export function PopoverButtonAlternate(props: IPopoverButtonProps) {
  const ref = useRef<HTMLButtonElement>(null);
  const { buttonProps } = useButton(props, ref);
  const { status, label } = props;
  return (
    <DropdownTrigger
      className={clsx(
        'mr-2 items-center justify-between flex flex-row rounded-[30px] px-2 text-sm w-24 h-8 hover:bg-gray-300 hover:text-gray-900',
        status === 'error' && 'border border-product-red500',
        props.isSelected ? 'bg-black text-white' : ' text-black',
      )}
      {...buttonProps}
      ref={ref}
      data-id="date-range-picker-button"
    >
      <div className={`flex items-center justify-self-start leading-4`}>
        <Icon name="time" size={14} className="mr-1.5" />
        <div className="mr-1.5">{label}</div>
      </div>
      <DropdownTriggerIcon />
    </DropdownTrigger>
  );
}
