export const CHART_QUICK_RANGE_BUTTON_LABELS = [
  'today',
  'last5Mins',
  'thisWeek',
  'thisMonth',
  'thisYear',
] as const;

//The addition of the index property is to access the proper modes
//from TIME_RANGE_MODE while not affecting functionality for the original modes/datepicker

export const CHART_QUICK_RANGE_BUTTON_LABELS_ALTERNATE = [
  { label: 'today', index: 1 },
  { label: 'yesterday', index: 6 },
  { label: 'sevenDays', index: 7 },
  { label: 'thirtyDays', index: 8 },
] as const;

export const DETECTION_QUICK_RANGE_BUTTON_LABELS = ['today', 'last5Mins', 'thisWeek'] as const;

/**
 * Date range picker behaves differently for
 * different scenarios - mainly which quick selection
 * options it surfaces.
 */
export enum EDateRangePickerType {
  CHARTS = 'charts',
  DETECTION = 'detection',
}
