import React, { memo, FC } from 'react';
import ReactDOM from 'react-dom';
import { TSV_Tag } from '@/types';

interface IRoomNameTagContainerProps {
  tags?: TSV_Tag[];
  hovered?: string;
}

export const RoomNameTagContainer: FC<IRoomNameTagContainerProps> = memo(({ tags, hovered }) => {
  return (
    <>
      {tags?.map(({ id, element, name, fillColor, color }) =>
        ReactDOM.createPortal(
          <span
            className="rounded py-1 px-3 text-center text-xxs"
            style={
              id === hovered
                ? { background: '#0000ff55', color: 'white' }
                : { background: fillColor, color }
            }
          >
            {name}
          </span>,
          element,
        ),
      )}
    </>
  );
});
