import React, { FC, SVGProps } from 'react';
import { COLORS } from '@/constants';

/** @deprecated use <Icon name="..." /> (import from @/components/primitives/icons/Icon) instead. */
const SvgComponent: FC<SVGProps<SVGSVGElement>> = ({ fill = COLORS.gray800, ...props }) => {
  return (
    <svg
      aria-hidden="true"
      data-prefix="fas"
      data-icon="question-circle-outline"
      className="prefix__svg-inline--fa prefix__fa-question-circle-outline prefix__fa-w-16"
      viewBox="0 0 22 22"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2323 7.42189C10.3673 7.27351 9.52128 7.77069 9.23002 8.59866C9.06508 9.06755 8.55125 9.31395 8.08236 9.14901C7.61347 8.98406 7.36707 8.47024 7.53202 8.00134C8.11454 6.3454 9.80648 5.35103 11.5366 5.6478C13.2665 5.94452 14.5303 7.44548 14.528 9.20055C14.5277 10.578 13.5065 11.4877 12.7772 11.9738C12.3852 12.2352 11.9995 12.4274 11.7154 12.5537C11.5721 12.6174 11.4514 12.6657 11.3644 12.6989C11.3209 12.7154 11.2856 12.7283 11.2598 12.7375L11.2286 12.7484L11.2186 12.7518L11.2151 12.753L11.2138 12.7534C11.2135 12.7535 11.2126 12.7538 10.928 11.9L11.2138 12.7534C10.7422 12.9106 10.2314 12.6562 10.0742 12.1846C9.91723 11.7137 10.1712 11.2047 10.6416 11.0468C10.6418 11.0467 10.6423 11.0466 10.6429 11.0463L10.6568 11.0415C10.6707 11.0365 10.6934 11.0283 10.7236 11.0168C10.7843 10.9937 10.8746 10.9576 10.9844 10.9088C11.2065 10.8101 11.4959 10.6648 11.7788 10.4762C12.3994 10.0624 12.728 9.62222 12.728 9.2L12.728 9.19866C12.7293 8.32095 12.0974 7.57027 11.2323 7.42189Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.40002 11C1.40002 5.69809 5.69809 1.40002 11 1.40002C16.302 1.40002 20.6 5.69809 20.6 11C20.6 16.302 16.302 20.6 11 20.6C5.69809 20.6 1.40002 16.302 1.40002 11ZM11 3.14548C6.66208 3.14548 3.14548 6.66208 3.14548 11C3.14548 15.338 6.66208 18.8546 11 18.8546C15.338 18.8546 18.8546 15.338 18.8546 11C18.8546 6.66208 15.338 3.14548 11 3.14548Z"
        fill={fill}
      />
      <mask id="path-3-inside-1_3020_350244" fill="white">
        <ellipse cx="11" cy="15.5" rx="0.9" ry="0.9" />
      </mask>
      <ellipse cx="11" cy="15.5" rx="0.9" ry="0.9" fill={fill} />
      <path
        d="M10.9 15.5C10.9 15.4448 10.9448 15.4 11 15.4V17.4C12.0494 17.4 12.9 16.5493 12.9 15.5H10.9ZM11 15.4C11.0553 15.4 11.1 15.4448 11.1 15.5H9.10004C9.10004 16.5493 9.95069 17.4 11 17.4V15.4ZM11.1 15.5C11.1 15.5552 11.0553 15.6 11 15.6V13.6C9.95069 13.6 9.10004 14.4507 9.10004 15.5H11.1ZM11 15.6C10.9448 15.6 10.9 15.5552 10.9 15.5H12.9C12.9 14.4507 12.0494 13.6 11 13.6V15.6Z"
        fill={fill}
        mask="url(#path-3-inside-1_3020_350244)"
      />
    </svg>
  );
};

export default SvgComponent;
