import * as d3 from 'd3';
import dayjs from 'dayjs';
import { TFunction } from 'i18next';
import {
  ECommonVariants,
  TDimensionType,
  TScreenPos,
  TStatsTrafficDP,
  TStatsOccupancyDP,
  ITimeRange,
} from '@/types';
import { convertTZ, dateToAxisLabel, generateDateIntervals } from '@/utils';
import React from 'react';
import { last } from 'lodash';

interface ChartCommonOptions {
  container: HTMLDivElement;
  timeRange: ITimeRange;
  tz: string;
  t: TFunction<'translation', undefined>;
  currentLang: string;
  variant?: ECommonVariants;
  shouldDrawVerticalLine?: boolean;
}

export class Common {
  onMouseOut!: () => void;
  onMouseMove!: (pos: TScreenPos, content: React.ReactNode) => void;

  public shouldDrawVerticalLine?: boolean;
  public container: HTMLDivElement;
  public timeRange: ITimeRange;
  public tz: string;
  public t: TFunction<'translation', undefined>;
  public currentLang: string;
  public variant: ECommonVariants;
  public dimensions!: TDimensionType;
  public svg!: d3.Selection<SVGSVGElement, unknown, null, undefined>;
  public focus!: d3.Selection<SVGGElement, unknown, null, undefined>;
  public xExtent!: [Date, Date];
  public yExtent!: number[];
  public x!: d3.ScaleTime<number, number, never>;
  public y!: d3.ScaleLinear<number, number, never>;
  public xAxis!: d3.Selection<SVGGElement, unknown, null, undefined>;
  public yAxis!: d3.Selection<SVGGElement, unknown, null, undefined>;
  public vGrid!: d3.Selection<SVGGElement, unknown, null, undefined>;
  public hGrid!: d3.Selection<SVGGElement, unknown, null, undefined>;
  public bisect!: (
    array: ArrayLike<TStatsTrafficDP | TStatsOccupancyDP>,
    x: Date,
    lo?: number | undefined,
    hi?: number | undefined,
  ) => number;
  public tickArgument!: d3.TimeInterval | null;
  public yAxisSegmentCount = 6;
  // For traffic only
  public barWidth?: number;
  public tickDates: Date[] = [];

  constructor(options: ChartCommonOptions) {
    this.container = options.container;
    this.timeRange = options.timeRange;
    this.tz = options.tz;
    this.t = options.t;
    this.currentLang = options.currentLang;
    this.variant = options.variant || ECommonVariants.PRIMARY;
    this.shouldDrawVerticalLine = options.shouldDrawVerticalLine;
  }

  /**
   * Initialize
   */
  init(): void {
    this.setDimension();
    this.setXTickInterval();
    this.setAxesScale();
    this.setBisect();
    this.setEventListeners();
    this.draw();
  }

  /**
   * Set dimension
   */
  setDimension(): void {
    const { width, height } = this.container.getBoundingClientRect();
    const margin =
      this.variant === ECommonVariants.PRIMARY
        ? { top: 15, right: 0, left: 0, bottom: 50 }
        : { top: 2, right: 2, left: 4, bottom: 8 };
    this.dimensions = {
      width,
      height,
      margin,
    };
  }

  /**
   * Set tick interval
   */
  setXTickInterval(): void {}

  /**
   * Set axes scale
   */
  setAxesScale(): void {}

  /**
   * Set bisect
   */
  setBisect(): void {
    this.bisect = d3.bisector((d: TStatsTrafficDP | TStatsOccupancyDP) =>
      convertTZ(dayjs(d.start).toDate(), this.tz),
    ).left;
  }

  /**
   * Set EventListeners
   */
  setEventListeners(): void {
    window.addEventListener('resize', this.onResize);
  }

  /**
   * Make x axis with tick values
   */
  makeXAxis(): d3.Axis<Date | d3.NumberValue> {
    this.tickDates = [];
    const xStart = this.timeRange.start;
    const xEnd = this.timeRange.end;
    const interval = this.timeRange.window;

    this.tickDates = generateDateIntervals(
      xStart.toISOString(),
      xEnd.toISOString(),
      this.tz,
      interval,
    );

    // Pop the last tick date if the end time is too close to last tick date. Otherwise last two labels will overlap
    const lastTick = last(this.tickDates)?.getTime() ?? 0;
    if (xEnd.unix() - lastTick / 1000 < interval / 3) {
      this.tickDates.pop();
    }
    if (xEnd.toDate().getTime() > lastTick) {
      this.tickDates.push(xEnd.toDate());
    }
    this.tickDates = this.tickDates.map((date) => convertTZ(date, this.tz));
    return d3.axisBottom(this.x).tickValues(this.tickDates);
  }

  /**
   * Make y axis
   */
  makeYAxis(): d3.Axis<d3.NumberValue> {
    return d3
      .axisLeft(this.y)
      .ticks(this.yAxisSegmentCount)
      .tickFormat((val, index) => (index ? `${val}` : ''));
  }

  /**
   * Draw
   */
  draw(): void {
    const { width, height } = this.dimensions;

    this.svg = d3
      .select(this.container)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .style('overflow', 'inherit');

    this.drawAxes();
    this.drawOverlay();
    this.drawChart();
    this.focus = this.svg.append('g').attr('class', 'd3-focus').style('display', 'none');
    if (this.shouldDrawVerticalLine) {
      this.drawHoverLine();
    }
  }

  /**
   * Draw axes&grid
   */
  drawAxes(): void {
    const { width, height, margin } = this.dimensions;

    // Draw axes
    this.xAxis = this.svg
      .append('g')
      .attr('class', `d3-x-axis ${this.variant}`)
      .attr('transform', `translate(0,${height - margin.bottom})`)
      .call(
        this.makeXAxis()
          .tickSize(0)
          .tickFormat((d, i) => {
            const label = dateToAxisLabel(
              d as Date,
              i,
              this.tickDates,
              dayjs(convertTZ(new Date(), this.tz)).tz(this.tz),
              this.t,
              new Intl.Locale(this.currentLang).language,
            );
            return label.time || label.date || '';
          }),
      );
    this.svg
      .append('g')
      .attr('class', `d3-x-axis d3-x-axis-date ${this.variant}`)
      .attr('transform', `translate(5,${height - margin.bottom + 16})`)
      .call(
        this.makeXAxis()
          .tickSize(0)
          .tickFormat((d, i) => {
            const label = dateToAxisLabel(
              d as Date,
              i,
              this.tickDates,
              dayjs().tz(this.tz),
              this.t,
              new Intl.Locale(this.currentLang).language,
            );
            return (label.time && label.date) || '';
          }),
      );
    this.yAxis = this.svg
      .append('g')
      .attr('class', `d3-y-axis ${this.variant}`)
      .attr('transform', `translate(${margin.left},0)`)
      .call(this.makeYAxis().tickSize(0).tickPadding(12));

    // Draw grids
    this.vGrid = this.xAxis
      .clone()
      .attr('class', 'd3-grid d3-v-grid')
      .call(
        this.makeXAxis()
          .tickSize(-height + margin.top + margin.bottom)
          // @ts-ignore
          .tickFormat(''),
      );
    this.hGrid = this.yAxis
      .clone()
      .attr('class', 'd3-grid d3-h-grid')
      .call(
        this.makeYAxis()
          .tickSize(-width + margin.right + margin.left)
          // @ts-ignore
          .tickFormat(''),
      );
  }

  /**
   * Draw chart
   */
  drawChart(): void {}

  /**
   * Draw hover line whenever mouse is over chart area
   */
  drawHoverLine(): void {
    const { height, margin } = this.dimensions;
    // Draw focus line&circle
    this.focus
      .append('line')
      .style('stroke-dasharray', '2, 2')
      .attr('class', 'd3-focus-line')
      .attr('stroke', '#666')
      .attr('stroke-width', 1)
      .attr('y1', -height + margin.top)
      .attr('y2', -margin.bottom);
    this.focus
      .append('circle')
      .attr('class', 'd3-focus-circle')
      .attr('r', 3)
      .attr('dy', 3)
      .attr('stroke', '#6A6D81')
      .attr('fill', '#24252C');
  }

  /**
   * Draw overlay
   */
  drawOverlay(): void {
    const { width, height, margin } = this.dimensions;
    this.svg
      .append('rect')
      .attr('class', 'd3-overlay')
      .attr('x', margin.left)
      .attr('y', margin.top)
      .attr('width', width - margin.right - margin.left)
      .attr('height', height - margin.bottom - margin.top)
      .on('mouseout', this.onOverlayMouseOut)
      .on('mousemove', this.onOverlayMouseMove);
  }

  /**
   * Get mouse offsets
   */
  getMouseOffsets(
    event: MouseEvent,
    data: TStatsTrafficDP[] | TStatsOccupancyDP[],
  ): [number, number, TStatsTrafficDP | TStatsOccupancyDP | undefined, number] {
    const x0 = this.x.invert(d3.pointer(event)[0]);
    const i = this.bisect(data, x0, 1);
    const d0 = data[i - 1];
    const d1 = data[i];

    let d = undefined;
    let dId = 0;
    if (d0 && d1) {
      let condition = true;
      if ('in' in d0) {
        // Traffic
        condition =
          Math.abs(
            this.x(x0) -
              this.x(
                convertTZ(
                  dayjs(d0.start)
                    .add(dayjs(d0.stop).diff(dayjs(d0.start)) / 2, 'ms')
                    .toDate(),
                  this.tz,
                ),
              ),
          ) >
          Math.abs(
            this.x(x0) -
              this.x(
                convertTZ(
                  dayjs(d1.start)
                    .add(dayjs(d1.stop).diff(dayjs(d1.start)) / 2, 'ms')
                    .toDate(),
                  this.tz,
                ),
              ),
          );
      } else {
        // Occupancy
        condition =
          Math.abs(this.x(x0) - this.x(convertTZ(d0.stop && dayjs(d0.stop).toDate(), this.tz))) >
          Math.abs(this.x(x0) - this.x(convertTZ(d1.stop && dayjs(d1.stop).toDate(), this.tz)));
      }
      d = condition ? d1 : d0;
      dId = condition ? i : i - 1;
    } else if (d0) {
      d = d0;
      dId = i - 1;
    } else if (d1) {
      d = d1;
      dId = i;
    }

    let offsetX = 0;
    let offsetY = 0;
    if (d) {
      if ('in' in d) {
        // Traffic
        offsetX =
          (this.x(convertTZ(dayjs(d.start).toDate(), this.tz)) +
            this.x(convertTZ(dayjs(d.stop).toDate(), this.tz))) /
            2 || 0;
        offsetY = this.y(d.in || 0);
      } else {
        // Occupancy
        offsetX = this.x(convertTZ(dayjs(d.stop).toDate(), this.tz)) || 0;
        offsetY = this.y(d.value || 0);
      }
    }

    return [offsetX, offsetY, d, dId];
  }

  /**
   * Listener window resize
   */
  onResize = (): void => {
    this.svg?.remove();
    this.setDimension();
    this.setXTickInterval();
    this.setAxesScale();
    this.draw();
  };

  /**
   * Listener mouseout event for overlay
   */
  onOverlayMouseOut = (): void => {
    this.onMouseOut();

    this.focus.style('display', 'none');
  };

  /**
   * Listener mousemove event for overlay
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onOverlayMouseMove = (event: MouseEvent): void => {};

  /**
   * Update data
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  update(data: any): void {
    this.svg?.remove();
    this.setXTickInterval();
    this.setAxesScale();
    this.draw();
  }

  /**
   * Dispose EventListeners
   */
  disposeEventListeners(): void {
    window.removeEventListener('resize', this.onResize);
  }

  /**
   * Dispose
   */
  dispose(): void {
    this.disposeEventListeners();
    this.svg?.remove();
  }
}
