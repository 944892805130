import { ApolloProvider as BaseProvider } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { ReactNode, useState } from 'react';
import { createApolloClient } from './apolloClient';

export interface ApolloProviderProps {
  children: ReactNode;
}

export function ApolloProvider({ children }: ApolloProviderProps) {
  const client = useApolloClient();

  return <BaseProvider client={client}>{children}</BaseProvider>;
}

function useApolloClient() {
  // note to developers: our ApolloProvider must be rendered within an Auth0Provider.
  const auth0 = useAuth0();

  // creates an Apollo Client instance which has access to Auth0's client methods to retrieve
  // an access token, which it needs to make requests and refresh expired sessions.
  const [client] = useState(() =>
    createApolloClient({ getToken: () => auth0.getAccessTokenSilently() }),
  );

  return client;
}
