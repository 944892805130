import { FC, SVGProps } from 'react';

const RatioIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="8" cy="8" r="7.5" fill="white" stroke="currentColor" />
      <circle cx="8" cy="8" r="4" fill="currentColor" />
    </svg>
  );
};

export default RatioIcon;
