import React, { FC, memo, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Timezone, getAllTimezones, getCountry } from 'countries-and-timezones';
import { SearchIcon } from '@heroicons/react/solid';
import Fuse from 'fuse.js';
import { useDebounce } from '@/hooks';
import { ICommonComponentProps } from '@/types';
import clsx from 'clsx';

export type TAugmentedTimezone = Timezone & {
  countryNames: string[];
};

const getAllAugmentedTimezones = (): TAugmentedTimezone[] => {
  return Object.values(getAllTimezones()).map((tz) => ({
    ...tz,
    countryNames: tz.countries.map((countryCode) => getCountry(countryCode).name),
  }));
};

interface ITimezoneDropdownContentProps extends ICommonComponentProps {
  name?: string;
  onSelect?: (timezone: Timezone) => void;
}

// TODO: a11y: make this a proper aria menu. probably requires refactoring so
// the outer dropdown is part of the total component.
export const TimezoneDropdownContent: FC<ITimezoneDropdownContentProps> = memo(
  ({ className, style, name = '', onSelect, ...rest }) => {
    const { t } = useTranslation();
    const containerRef = useRef<HTMLDivElement | null>(null);
    const allTimezones = useMemo(() => getAllAugmentedTimezones(), []);
    const searchable = useMemo(() => {
      return new Fuse(allTimezones, {
        keys: ['countries', 'countryNames', 'name', 'dstOffsetStr', 'utcOffsetStr'],
      });
    }, []);
    const [searchText, setSearchText] = useState<string>('');
    const debouncedSearchText = useDebounce(searchText, 500);
    const [timezonesShowed, setTimezonesShowed] = useState(allTimezones);

    useEffect(() => {
      if (!searchText) {
        setTimezonesShowed(allTimezones);
      } else {
        const result = searchable.search(searchText);
        setTimezonesShowed(result.map((res) => res.item));
        containerRef.current?.scrollTo(0, 0);
      }
    }, [debouncedSearchText, name]);

    return (
      <div
        ref={containerRef}
        className={clsx(
          'max-h-96 overflow-y-auto overflow-x-hidden overscroll-contain text-sm w-[350px]',
          className,
        )}
        style={style}
        {...rest}
      >
        <div className="sticky top-0 bg-white px-2 py-2 shadow-sm">
          <div className="flex justify-between rounded-full bg-product-gray100 px-4 py-2">
            <SearchIcon className="mr-2 w-4 text-product-gray400" />
            <input
              className="outline-none bg-product-gray100"
              style={{ flexGrow: 1 }}
              placeholder={t('typeToSearchTimezone')}
              value={searchText}
              onKeyDown={(e) => {
                if (e.key === ' ') {
                  e.stopPropagation();
                }
              }}
              onChange={(e) => {
                setSearchText(e.currentTarget.value);
              }}
            />
          </div>
        </div>
        {timezonesShowed.map((tz) => {
          const selected = name === tz.name;
          return (
            <button
              key={tz.name}
              className="cursor-pointer border-t border-gray-200 px-4 py-2 w-full hover:bg-product-gray100 text-left"
              onClick={() => onSelect?.(tz)}
            >
              <div className="flex justify-between">
                <span className="w-2/3 place-self-center overflow-hidden overflow-ellipsis">
                  <span className={clsx(selected && 'text-product-red500')}>{tz.name}</span>
                  <span className="ml-2 overflow-hidden truncate text-xs text-product-gray600">
                    {tz.countryNames.join(', ')}
                  </span>
                </span>
                <span
                  className={clsx(
                    'place-self-center rounded px-2 py-1',
                    selected
                      ? 'bg-product-red50 text-product-red500'
                      : 'bg-product-gray100 text-product-gray400',
                  )}
                >
                  UTC{tz.utcOffsetStr}
                </span>
              </div>
            </button>
          );
        })}
      </div>
    );
  },
);
