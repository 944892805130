import React, { FC, SVGProps } from 'react';

const HeatmapIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      aria-hidden="true"
      data-prefix="far"
      data-icon="plus"
      className="prefix__svg-inline--fa prefix__fa-dwell-heatmap prefix__fa-w-20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.612 4.87776C11.913 4.87776 12.9678 3.83059 12.9678 2.53885C12.9678 1.24711 11.913 0.199951 10.612 0.199951C9.31089 0.199951 8.25616 1.24711 8.25616 2.53885C8.25616 3.83059 9.31089 4.87776 10.612 4.87776ZM4.15775 2.86629C4.15775 3.79634 3.39834 4.5503 2.46157 4.5503C1.5248 4.5503 0.765398 3.79634 0.765398 2.86629C0.765398 1.93624 1.5248 1.18228 2.46157 1.18228C3.39834 1.18228 4.15775 1.93624 4.15775 2.86629ZM4.86405 9.88299C4.86405 10.9164 4.02027 11.7541 2.97941 11.7541C1.93856 11.7541 1.09478 10.9164 1.09478 9.88299C1.09478 8.8496 1.93856 8.01187 2.97941 8.01187C4.02027 8.01187 4.86405 8.8496 4.86405 9.88299ZM10.0477 11.0057C10.6722 11.0057 11.1785 10.5031 11.1785 9.88302C11.1785 9.26298 10.6722 8.76035 10.0477 8.76035C9.42318 8.76035 8.91691 9.26298 8.91691 9.88302C8.91691 10.5031 9.42318 11.0057 10.0477 11.0057ZM1.89613 18.8176C2.8329 18.8176 3.5923 18.0637 3.5923 17.1336C3.5923 16.2036 2.8329 15.4496 1.89613 15.4496C0.959354 15.4496 0.199951 16.2036 0.199951 17.1336C0.199951 18.0637 0.959354 18.8176 1.89613 18.8176ZM17.9625 11.8945C18.9773 11.8945 19.8 11.0777 19.8 10.0701C19.8 9.06256 18.9773 8.24577 17.9625 8.24577C16.9476 8.24577 16.125 9.06256 16.125 10.0701C16.125 11.0777 16.9476 11.8945 17.9625 11.8945ZM13.062 16.8061C13.062 18.4596 11.712 19.7999 10.0466 19.7999C8.38121 19.7999 7.03116 18.4596 7.03116 16.8061C7.03116 15.1527 8.38121 13.8123 10.0466 13.8123C11.712 13.8123 13.062 15.1527 13.062 16.8061ZM17.9624 18.3031C18.6129 18.3031 19.1403 17.7795 19.1403 17.1336C19.1403 16.4877 18.6129 15.9641 17.9624 15.9641C17.3119 15.9641 16.7845 16.4877 16.7845 17.1336C16.7845 17.7795 17.3119 18.3031 17.9624 18.3031ZM19.7057 2.86628C19.7057 3.51216 19.1784 4.03574 18.5279 4.03574C17.8773 4.03574 17.35 3.51216 17.35 2.86628C17.35 2.22041 17.8773 1.69683 18.5279 1.69683C19.1784 1.69683 19.7057 2.22041 19.7057 2.86628Z"
      />
    </svg>
  );
};

export default HeatmapIcon;
