import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/zh';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { ESQUnit, ELanguage } from '@/types';

dayjs.extend(localizedFormat);
dayjs.extend(timezone);

export const isDev = import.meta.env.VITE_DEV === 'true';

export const LOCAL_TZ = dayjs.tz.guess();

export const DEFAULT_LANG = ELanguage.EN_US;

export const DEFAULT_ORG_LOGO_NAME = 'org_default';

export const DT_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const T_FORMAT = 'HH:mm';

export const FONT_FAMILY_PER_LANG = {
  [ELanguage.EN_US]: 'Averta',
  [ELanguage.ZH_HANS]: 'OpenSans',
};

export const SQ_METRIC_TO_UNIT_CONVERSION_FEE = {
  [ESQUnit.METRIC]: 1,
  [ESQUnit.INCH]: 1550,
  [ESQUnit.FEET]: 10.7639,
};

// TODO Retouch later
export const SPACES_FOR_OCCUPANCY_HIDE = ['space_292sE9di3cHAg2Zo1zVFOZ3jRJK'];

// TODO Retouch later
export const SPACES_FOR_HEADCOUNT_NEGATIVE_HIDE = [
  'space_258DyG5fBbO0fBpipWa2SrWxsSn', // eval_251262
  'space_258EktSi5R467Zex4s1giRXGuPz', // eval_251261
  'space_258D9nUfs5MGttbwqpxLdEdgGtP', // eval_251260
  'space_258BNeqxTeXSORo0PuIKoqMCZib', // eval_251259
  'space_29vEnJfs0p1WLtd0x93A3X1UoNd', // eval_251259
  'space_258AnQ7qt8AuHrjqFIxJn7lT7IA', // eval_251258
  'space_1zTwHcERujYQKkTuNz7er3EIIa9', // eval_251070
  'space_1zTwBFAFhy5wodmPYogdIt8yW1P', // eval_251069
  'space_25RoXiE4LkfwLTvtu1oS7nWM8JW', // eval_251068
  'space_1zTgYL78adcQiuWlgiJwtopENnY', // eval_251067
  'space_27VLY22k58CcqfYAFZVf1qYOpRL', // eval_251280
  'space_27VJXzSpsw6b9hcJpe0i6TEDkVd', // eval_251281
  'space_27VLOHNRTA0w03ObuEN8OGeJ1NW', // eval_251279
  'space_27VKNEp1tq11rzioFlkdW2lHgfw', // eval_251278
  'space_28pRXF5xW0iPBLxr5v2uNXVCjOA', // eval_251278
  'space_27VK14p01ZAQky7EepIe5wBmUgG', // eval_251277
  'space_27VZ5bR35hTNGzXgpTwnSthp52w', // eval_251276
  'space_249d5tdWQ6M3r9ygHtuGhRyXj3C', // eval_251255
  'space_2EWCCyXyzeBT0ZffRRk48nFdGya', // eval_251662
];

// Fixed traffic windows in seconds
export const TRAFFIC_WINDOWS = [
  60 * 1,
  60 * 2,
  60 * 3,
  60 * 10,
  60 * 15,
  60 * 30,
  60 * 60,
  60 * 60 * 4,
  60 * 60 * 6,
  60 * 60 * 12,
  60 * 60 * 24,
  60 * 60 * 24 * 2,
  60 * 60 * 24 * 3,
  60 * 60 * 24 * 7,
  60 * 60 * 24 * 14,
  60 * 60 * 24 * 30,
];

// Fixed occupancy windows in seconds
export const OCCUPANCY_WINDOWS = [
  60 * 1,
  60 * 2,
  60 * 3,
  60 * 5,
  60 * 10,
  60 * 30,
  60 * 60,
  60 * 60 * 2,
  60 * 60 * 4,
  60 * 60 * 8,
  60 * 60 * 12,
  60 * 60 * 24,
  60 * 60 * 24 * 2,
  60 * 60 * 24 * 7,
];

export const TRAFFIC_MAX_DATA_COUNTS = 20;

export const OCCUPANCY_MAX_DATA_COUNTS = 200;

export const DETECTIONS_WINDOW = 20; // in seconds

export const DATE_TIME_FORMAT = {
  zh: {
    monthDate: 'MMMD日',
    hourMinute: 'Ah时mm分',
    hour: 'Ah时',
    fullDateTime: 'YYYY年MMMD日Ahh时mm分',
  },
  en: {
    monthDate: 'MMM D',
    hourMinute: 'h:mmA',
    hour: 'hA',
    fullDateTime: 'hh:mm A MMM D YYYY',
  },
};

export const DATE_FORMAT = 'YYYY-MM-DD';

export const DATE_ISO8601_FORMAT = 'YYYY-MM-DDTHH:mm:ss[Z]';

export const ZERO_TIME = '0001-01-01T00:00:00Z';
