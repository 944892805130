import { SubmitButton } from '@/components';
import { useAppDispatch } from '@/redux/hooks';
import { useSRZId } from '@/hooks/useOrg';
import { gql, useMutation } from '@apollo/client';
import { datadogRum } from '@datadog/browser-rum';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { GET_SPACE_STATE } from '@/components/studio/ScopingEditor/useGetState';
import { baseApi } from '@/hooks/api/base';
import { Button } from '@/components/primitives';
import { closeDialog, setDialog } from '@/redux/reducers/dialog';
import { SidebarFloorDocument } from '@/.generated/graphql';

const DELETE_SPACE_MUTATION = gql`
  mutation deleteFloors($ids: [String!]) {
    deleteFloors(ids: $ids) {
      id: floor_id
      name
    }
  }
`;

export function DeleteSpaceDialog() {
  const { spaceId } = useSRZId();
  const dispatch = useAppDispatch();
  const [deleteSpace] = useMutation(DELETE_SPACE_MUTATION, {
    refetchQueries: [GET_SPACE_STATE, SidebarFloorDocument],
    variables: {
      spaceId,
    },
  });
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  return (
    <form
      className="flex flex-col gap-4"
      onSubmit={async (ev) => {
        ev.preventDefault();
        setLoading(true);
        // leaving "space" action here for historical comparison, but transitioning to
        // new naming convention for actions
        datadogRum.addAction('Delete space', { source: 'studio-on-web' });
        datadogRum.addAction('Delete floor', { source: 'studio-on-web' });
        await deleteSpace({
          variables: {
            ids: [spaceId],
          },
        });
        dispatch(baseApi.util.invalidateTags([{ type: 'Space', id: spaceId }, 'Space']));
        history.push({ pathname: '/', search: '' });
        setLoading(false);
        dispatch(closeDialog());
      }}
    >
      <h1 className="text-xl font-semibold">Are you sure you want to delete this floor?</h1>
      <p className="text-xs text-product-gray900">
        This floor will be deleted permanently. You can&apos;t undo this action. All floor and
        device configuration will be lost.
      </p>
      <div className="flex justify-end gap-4">
        <Button type="button" variant="ghost" onClick={() => dispatch(closeDialog())}>
          Cancel
        </Button>
        <SubmitButton
          color="red"
          loading={loading}
          loadingContent="Deleting"
          className="!rounded-md"
        >
          Delete
        </SubmitButton>
      </div>
    </form>
  );
}

export function DeleteSpaceButton() {
  const dispatch = useAppDispatch();
  const onClick = async () =>
    dispatch(
      setDialog({
        content: <DeleteSpaceDialog />,
      }),
    );
  return (
    <Button variant="outline" onClick={onClick}>
      Delete Floor
    </Button>
  );
}
