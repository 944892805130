import { getButtonClassName } from './getButtonClassName';
import { Slot } from '@radix-ui/react-slot';
import { twMerge } from 'tailwind-merge';
import { HTMLAttributes, ReactNode, forwardRef } from 'react';

export type ButtonSize = 'md' | 'sm' | 'xs';
export type ButtonColor = 'black' | 'red';
export type ButtonVariant = 'solid' | 'normal' | 'outline' | 'ghost';

// merely extending "HTMLElement"'s attributes here to make typing compatible
// with any asChild usage necessary.
export interface ButtonProps extends HTMLAttributes<HTMLElement> {
  /** We currently support a subset of design system button sizes: md (default), sm, and xs. */
  size?: ButtonSize;
  /** Color controls the overall palette of a button. We have "black" and "red" palettes. Use `variant` for different visual styles like "outline" or "ghost" */
  color?: ButtonColor;
  /** Variants represent different visual priority for buttons. In order of priority: "solid" (primary), "normal" (secondary), "outline" ('third'), "ghost" ('fourth') */
  variant?: ButtonVariant;
  /** Loading is presently an alias for disabled and disables the button. In the future this may have more semantic meaning. */
  loading?: boolean;
  /** Pass true to "mask" this component over a child you pass in. This visually styles the child as a Button and composes behavior. */
  asChild?: boolean;
  /** Customize the styling of this button further based on whatever variants you've specified in props. */
  className?: string;
  /** Makes the button non-interactive and visually de-emphasized. */
  disabled?: boolean;
  children?: ReactNode;
  /** HTML type controls how a button behaves in a form. We default to 'button', making 'submit' an explicit opt-in behavior. */
  type?: 'button' | 'submit' | 'reset';
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  {
    className,
    color = 'black',
    size = 'md',
    variant = 'solid',
    disabled,
    loading,
    children,
    asChild,
    // defaulting to "button" - this makes it easier to use
    // buttons inside forms without having to remember to turn off
    // "submit" type.
    type = 'button',
    ...props
  },
  ref,
) {
  const Comp = asChild ? Slot : 'button';

  return (
    <Comp
      ref={ref as any}
      {...props}
      type={type}
      disabled={disabled || loading}
      data-variant={variant}
      data-color={color}
      data-size={size}
      // twMerge allows users of this component to override styles
      // without worrying about specificity
      className={twMerge(getButtonClassName({ color, size, variant }), className)}
    >
      {children}
    </Comp>
  );
});
