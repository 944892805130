import clsx from 'clsx';
import { SliderStateOptions, useSliderState } from 'react-stately';
import { AriaSliderProps, useNumberFormatter, useSlider } from 'react-aria';
import { FC, memo, useRef } from 'react';
import { ICommonComponentProps } from '@/types';
import { Thumb } from './Thumb';

interface ISliderProps
  extends ICommonComponentProps,
    Omit<SliderStateOptions<number>, 'numberFormatter'>,
    AriaSliderProps<number> {
  tooltipLabel?: string;
  onPointerDown?: () => void;
}

export const Slider: FC<ISliderProps> = memo(
  ({ className, style, tooltipLabel, onPointerDown, ...rest }) => {
    const trackRef = useRef(null);
    const numberFormatter = useNumberFormatter();
    const state = useSliderState({ ...rest, numberFormatter });
    const { groupProps, trackProps, labelProps, outputProps } = useSlider(rest, state, trackRef);

    return (
      <div
        {...groupProps}
        className={clsx(
          'relative flex',
          state.orientation === 'horizontal' && 'w-60 flex-col',
          state.orientation === 'vertical' && 'h-60',
          className,
        )}
        style={style}
      >
        {/* Create a container for the label and output element. */}
        {rest.label && (
          <div className="flex justify-between">
            <label {...labelProps}>{rest.label}</label>
            <output {...outputProps}>{state.getThumbValueLabel(0)}</output>
          </div>
        )}
        {/* The track element holds the visible track line and the thumb. */}
        <div
          {...trackProps}
          ref={trackRef}
          className={clsx(
            'before:absolute before:block before:bg-gray-100 before:content-[attr(x)]',
            state.orientation === 'horizontal' &&
              'h-auto w-full before:top-1/2 before:h-2 before:w-full before:-translate-y-1/2 before:rounded',
            state.orientation === 'vertical' &&
              'h-full w-auto before:left-1/2 before:h-full before:w-2 before:-translate-x-1/2 before:rounded',
            state.isDisabled && 'opacity-50',
          )}
          onPointerDown={onPointerDown}
        >
          <Thumb state={state} trackRef={trackRef} tooltipLabel={tooltipLabel} />
        </div>
      </div>
    );
  },
);
