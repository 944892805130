import React, { memo, FC } from 'react';
import { PrimaryRefreshIcon, AutoTooltip } from '@/components';
import { ICommonComponentProps } from '@/types';
import clsx from 'clsx';
import dayjs, { Dayjs } from 'dayjs';
import { sentenceCase } from 'change-case';
import { useTranslation } from 'react-i18next';
import { useTime } from '@/redux/hooks';

function RefreshTimestamp() {
  const { t } = useTranslation();
  const time = useTime();
  const ts = time ? dayjs(time.currentDate) : time;
  return <>{ts ? sentenceCase(t('updated', { timeAgo: ts?.fromNow() })) : null}</>;
}

interface IPrimaryRefreshProps extends ICommonComponentProps {
  refreshing?: boolean;
  refreshTime?: Dayjs;
  onClick?: () => void;
}

export const PrimaryRefresh: FC<IPrimaryRefreshProps> = memo(
  ({ className, refreshing, style, onClick, ...rest }) => {
    return (
      <AutoTooltip label={<RefreshTimestamp />}>
        <button
          data-id="primaryRefreshButton"
          type="button"
          className={clsx(
            'border-full relative flex flex-row items-center justify-center gap-1 p-2 hover:bg-gray-100',
            className,
          )}
          style={style}
          onClick={onClick}
          {...rest}
        >
          <div className={refreshing ? 'animate-spin' : ''}>
            <PrimaryRefreshIcon width={16} />
          </div>
        </button>
      </AutoTooltip>
    );
  },
);
