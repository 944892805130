import {
  ButtonGroup,
  EnterFullscreenIcon,
  ExitFullscreenIcon,
  KeyboardIcon,
  MinusIcon,
  OrbitIcon,
  PlusIcon,
  QuestionTooltip,
  ReplayBar,
  SpaceVisualizationOptions,
  ToggleButton,
  VisionIcon,
} from '@/components';
import { HOT_KEYS } from '@/constants';
import { useSpaceVisualizerActivitySensors, useGetDetectionsState } from '@/hooks';
import {
  EQuestionTooltipKey,
  ESV_Vision_Options,
  ICommonComponentProps,
  ISV_Options,
} from '@/types';
import { getTooltipContent } from '@/utils';
import clsx from 'clsx';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

interface IBottomActionBarProps extends ICommonComponentProps {
  detailPanelOpen: boolean;
  isPresentationIdle: boolean;
  options: ISV_Options;
  hasFloorplan: boolean;
  onVizItemToggle: (option: ESV_Vision_Options, value: boolean) => void;
  onOrbitModeToggle: () => void;
  onZoomIn: () => void;
  onZoomOut: () => void;
  onPresentationModeToggle: () => void;
}

export const BottomActionBar = ({
  className,
  style,
  detailPanelOpen,
  isPresentationIdle,
  options,
  hasFloorplan,
  onVizItemToggle,
  onOrbitModeToggle,
  onZoomIn,
  onZoomOut,
  onPresentationModeToggle,
  ...rest
}: IBottomActionBarProps) => {
  const { t } = useTranslation();
  const { isSuccess: isSuccessDetections, isFetching: isFetchingDetections } =
    useGetDetectionsState();
  // filter by room, partition into activity, headcount, and entry
  const sensors = useSpaceVisualizerActivitySensors();
  const toolsRef = useRef<HTMLDivElement | null>(null);

  return (
    <div
      className={clsx(
        'pointer-events-auto absolute bottom-0 right-0 flex w-full items-end justify-end gap-2 p-8',
        className,
      )}
      style={style}
      {...rest}
    >
      {/* Replay bar */}
      {options.visionOptions[ESV_Vision_Options.SPACE_VISUALIZATIONS] &&
        (options.visionOptions[ESV_Vision_Options.DETECTION_REPLAY] ||
          options.visionOptions[ESV_Vision_Options.UTILIZATION_HEATMAP]) &&
        isSuccessDetections &&
        !isFetchingDetections && (
          <ReplayBar
            detailPanelOpen={detailPanelOpen}
            toolsRef={toolsRef}
            isPresentationIdle={isPresentationIdle}
          />
        )}
      {/* Tools */}
      {!isPresentationIdle && (
        <div ref={toolsRef} className="flex gap-2">
          <ButtonGroup
            content={[
              {
                buttonContent: <VisionIcon className="w-5 fill-current" />,
                tooltipLabel: t('showHide'),
                buttonProps: { 'aria-label': 'Visibility' },
                popover: (
                  <div className="min-w-300px overflow-hidden rounded border border-product-gray100">
                    {Object.entries(options.visionOptions).map(([option, value]) => {
                      // Since heatmap and detection replay mode are sub options of space visualization, skip them
                      if (
                        option === ESV_Vision_Options.UTILIZATION_HEATMAP ||
                        option === ESV_Vision_Options.DETECTION_REPLAY
                      )
                        return null;

                      let val = value;
                      let disabled = false;

                      if (option === ESV_Vision_Options.FLOOR_PLAN_IMAGE) {
                        val = value && hasFloorplan;
                        disabled = !hasFloorplan;
                      }

                      // Disable some toggles when there is no presence sensors in the space
                      if (
                        option === ESV_Vision_Options.SENSOR_COVERAGE_AREAS ||
                        option === ESV_Vision_Options.SPACE_VISUALIZATIONS
                      ) {
                        val = value && !!sensors.length;
                        disabled = !sensors.length;
                      }

                      return (
                        <div key={option}>
                          <div className="flex h-11 items-center justify-between border border-product-gray100 bg-white px-5 text-sm group-hover:text-product-gray900 group-focus:text-product-gray900">
                            <div className={clsx('flex gap-2')}>
                              <span>{t(option as any)}</span>
                              {option === ESV_Vision_Options.SPACE_VISUALIZATIONS && (
                                <QuestionTooltip
                                  {...getTooltipContent(
                                    t,
                                    EQuestionTooltipKey.SPACE_VISUALIZATIONS_TOGGLE,
                                  )}
                                  placement="top"
                                  variant="outline"
                                  size="short"
                                />
                              )}
                            </div>
                            <ToggleButton
                              value={val}
                              onClick={() => onVizItemToggle(option as ESV_Vision_Options, !val)}
                              disabled={disabled}
                            />
                          </div>
                          {option === ESV_Vision_Options.SPACE_VISUALIZATIONS && val && (
                            <SpaceVisualizationOptions
                              options={options.visionOptions}
                              optionClassName="border bg-gray-50 border-product-gray100 pl-10 border-t-transparent border-b-transparent"
                              onChange={onVizItemToggle}
                            />
                          )}
                        </div>
                      );
                    })}
                  </div>
                ),
                portalId: 'space-visualizer-container',
              },
              {
                buttonContent: <KeyboardIcon className="w-5 fill-current" />,
                tooltipLabel: t('keyActions'),
                buttonProps: { 'aria-label': 'Keyboard shortcuts' },
                popover: (
                  <div className="min-w-300px">
                    {HOT_KEYS.map((hotKey, index) => {
                      return (
                        <div
                          key={index}
                          className={clsx(
                            'flex h-11 items-center justify-between border border-product-gray100 bg-white px-5 text-sm group-hover:text-product-gray900 group-focus:text-product-gray900',
                            index === HOT_KEYS.length - 1 && 'rounded-b-md',
                          )}
                        >
                          <span>{t(hotKey[0])}</span>
                          <span className="rounded-md bg-product-gray50 px-3 py-1 text-xxs text-product-gray600">
                            {t(hotKey[1])}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                ),
                portalId: 'space-visualizer-container',
              },
            ]}
          />
          <ButtonGroup
            className="w-12"
            content={[
              {
                buttonContent: <OrbitIcon className="w-5 fill-current" />,
                tooltipLabel: t('orbit'),
                onClick: onOrbitModeToggle,
                active: options.orbitMode,
                buttonProps: { 'aria-label': 'Pan' },
                portalId: 'space-visualizer-container',
              },
            ]}
          />
          <ButtonGroup
            content={[
              {
                buttonContent: <MinusIcon className="w-4 fill-current" />,
                tooltipLabel: t('zoomOut'),
                onClick: onZoomOut,
                buttonProps: { 'aria-label': 'Zoom out' },
                portalId: 'space-visualizer-container',
              },
              {
                buttonContent: <span className="w-11">{`${options.zoom.toFixed(0)}%`}</span>,
                disabledOnHover: true,
                portalId: 'space-visualizer-container',
              },
              {
                buttonContent: <PlusIcon className="w-4" />,
                tooltipLabel: t('zoomIn'),
                onClick: onZoomIn,
                buttonProps: { 'aria-label': 'Zoom in' },
                portalId: 'space-visualizer-container',
              },
            ]}
          />
          <ButtonGroup
            className="w-12"
            content={[
              {
                buttonContent: options.presentMode ? (
                  <ExitFullscreenIcon className="w-5 fill-current" />
                ) : (
                  <EnterFullscreenIcon className="w-5 fill-current" />
                ),
                tooltipLabel: options.presentMode ? t('exitFullScreen') : t('fullScreen'),
                onClick: onPresentationModeToggle,
                active: options.presentMode,
                buttonProps: { 'aria-label': 'Present' },
                portalId: 'space-visualizer-container',
              },
            ]}
          />
        </div>
      )}
    </div>
  );
};
