import { AutoTooltip, QuestionCircleOutlineIcon, QuestionCircleSolidIcon } from '@/components';
import { ICommonComponentProps } from '@/types';
import { Placement } from '@floating-ui/react';
import clsx from 'clsx';
import { ReactNode } from 'react';

interface IQuestionTooltipProps extends ICommonComponentProps {
  title?: string;
  description?: ReactNode;
  color?: string;
  placement?: Placement;
  variant?: 'solid' | 'outline';
  size?: 'long' | 'short';
  zIndex?: number;
}

export const QuestionTooltip = ({
  variant = 'outline',
  title,
  description,
  size = 'long',
  ...rest
}: IQuestionTooltipProps) => {
  return (
    <AutoTooltip
      className="gap-2.5 align-top"
      variant="white"
      label={
        <div
          className={clsx(
            'flex flex-col text-xs text-gray-600',
            size === 'long' ? 'max-w-400px' : 'max-w-240px',
          )}
        >
          {title && <p className="whitespace-nowrap font-bold">{title}</p>}
          <div className="font-normal">{description}</div>
        </div>
      }
      {...rest}
    >
      {variant === 'outline' ? (
        <QuestionCircleOutlineIcon
          className={clsx('mb-0.5 inline cursor-pointer', rest.className)}
          width="14px"
          fill={rest.color || '#A8AAB8'}
        />
      ) : (
        <QuestionCircleSolidIcon
          className={clsx('mb-0.5 inline cursor-pointer', rest.className)}
          width="14px"
          fill={rest.color || '#A8AAB8'}
        />
      )}
    </AutoTooltip>
  );
};
