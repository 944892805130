import clsx from 'clsx';

/**
 * Renders a text string with part of it bolded to indicate a text match for search results.
 */
function TextMatchDisplay({
  className,
  text,
  match,
  variant,
}: {
  className?: string;
  /** Text to render */
  text: string;
  /** String to compare (match) against. It will match `text` if it's a substring of `text`. */
  match: string;
  variant?: 'highlight' | 'bold';
}) {
  const index = text.toLowerCase().indexOf(match.toLowerCase());
  if (index === -1) {
    return (
      <span
        className={clsx(variant === 'highlight' ? '' : 'text-gray-500 line-clamp-2', className)}
      >
        {text}
      </span>
    );
  }
  const before = text.slice(0, index);
  const matchText = text.slice(index, index + match.length);
  const after = text.slice(index + match.length);
  return (
    <span className={clsx(variant === 'highlight' ? '' : 'text-gray-500 line-clamp-2', className)}>
      {before}
      <span className={clsx('text-gray-900', variant === 'highlight' && 'bg-yellow-300 rounded')}>
        {matchText}
      </span>
      {after}
    </span>
  );
}

export default TextMatchDisplay;
