import React, { FC, SVGProps } from 'react';

/** @deprecated use <Icon name="..." /> (import from @/components/primitives/icons/Icon) instead. */
const SvgComponent: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      aria-hidden="true"
      data-prefix="far"
      data-icon="presentation"
      className="prefix__svg-inline--fa prefix__fa-user prefix__fa-w-14"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M9.61169 3.6001L4.99744 3.6001C4.11853 3.6001 3.59997 4.10986 3.59997 4.99756L3.59997 9.58545C3.59998 10.0513 3.95154 10.4028 4.42615 10.4028C4.89197 10.4028 5.22595 10.0601 5.22595 9.58545L5.22595 8.92627L5.05896 6.13135L7.15955 8.3374L9.65564 10.8599C9.80505 11.0181 10.0072 11.0972 10.2269 11.0972C10.7279 11.0972 11.097 10.7544 11.097 10.2534C11.097 10.0249 11.0092 9.81396 10.851 9.65576L8.34607 7.15088L6.13123 5.05029L8.93494 5.22607L9.61169 5.22607C10.0863 5.22607 10.4379 4.89209 10.4467 4.41748C10.4467 3.94287 10.0863 3.6001 9.61169 3.6001Z" />
      <path d="M19.4338 14.1118C19.4338 13.6372 19.7766 13.2856 20.2424 13.2856C20.717 13.2856 21.0598 13.646 21.0598 14.1118L21.0598 18.7876C21.0598 19.6665 20.55 20.1851 19.6624 20.1851L14.9602 20.1851C14.4856 20.1851 14.134 19.8423 14.134 19.3677C14.134 18.8931 14.4944 18.5591 14.9602 18.5591L15.7249 18.5591L18.5286 18.7261L16.3137 16.6343L13.7649 14.0767C13.5979 13.9185 13.5188 13.7075 13.5188 13.4702C13.5188 12.9692 13.8879 12.6353 14.3889 12.6353C14.5999 12.6353 14.802 12.7144 14.9602 12.8726L17.5002 15.439L19.6096 17.645L19.4338 14.8589L19.4338 14.1118Z" />
    </svg>
  );
};

export default SvgComponent;
