import { MouseEvent, FC, memo, useState } from 'react';
import { ICommonComponentProps, ISensor } from '@/types';
import { CheckIcon } from '@/components';
import { divideArrayAlmostEvenly } from '@/utils';
import clsx from 'clsx';

const SensorButton = ({
  color,
  onClick,
  id,
  sensors,
  selected,
  name,
  mac_address,
}: {
  color?: string;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  id: string;
  sensors: ISensor[];
  selected?: boolean;
  name?: string;
  mac_address: string;
}) => {
  const [sensorButtonHover, setSensorButtonHover] = useState(false);
  return (
    <button
      data-id="sensorViewButtonToggle"
      className={clsx(
        'flex h-10 w-full flex-row items-center justify-center rounded-xl border border-gray-300',
        sensors.length <= 3 && 'w-[355px]',
        sensors.length === 4 && 'w-[264px]',
        sensors.length >= 5 && 'w-[210px]',
      )}
      style={{
        backgroundColor: selected || sensorButtonHover ? color + '11' : 'transparent',
        borderColor: selected || sensorButtonHover ? color + '11' : '#E0E0E6',
      }}
      value={id}
      onMouseEnter={() => setSensorButtonHover(true)}
      onMouseLeave={() => setSensorButtonHover(false)}
      onClick={(e: MouseEvent<HTMLButtonElement>) => {
        onClick?.(e);
        setSensorButtonHover(false);
      }}
    >
      <div className="flex flex-row items-center gap-2 px-2.5">
        <span
          className="h-3 w-3 rounded-sm border"
          style={{
            backgroundColor: selected ? color : '#FFFFFF',
            borderColor: selected ? color : '#E0E0E6',
          }}
        >
          <CheckIcon
            className="my-[1px] ml-[0.6px] mr-[1.4px] h-[9px] w-[9px] bg-transparent fill-current"
            style={{ color: selected ? 'white' : 'transparent' }}
          />
        </span>
        <span className="text-xs font-medium text-black">
          {name || mac_address.substr(mac_address.length - 8, 8)}
        </span>
      </div>
    </button>
  );
};

interface ISensorButtonViewProps extends ICommonComponentProps {
  sensors: ISensor[];
  selectedSensors: ISensor[];
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
}

const SensorButtonRow: FC<ISensorButtonViewProps> = memo(
  ({ className, style, sensors, selectedSensors, onClick, ...rest }) => {
    return (
      <div
        className={clsx(
          'mb-2.5 flex w-full flex-row gap-3',
          className,
          sensors.length <= 2 && 'justify-center',
          sensors.length >= 3 && 'justify-evenly',
        )}
        style={style}
        {...rest}
      >
        {sensors.map(({ id, name, mac_address, color }) => (
          <SensorButton
            key={id}
            id={id}
            name={name}
            mac_address={mac_address}
            color={color}
            sensors={sensors}
            selected={selectedSensors.some((s) => s.id === id)}
            onClick={onClick}
          />
        ))}
      </div>
    );
  },
);

export const SensorButtonView: FC<ISensorButtonViewProps> = memo(
  ({ className, style, sensors, selectedSensors, onClick, ...rest }) => {
    const dividedSensors = divideArrayAlmostEvenly(sensors, 5);
    return (
      <>
        {dividedSensors.map((sensorRow, index) => (
          <SensorButtonRow
            key={index}
            {...{ className, style, selectedSensors, onClick, ...{ sensors: sensorRow }, ...rest }}
          />
        ))}
      </>
    );
  },
);
