import { configureStore, PreloadedState } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import { baseApi } from '@/hooks/api/base';
import { setupListeners } from '@reduxjs/toolkit/query';

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  const store = configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }).concat(baseApi.middleware),
  });

  setupListeners(store.dispatch);
  return store;
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
