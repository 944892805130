import React, { useEffect, useMemo, useState } from 'react';
import { Menu } from 'antd';
import { useSidebarSiteQuery } from '@/.generated/graphql';
import { Icon } from '../primitives/icon/Icon';
import { SearchInput } from '../primitives/SearchInput';
import { updateUrlParams } from './utils';
import { useHistory, useLocation } from 'react-router-dom';
import { MenuFoldOutIcon } from '../Icons';
import { CreateSpaceButton } from '@/pages/HomePage/OrganizationPage/Sidebar/CreateSpaceButton';
import { TextMatchDisplay } from '@/components/primitives/TextMatchDisplay';
import { BuildingEditMenu } from './BuildingEditMenu';
import { SiteEditMenu } from './SiteEditMenu';
import { datadogRum } from '@datadog/browser-rum';
import { gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { getTypeFromId } from '../studio/utils/utils';

interface SidebarMenuProps {
  onSidebarOpenClose: (open: boolean) => void;
  sidebarOpen: boolean;
}
type MenuItem = {
  key: string;
  label: string | React.ReactNode;
  type?: any;
  children?: MenuItem[];
};

const SidebarMenu: React.FC<SidebarMenuProps> = ({ onSidebarOpenClose, sidebarOpen }) => {
  const { data: siteData } = useSidebarSiteQuery({
    fetchPolicy: 'network-only',
  });
  const [filterValue, setFilterValue] = React.useState('');
  const history = useHistory();
  const [selectedKey, setSelectedKey] = useState<string | null>(null);
  const onToggleSidebar = () => {
    onSidebarOpenClose(false);
  };
  const location = useLocation();
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const floorId = params.get('spaceId');
    const roomId = params.get('roomId');
    const zoneId = params.get('zoneId');
    if (roomId) {
      setSelectedKey(roomId);
    } else if (zoneId) {
      setSelectedKey(zoneId);
    } else if (floorId) {
      setSelectedKey(floorId);
    } else {
      setSelectedKey(null);
    }
  }, [location]);
  const filteredItems: MenuItem[] | undefined = useMemo(() => {
    return siteData?.sites?.data
      .map((site) => ({
        ...site,
        buildings: site?.buildings
          ?.map((building) => ({
            ...building,
            floors: building?.floors
              ?.map((floor) => {
                // Combine rooms and zones into a single array
                const combined = [
                  ...(floor?.rooms
                    ?.map((room) => {
                      // Add zones to the room object if they have a matching roomId
                      const zonesForRoom =
                        floor?.zones?.filter((zone) => zone.roomId === room.id) ?? [];
                      return {
                        ...room,
                        zones: zonesForRoom,
                      };
                    })
                    .filter(
                      (room) =>
                        room.name?.toLowerCase().includes(filterValue.toLowerCase()) ||
                        room.zones.some(
                          (zone) => zone.name?.toLowerCase().includes(filterValue.toLowerCase()),
                        ),
                    ) ?? []),
                  ...(floor?.zones
                    ?.filter((zone) => !zone.roomId)
                    .filter(
                      (zone) => zone.name?.toLowerCase().includes(filterValue.toLowerCase()),
                    ) ?? []),
                ];
                combined.sort((a, b) => a.name.localeCompare(b.name)); //sort rooms and zones
                return {
                  ...floor,
                  roomszones: combined,
                };
              })
              .filter(
                (floor) =>
                  floor.name?.toLowerCase().includes(filterValue.toLowerCase()) ||
                  (floor.roomszones && floor.roomszones.length > 0),
              )
              ?.sort((a, b) => a.name.localeCompare(b.name)), //sort floors
          }))
          .filter(
            (building) =>
              building?.name?.toLowerCase().includes(filterValue.toLowerCase()) ||
              building?.floors?.length > 0,
          )
          ?.sort((a, b) => a.name.localeCompare(b.name)), //sort buildings
      }))
      .filter(
        (site) =>
          site?.name?.toLowerCase().includes(filterValue.toLowerCase()) ||
          site?.buildings?.length > 0,
      )
      ?.sort((a, b) => a.name.localeCompare(b.name)) //sort sites
      .map((site) => ({
        label: (
          <span
            className="text-xs min-w-0 overflow-hidden text-ellipsis text-product-gray500 font-semibold leading-5 flex justify-between"
            data-id="sidebar-nav-site-item"
          >
            <TextMatchDisplay
              text={`Site - ${site.name}`}
              match={filterValue}
              variant="highlight"
            ></TextMatchDisplay>
            <SiteEditMenu id={site.id} blockDelete={site.buildings && site.buildings.length > 0} />
          </span>
        ),
        type: 'group',
        key: site.id,
        children: site.buildings?.map((building) => ({
          label: (
            <div className="flex w-full justify-between pl-4 overflow-hidden text-ellipsis hover:rounded-r-full">
              <div className="flex items-center">
                <TextMatchDisplay
                  variant="highlight"
                  text={building.name}
                  match={filterValue}
                  className="overflow-hidden text-ellipsis whitespace-nowrap mr-4"
                />
              </div>
              <span className="mr-4 flex items-center">
                {' '}
                <BuildingEditMenu
                  id={building.id}
                  blockDelete={building.floors && building.floors.length > 0}
                />
              </span>
            </div>
          ),
          key: building.id,
          children: building.floors.map((floor) => ({
            label: (
              <div
                className="flex items-center pl-8 overflow-hidden text-ellipsis hover:rounded-r-full"
                onClick={() => updateUrlParams(history, { floorId: floor.id })}
              >
                <Icon name="layer" className="mr-2" />
                <TextMatchDisplay
                  variant="highlight"
                  text={floor.name}
                  match={filterValue}
                  className="overflow-hidden text-ellipsis whitespace-nowrap mr-4"
                />
              </div>
            ),
            key: floor.id,
            children: [
              ...(floor?.roomszones?.map((item) => ({
                label: (
                  <div
                    className="flex items-center pl-12 overflow-hidden whitespace-nowrap text-ellipsis"
                    onClick={() => {
                      getTypeFromId(item.id) === 'room'
                        ? updateUrlParams(history, { floorId: item.floorId, roomId: item.id })
                        : updateUrlParams(history, { floorId: item.floorId, zoneId: item.id });
                    }}
                  >
                    <Icon
                      name={getTypeFromId(item.id) === 'room' ? 'room' : 'zone'}
                      className="mr-2"
                    />
                    <TextMatchDisplay
                      variant="highlight"
                      text={item.name}
                      match={filterValue}
                      className="overflow-hidden text-ellipsis whitespace-nowrap mr-4"
                    />{' '}
                  </div>
                ),
                key: item.id,
                //zones within rooms
                ...(floor.zones &&
                  floor?.zones?.filter((zone) => zone.roomId === item.id).length > 0 && {
                    children: floor.zones
                      .filter((zone) => zone.roomId === item.id)
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .filter(
                        (zone) => zone.name?.toLowerCase().includes(filterValue.toLowerCase()),
                      )
                      .map((zone) => ({
                        label: (
                          <div className="flex items-center pl-[64px]">
                            <Icon name="zone" className="mr-2" />
                            <TextMatchDisplay
                              variant="highlight"
                              text={zone.name}
                              match={filterValue}
                              className="overflow-hidden text-ellipsis whitespace-nowrap mr-4"
                            />
                          </div>
                        ),
                        key: zone.id,
                        expandicon: <></>,
                        onClick: () => {
                          updateUrlParams(history, { floorId: zone.floorId, zoneId: zone.id });
                        },
                      })),
                  }),
              })) || []),
            ],
          })),
        })),
      }));
  }, [siteData, filterValue, history]);

  return (
    <>
      <div className="flex gap-2 px-3 py-2 items-center">
        <MenuFoldOutIcon
          onClick={onToggleSidebar}
          className="w-4 h-4 m-2 text-product-gray900 cursor-pointer"
          color="black"
        />
        <SearchInput
          data-id="search-input"
          value={filterValue}
          onFocus={() => {
            datadogRum.addAction('Activate new sidebar search', { source: 'sites-and-buildings' });
          }}
          onClear={() => {
            setFilterValue('');
          }}
          onChange={(ev) => {
            setFilterValue(ev.target.value);
          }}
        />{' '}
        {!sidebarOpen && <CreateSpaceButton data-id="create-new-floor" variant="compact" />}
      </div>

      {filterValue && filteredItems?.length === 0 ? (
        <NullSearch />
      ) : (
        <div className="flex flex-col h-[86vh] min-h-0">
          <Menu
            data-id="sidebar-menu"
            inlineIndent={0}
            mode="inline"
            className="flex-grow border-r-0 max-h-screen overflow-auto"
            items={filteredItems}
            selectedKeys={selectedKey ? [selectedKey] : []}
          />
          {sidebarOpen && (
            <div className="">
              <div>
                <hr className="border-t border-gray-200 m-4" />

                <div className="flex justify-end m-4">
                  {' '}
                  <CreateSpaceButton data-id="create-new-floor" />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default SidebarMenu;

export function NullSearch() {
  const { t } = useTranslation();
  return (
    <div className="flex justify-center items-center">
      <div className="flex flex-col items-center gap-2 px-3 py-2 w-[216px]">
        <span className="text-xs font-semibold">{t('noResults')}</span>
        <span className="text-[10px] text-center">{t('searchAction')}</span>
      </div>
    </div>
  );
}

export const SidebarMenuZoneFragment = gql`
  fragment SidebarMenuZone on Zone {
    id: zone_id
    name
    floorId: floor_id
    roomId: room_id
  }
`;

export const SidebarMenuRoomFragment = gql`
  fragment SidebarMenuRoom on Room {
    id: room_id
    name
    floorId: floor_id
  }
`;
export const SidebarMenuFloorFragment = gql`
  fragment SidebarMenuFloor on Floor {
    id: floor_id
    name
    rooms {
      id: room_id
      ...SidebarMenuRoom
    }
    zones {
      id: zone_id
      ...SidebarMenuZone
    }
  }
  ${SidebarMenuRoomFragment}
  ${SidebarMenuZoneFragment}
`;

export const SidebarMenuBuildingFragment = gql`
  fragment SidebarMenuBuilding on Building {
    id
    name
    floors {
      id: floor_id
      ...SidebarMenuFloor
    }
  }
  ${SidebarMenuFloorFragment}
`;

export const SidebarMenuSiteFragment = gql`
  fragment SidebarMenuSite on Site {
    id
    name
    buildings {
      id
      ...SidebarMenuBuilding
    }
  }
  ${SidebarMenuBuildingFragment}
`;

export const SidebarMenuFloorQuery = gql`
  query SidebarFloor {
    floors {
      data {
        id: floor_id
        ...SidebarMenuFloor
      }
    }
  }
  ${SidebarMenuFloorFragment}
`;

export const SidebarMenuSiteQuery = gql`
  query SidebarSite {
    sites {
      data {
        id
        ...SidebarMenuSite
      }
    }
  }
  ${SidebarMenuSiteFragment}
`;
