import OrganizationLogo from '@/components/OrganizationLogo/logo';
import { PageLayoutSidebarLogoContainer } from '@/components/PageLayout';
import { UserMenu } from '@/pages/HomePage/OrganizationPage/Header/UserMenu';
import { QuestionMenu } from '@/components';
import { useAuthUser } from '@/hooks';
import { useHistory } from 'react-router-dom';

const DashboardHeader = () => {
  const { user } = useAuthUser();
  const history = useHistory();

  const navigateToTab = () => {
    history.push({ pathname: '/', search: '' });
  };
  return (
    <div className="bg-product-gray900 flex justify-between items-center">
      <PageLayoutSidebarLogoContainer>
        <OrganizationLogo onClick={navigateToTab} className="cursor-pointer" />
      </PageLayoutSidebarLogoContainer>
      <div className="flex items-center gap-2">
        <QuestionMenu />
        {user && <UserMenu user={user} />}
      </div>
    </div>
  );
};

export default DashboardHeader;
