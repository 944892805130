import React, { FC, SVGProps } from 'react';

/** @deprecated use <Icon name="..." /> (import from @/components/primitives/icons/Icon) instead. */
const SvgComponent: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      aria-hidden="true"
      data-prefix="far"
      data-icon="presentation"
      className="prefix__svg-inline--fa prefix__fa-user prefix__fa-w-14"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M5.08496 11.097H9.69922C10.5781 11.097 11.0967 10.5873 11.0967 9.69959V5.11169C11.0967 4.64587 10.7451 4.29431 10.2705 4.29431C9.80469 4.29431 9.4707 4.63709 9.4707 5.11169V5.77087L9.63769 8.5658L7.53711 6.35974L5.04102 3.83728C4.8916 3.67908 4.68945 3.59998 4.46973 3.59998C3.96875 3.59998 3.59961 3.94275 3.59961 4.44373C3.59961 4.67224 3.6875 4.88318 3.8457 5.04138L6.35059 7.54626L8.56543 9.64685L5.76172 9.47107H5.08496C4.61035 9.47107 4.25879 9.80505 4.25 10.2797C4.25 10.7543 4.61035 11.097 5.08496 11.097ZM14.3357 19.5345C14.8016 19.5345 15.1443 19.183 15.1443 18.7084V17.9613L14.9686 15.1752L17.0779 17.3812L19.618 19.9476C19.7762 20.1058 19.9783 20.1849 20.1893 20.1849C20.6902 20.1849 21.0594 19.851 21.0594 19.35C21.0594 19.1127 20.9803 18.9017 20.8133 18.7435L18.2645 16.1859L16.0496 14.0941L18.8533 14.2611H19.618C20.0838 14.2611 20.4441 13.9271 20.4441 13.4525C20.4441 12.9779 20.0926 12.6351 19.618 12.6351H14.9158C14.0281 12.6351 13.5184 13.1537 13.5184 14.0326V18.7084C13.5184 19.1742 13.8611 19.5345 14.3357 19.5345Z" />
    </svg>
  );
};

export default SvgComponent;
