import React, { FC, SVGProps } from 'react';

/** @deprecated use <Icon name="..." /> (import from @/components/primitives/icons/Icon) instead. */
const SvgComponent: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="3"
      height="12"
      viewBox="0 0 3 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.5001 2.79299C2.26023 2.79299 2.8501 2.20313 2.8501 1.43691C2.8501 0.695022 2.24807 0.086914 1.5001 0.086914C0.770368 0.086914 0.150097 0.695022 0.150097 1.43691C0.150097 2.17272 0.770368 2.79299 1.5001 2.79299ZM1.5001 7.34772C2.26023 7.34772 2.8501 6.75786 2.8501 5.99772C2.8501 5.25583 2.24807 4.6538 1.5001 4.6538C0.770367 4.6538 0.150097 5.25583 0.150097 5.99772C0.150097 6.73353 0.770367 7.34772 1.5001 7.34772ZM1.5001 11.9146C2.26023 11.9146 2.8501 11.3247 2.8501 10.5646C2.8501 9.81664 2.24807 9.20853 1.5001 9.20853C0.770367 9.20853 0.150097 9.81664 0.150097 10.5646C0.150097 11.2943 0.770367 11.9146 1.5001 11.9146Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SvgComponent;
