import { useCallback, useState, useContext } from 'react';
import { useAuth0, LogoutOptions, User } from '@auth0/auth0-react';
import { UserContext, UserContextValue } from '@/contexts';
import { API_BASE_URL } from '@/constants';

/**
 * Hooks for user
 * @returns user&username
 */
export const useAuthUser = (): { user?: User; name?: string } & UserContextValue => {
  const { user } = useAuth0();
  const { clientId, roles, isSuperUser } = useContext(UserContext);

  const name =
    (user?.nickname || user?.name) === 'verizon-ihub' ? 'Verizon' : user?.nickname || user?.name;

  return { user, name, clientId, roles, isSuperUser };
};

/**
 * Hooks for proceeding auth logout
 * @returns logout function
 */
export const useAuthLogout = (): (() => void) => {
  const { logout } = useAuth0();

  // Trigger logout
  const triggerLogout = useCallback(() => {
    const logoutOptions: LogoutOptions = {
      returnTo: window.location.origin,
      client_id: import.meta.env.VITE_AUTH0_CLIENT_ID,
    };
    try {
      logout(logoutOptions);
    } catch (error) {
      console.error(error);
    }
  }, [logout]);

  return triggerLogout;
};

/**
 * Hooks for proceeding auth password change
 * @returns change password
 */
export const useAuthChangePassword = (): {
  loadingChangePassword: boolean;
  triggerChangePassword: () => void;
} => {
  // Maybe, if we need to store some states related to this action, should consider saga in the future
  const [loadingChangePassword, setLoadingChangePassword] = useState(false);

  const auth0 = useAuth0();

  // Trigger change password
  const triggerChangePassword = useCallback(async () => {
    setLoadingChangePassword(true);

    try {
      const token = await auth0.getAccessTokenSilently();
      const response = await fetch(`${API_BASE_URL}/api/v2/users/password-change/url`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`Reset password failed: ${response.status}: ${response.url}`);
      }

      const body = await response.json();
      const ticket = body.ticket;

      if (ticket) {
        window.open(ticket, '', '');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingChangePassword(false);
    }
  }, [auth0]);

  return { loadingChangePassword, triggerChangePassword };
};
