import { THive, ISpace, IRoom, IZone, TXYPoint, TXYZPoint } from '@/types';

export enum ESensorMode {
  PRESENCE = 'presence',
  TRAFFIC = 'traffic',
  REPEATER = 'repeater',
}

export enum ESensorModel {
  HEATIC1 = 'Heatic 1',
  HEATIC2 = 'Heatic 2',
  HEATIC2_PLUS = 'Heatic 2+',
  HEATIC2_WIRED = 'Heatic Wired',
  UNKNOWN = '',
}

export interface ISensor {
  id: string;
  center: TXYPoint;
  height: number;
  mac_address: string;
  name?: string;
  mode: ESensorMode;
  isEntrance: boolean;
  hive?: THive;
  color?: string;
  space?: Partial<ISpace>;
  room?: Partial<IRoom>;
  zone?: Partial<IZone>;
  orientation?: TXYZPoint;
  effective_field_of_view?: number;
  field_of_view: number;
  parallel_to_door?: boolean;
  sensitivity?: number;
  last_battery_change_date?: string;
  next_battery_change_date?: string;
  // ... more
}

export type TSensorDirection = 'downward' | 'leftward' | 'upward' | 'rightward';
