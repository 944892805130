import { useState } from 'react';
import { LoadingSpinner } from '../LoadingSpinner';
import clsx from 'clsx';
import { Button, ButtonProps } from '@/components/primitives';

// TODO: plug this into a form library instead of requiring
// manual state management
export function SubmitButton({
  className,
  loading,
  onClick,
  loadingContent,
  children,
  disabled,
  ...rest
}: Omit<ButtonProps, 'onClick'> & {
  className?: string;
  loading?: boolean;
  loadingContent?: React.ReactNode;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent) => void | Promise<void>;
}) {
  const [submitting, setSubmitting] = useState(false);
  return (
    <Button
      type="submit"
      className={clsx(className)}
      disabled={disabled}
      onClick={async (e) => {
        try {
          setSubmitting(true);
          const onClickResult = onClick?.(e);
          if (onClickResult instanceof Promise) {
            await onClickResult;
          }
        } finally {
          setSubmitting(false);
        }
      }}
      {...rest}
    >
      <span className="flex gap-1">
        {loading ?? submitting ? (
          <>
            <LoadingSpinner size={20} style={{ fill: 'white' }} />
            {loadingContent}
          </>
        ) : (
          children
        )}
      </span>
    </Button>
  );
}
