import { ForwardedRef, forwardRef, createRef } from 'react';
import clsx from 'clsx';
import { useDateFieldState, DateSegment, DateFieldState } from '@react-stately/datepicker';
import { useDateField, useDateSegment } from '@react-aria/datepicker';
import { DateValue } from '@react-types/calendar';
import { AriaDatePickerProps } from '@react-types/datepicker';
import { createCalendar } from '@internationalized/date';
import { useTranslation } from 'react-i18next';
import { Icon } from '@/components/primitives/icon/Icon';

function splitDateSegments(segments: DateSegment[]): {
  dateSegments: DateSegment[];
  timeSegments: DateSegment[];
} {
  segments = segments.filter((segment) => segment.type !== 'timeZoneName');
  const indexToSplit = segments.findIndex((segment) => segment.text.includes(','));
  return {
    dateSegments: segments.slice(0, indexToSplit),
    timeSegments: segments.slice(indexToSplit + 1),
  };
}

interface IDateFieldProps extends AriaDatePickerProps<DateValue> {
  untilNow?: boolean;
}

export function DateField({ value, maxValue, ...props }: IDateFieldProps) {
  const state = useDateFieldState({
    ...props,
    maxValue,
    value: value && maxValue && value.compare(maxValue) > 0 ? maxValue : value,
    locale: 'en-US',
    createCalendar,
  });

  const ref = createRef<HTMLDivElement>();
  const { fieldProps } = useDateField(props, state, ref);
  const { dateSegments, timeSegments } = splitDateSegments(state.segments);
  const { t } = useTranslation();
  return (
    <div>
      <StyledField {...fieldProps} ref={ref}>
        <Icon name="calendar" className="ml-0.5 mr-1" size={15} />
        {dateSegments.map((segment, i) => (
          <WrappedDateSegment key={i} segment={segment} state={state} />
        ))}
      </StyledField>
      <div className="inline-flex w-2" />
      <StyledField {...fieldProps} ref={ref}>
        <Icon name="time" className="ml-0.5 mr-1" size={15} />
        {props.untilNow ? (
          <div className="ml-1" role="term" aria-label="End now">
            {t('now')}
          </div>
        ) : (
          timeSegments.map((segment, i) => (
            <WrappedDateSegment key={i} segment={segment} state={state} />
          ))
        )}
      </StyledField>
    </div>
  );
}

interface IStyledFieldProps {
  children: any;
}

export const StyledField = forwardRef(
  (props: IStyledFieldProps, ref: ForwardedRef<HTMLDivElement>) => {
    return (
      <div
        className="relative inline-flex w-[136px] items-center rounded-md border-gray-300 bg-gray-50 px-1 py-2 pr-2 text-gray-900 transition-all hover:border-gray-400"
        ref={ref}
      >
        {props.children}
      </div>
    );
  },
);

interface IWrappedDateSegment {
  state: DateFieldState;
  segment: DateSegment;
  key: number;
}

function WrappedDateSegment({ segment, state }: IWrappedDateSegment) {
  const ref = createRef<HTMLDivElement>();
  const { segmentProps } = useDateSegment(segment, state, ref);
  return (
    <div
      className={clsx(
        'outline-none rounded-md px-0.5 text-right leading-4 focus:bg-red-400 focus:text-white',
        segment.isPlaceholder && 'text-gray-500',
        !segment.isPlaceholder && !segment.isEditable && 'text-gray-600',
        !segment.isPlaceholder && segment.isEditable && 'text-product-gray700',
      )}
      {...segmentProps}
      ref={ref}
    >
      {segment.text}
    </div>
  );
}
