import { ImpersonateFloorQueryResult } from '@/.generated/graphql';
import { API_BASE_URL } from '@/constants';
import { gql, useApolloClient } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';

/**
 * Superusers only. Calls the impersonation API to rewrite the
 * user's metadata to grant access to the specified Client ID.
 * This is used to impersonate a client and access their data.
 * A user can only have one Client ID assigned at a time, so this
 * moves them to a different "account" in the application.
 *
 * Calling this function automatically redirects the user to the
 * dashboard for the new client.
 */
export function useImpersonateClient() {
  const auth0 = useAuth0();
  const apollo = useApolloClient();

  return useCallback(
    async (clientId: string) => {
      const token = await auth0.getAccessTokenSilently();
      const response = await fetch(`${API_BASE_URL}/api/v2/superusers/impersonate`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ client_id: clientId, refresh_token: 'fake' }),
      });

      // here's the thing... we expect this to fail. this API was originally designed
      // to require a refresh_token from Auth0, and automatically refresh the access
      // token and return the new one for us. However, we've since abandoned digging
      // into Auth0's internals to get the refresh token and/or set the access token
      // directly, so we can't do either of those things anymore. Instead, we just
      // ask Auth0 to refresh our token for us.
      // This means we ignore a 401 error here, which arises from not providing a
      // refresh_token parameter. The API will do the rest of the functionality even
      // though this error is thrown.
      // A `response.ok` path is included here just for caution's sake, but we
      // will never hit it in practice.
      if (response.ok || response.status === 401) {
        try {
          // we must now refresh our auth0 token
          await auth0.getAccessTokenSilently({
            ignoreCache: true,
          });
          // we also set the new client ID in localstorage
          window.sessionStorage.setItem('client_id', clientId);

          // and now we are logged into the new account, so we can access
          // the floors and determine which one to redirect to
          const { data } = await apollo.query<ImpersonateFloorQueryResult['data']>({
            query: ImpersonateFloorQuery,
            fetchPolicy: 'network-only',
          });
          const firstFloor = [...(data?.floors?.data ?? [])].sort((a, b) =>
            a.name.localeCompare(b.name),
          )[0];
          const path = '/' + (firstFloor ? `?spaceId=${firstFloor.id}` : '');
          // eslint-disable-next-line no-console
          console.info(`Redirecting to ${path}`);
          // finally, reload the page and navigate to the floor
          window.location.assign(path);
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error('Failed to switch accounts', err);
          // we might be in a bad state, so just reload the page
          window.location.reload();
        }
      } else {
        // eslint-disable-next-line no-console
        console.error('Failed to switch accounts', response.status, await response.text());
        throw new Error('Failed to switch accounts');
      }
    },
    [auth0, apollo],
  );
}

const ImpersonateFloorQuery = gql`
  query ImpersonateFloor {
    floors {
      data {
        id: floor_id
        name
      }
    }
  }
`;
