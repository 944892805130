import React, { FC, SVGProps } from 'react';

/** @deprecated use <Icon name="..." /> (import from @/components/primitives/icons/Icon) instead. */
const SvgComponent: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="14"
      height="14"
      aria-hidden="true"
      data-prefix="far"
      data-icon="plus"
      className="prefix__svg-inline--fa prefix__fa-user prefix__fa-w-14"
      viewBox="0 0 14 14"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.89994 1.60001C7.89994 1.10296 7.49699 0.700012 6.99994 0.700012C6.50288 0.700012 6.09994 1.10295 6.09994 1.60001V6.10001H1.59995C1.1029 6.10001 0.699951 6.50296 0.699951 7.00001C0.699951 7.49707 1.1029 7.90001 1.59995 7.90001H6.09994V12.4C6.09994 12.8971 6.50288 13.3 6.99994 13.3C7.49699 13.3 7.89994 12.8971 7.89994 12.4V7.90001H12.4C12.897 7.90001 13.3 7.49707 13.3 7.00001C13.3 6.50296 12.897 6.10001 12.4 6.10001H7.89994V1.60001Z"
      />
    </svg>
  );
};

export default SvgComponent;
