import { Route, Switch } from 'react-router-dom';
import { useUser } from '@/hooks';
import { LoadingSpinner } from '@/components';
import LandingPage from '@/pages/HomePage';
import { ErrorPage } from '@/pages/ErrorPage';
import { isDev } from './constants';

function MainPage() {
  const { isAuthenticated, isLoading } = useUser();
  if (isLoading || !isAuthenticated) {
    return <LoadingSpinner />;
  }
  return <LandingPage />;
}

function Routing() {
  return (
    <Switch>
      <Route
        exact
        path={['/', '/devices', '/space-visualizer', '/studio', ...(isDev ? ['/demo'] : [])]}
      >
        <MainPage />
      </Route>
      <Route>
        <ErrorPage error={{ message: '404', name: '' }} resetErrorBoundary={() => {}} />
      </Route>
    </Switch>
  );
}

export default Routing;
