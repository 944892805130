import React, { FC, SVGProps } from 'react';

const InfoIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.59961 7.99998C1.59961 4.46535 4.46499 1.59998 7.99961 1.59998C11.5342 1.59998 14.3996 4.46535 14.3996 7.99998C14.3996 11.5346 11.5342 14.4 7.99961 14.4C4.46499 14.4 1.59961 11.5346 1.59961 7.99998ZM7.99961 2.76361C5.10765 2.76361 2.76325 5.10801 2.76325 7.99998C2.76325 10.8919 5.10765 13.2363 7.99961 13.2363C10.8916 13.2363 13.236 10.8919 13.236 7.99998C13.236 5.10801 10.8916 2.76361 7.99961 2.76361Z"
        fill="currentColor"
      />
      <mask id="path-2-inside-1_2448_9713" fill="white">
        <ellipse cx="7.99971" cy="10.4" rx="0.8" ry="0.8" />
      </mask>
      <ellipse cx="7.99971" cy="10.4" rx="0.8" ry="0.8" fill="currentColor" />
      <path
        d="M7.79971 10.4C7.79971 10.2895 7.88925 10.2 7.99971 10.2V12.2C8.99382 12.2 9.79971 11.3941 9.79971 10.4H7.79971ZM7.99971 10.2C8.11016 10.2 8.19971 10.2895 8.19971 10.4H6.19971C6.19971 11.3941 7.0056 12.2 7.99971 12.2V10.2ZM8.19971 10.4C8.19971 10.5104 8.11016 10.6 7.99971 10.6V8.59998C7.0056 8.59998 6.19971 9.40586 6.19971 10.4H8.19971ZM7.99971 10.6C7.88925 10.6 7.79971 10.5104 7.79971 10.4H9.79971C9.79971 9.40586 8.99382 8.59998 7.99971 8.59998V10.6Z"
        fill="currentColor"
        mask="url(#path-2-inside-1_2448_9713)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.0002 4.79999C8.44202 4.79999 8.8002 5.15816 8.8002 5.59999L8.8002 7.99999C8.8002 8.44182 8.44202 8.79999 8.0002 8.79999C7.55837 8.79999 7.2002 8.44182 7.2002 7.99999L7.2002 5.59999C7.20019 5.15816 7.55837 4.79999 8.0002 4.79999Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default InfoIcon;
