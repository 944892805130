export const enterFullscreen = (dom: HTMLElement | null) => {
  if (dom === null) return;

  if (dom.requestFullscreen) {
    dom.requestFullscreen();
    // @ts-ignore
  } else if (dom.webkitRequestFullscreen) {
    /* Safari */
    // @ts-ignore
    dom.webkitRequestFullscreen();
    // @ts-ignore
  } else if (dom.msRequestFullscreen) {
    /* IE11 */
    // @ts-ignore
    dom.msRequestFullscreen();
  }
};

export const exitFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
    // @ts-ignore
  } else if (document.webkitExitFullscreen) {
    /* Safari */
    // @ts-ignore
    document.webkitExitFullscreen();
    // @ts-ignore
  } else if (document.msExitFullscreen) {
    /* IE11 */
    // @ts-ignore
    document.msExitFullscreen();
  }
};
