import { useAuthUser } from '@/hooks/useAuth';
import LOGO_CENTURY from '/century.png';
import { LogoIcon } from '@/components/Icons';

export interface OrganizationLogoProps {}

// THIS IS A HACK, DATA SHOULD COME FROM SERVER
// TODO: REMOVE THIS AND MOVE TO BACKEND
const centuryLogo = { src: LOGO_CENTURY, width: 120 };

const logoMappings: Record<string, typeof centuryLogo> = {
  client_0d4d752cd84a10a7b67c008fcc8c2fde76eaa4f1: centuryLogo, // 042
  client_0d4d753ea944e7f78a8cee9ebece0eaf83ed1281: centuryLogo, // 043
  client_0d4d75462a6be7dc65db3228bf2b8f0b9c7ab836: centuryLogo, // 044
  client_0d4d755bac9460b03f3c852bdf03742a3d754d95: centuryLogo, // 046
  client_0d4d756a8739abdb0a09908a4f6818f0cc963152: centuryLogo, // 047
  client_0d4d7e31c49f608171376d4826cafdde6bb59eec: centuryLogo, // 067
  client_24stEgzM8rbQE8OOQ7OGzMLOTXe: centuryLogo, // 068
  client_0d4d7e439d520cea7cd388643cb9e42211570b0a: centuryLogo, // 069
  client_0d4d7e4e7f1c8eb7e16786bbd01664a3480d7450: centuryLogo, // 070
  client_249clstv9sYlJ8kyBWQQDQG6CCn: centuryLogo, // 255
  client_257JdooiKPSLPdldb7s5wb9idd6: centuryLogo, // 258
  client_257Jp7PPH66fa9T03y6cpuLfJvp: centuryLogo, // 259
  client_257JsCOq7jIcYPLZ68v7V5JsSs9: centuryLogo, // 260
  client_257JutMv4FlfHv5jA8wXtP7N9YJ: centuryLogo, // 261
  client_257JyMNVpzcYjhoPC5nkSqP0qzc: centuryLogo, // 262
  client_27ULmjbxnOSHapA5xgEhFa3rUoq: centuryLogo, // 276
  client_27UM1WKb1oJQkMXBtjJ6xNilXf0: centuryLogo, // 277
  client_27UM8rzVnbZHVU5RHVa4gLtqXXx: centuryLogo, // 278
  client_27UMGEib71HihMKGD69ftJ6voIC: centuryLogo, // 279
  client_27UMMV4sUjFb2DJB7f1woA749qJ: centuryLogo, // 280
  client_27UMU31Af00kuaSq67NzKsNUEFM: centuryLogo, // 281
  client_2DpR1bqOVOhHVd3M0ZBMg10vLpL: centuryLogo, // 662
  client_2DpYWuxV2PXXh0lauT2fBV7dPdt: centuryLogo, // 663
};

export function OrganizationLogo(props: OrganizationLogoProps) {
  const { clientId } = useAuthUser();

  // Determine logo based on clientId
  if (clientId) {
    const lm = logoMappings[clientId];
    if (lm) {
      return <img src={lm.src} width={lm.width} alt="" {...props} />;
    }
  }

  return <LogoIcon width={64} {...props} />;
}

export default OrganizationLogo;
