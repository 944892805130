import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ITime } from '@/types';
import { useGetDetectionsSZState, useLazyGetDetectionsSZQuery } from './api/detection';
import { useSRZId } from './useOrg';
import { useTime } from '@/redux/hooks';
import { updateTimeRange } from '@/redux/reducers/time';
import dayjs from 'dayjs';
import { EDateRangePickerType } from '@/components/primitives/DateTimePicker';
import { SVRenderer } from '@/components/SpaceVisualizer';
import { gql } from '@apollo/client';
import { useDetectionsActivitySensorsQuery } from '@/.generated/graphql';

/**
 * Hooks for fetching detections with progress
 */
export const useGetDetectionsWithProgress = (): {
  progress: number;
  load: (time: ITime) => void;
} => {
  const dispatch = useDispatch();
  const { roomId, spaceId } = useSRZId();
  const sensors = useSpaceVisualizerActivitySensors();
  const [trigger] = useLazyGetDetectionsSZQuery({
    refetchOnFocus: false,
  });
  const [progress, setProgress] = useState<number>(0);

  const macAddresses = sensors.map((s) => s.macAddress);

  // Action for loading detections
  const load = useCallback(
    ({ detection: { timeRange }, timezone }: ITime) => {
      if (!spaceId || !macAddresses) return;

      trigger({
        data_types: ['detections_local'],
        spaceId,
        roomId,
        mac_addresses: macAddresses,
        start: dayjs(timeRange.startEpoch).tz(timezone).utc().format(),
        stop: dayjs(timeRange.endEpoch).tz(timezone).utc().format(),
        onStart: () => setProgress(0),
        onProgress: setProgress,
        onEnd: () => setProgress(100),
      });
      dispatch(
        updateTimeRange({
          range: timeRange,
          type: EDateRangePickerType.DETECTION,
        }),
      );
    },
    [dispatch, roomId, macAddresses, spaceId, trigger],
  );

  return { progress, load };
};

/**
 * Hooks for getting detections cache data
 */
export const useGetDetectionsState = () => {
  const { roomId, spaceId } = useSRZId();
  const sensors = useSpaceVisualizerActivitySensors();
  const time = useTime();

  return useGetDetectionsSZState(
    {
      data_types: ['detections_local'],
      // assertion is validated by skip below
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      spaceId: spaceId!,
      roomId,
      mac_addresses: sensors.map((s) => s.macAddress) || [],
      // assertion is validated by skip below
      start: dayjs(time?.detection.timeRange.startEpoch)
        .utc()
        .format(),
      // assertion is validated by skip below
      stop: dayjs(time?.detection.timeRange.endEpoch)
        .utc()
        .format(),
    },
    { skip: !spaceId || !sensors || !time },
  );
};

// getting only activity sensors
export function useSpaceVisualizerActivitySensors() {
  const { roomId, spaceId: floorId } = useSRZId();
  const { data } = useDetectionsActivitySensorsQuery({
    // asserted by skip
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { floorId: floorId! },
    skip: !floorId,
  });
  // filter to room and activity sensors
  const sensors = useMemo(() => {
    const rawSensors = data?.floors?.data?.[0]?.sensors || [];
    return rawSensors.filter((s) => s.roomId === roomId && s.mode === 'activity');
  }, [data, roomId]);

  return sensors;
}
useSpaceVisualizerActivitySensors.queries = {
  Sensors: gql`
    query DetectionsActivitySensors($floorId: String!) {
      floors(ids: [$floorId]) {
        data {
          id: floor_id
          sensors {
            id: sensor_id
            roomId: room_id
            mode
            macAddress: mac_address
            ...SpaceVisualizerRendererSensor
          }
        }
      }
    }
    ${SVRenderer.fragments.Sensor}
  `,
};
