import { useApolloClient } from '@apollo/client';
import { useState, createContext, Dispatch, SetStateAction, useContext, useEffect } from 'react';

export type RoleDefinition = 'Superuser' | 'Devices' | 'Zones' | 'StudioWeb';

export type UserContextValue = {
  roles: RoleDefinition[];
  clientId: string;
  isSuperUser: boolean;
};

const initialValue: UserContextValue = {
  roles: [],
  clientId: '',
  isSuperUser: false,
};

/**
 * UserContext is used for app-wide user type values that are either not stored elsewhere or are harder
 * to access. It is primarily updated by useAuth.
 */
export function UserContextProvider({ children }: { children: React.ReactNode }) {
  const [value, setValue] = useState(initialValue);
  const apollo = useApolloClient();
  useEffect(() => {
    // reset apollo cache when client ID changes -
    // the same queries may return different data for different
    // clients
    apollo.resetStore();
  }, [apollo, value.clientId]);
  return <UserContext.Provider value={{ ...value, setValue }}>{children}</UserContext.Provider>;
}

export const UserContext = createContext<
  UserContextValue & {
    setValue: Dispatch<SetStateAction<UserContextValue>>;
  }
>({ ...initialValue, setValue: () => {} });

export const useUserContext = () => {
  const context = useContext(UserContext);

  return context;
};
